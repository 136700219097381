import { Component, Input, OnInit } from '@angular/core';
import { SharedDataService } from '../../services/shared-data/shared-data.service';

declare var window: any;
@Component({
    selector: 'reports-nav-menu',
    inputs: ['currentTab'],
    templateUrl: './reports-nav.component.html'
})
export class ReportsNavMenu implements OnInit {
    @Input() currentTab: string;

    navItems = [
        { id: 'export-questions-and-responses', title: 'Export Questions and Responses' }
    ];

    constructor(
        private sharedData: SharedDataService
    ) { }

    ngOnInit() {
        const env = window.location.host;
    }
}
