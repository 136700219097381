<div class="mb-3">
    <button type="button"
            class="btn btn-primary mr-2"
            (click)="saveReorderChanges()"
            [disabled]="!hasChanges">
        <i class="fa fa-save"></i>
        Save Changes
    </button>
    <button type="button"
            class="btn btn-secondary mr-2"
            (click)="cancelReorderChanges()"
            [disabled]="!hasChanges">
        <i class="fa fa-ban"></i>
        Cancel
    </button>
    <button type="button"
            class="btn btn-secondary"
            (click)="closeReorderChanges()">
        <i class="fa fa-times"></i>
        Close
    </button>
</div>
<div *ngIf="isQuestion">
    <div class="col-md-12" *ngFor="let node of rootProfileSections">
        <div class="drag-container">
            <ng-template [ngTemplateOutlet]="questionTemplateRef" [ngTemplateOutletContext]="{ section: node }"></ng-template>
        </div>
    </div>
</div>
<div *ngIf="!isQuestion" class="drag-container">
    <ng-template [ngTemplateOutlet]="sectionTemplateRef" [ngTemplateOutletContext]="{ sections: unsortedProfileSections }"></ng-template>
</div>

<!-- Define the recursive template. -->
<ng-template #questionTemplateRef let-section="section">
    <div class="form-row">
        <div class="col-auto">
            <button type="button"
                    data-toggle="button"
                    class="btn btn-secondary tree-node-toggle active"
                    aria-pressed="true"
                    (click)="toggleSection(section)">
                <i class="fa fa-tree-state text-primary"></i>
            </button>
        </div>
        <div class="col">
            <div cdkDropList id="{{section.id}}"
                 class="item-list mb-3"
                 [cdkDropListData]="section.questions"
                 [cdkDropListAutoScrollDisabled]="true"
                 [cdkDropListConnectedTo]="allProfileSectionIds"
                 (cdkDropListDropped)="drop($event)">
                <div class="section-heading-container d-flex mb-2 input-group">
                    <input class="form-control section-heading w-100" readonly [ngModel]="section.name" />
                    <a role="button"
                       class="btn btn-outline-success"
                       ngbTooltip="Reassign the display order for this section."
                       tooltipClass="tooltip-class"
                       [class.disabled]="!section.questions.length"
                       (click)="resetDisplayOrder(section)">
                        <i class="fa fa-refresh"></i>
                    </a>
                </div>
                <div *ngFor="let question of section.questions; index as i" cdkDrag
                     class="item-box collapse"
                     [class.show]="!section.isCollapsed"
                     [class.moved]="checkMoved(section, question, i)"
                     cdkDragBoundary=".drag-container"
                     [cdkDragData]="question"
                     cdkDragLockAxis="y">
                    <button *ngIf="checkMoved(section, question, i)"
                            class="btn btn-xs btn-primary mr-2"
                            ngbTooltip="Undo"
                            (click)="undoMove(null, section, question, i)">
                        <i class="fa fa-undo"></i>
                    </button>
                    <span class="question-text text-truncate">{{ getDisplayText(question) }}</span>
                    <span class="display-order" title="Display Order">{{ question.displayOrder }}</span>
                    <!--<span *ngIf="displayEditQuestion?.id != question.id" class="display-order">{{ question.displayOrder }}</span>
            <div *ngIf="displayEditQuestion?.id == question.id">
                <input [(ngModel)]="question.displayOrder" />
            </div>
            <button *ngIf="displayEditQuestion?.id != question.id"
                    class="btn btn-primary btn-xs"
                    (click)="setEditQuestion(question)">
                <i class="fa fa-pencil"></i>
            </button>
            <button *ngIf="displayEditQuestion?.id == question.id"
                    class="btn btn-outline-primary btn-xs"
                    (click)="saveDisplayQuestion(question)">
                <i class="fa fa-save"></i>
            </button>-->
                    <span *cdkDragPreview class="item-box">
                        <span class="question-text text-truncate">{{ getDisplayText(question) }}</span>
                        <span class="section-name text-truncate">{{ section.name }}</span>
                        <span class="display-order">{{ question.displayOrder }}</span>
                    </span>
                </div>
            </div>

            <div *ngIf="section.childSections.length" class="collapse" [class.show]="!section.isCollapsed">
                <ng-template ngFor let-child [ngForOf]="section.childSections">
                    <ng-template [ngTemplateOutlet]="questionTemplateRef"
                                 [ngTemplateOutletContext]="{ section: child }">
                    </ng-template>
                </ng-template>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #sectionTemplateRef let-sections="sections">
    <div cdkDropList
        (cdkDropListDropped)="drop($event)"
        [cdkDropListData]="sections"
        [cdkDropListAutoScrollDisabled]="true">
        <div *ngFor="let section of sections; index as i"
            cdkDrag
            [cdkDragData]="section"
            cdkDragLockAxis="y"
            cdkDragBoundary=".drag-container"
            class="form-row">
            <div class="col-auto">
                <button type="button"
                    data-toggle="button"
                    class="btn btn-secondary tree-node-toggle active"
                    aria-pressed="true"
                    (click)="toggleSection(section)"
                    [disabled]="!section.childSections.length">
                    <i class="fa fa-tree-state text-primary"></i>
                </button>
            </div>
            <div class="col">
                <div class="item-list mb-3">
                    <div class="section-heading-container d-flex mb-2 input-group">
                        <div class="item-box">
                            <button *ngIf="checkMoved(section, null, i)"
                                    class="btn btn-xs btn-primary mr-2"
                                    ngbTooltip="Undo"
                                    (click)="undoMove(sections, section, null, i)">
                                <i class="fa fa-undo"></i>
                            </button>
                            <span class="question-text">{{section.name}}</span>
                        </div>
                    </div>
                </div>
                <div *ngIf="section.childSections.length" class="collapse" [class.show]="!section.isCollapsed">
                    <ng-template [ngTemplateOutlet]="sectionTemplateRef" [ngTemplateOutletContext]="{ sections: section.childSections }"></ng-template>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<div *ngIf="inspectionProfile.profileSections.length > 10">
    <button type="button"
            class="btn btn-primary mr-2"
            (click)="saveReorderChanges()"
            [disabled]="!hasChanges">
        <i class="fa fa-save"></i>
        Save Changes
    </button>
    <button type="button"
            class="btn btn-secondary mr-2"
            (click)="cancelReorderChanges()"
            [disabled]="!hasChanges">
        <i class="fa fa-ban"></i>
        Cancel
    </button>
    <button type="button"
            class="btn btn-secondary"
            (click)="closeReorderChanges()">
        <i class="fa fa-times"></i>
        Close
    </button>
</div>
