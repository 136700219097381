import { Component, OnInit } from '@angular/core';
import { UnitOfWorkService } from '../../../services/unit-of-work/unit-of-work.service';
import { Router, Route, ActivatedRoute } from '@angular/router';
import { ApplicationInsightsService } from '../../../services/application-insights/application-insights.service';
import { Observable } from 'rxjs';
import { DialogHelper } from '../../../services/dialog-helper';
import { IsBusyService } from '../../../services/is-busy/is-busy.service';
import * as model from '../../../../model/model';
import * as modelDto from '../../../../model/modelDto';
import { GlobalErrorHandler } from '../../../services/global-error-handler';

const loadingKey: string = 'profile-sections:loadData';
const saveKey: string = 'profile-sections:saveChanges';
const defaultLimit: number = 15;

@Component({
    selector: 'app-profile-sections',
    templateUrl: './profile-sections.component.html',
})
export class ProfileSectionsComponent implements OnInit {

    profileSections: model.ProfileSection[] = [];
    page: number = 1;
    limit: number = defaultLimit;
    pageCount: number = 1;
    pageLimitOptions = [15, 25, 50, 100];

    filters: modelDto.GridFilter[] = [];
    nameFilter: string = null;
    profileFilter: string = null;


    constructor(
		private uow: UnitOfWorkService,
        private appInsights: ApplicationInsightsService,
        private errorHandler: GlobalErrorHandler,
		private activeRoute: ActivatedRoute,
		public router: Router,
		public isBusy: IsBusyService,
		public dialogHelper: DialogHelper
	) {
        appInsights.logPageView();
    }

    ngOnInit() {
        this.activeRoute.queryParams.subscribe(routeParams => {
            this.page = routeParams.page && !Number.isNaN(routeParams.page) ? parseInt(routeParams.page) : 1;
            this.limit = routeParams.limit && !Number.isNaN(routeParams.limit) ? parseInt(routeParams.limit) : defaultLimit;

            this.updateDataGrid();
        });
    }

    canDeactivate(): Observable<boolean> | boolean {
        if (this.uow.hasChanges.value) {
            let proceed = this.dialogHelper.confirm();

            proceed.subscribe(result => {
                if (result) {
                    this.uow.rollback();
                }
            })

            return proceed;
        }

        return true;
    }


    get activeProfileSections() {
        return this.profileSections.filter(m => !m.entityAspect.entityState.isDeleted() && !m.entityAspect.entityState.isDetached());
    }

    getProfileSections(page: number = 1, limit: number = defaultLimit) {
        return this.uow.getProfileSections(page, limit, JSON.stringify(this.filters))
            .then(result => {
                this.profileSections = result;
            });
    }

    getPageCount(limit: number = defaultLimit) {
        return this.uow.getProfileSectionsPageCount(limit, JSON.stringify(this.filters))
            .then(result => {
                this.pageCount = result[0];
            });
    }

    updateFilters(key: string, value: any) {
        if (this.filters.some(m => m.key == key)) {
            let filter = this.filters.find(m => m.key == key);
            filter.value = value;
        }
        else {
            this.filters.push({ key: key, value: value } as modelDto.GridFilter)
        }

        this.updateDataGrid();
    }

    clearFilters() {
        this.filters = [];
        this.nameFilter = null;
        this.profileFilter = null;

        this.updateDataGrid();
    }

    updateDataGrid() {
        this.isBusy.set(loadingKey, true, 'Loading data');
        Promise
            .all([
                this.getPageCount(this.limit),
                this.getProfileSections(this.page, this.limit)
            ])
            .catch(reason => {
                this.errorHandler.handleError(reason);
            })
            .finally(() => {
                this.isBusy.set(loadingKey, false);
            });
    }

    saveProfileSectionChanges(section: model.ProfileSection, message: string) {
        this.isBusy.set(saveKey, true, message);
        this.uow.profileSections.saveChanges([section])
            .then(() => {
            })
            .catch(reason => {
                this.errorHandler.handleError(reason);
            })
            .finally(() => {
                this.isBusy.set(saveKey, false);
            });
    }

    destroyProfileSection(section: model.ProfileSection) {
        if (section) {
            section.entityAspect.setDeleted();
            this.saveProfileSectionChanges(section, 'Removing profile section')
        }
    }
}
