import { Component, OnInit } from '@angular/core';
import { UnitOfWorkService } from '../../../services/unit-of-work/unit-of-work.service';
import Utilities from '../../../services/utilities';
import { Router, ActivatedRoute } from '@angular/router';
import { ApplicationInsightsService } from '../../../services/application-insights/application-insights.service';
import { Observable } from 'rxjs';
import { DialogHelper } from '../../../services/dialog-helper';
import { IsBusyService } from '../../../services/is-busy/is-busy.service';
import * as model from '../../../../model/model';
import * as modelDto from '../../../../model/modelDto';
import { GlobalErrorHandler } from '../../../services/global-error-handler';

const LOADING_KEY: string = 'profile-question:loadData';
const SAVING_KEY: string = 'profile-question:saveChanges';

@Component({
    selector: 'app-profile-question',
    templateUrl: './question-editor.component.html',
})
export class ProfileQuestionAdminComponent implements OnInit {

    inspectionProfileId: number = null;
    standardOptions: model.StandardQuestionOption[] = [];
    inspectionProfiles: modelDto.InspectionProfileDto[] = [];
    profileSections: model.ProfileSection[] = [];
    profileSectionsForQuestionOptions: model.ProfileSection[] = [];
    tags: model.Tag[] = [];

    id: string = null;
    question: model.Question;
    isLoading: boolean = true;
    wasPreviouslyUnassigned: boolean = false;

    selectedSectionId: number = null;

    constructor(private activeRoute: ActivatedRoute,
        private uow: UnitOfWorkService,
        private appInsights: ApplicationInsightsService,
        private errorHandler: GlobalErrorHandler,
        public router: Router,
		public isBusy: IsBusyService,
        public dialogHelper: DialogHelper
	) {
        appInsights.logPageView();
        this.question = uow.questions.createEntity();
    }

    ngOnInit() {
        this.activeRoute.queryParams.subscribe(routeParams => {
            this.isLoading = true;
            this.isBusy.set(LOADING_KEY, true, 'Loading data');
            Promise
                .all([
                    this.getStandardQuestionOptions(),
                    this.getInspectionProfiles(),
                    this.getSectionsForProfile(),
                    this.getTags()
                ])
                .then(() => {
                    this.getQuestion(routeParams.id);
                })
                .catch(reason => {
                    this.errorHandler.handleError(reason);
                })
                .finally(() => {
                    this.isBusy.set(LOADING_KEY, false);
                    this.isLoading = false;
                });
        });
    }

    canDeactivate(): Observable<boolean> | boolean {
        if (this.uow.hasChanges.value) {
            let proceed = this.dialogHelper.confirm();

            proceed.subscribe(result => {
                if (result) {
                    this.uow.rollback();
                }
            })

            return proceed;
        }

        return true;
    }


    getStandardQuestionOptions() {
        return this.uow.getStandardQuestionOptions()
            .then(result => {
                this.standardOptions = result;
            });
    }

    getInspectionProfiles() {
        return this.uow.getInspectionProfilesForLookup()
            .then(result => {
                this.inspectionProfiles = result;
            });
    }

    getProfileSections() {
        return this.uow.getProfileSectionsForLookup()
            .then(result => {
                this.profileSections = result;
            });
    }

    getTags() {
        return this.uow.getActiveTags()
            .then(result => {
                this.tags = result;
            });
    }

    getDisplayText(question: model.Question) {
        return question.name || question.description.substr(0, 40) + '...';
    }


    get activeInspectionProfiles() {
        return this.inspectionProfiles.sort(Utilities.sortByName);
    }

    get activeProfileSections() {
        return this.profileSections.sort(Utilities.sortByName);
    }

    get availableProfileSections() {
        return this.profileSectionsForQuestionOptions.sort(Utilities.sortByName);
    }

    get activeQuestionOptions() {
        return this.question.questionOptions.filter(m => !m.isDeleted).sort(Utilities.sortByDisplayOrder);
    }

    get activeQuestionTags() {
        return this.question.questionTags.filter(m => !m.isDeleted).sort(Utilities.sortByName);
    }

    get hasChanges() {
        const a = this.uow.hasChanges.value;
        return a;
    }

    undoChanges() {
        if (this.hasChanges) {
            this.uow.rollback();
        }
    }


    maxOptionOrder(options: model.QuestionOption[]): number {
        return options && options.length > 0 ? Math.max.apply(Math, options.map(function (o) { return o.displayOrder; })) : 0;
    }


    getSectionsForProfile() {
        if (this.inspectionProfileId) {
            if (this.question.profileSection && this.question.profileSection.inspectionProfileId !== this.inspectionProfileId) {
                this.question.profileSectionId = null;
            }

            return this.uow.getSectionsForInspectionProfile(this.inspectionProfileId)
                .then(result => {
                    this.profileSections = result;
                });
        }
        else {
            this.question.profileSectionId = null;
            this.profileSections = [];
        }
    }

    getProfileSectionsForSection() {
        if (this.question && this.question.profileSectionId > 0) {
            return this.uow.getProfileSectionsForSection(this.question.profileSectionId)
                .then(result => {
                    this.profileSectionsForQuestionOptions = result;

                    this.uow.getMaxQuestionDisplayOrder(this.question.profileSectionId)
                        .then(result => {
                            this.question.displayOrder = result[0];
                        });
                });
        }
        else {
            this.profileSectionsForQuestionOptions = [];
        }
    }

    getQuestion(id: string) {
        this.id = id;
        this.uow.questions.cancelChanges(this.question);

        if (id == 'new') {
            this.question = this.uow.questions.createEntity();
            this.question.displayOrder = 0;

            this.getProfileSectionsForSection();
        } else {
            const qid = parseInt(id);
            if (!isNaN(qid)) {
                return this.uow.getQuestionById(qid)
                    .then(result => {
                        if (result && result.length > 0) {
                            this.question = result[0];

                            if (this.question) {
                                if (this.question.profileSection) {
                                    this.inspectionProfileId = this.question.profileSection.inspectionProfileId;

                                    this.getSectionsForProfile();
                                }
                                else {
                                    this.wasPreviouslyUnassigned = true;

                                    this.getProfileSectionsForSection();
                                }
                            }
                            else {
                                this.router.navigate(['./profile-questions'], { relativeTo: this.activeRoute.parent });
                            }
                        }
                        else {
                            this.router.navigate(['./profile-questions'], { relativeTo: this.activeRoute.parent });
                        }
                    });
            }
            else {
                this.router.navigate(['./profile-questions'], { relativeTo: this.activeRoute.parent });
            }
        }
    }


    addOption() {
        const option = this.uow.questionOptions.createEntity();
        option.text = null;
        option.standardQuestionOptionId = null;
        option.displayOrder = (this.maxOptionOrder(this.question.questionOptions) || 0) + 1;
        option.question = this.question;
    }

    removeOption(option: model.QuestionOption) {
        if (confirm('Are you sure you want to remove this option?')) {
            if (option.id > 0) {
                option.isDeleted = true;
            }
            else {
                this.uow.questionOptions.cancelChanges(option);
            }
        }
    }

    checkOption(options: model.ProfileSectionQuestionOption[], section: model.ProfileSection) {
        if (options) {
            return options.some(m => m.profileSectionId == section.id);
        }

        return false;
    }

    addTriggeredSection(m: string, option: model.QuestionOption) {
        const $select = $(m);
        const id = $select.val();

        if (!option.profileSectionQuestionOptions.some(m => m.profileSectionId == id)) {
            const psqo = this.uow.profileSectionQuestionOptions.createEntity();
            const section = this.profileSectionsForQuestionOptions.find(m => m.id == id);
            if (section) {
                psqo.profileSection = section
                psqo.questionOption = option;
            }

            option.profileSectionQuestionOptions.push(psqo);
        }


        $select.find('option:selected').prop('disabled', true);

        return $select.find('option:selected').val();
    }

    removeSectionFromOption(m: string, option: model.QuestionOption, section: model.ProfileSectionQuestionOption) {
        const $select = $(m);
        $select.find('option[value="' + section.profileSectionId + '"]').prop('disabled', false);

        if (section.id > 0) {
            section.entityAspect.setDeleted();
        }
        else {
            this.uow.profileSectionQuestionOptions.cancelChanges(section);
        }
    }

    addTag(tagId: number) {
        if (!this.question.questionTags.some(m => m.tagId == tagId)) {
            const qt = this.uow.questionTags.createEntity();
            const tag = this.tags.find(m => m.id == tagId);
            qt.tag = tag;
            qt.question = this.question;
        }

        return null;
    }

    removeTag(tag: model.QuestionTag) {
        if (confirm('Are you sure you want to remove tag "' + tag.tag.name + '"?')) {
            if (tag.id > 0) {
                tag.entityAspect.setDeleted();
            }
            else {
                this.uow.questionTags.cancelChanges(tag);
            }
        }
    }


    saveChanges() {
        this.isBusy.set(SAVING_KEY, true, 'Saving question');

        // Is this a new question?
        if (this.id == 'new') {
            if (this.question.profileSectionId) {
                this.uow.getMaxQuestionDisplayOrder(this.question.profileSectionId)
                    .then(result => {
                        this.question.displayOrder = result[0];

                        return this.saveNow();
                    })
                    .catch(reason => {
                        this.errorHandler.handleError(reason);
                    })
                    .finally(() => {
                        this.isBusy.set(SAVING_KEY, false);
                    });
            }
            else {
                return this.saveNow();
            }
        }
        else {
            return this.saveNow();
        }

    }


    saveNow() {
        return this.uow.commit()
            .then(() => {
                if (this.question.profileSectionId) {
                    this.isBusy.set(SAVING_KEY, true, 'Propagating question updates to engagements');

                    return this.uow.propagateQuestionChanges(this.question.id)
                        .then(() => {
                            if (!confirm('Done! Continue editing "' + this.getDisplayText(this.question) + '"?')) {
                                this.router.navigate(['./profile-questions'], { relativeTo: this.activeRoute.parent });
                            }
                            else {
                                if (this.id == 'new') {
                                    this.router.navigate(['./profile-question'], { queryParams: { id: this.question.id }, relativeTo: this.activeRoute.parent });
                                }
                            }
                        })
                        .catch(reason => {
                            this.errorHandler.handleError(reason);
                        })
                        .finally(() => {
                            this.isBusy.set(SAVING_KEY, false);
                        });
                }
                else {
                    if (!confirm('Done! Continue editing "' + this.getDisplayText(this.question) + '"?')) {
                        this.router.navigate(['./profile-questions'], { relativeTo: this.activeRoute.parent });
                    }
                    else {
                        if (this.id == 'new') {
                            this.router.navigate(['./profile-question'], { queryParams: { id: this.question.id }, relativeTo: this.activeRoute.parent });
                        }
                    }
                }
            })
            .catch(reason => {
                this.errorHandler.handleError(reason);
            })
            .finally(() => {
                this.isBusy.set(SAVING_KEY, false);
            });
    }


    goHome() {
        this.router.navigate(['./profile-questions'], { relativeTo: this.activeRoute.parent });
    }
}
