<nav class="btn-toolbar justify-content-between my-2" role="toolbar">
    <div class="btn-group btn-group-sm" role="group">
        <button *ngIf="page <= 1" class="btn btn-outline-secondary" disabled>
            <i class="fa fa-fast-backward"></i>
        </button>
        <button *ngIf="page <= 1" class="btn btn-outline-secondary mx-1" disabled>
            <i class="fa fa-backward"></i>
        </button>
        <a [routerLink]="[actionName]" [queryParams]="{ page: 1, limit: limit }" *ngIf="page > 1" class="btn btn-outline-secondary">
            <i class="fa fa-fast-backward"></i>
        </a>
        <a [routerLink]="[actionName]" [queryParams]="{ page: page - 1, limit: limit }" *ngIf="page > 1" class="btn btn-outline-secondary mx-1">
            <i class="fa fa-backward"></i>
        </a>
        <a [routerLink]="[actionName]" [queryParams]="{ page: page + 1, limit: limit }" *ngIf="page < pageCount" class="btn btn-outline-secondary mr-1">
            <i class="fa fa-forward"></i>
        </a>
        <a [routerLink]="[actionName]" [queryParams]="{ page: pageCount, limit: limit }" *ngIf="page < pageCount" class="btn btn-outline-secondary">
            <i class="fa fa-fast-forward"></i>
        </a>
        <button *ngIf="page >= pageCount" class="btn btn-outline-secondary mr-1" disabled>
            <i class="fa fa-forward"></i>
        </button>
        <button *ngIf="page >= pageCount" class="btn btn-outline-secondary" disabled>
            <i class="fa fa-fast-forward"></i>
        </button>
    </div>
    <div class="input-group input-group-sm">
        <p class="form-control-plaintext form-control-sm">
            Show
            <a [routerLink]="[actionName]" [queryParams]="{ page: page, limit: i }" *ngFor="let i of limits" class="mx-1" [class.font-weight-bold]="i == limit">{{i}}</a>
            <span class="mx-1">|</span>
            Page <b class="mx-1">{{page}}</b> of <b class="mx-1">{{pageCount}}</b>
        </p>
    </div>
</nav>
