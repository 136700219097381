<div class="header">
    <h3>My Team's Inspections</h3>
</div>

<div class="row">
    <div class="col sidebar sidebar-tree-view">
        <div class="sidebar-inner">
            <div class="form-group">
                <button type="button" class="btn btn-sm btn-primary mr-2" (click)="reloadSections()">
                    <i class="fa fa-refresh"></i>
                    <span class="sr-only">Reload sections</span>
                </button>
                <button type="button" class="btn btn-sm btn-secondary mr-1" (click)="expandAll()">
                    <i class="fa fa-expand"></i>
                    Expand All
                </button>
                <button type="button" class="btn btn-sm btn-secondary" (click)="collapseAll()">
                    <i class="fa fa-compress"></i>
                    Collapse All
                </button>
            </div>
            <div class="inner-scroller flex-scroll">
                <ol id="engagement-sections" class="tree tree-sm">
                    <li class="tree-branch" *ngFor="let node of rootEngagementSections; index as i">
                        <engagement-tree-node-viewer [node]="node" [i]="i" [context]="this"></engagement-tree-node-viewer>
                    </li>
                </ol>
            </div>
        </div>
    </div>

    <div class="col inspection-body">
        <header class="p-3 mb-3 bg-bdo-secondary">
            <table>
                <tr>
                    <th class="pr-2">Engagement Code:</th>
                    <td colspan="3"><span class="h5">{{engagement.engagementCode}}</span></td>
                </tr>
                <tr>
                    <th class="pr-2">Client Name:</th>
                    <td colspan="3"><span class="h4">{{engagement.clientName}}</span></td>
                </tr>
                <tr>
                    <td colspan="4">&nbsp;</td>
                </tr>
                <tr>
                    <th class="pr-2">Team Captain:</th>
                    <td colspan="3">{{engagement.teamCaptain}}</td>
                </tr>
                <tr>
                    <th class="pr-2">Office:</th>
                    <td class="pr-4">{{engagement.officeName}}</td>
                    <th class="pr-2">Year:</th>
                    <td class="pr-4">{{engagement.inspectionYear}}</td>
                </tr>
                <tr>
                    <th class="pr-2">Region:</th>
                    <td class="pr-4">{{engagement.regionCode}}</td>
                </tr>
            </table>
        </header>

        <div *ngIf="currentSection == null">
            <p>Please select a section to review:</p>
            <ul>
                <li *ngFor="let node of rootSections">
                    <section-list-item [node]="node" [context]="this"></section-list-item>
                </li>
            </ul>
            <hr />
            <a [routerLink]="['/team-inspections']" class="btn btn-primary">
                <i class="fa fa-reply"></i>
                Return to inspections list
            </a>
        </div>

        <div *ngIf="currentSection != null">
            <h5>
                <span class="text-muted mr-2">Section:</span>{{currentSection.name}}
            </h5>

            <div class="form-group">
                <label>
                    <i class="fa fa-users"></i>
                    Assigned To:
                </label>
                <p class="form-text" *ngIf="!currentSection.inspectionTeamMembers || currentSection.inspectionTeamMembers.length == 0">
                    None
                </p>
                <ul class="pl-4" *ngIf="currentSection.inspectionTeamMembers && currentSection.inspectionTeamMembers.length > 0">
                    <li *ngFor="let teamMember of currentSection.inspectionTeamMembers">
                        {{teamMember.fullName}}
                    </li>
                </ul>
            </div>

            <div class="form-group">
                <button type="button"
                        class="btn btn-sm btn-primary mr-1"
                        [disabled]="parentSection == null"
                        (click)="setActiveSection(parentSection)">
                    <i class="fa fa-level-up"></i>
                    <span class="sr-only">Up one level</span>
                </button>
                <button type="button" class="btn btn-sm btn-secondary mr-2" (click)="closeSection()">
                    <i class="fa fa-times"></i>
                    Close section
                </button>
                <button type="button"
                        class="btn btn-sm btn-secondary mr-1"
                        [disabled]="previousSection == null"
                        (mouseenter)="setHoverText(previousSection)"
                        (mouseleave)="setHoverText(null)"
                        (click)="setActiveSection(previousSection)">
                    <i class="fa fa-arrow-left"></i>
                    <span class="sr-only">Prev</span>
                </button>
                <button type="button"
                        class="btn btn-sm btn-secondary"
                        [disabled]="nextSection == null"
                        (mouseenter)="setHoverText(nextSection)"
                        (mouseleave)="setHoverText(null)"
                        (click)="setActiveSection(nextSection)">
                    <span class="sr-only">Next</span>
                    <i class="fa fa-arrow-right"></i>
                </button>
                <em class="mx-2">{{hoverSectionName}}</em>
            </div>

            <hr />

            <div *ngIf="currentQuestion != null">
                <div class="form-group" *ngIf="currentSection.questions && currentSection.questions.length > 0">
                    <button type="button"
                            class="btn btn-sm btn-secondary mr-1"
                            [disabled]="questionNumber == 1"
                            (click)="loadQuestion(questionNumber - 1)">
                        <i class="fa fa-arrow-left"></i>
                        <span class="sr-only">Prev</span>
                    </button>
                    <button type="button"
                            class="btn btn-sm btn-secondary"
                            [disabled]="questionNumber == currentSection.questions.length"
                            (click)="loadQuestion(questionNumber + 1)">
                        <span class="sr-only">Next</span>
                        <i class="fa fa-arrow-right"></i>
                    </button>
                    <span class="mx-2">
                        Question <b>{{questionNumber}}</b> of <b>{{currentSection.questions.length}}</b>
                    </span>
                </div>

                <h4 *ngIf="currentQuestion.name != null">
                    {{currentQuestion.name}}
                </h4>

                <div class="lead">
                    <p>{{ currentQuestion.description }}</p>
                </div>

                <div class="form-group mb-3">
                    <div class="form-check form-check-inline" *ngFor="let option of currentQuestionOptions; index as i">
                        <input class="form-check-input"
                               type="radio"
                               name="currentQuestion-selected-option"
                               id="option-{{i}}"
                               [(ngModel)]="currentQuestion.selectedOptionId"
                               disabled
                               [value]="option.id" />
                        <label class="form-check-label" [class.selected-option-highlight]="currentQuestion.selectedOptionId != null && option.id == currentQuestion.selectedOptionId" for="option-{{i}}">{{option.text}}</label>
                    </div>
                </div>

                <div class="form-group">
                    <label for="current-question-status">
                        <i class="fa fa-flag"></i>
                        Status
                    </label>
                    <pre class="d-block" *ngIf="currentQuestion.selectedOptionId == null && !currentQuestion.isComplete">Not Started</pre>
                    <pre class="d-block" *ngIf="currentQuestion.selectedOptionId != null && !currentQuestion.isComplete">In Progress</pre>
                    <pre class="d-block text-success" *ngIf="currentQuestion.isComplete"><i class="fa fa-check"></i>Complete</pre>
                </div>

                <div class="form-group">
                    <label for="current-question-response">
                        <i class="fa fa-user"></i>
                        Submitted By
                    </label>
                    <pre class="d-block" *ngIf="!currentQuestion.respondingTeamMemberName">None</pre>
                    <pre class="d-block" *ngIf="currentQuestion.respondingTeamMemberName">{{currentQuestion.respondingTeamMemberName}} <small class="text-muted">[{{formatDate(currentQuestion.responseDate)}}]</small></pre>
                </div>

                <div class="form-group">
                    <label for="current-question-comment">
                        <i class="fa fa-comments"></i>
                        Comments ({{currentQuestion.comments.length}})
                    </label>
                    <ul class="comments-list mt-0">
                        <li *ngIf="!currentQuestion.comments || currentQuestion.comments.length == 0">None</li>
                        <li *ngFor="let comment of currentQuestion.comments">
                            <p>{{ comment.text }}</p>
                            <div class="text-muted text-sm font-italic">
                                <p class="mb-1">{{comment.inspectionTeamMember?.fullName}}</p>
                                <p><small>[{{formatDate(comment.timestamp)}}]</small></p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

        </div>
    </div>
    <div class="col sidebar sidebar-help-text">
        <h4>
            <i class="fa fa-question-circle text-info"></i>
            Help
        </h4>
        <div class="inner-scroller" *ngIf="currentQuestion">
            <p>{{ currentQuestion.helpText }}</p>
        </div>
    </div>
</div>
