<div class="header">
    <h3>Inspection Code Setup</h3>
</div>
<div class="row">
    <div class="col form-wrapper">
        <form #engagementForm="ngForm" class="editor-form">
            <div class="mt-2 mb-4">
                <button type="submit"
                        class="btn btn-primary mr-2"
                        [disabled]="!engagementForm.valid"
                        (click)="saveChanges()">
                    <i class="fa fa-save"></i>
                    Save
                </button>
                <button type="button"
                        class="btn btn-secondary mr-2"
                        (click)="goHome()">
                    <i class="fa fa-times"></i>
                    Cancel
                </button>
                <small class="form-text text-muted d-inline-flex">Please note: <b class="required px-2">*</b> indicates a required field</small>
            </div>
            <hr />
            <fieldset class="mb-2" [disabled]="isLoading">
                <legend>Metadata</legend>
                <div class="row">
                    <div class="form-group mb-0 d-flex col-md-2">
                        <label class="form-label col-sm-3 required" for="engagement-status">Status</label>
                        <div class="col d-flex">
                            <p class="mb-2">{{getStatusName(engagement.status)}}</p>
                            <button type="button"
                                    class="btn btn-sm btn-primary ml-3 mb-3"
                                    *ngIf="engagement.status == 0 && engagement.id > 0"
                                    [disabled]="!engagementForm.valid || IsPublishing" (click)="publishEngagement()">
                                <i class="fa fa-check-square-o"></i>
                                Publish
                            </button>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col-md-12 required" for="engagement-year">Inspection Year</label>
                        <div class="small-input col-md-12">
                            <input type="text"
                                   class="form-control editor-sm"
                                   id="engagement-year"
                                   name="engagement-year"
                                   pattern="[1-2]{1}[0-9]{3}"
                                   [(ngModel)]="engagement.inspectionYear"
                                   required />
                            <small class="form-text text-danger validation-message">Invalid</small>
                        </div>
                    </div>
                    <div class="form-group" style="width: 13.4rem;">
                        <label class="form-label required" for="engagement-code">Code</label>
                        <div class="">
                            <input type="text"
                                   class="form-control editor-sm"
                                   id="engagement-code"
                                   name="engagement-code"
                                   [(ngModel)]="engagement.code"
                                   required />
                            <small class="form-text text-danger validation-message">Engagement Code is invalid.</small>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2"></div>
                    <div class="form-group">
                        <label class="form-label required mb-1" for="client-name">Client</label>
                        <div class="client-dropdown inspection-setup-dropdown">
                            <ng-select [items]="clients"
                                       [virtualScroll]="true"
                                       [loading]="clientsLoading"
                                       bindLabel="label"
                                       bindValue="id"
                                       placeholder="Select one..."
                                       id="client-name"
                                       name="client-name"
                                       [(ngModel)]="engagement.clientId"
                                       (change)="updateClientFields()"
                                       (search)="onClientsSearch($event)"
                                       (scrollToEnd)="onClientsScrollToEnd()">
                                <!--(scroll)="onClientsScroll($event)"-->
                                <ng-template ng-header-tmp>
                                    <small class="form-text text-muted">Loaded {{clients.length}} of {{clientsCount}}</small>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-index="index">
                                    {{item.label}}
                                </ng-template>
                            </ng-select>
                            <small class="form-text text-danger validation-message">Client is invalid.</small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="form-label col-md-12 mb-2" for="project">Project</label>
                        <div class="col-md-12">
                            <div class="input-select-group inspection-setup-dropdown">
                                <ng-select [items]="availableProjects"
                                           [virtualScroll]="true"
                                           [loading]="projectsLoading"
                                           bindLabel="label"
                                           placeholder="Select Project..."
                                           id="project-id"
                                           name="project-id"
                                           [(ngModel)]="selectedProject"
                                           (change)="addProject()"
                                           (search)="onProjectsSearch($event)"
                                           (scrollToEnd)="onProjectsScrollToEnd()">
                                    <!--(scroll)="onProjectsScroll($event)"-->
                                    <ng-template ng-header-tmp>
                                        <small class="form-text text-muted">Loaded {{projects.length}} of {{projectsCount}}</small>
                                    </ng-template>
                                    <ng-template ng-option-tmp let-item="item" let-index="index">
                                        {{item.label}}
                                    </ng-template>
                                </ng-select>
                            </div>
                            <table class="table table-striped table-align-middle mb-0">
                                <tbody>
                                    <tr *ngFor="let engProj of engagement.engagementProjects">
                                        <td>
                                            <button type="button"
                                                    class="btn btn-sm btn-primary"
                                                    (click)="removeProject(engProj)">
                                                <i class="fa fa-fw fa-trash"></i>
                                            </button>
                                        </td>
                                        <td>{{engProj?.project.label}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <small class="form-text text-danger validation-message">Project is invalid.</small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="form-label" for="apt-caw">Workspace Name</label>
                        <div>
                            <div class="input-select-group inspection-setup-dropdown">
                                <ng-select [items]="availableAptCaws"
                                           [virtualScroll]="true"
                                           [loading]="aptCawsLoading"
                                           bindLabel="label"
                                           placeholder="Select Apt Workspace..."
                                           id="apt-caw-id"
                                           name="apt-caw-id"
                                           [(ngModel)]="selectedAptCaw"
                                           (change)="addAptCaw()"
                                           (search)="onAptCawsSearch($event)"
                                           (scrollToEnd)="onAptCawsScrollToEnd()">
                                    <!--(scroll)="onAptCawsScroll($event)"(scrollToEnd)="onAptCawsScrollToEnd()"-->
                                    <ng-template ng-header-tmp>
                                        <small class="form-text text-muted">Loaded {{aptCaws.length}} of {{aptCawsCount}}</small>
                                    </ng-template>
                                    <ng-template ng-option-tmp let-item="item">
                                        {{item.label}}
                                    </ng-template>
                                </ng-select>
                            </div>
                            <table class="table table-striped table-align-middle mb-0">
                                <tbody>
                                    <tr *ngFor="let engApt of engagement.engagementAptCaws">
                                        <td>
                                            <button type="button"
                                                    class="btn btn-sm btn-primary"
                                                    (click)="removeAptCaw(engApt)">
                                                <i class="fa fa-fw fa-trash"></i>
                                            </button>
                                        </td>
                                        <td>{{engApt.aptCaw.label}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <small class="form-text text-danger validation-message">APT CAW is invalid.</small>
                        </div>
                    </div>
                </div>
                <div class="row bg-bdo-secondary">
                    <div class="col-md-2"></div>
                    <div class="form-group">
                        <label class="form-label" for="client-fiscal-year-end">Client Fiscal Year End</label>
                        <div class="">
                            <div class="input-group">
                                <input type="text"
                                       class="form-control datepicker"
                                       id="client-fiscal-year-end"
                                       name="client-fiscal-year-end"
                                       placeholder="Select date..."
                                       ngbDatepicker #fiscalYearDate="ngbDatepicker"
                                       [(ngModel)]="engagement.clientFiscalYearEnd"
                                       (focus)="fiscalYearDate.toggle()" />
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary" type="button" (click)="fiscalYearDate.toggle()">
                                        <i class="fa fa-calendar"></i>
                                    </button>
                                </div>
                            </div>
                            <small class="form-text text-danger validation-message">Client Fiscal Year End is invalid.</small>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="label-container">
                            <label class="form-label col-md-12" for="entity-type">Entity Type</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text"
                                   readonly="readonly"
                                   class="form-control editor-sm"
                                   id="entity-type"
                                   name="entity-type"
                                   [(ngModel)]="engagement.clientEntityType" />
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="label-container">
                            <label class="form-label" for="engagement-type">Engagement Type</label>
                        </div>
                        <div class="">
                            <input type="text"
                                   readonly="readonly"
                                   class="form-control editor-sm"
                                   id="engagement-type"
                                   name="engagement-type"
                                   [(ngModel)]="engagement.engagementType" />
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="label-container">
                            <label class="col-md-12" for="total-engagement-hours">Total Engagement Hours</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text"
                                   class="form-control editor-sm"
                                   id="total-engagement-hours"
                                   name="total-engagement-hours"
                                   [(ngModel)]="engagement.totalEngagementHours"
                                   pattern="^-?\d*[.,]?\d{0,2}$" />
                            <small class="form-text text-danger validation-message">Total Engagement Hours is invalid.</small>
                        </div>
                    </div>
                </div>
                <div class="row bg-bdo-secondary">
                    <div class="col-md-2"></div>
                    <div class="form-group">
                        <div class="label-container py-1">
                            <label class="form-label col-md-12 required" for="office">Office</label>
                        </div>
                        <div class="col-md-12">
                            <select class="custom-select editor-md"
                                    id="office"
                                    name="office"
                                    [(ngModel)]="engagement.officeId"
                                    required
                                    (change)="updateRegionCode()">
                                <option [ngValue]="null">Select one...</option>
                                <option *ngFor="let office of availableOffices" [value]="office.id">{{office.id}} - {{office.name}}</option>
                            </select>
                            <small class="form-text text-danger validation-message">Office is invalid.</small>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="label-container py-1">
                            <label class="form-label col-md-12 required" for="region-code">Region</label>
                        </div>
                        <div class="col-md-12">
                            <select class="custom-select editor-sm"
                                    id="region-code"
                                    name="region-code"
                                    [(ngModel)]="engagement.regionCode"
                                    required
                                    disabled="disabled">
                                <option [ngValue]="null">Select one...</option>
                                <option *ngFor="let region of regions" [value]="region">{{region}}</option>
                            </select>
                            <small class="form-text text-danger validation-message">Region is invalid.</small>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="label-container py-1">
                            <label class="form-label col-md-12" for="business-line">Business Line</label>
                        </div>
                        <div class="col-md-12">
                            <select class="custom-select editor-md"
                                    id="business-line"
                                    name="business-line"
                                    [disabled]="disableBusinessLine == true"
                                    [(ngModel)]="engagement.businessLineId"
                                    disabled="disabled">
                                <option [ngValue]="null">Select one...</option>
                                <option *ngFor="let businessLine of businessLines" [value]="businessLine.id">{{businessLine.name}}</option>
                            </select>
                            <small class="form-text text-danger validation-message">Business Line is invalid.</small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="form-label col-md-12" for="industry">Industry Name</label>
                        <div class="col-md-12">
                            <div class="input-select-group inspection-setup-dropdown">
                                <ng-select [items]="availableIndustries"
                                           id="industry"
                                           name="industry"
                                           bindLabel="name"
                                           placeholder="Select Industry..."
                                           [(ngModel)]="selectedIndustry"
                                           (change)="addIndustry()">
                                </ng-select>
                            </div>
                            <table class="table table-striped table-align-middle mb-0">
                                <tbody>
                                    <tr *ngFor="let engInd of engagement.engagementIndustries">
                                        <td>
                                            <button type="button"
                                                    class="btn btn-sm btn-primary"
                                                    (click)="removeIndustry(engInd)">
                                                <i class="fa fa-fw fa-trash"></i>
                                            </button>
                                        </td>
                                        <td>{{engInd.industryName || engInd.industry.name}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <small class="form-text text-danger validation-message">Industry is invalid.</small>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-2"></div>
                    <div class="form-group">
                        <div class="label-container">
                            <label class="col-md-12" for="market-cap">Market Cap (thousands)</label>
                        </div>
                        <div class="col-md-12">
                            <input type="text"
                                   class="form-control editor-sm"
                                   id="market-cap"
                                   name="market-cap"
                                   [(ngModel)]="engagement.marketCap"
                                   pattern="^-?\d*[.,]?\d{0,2}$" />
                            <small class="form-text text-danger validation-message">Market Cap is invalid.</small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="" for="start-of-inspection-fieldwork">Start of Inspection Fieldwork</label>
                        <div class="">
                            <div class="input-group">
                                <input type="text"
                                       class="form-control datepicker"
                                       id="start-of-inspection-fieldwork"
                                       name="start-of-inspection-fieldwork"
                                       placeholder="Select date..."
                                       ngbDatepicker #startfieldworkDate="ngbDatepicker"
                                       [(ngModel)]="engagement.startOfInspectionFieldWork"
                                       (focus)="startfieldworkDate.toggle()"
                                       />
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary" type="button" (click)="startfieldworkDate.toggle()">
                                        <i class="fa fa-calendar"></i>
                                    </button>
                                </div>
                            </div>
                            <small class="form-text text-danger validation-message">Start of Inspection Fieldwork is invalid.</small>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="col-md-12" for="end-of-inspection-fieldwork">End of Inspection Fieldwork</label>
                        <div class="col-md-12">
                            <div class="input-group">
                                <input type="text"
                                       class="form-control datepicker"
                                       id="end-of-inspection-fieldwork"
                                       name="end-of-inspection-fieldwork"
                                       placeholder="Select date..."
                                       ngbDatepicker #fieldworkDate="ngbDatepicker"
                                       [(ngModel)]="engagement.endOfInspectionFieldWork"
                                       (focus)="fieldworkDate.toggle()" />
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary" type="button" (click)="fieldworkDate.toggle()">
                                        <i class="fa fa-calendar"></i>
                                    </button>
                                </div>
                            </div>
                            <small class="form-text text-danger validation-message">End of Inspection Fieldwork is invalid.</small>
                        </div>
                    </div>
                </div>
                <div>
                    <hr class="w-75" />
                </div>
            </fieldset>
    <hr />
    <fieldset class="mb-2" [disabled]="isLoading">
        <legend>Engagement Review Questionnaire</legend>
        <div class="form-group row mb-1">
            <label class="form-label col-sm-3 required" for="inspection-profile">Available Questionnaires</label>
            <div class="col-md-6">
                <ng-select [items]="inspectionProfiles"
                           [virtualScroll]="true"
                           bindLabel="name"
                           bindValue="id"
                           placeholder="Select one..."
                           id="inspection-profile-id"
                           name="inspection-profile-id"
                           [(ngModel)]="engagement.inspectionProfileId"
                           [disabled]="engagement.id > 0 && engagement.status > 0"
                           required
                           (change)="syncMetadataFromProfile()">
                    <ng-template ng-option-tmp let-item="item">
                        {{item.name}}
                    </ng-template>
                </ng-select>
                <small class="form-text text-danger validation-message">
                    <i class="fa fa-exclamation-triangle text-danger"></i>
                    Engagement Review Questionnaire is invalid.
                </small>
                <ul class="form-text text-muted text-sm mb-3 list-unstyled">
                    <li class="mb-1">
                        <i class="fa fa-info-circle text-info"></i>
                        The Engagement Review Questionnaire defines the sections and questions associated with this engagement.
                    </li>
                    <li *ngIf="engagement.status > 0">
                        <i class="fa fa-exclamation-circle text-danger"></i>
                        Engagement Review Questionnaire cannot be changed once engagement is active.
                    </li>
                </ul>
            </div>
        </div>
        <div class="form-group row">
            <label class="form-label col-sm-3 mb-2">Preview</label>
            <div class="col">
                <div class="form-group">
                    <button type="button" class="btn btn-primary mr-1" style="width: 8rem;" (click)="getInspectionProfileById()" [disabled]="isBusy.isBusy || !engagement.inspectionProfileId || previewEngagement">
                        <i class="fa fa-eye mr-2" *ngIf="!isBusy.isBusy || !(isBusy.isBusy && engagement?.inspectionProfileId)"></i>
                        <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" *ngIf="isBusy.isBusy && engagement?.inspectionProfileId"></span>
                        <span *ngIf="isBusy.isBusy && engagement?.inspectionProfileId">Loading...</span>
                        <span *ngIf="!isBusy.isBusy || !(isBusy.isBusy && engagement?.inspectionProfileId)">Preview</span>
                    </button>
                    <button type="button" class="btn btn-secondary mr-1" (click)="expandAll()" [disabled]="!previewEngagement">
                        <i class="fa fa-expand"></i>
                        Expand all
                    </button>
                    <button type="button" class="btn btn-secondary" (click)="collapseAll()" [disabled]="!previewEngagement">
                        <i class="fa fa-compress"></i>
                        Collapse all
                    </button>
                </div>
                <div *ngIf="previewEngagement">
                    <div class="card tree-wrapper" *ngIf="engagement.status == 0">
                        <div class="card-body tree-view-scroller">
                            <ol id="engagement-sections" class="tree">
                                <li class="tree-branch" *ngFor="let node of rootProfileSections; index as i">
                                    <section-tree-node-viewer [node]="node" [i]="i" [context]="this"></section-tree-node-viewer>
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div class="card" *ngIf="engagement.status > 0">
                        <div class="card-body tree-view-scroller">
                            <ol id="engagement-sections" class="tree">
                                <li class="tree-branch" *ngFor="let node of rootEngagementSections; index as i">
                                    <engagement-section-tree-node-viewer [node]="node" [i]="i" [context]="this"></engagement-section-tree-node-viewer>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </fieldset>
    <hr />
    <fieldset class="mb-2" [disabled]="isLoading">
        <legend>Inspection Team</legend>
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group row">
                    <label class="form-label col-sm-3 required" for="engagement-inspection-lead">Team Captain</label>
                    <div class="col">
                        <app-employee-search [employeeList]="employees"
                                             (onChange)="addTeamCaptainToTeamIfNecessary()"
                                             [(model)]="engagement.inspectionLeadId"
                                             [errorMessage]="'Team Captain is invalid.'"
                                             [required]="true"
                                             (onSearch)="searchEmployees($event)">
                        </app-employee-search>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="form-label col-sm-3" for="available-team-members-it">Available team members</label>
                    <div class="col">
                        <app-employee-search [employeeList]="availableInspectionEmployees"
                                             [(model)]="selectedInspectionTeamId"
                                             (buttonClick)="addInspectionTeamMember()"
                                             [showButton]="true"
                                             [buttonScreenReaderText]="'Add to inspection team'"
                                             (onSearch)="searchEmployees($event)">
                        </app-employee-search>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="form-label col-sm-3">Assigned inspection team members</label>
                    <div class="col">
                        <table class="table table-striped table-align-middle mb-0">
                            <thead>
                                <tr>
                                    <th style="width: 3rem;"></th>
                                    <th>Name</th>
                                    <th>Specialty Reviewer</th>
                                    <th>Sections Assigned</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="!engagement.inspectionTeamMembers || engagement.inspectionTeamMembers.length == 0">
                                    <td colspan="3">
                                        <span class="d-block p-2">
                                            <i class="fa fa-ban text-danger"></i>
                                            Engagement contains no inspection team members&hellip;
                                        </span>
                                    </td>
                                </tr>
                                <tr *ngFor="let teamMember of assignedInspectionTeamMembers; index as i">
                                    <td>
                                        <button *ngIf="teamMember.employeeId != engagement.inspectionLeadId"
                                                type="button"
                                                class="btn btn-sm btn-primary"
                                                (click)="removeInspectionTeamMember(teamMember)">
                                            <i class="fa fa-fw fa-trash"></i>
                                        </button>
                                        <span class="bdo-tag bdo-tag-dark" *ngIf="teamMember.employeeId == engagement.inspectionLeadId">TC</span>
                                    </td>
                                    <td>
                                        {{teamMember.employee?.fullName}}
                                    </td>
                                    <td>
                                        <select class="custom-select"
                                                id="specialty-reviewer-type-{{i}}"
                                                name="specialty-reviewer-type-{{i}}"
                                                [(ngModel)]="teamMember.specialtyReviewerTypeId"
                                                (change)="specialtyReviewerTypeAdded()">
                                            <option [ngValue]="null">Select one...</option>
                                            <option *ngFor="let type of availableSpecialtyReviewerTypes(teamMember.specialtyReviewerTypeId)" [value]="type.id">{{type.name}}</option>
                                        </select>
                                    </td>
                                    <!--Uses InspectionTeamMemberProfileSection when engagement is not published-->
                                    <td *ngIf="engagement.status == 0">
                                        <ng-select [items]="highLevelProfileSections"
                                                   id="profile-section-{{i}}"
                                                   name="profile-section-{{i}}"
                                                   bindLabel="name"
                                                   placeholder="Select one..."
                                                   [(ngModel)]="selectedProfileSections[i]"
                                                   (change)="addInspectionTeamMemberProfileSection(i, teamMember.id)">
                                        </ng-select>
                                        <table class="table table-striped table-align-middle mb-0">
                                            <tbody>
                                                <tr *ngFor="let section of getInspectionTeamMemberProfileSections(teamMember.id)">
                                                    <td>
                                                        <button type="button"
                                                                class="btn btn-sm btn-primary"
                                                                (click)="removeInspectionTeamMemberProfileSection(section)">
                                                            <i class="fa fa-fw fa-trash"></i>
                                                        </button>
                                                    </td>
                                                    <td>{{section.profileSection.name}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <!--Uses InspectionTeamMemberEngagementSection when engagement IS published-->
                                    <td *ngIf="engagement.status > 0">
                                        <ng-select [items]="highLevelEngagementSections"
                                                   id="engagement-section-{{i}}"
                                                   name="engagement-section-{{i}}"
                                                   bindLabel="name"
                                                   placeholder="Select one..."
                                                   [(ngModel)]="selectedEngagementSections[i]"
                                                   (change)="addInspectionTeamMemberEngagementSection(selectedEngagementSections[i], teamMember.id)">
                                        </ng-select>
                                        <table class="table table-striped table-align-middle mb-0">
                                            <tbody>
                                                <tr *ngFor="let section of getHighLevelInspectionTeamMemberEngagementSections(teamMember.id)">
                                                    <td>
                                                        <button type="button"
                                                                class="btn btn-sm btn-primary"
                                                                (click)="removeInspectionTeamMemberEngagementSection(section)">
                                                            <i class="fa fa-fw fa-trash"></i>
                                                        </button>
                                                    </td>
                                                    <td>{{section.engagementSection.name}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <!--<div class="form-group row">
                    <label class="form-label col-sm-3" for="engagement-tax-reviewer">Tax Reviewer</label>
                    <div class="col">
                        <app-employee-search [employeeList]="employees"
                                             [(model)]="engagement.taxReviewerId"
                                             [errorMessage]="'Tax Reviewer is invalid.'"
                                             (onSearch)="searchEmployees($event)">
                        </app-employee-search>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="form-label col-sm-3" for="engagement-is-reviewer">IS Reviewer</label>
                    <div class="col">
                        <app-employee-search [employeeList]="employees"
                                             [(model)]="engagement.isReviewerId"
                                             [errorMessage]="'IS Reviewer is invalid.'"
                                             (onSearch)="searchEmployees($event)">
                        </app-employee-search>
                    </div>
                </div>-->
            </div>
        </div>
    </fieldset>
    <hr />
    <fieldset class="mb-2" [disabled]="isLoading">
        <legend>Engagement Team</legend>

        <div class="form-group row">
            <label class="form-label col-sm-3" for="available-team-members-et">Available team members</label>
            <div class="col">
                <app-employee-search [employeeList]="availableEngagementEmployees"
                                     [(model)]="selectedEngagementTeamId"
                                     (buttonClick)="addEngagementTeamMember()"
                                     [showButton]="true"
                                     [buttonScreenReaderText]="'Add to engagement team'"
                                     (onSearch)="searchEmployees($event)">
                </app-employee-search>
            </div>
        </div>
        <div class="form-group row">
            <label class="form-label col-sm-3" for="assigned-team-members-et">Assigned engagement team members</label>
            <div class="col-sm-6">
                <table class="table table-sm table-striped table-align-middle mb-0">
                    <thead>
                        <tr>
                            <th style="width: 3rem;"></th>
                            <th style="width: 40%;">Name</th>
                            <th style="width: 6rem;">Hours</th>
                            <th style="width: 40%;">Role <span *ngIf="assignedEngagementTeamMembers && assignedEngagementTeamMembers.length" class="text-danger">*</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="!engagement.engagementTeamMembers || engagement.engagementTeamMembers.length == 0">
                            <td colspan="4">
                                <span class="d-block p-2">
                                    <i class="fa fa-ban text-danger"></i>
                                    Engagement contains no engagement team members&hellip;
                                </span>
                            </td>
                        </tr>
                        <tr *ngFor="let teamMember of assignedEngagementTeamMembers; index as i">
                            <td>
                                <button type="button"
                                        class="btn btn-sm btn-primary"
                                        (click)="removeEngagementTeamMember(teamMember)">
                                    <i class="fa fa-fw fa-trash"></i>
                                </button>
                            </td>
                            <td>{{teamMember.employee?.fullName}}</td>
                            <td>
                                <input type="text"
                                       id="engagement-teamMember-{{i}}-hours"
                                       name="engagement-teamMember-{{i}}-hours"
                                       class="form-control"
                                       pattern="^-?\d*[.,]?\d{0,2}$"
                                       [(ngModel)]="teamMember.hours" />
                            </td>
                            <td>
                                <select class="custom-select"
                                        id="engagement-teamMember-{{i}}-role"
                                        name="engagement-teamMember-{{i}}-role"
                                        [(ngModel)]="teamMember.inspectionTeamRoleId" required>
                                    <option [ngValue]="null">Select one...</option>
                                    <option *ngFor="let role of teamMemberRoles" [value]="role.id">{{role.name}}</option>
                                </select>
                                <small class="form-text text-danger validation-message">Role is invalid.</small>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </fieldset>
    <hr />
    <fieldset class="mb-2" [disabled]="isLoading">
        <legend>Comments</legend>
        <div class="form-group row">
            <label class="form-label col-sm-3" for="engagement-comments">Comments</label>
            <div class="col-sm-6">
                <textarea class="form-control text-editor text-editor-md"
                          id="engagement-comments"
                          name="engagement-comments"
                          [(ngModel)]="engagement.comments"></textarea>
            </div>
        </div>
    </fieldset>
    </form>
</div>
</div>
