import * as $ from 'jquery';
import { SaveResult, EntityState } from 'breeze-client';
import { MetaEntity } from '../../model/metaEntity';
import Strings from './strings';
import Config from './config';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';
import { StorageItem } from '../../typings';

const LOADING_SCREEN_CLASS = 'finished-loading';
const LOADING_SHELL_CLASS = '.app-loading-shell';

export default class Utilities {
    static emptyPromise = new Promise<any>((resolve) => { resolve(null); });

    static valueAsPromise<T>(value: T): Promise<T> {
        return new Promise<T>((resolve) => resolve(value));
    }

    static showLoadingScreen() {
        if ($(LOADING_SHELL_CLASS).hasClass(LOADING_SCREEN_CLASS)) {
            $(LOADING_SHELL_CLASS).removeClass(LOADING_SCREEN_CLASS);
        }
    }

    static hideLoadingScreen() {
        if (!$(LOADING_SHELL_CLASS).hasClass(LOADING_SCREEN_CLASS)) {
            $(LOADING_SHELL_CLASS).addClass(LOADING_SCREEN_CLASS);
        }
    }

    static decodeHtml(html: string) {
        const txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    }

    static isMobileSafari() {
        return navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/) && !navigator.userAgent.match(/CriOS/);
    }

    static numberWithCommas(x: number, round?: number): string {
        let y = 0;
        if (x) {
            y = x;
        }

        const parts = (round == null ? y.toString() : y.toFixed(round)).split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    }

    static camelCaseDto(dto: any): any {
        const m = {};
        $.map(dto, function (prop, propName: string) {
            m[propName.charAt(0).toLowerCase() + propName.slice(1)] = dto[propName];
        });

        return m;
    }

    static formatSavedMessage(saveResult: SaveResult) {
        let result = "";

        saveResult.entities.forEach(function (entity: MetaEntity) {
            if (entity.entityAspect.valueQueue) {
                if (entity.entityAspect.fieldsSavingEntityState == EntityState.Modified) {
                    for (const field in entity.entityAspect.fieldsSaving) {
                        if (entity.entityAspect.valueQueue[field]) {
                            entity.entityAspect.valueQueue[field].orig = entity[field]();
                            entity.entityAspect.valueQueue[field].isSaved = true;
                        }
                    }
                }
            }

            if (result.length) {
                result += "<br />";
            }

            if (entity.entityAspect.fieldsSavingEntityState == EntityState.Deleted || (entity.hasOwnProperty("isDeleted") && entity.isDeleted && (entity.entityAspect.fieldsSaving["isDeleted"] !== undefined && entity.isDeleted == true))) {
                if (entity.entityAspect.delMsg) {
                    result = entity.entityAspect.delMsg;
                } else if (entity.name) {
                    result = Strings.toasts.entityDeleted.replace(/\{0\}/gi, entity.name);
                }
            } else if (entity.entityAspect.fieldsSavingEntityState == EntityState.Added || (entity.hasOwnProperty("isDeleted") && entity.isDeleted && (entity.entityAspect.fieldsSaving["isDeleted"] !== undefined && !entity.isDeleted))) {
                if (entity.entityAspect.addMsg) {
                    result = entity.entityAspect.addMsg;
                } else if (entity.name) {
                    result = Strings.toasts.entityAdded.replace(/\{0\}/gi, entity.name);
                }
            } else if (entity.entityAspect.fieldsSavingEntityState == EntityState.Modified) {
                for (const field in entity.entityAspect.fieldsSaving) {
                    if (Config.skipFieldsForMessage.indexOf(field) >= 0) {
                        continue;
                    }

                    const fieldName = entity.entityType.props[field].displayName;
                    let fieldValue = entity[field];

                    if (/.+Id$/gi.test(field)) {
                        const actualField = field.replace(/Id$/gi, "");
                        if (entity[actualField]) {
                            const actualEntity = entity[actualField];
                            if (actualEntity && actualEntity.name) {
                                fieldValue = actualEntity.name;
                            }
                        }
                    }

                    if (fieldValue && fieldValue.length > 28) {
                        fieldValue = fieldValue.substring(0, 25) + "...";
                    }
                    result += fieldName + ": " + fieldValue + "<br />";
                }
            }

            result = result.replace(/<br \/>$/gi, "");

            entity.entityAspect.fieldsSaving = {};
        });

        return result;
    }

    static getCurrentDate(): Date {
        return new Date();
    }

    static sortByValue(a: string, b: string): number {
        return a.localeCompare(b);
    }

    static sortById(a, b): number {
        return a.id > b.id ? 1 : a.id < b.id ? -1 : 0;
    }

    static sortByName(a, b): number {
        return a.name.localeCompare(b.name);
    }

    static sortByDisplayOrder(a, b): number {
        return a.displayOrder > b.displayOrder ? 1 : a.displayOrder < b.displayOrder ? -1 : 0;
    }

    static koPropIfAvailable(item: BehaviorSubject<any>, propName: string, defaultValue: any): any {
        if (item && item.value)
            if (item.value[propName] && typeof item.value[propName] == 'function')
                return item.value[propName]();
            else if (item.value[propName])
                return item.value[propName];
        return defaultValue;
    }

    // Helper function for grouping a collection by one of the properties defined in the collection's type
    static groupByField(field: string, self: Array<{}>) {
        var groups = {};
        self.forEach(function (el) {
            if (field in groups == false) {
                groups[field] = [];
            }
            groups[field].push(el);
        });

        return Object.keys(groups).map(function (key) {
            return {
                key: key,
                values: groups[key]
            };
        });
    }

    static onlyUnique(value: string, index: number, self: Array<string>) {
        return self.indexOf(value) == index;
    }
}


export class Store {
    expires1Day: Date;
    expires30Days: Date;

    constructor() {
        this.expires1Day = moment().add(Config.dayMs, "ms").toDate();
        this.expires30Days = moment().add(Config.storeExpirationMs, "ms").toDate();
    }

    clear(key: string) {
        localStorage.removeItem(key);
    }

    fetch(key: string): string {
        const cache = localStorage.getItem(key);
        if (cache) {
            const item: StorageItem = JSON.parse(cache);
            if (moment().isSameOrBefore(item.expires)) {
                return item.item;
            } else {
                this.clear(key);
            }
        }

        return null;
    }

    save(key: string, value: string) {
        const item: StorageItem = {
            item: value,
            expires: this.expires30Days
        }
        const cache = JSON.stringify(item);

        localStorage.setItem(key, cache);
    }

    saveForADay(key: string, value: string) {
        const item: StorageItem = {
            item: value,
            expires: this.expires1Day
        }
        const cache = JSON.stringify(item);

        localStorage.setItem(key, cache);
    }

    saveForCurrentSession(key: string, value: string) {
        sessionStorage.setItem(key, value);
    }

    fetchFromSession(key: string): string {
        const value = sessionStorage.getItem(key);
        if (value) {
            return value;
        }

        return null;
    }
}
export const store = new Store();

interface listPickerData {
    isSelected: BehaviorSubject<boolean>,
    name
}

export class ListPicker<T> {
    expand = new BehaviorSubject(true);

    toggle = () => {
        this.expand.next(!this.expand.value);
    }

    allData: BehaviorSubject<T[]>;

    mapToString: (item: T) => string;

    name: string = "";

    selectAll = { isSelected: new BehaviorSubject(true), name: 'Select All' };

    private isAutoDeselecting = false;

    private availableBase = new BehaviorSubject<listPickerData[]>([]);
    available = new BehaviorSubject<listPickerData[]>([]);
    selected = new BehaviorSubject<string[]>([]);

    updateData() {
        if (this.selected.value.length == this.availableBase.value.length && !this.selectAll.isSelected.value) {
            this.isAutoDeselecting = true;
            this.selectAll.isSelected.next(true);
        } else if (this.selected.value.length < this.availableBase.value.length && this.selectAll.isSelected.value) {
            this.isAutoDeselecting = true;
            this.selectAll.isSelected.next(false);
        }
    }

    constructor(name: string, data: BehaviorSubject<T[]>, mapToStringCallback: (item: T) => string) {
        this.name = name;
        this.allData = data;
        this.allData.subscribe(val => {
            const items = val
                .map(this.mapToString)
                .filter(this.onlyUnique)
                .sort()
                .map((data) => {
                    return { isSelected: new BehaviorSubject(true), name: data };
                });

            this.availableBase.next(items);
        });
        this.mapToString = mapToStringCallback;

        this.selectAll.isSelected.subscribe((val) => {
            if (this.isAutoDeselecting == false) {
                this.availableBase.value.forEach((item) => { item.isSelected.next(val); });
            }
            else {
                this.isAutoDeselecting = false;
            }

            this.updateData();
        });

        this.availableBase.subscribe(val => {
            const availableItems = this.availableBase.value;
            this.available.next(availableItems);

            const selectedItems = val
                .filter((item) => item.isSelected.value)
                .map((item) => item.name);
            this.selected.next(selectedItems);

            this.updateData();
        });
    }

    isSelected(item): boolean {
        if (this.selectAll.isSelected.value)
            return true;

        if (this.selected.value.length == 0)
            return false;

        const value = this.mapToString(item);
        return this.selected.value.indexOf(value) !== -1;
    }

    onlyUnique(value: string, index: number, self: Array<string>) {
        return self.indexOf(value) == index;
    }
}

export class DateRangePicker<T> {
    expand = new BehaviorSubject(true);

    toggle = () => {
        this.expand.next(!this.expand.value);
    }

    allData: BehaviorSubject<T[]>;

    mapToDate: (item: T) => Date;

    name: string = "";

    selectAll = { isSelected: new BehaviorSubject(true), name: 'Select All' };

    minDate = new BehaviorSubject<Date>(null);

    maxDate = new BehaviorSubject<Date>(null);

    constructor(name: string, data: BehaviorSubject<T[]>, mapToDateCallback: (item: T) => Date) {
        this.name = name;
        this.allData = data;
        this.mapToDate = mapToDateCallback;

        this.minDate.subscribe((val) => {
            if (val && this.selectAll.isSelected.value)
                this.selectAll.isSelected.next(false);

            if (!val && !this.maxDate.value)
                this.selectAll.isSelected.next(true);
        });

        this.maxDate.subscribe((val) => {
            if (val && this.selectAll.isSelected.value)
                this.selectAll.isSelected.next(false);

            if (!val && !this.minDate.value)
                this.selectAll.isSelected.next(true);
        });
    }

    isSelected(item): boolean {
        if (this.selectAll.isSelected.value)
            return true;

        const value = this.mapToDate(item);
        if (!value)
            return false;

        if (this.minDate.value && moment(value).isBefore(this.minDate.value))
            return false;

        if (this.maxDate.value && moment(value).isAfter(moment(this.maxDate.value).endOf("day")))
            return false;

        return true;
    }
}
