import { Component, OnInit } from '@angular/core';
import { UnitOfWorkService } from '../../services/unit-of-work/unit-of-work.service';
import { ApplicationInsightsService } from '../../services/application-insights/application-insights.service';
import { Observable } from 'rxjs';
import { DialogHelper } from '../../services/dialog-helper';


@Component({
    selector: 'app-user-not-active',
    templateUrl: './not-active.component.html',
})
export class NoLoginFoundComponent implements OnInit {

    constructor(
        private uow: UnitOfWorkService,
        private appInsights: ApplicationInsightsService,
        public dialogHelper: DialogHelper
    ) {
        appInsights.logPageView();
    }

    ngOnInit() {

    }

    canDeactivate(): Observable<boolean> | boolean {
        if (this.uow.hasChanges.value) {
            let proceed = this.dialogHelper.confirm();

            proceed.subscribe(result => {
                if (result) {
                    this.uow.rollback();
                }
            })

            return proceed;
        }

        return true;
    }

}
