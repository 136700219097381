import { Component, OnInit } from '@angular/core';
import { SharedDataService } from '../../../services/shared-data/shared-data.service';
import Utilities from '../../../services/utilities';

@Component({
    selector: 'app-access-denied',
    templateUrl: './access-denied.component.html',
    styleUrls: ['./access-denied.component.less']
})
export class AccessDeniedComponent implements OnInit {
    constructor(private sharedData: SharedDataService) { }

    get accessType(): string {
        return !this.sharedData ? 'site' : this.sharedData.accessType;
    }

    ngOnInit(): void {
        Utilities.hideLoadingScreen();
    }
}
