import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import Config from '../../services/config';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Role } from '../../../model/role';
import { Router, Route } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { filter } from 'rxjs/operators';
import { InteractionStatus } from '@azure/msal-browser';
import { Profile } from '../../../typings';
import { SharedDataService } from '../../services/shared-data/shared-data.service';
import { ServerSideConfigDataService } from '../../services/server-side-config-data/server-side-config-data.service';

@Component({
    selector: 'app-nav-menu',
    templateUrl: './nav-menu.component.html',
    styleUrls: ['./nav-menu.component.less']
})
export class NavMenuComponent implements OnDestroy, OnInit {
    appTitle = Config.appTitle;
    name: BehaviorSubject<string> = new BehaviorSubject<string>("");
    showMenu: boolean;
    private userSub: Subscription;

    isAdmin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isNationalQcAdmin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    hasICSAccess: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    routes: Route[] = [];

    constructor(private router: Router, private broadcastService: MsalBroadcastService, private msalService: MsalService, private cd: ChangeDetectorRef, private sharedData: SharedDataService, private serverSide: ServerSideConfigDataService) {
        this.showMenu = false;
    }

    ngOnInit(): void {
        this.userSub = this.broadcastService.inProgress$
            .pipe(filter((status: InteractionStatus) => status === InteractionStatus.None))
            .subscribe(() => {
                this.showMenu = true;

                const account: Profile = this.msalService.instance.getAllAccounts()[0];

                this.name.next(account.idTokenClaims.name);
                this.isAdmin.next(account.idTokenClaims && account.idTokenClaims.roles && account.idTokenClaims.roles.includes(Role.admin));
                this.isNationalQcAdmin.next(account.idTokenClaims && account.idTokenClaims.roles && account.idTokenClaims.roles.includes(Role.nationalQcAdmin));
                this.hasICSAccess.next(account.idTokenClaims && account.idTokenClaims.roles && account.idTokenClaims.roles.includes(Role.icsAccess));
                this.isActive.next(account.idTokenClaims.acct === '0');
                this.serverSide
                    .loadServerSideData()
                    .then(() => { this.getRoutes(); });
            });
    }

    signOut(): void {
        this.msalService.logoutRedirect();
    }

    ngOnDestroy(): void {
        if (this.userSub) {
            this.userSub.unsubscribe();
        }
    }

    getRoutes(): void {
        const foundRoutes: Route[] = [];

        if (this.router) {
            this.router.config.forEach(route => {
                if (route.data && route.data["order"]) { 
                    foundRoutes.push(route);
                }

                if (route.children && route.children.length) {
                    route.children.forEach(childRoute => {
                        if (childRoute.data && childRoute.data["order"]) {
                            if (childRoute.data['requiresAdmin'] === true) {
                                if (this.sharedData.me.value.isAdmin) {
                                    foundRoutes.push(childRoute);
                                }
                            } else if (childRoute.data['requiresQcAdmin'] === true) {
                                if (this.sharedData.me.value.isAdmin || this.sharedData.me.value.isNationalQcAdmin) {
                                    foundRoutes.push(childRoute);
                                }
                            } else {
                                foundRoutes.push(childRoute);
                            }
                        }
                    })
                }
            })
        }
        this.routes = foundRoutes.sort((a, b) => {
            return Number(a.data["order"]) == Number(b.data["order"]) ? 0 : Number(a.data["order"]) < Number(b.data["order"]) ? -1 : 1;
        });
    }

    isSelfOrParentRoute(route: Route): boolean {
        if (route) {
            return this.router.isActive(route.path, true) || (route.path != "" && this.router.url.includes(route.path));
        }

        return false;
    }

}

