import { Component, OnInit } from '@angular/core';
import { UnitOfWorkService } from '../../services/unit-of-work/unit-of-work.service';
import { ApplicationInsightsService } from '../../services/application-insights/application-insights.service';
import { Observable } from 'rxjs';
import { DialogHelper } from '../../services/dialog-helper';
import { IsBusyService } from '../../services/is-busy/is-busy.service';
import * as model from '../../../model/model';
import { GlobalErrorHandler } from '../../services/global-error-handler';

@Component({
    selector: 'app-standard-question-options',
    templateUrl: './standard-question-options.component.html',
})
export class StandardQuestionOptionsAdminComponent implements OnInit {

    standardQuestionOptions: model.StandardQuestionOption[] = [];

    newOptionName: string = null;
    newOptionValue: string = null;

    answerSetOptions = [];

    noAnswerNeededName: string = 'No answer needed';

    get activeStandardOptions() {
        return this.standardQuestionOptions.filter(m => !m.entityAspect.entityState.isDeleted() && !m.entityAspect.entityState.isDetached()).sort((a, b) => { return a.name.localeCompare(b.value); });
    }

    constructor(
		private uow: UnitOfWorkService,
        private appInsights: ApplicationInsightsService,
        private errorHandler: GlobalErrorHandler,
		public isBusy: IsBusyService,
		public dialogHelper: DialogHelper
	) {
        appInsights.logPageView();
    }

    ngOnInit() {
        this.isBusy.set('admin:loadData', true, 'Loading data');
        Promise.all([
            this.getStandardQuestionOptions()
        ])
        .catch(reason => {
            this.errorHandler.handleError(reason);
        })
        .finally(() => {
            this.isBusy.set('admin:loadData', false);
        });
    }

    canDeactivate(): Observable<boolean> | boolean {
        if (this.uow.hasChanges.value) {
            let proceed = this.dialogHelper.confirm();

            proceed.subscribe(result => {
                if (result) {
                    this.uow.rollback();
                }
            })

            return proceed;
        }

        return true;
    }


    getStandardQuestionOptions() {
        return this.uow.getStandardQuestionOptions()
            .then(result => {
                this.standardQuestionOptions = result.sort((a, b) => (a.id < b.id || a.name === this.noAnswerNeededName ? -1 : 1));

                this.standardQuestionOptions.forEach(o => {

                    this.answerSetOptions[o.id] = [
                        { Key: 'A', Value: o.answerSetOptions.filter(x => x.answerSetCode == 'A').length > 0 },
                        { Key: 'B', Value: o.answerSetOptions.filter(x => x.answerSetCode == 'B').length > 0 },
                        { Key: 'C', Value: o.answerSetOptions.filter(x => x.answerSetCode == 'C').length > 0 },
                        { Key: 'D', Value: o.answerSetOptions.filter(x => x.answerSetCode == 'D').length > 0 },
                        { Key: 'E', Value: o.answerSetOptions.filter(x => x.answerSetCode == 'E').length > 0 },
                        { Key: 'F', Value: o.answerSetOptions.filter(x => x.answerSetCode == 'F').length > 0 }
                    ];
                });
            });
    }

    updateAnswerSetOptions(questionOption: model.StandardQuestionOption) {

        this.answerSetOptions[questionOption.id].forEach(a => {

            let aso = questionOption.answerSetOptions.find(x => x.answerSetCode == a.Key);

            if (a.Value && !aso) {
                let createdAnswerSetOption = this.uow.answerSetOptions.createEntity();

                createdAnswerSetOption.answerSetCode = a.Key;
                createdAnswerSetOption.standardQuestionOptionId = questionOption.id;

                questionOption.answerSetOptions.push(createdAnswerSetOption);
                this.uow.answerSetOptions.saveChanges();
            } else if (!a.Value && !!aso) {
                
                let index = questionOption.answerSetOptions.indexOf(aso);
                questionOption.answerSetOptions.splice(index, 1);

                aso.entityAspect.setDeleted();
                this.uow.answerSetOptions.saveChanges([aso]);
            }
        });
    }


    saveQuestionOptionChanges(questionOption: model.StandardQuestionOption, message: string) {
        this.isBusy.set('admin:saveQuestionOption', true, message);
        this.uow.standardQuestionOptions.saveChanges([questionOption])
            .then(() => {
                this.newOptionName = null;
                this.newOptionValue = null;

                this.updateAnswerSetOptions(questionOption);
            })
            .catch(reason => {
                this.errorHandler.handleError(reason);
            })
            .finally(() => {
                this.isBusy.set('admin:saveQuestionOption', false);
            });
    }


    createQuestionOption() {
        if (this.newOptionName.trim() != '' && this.newOptionValue.trim() != '') {
            let newQuestionOption = this.uow.standardQuestionOptions.createEntity();
            newQuestionOption.name = this.newOptionName.trim();
            newQuestionOption.value = this.newOptionValue.trim();

            this.standardQuestionOptions.push(newQuestionOption);

            this.saveQuestionOptionChanges(newQuestionOption, 'Saving standard question option');
        }
    }

    updateQuestionOption(questionOption: model.StandardQuestionOption) {
        this.saveQuestionOptionChanges(questionOption, 'Updating standard question option');
    }

    destroyQuestionOption(questionOption: model.StandardQuestionOption) {
        if (questionOption) {
            if (confirm('Are you sure you want to remove "' + questionOption.value + '"?')) {
                this.uow.standardQuestionOptions.cancelChanges(questionOption);
                questionOption.entityAspect.setDeleted();

                this.saveQuestionOptionChanges(questionOption, 'Removing standard question option "' + questionOption.value + '"');
            }
        }
    }
}
