import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ToastrService, ActiveToast } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class IsBusyService implements OnDestroy {
    private sub: Subscription;
    private isBusySubscribers: IsBusyValue[] = [];
    private isBusyBs = new BehaviorSubject<boolean>(false);

    constructor(
        private toastr: ToastrService
    ) {
        this.sub = this.isBusyBs.subscribe((value) => {
            if (value == true) {
                $('.loading-bar-dots.loading-data').css('display', 'flex');
            } else {
                $('.loading-bar-dots.loading-data').fadeOut('slow');
            }
        });
    }

    get isBusy() {
        return this.isBusyBs.value;
    }

    set(key: string, isBusy: boolean, message?: string, toast?: ActiveToast<any>) {
        const context = this;
        setTimeout(() => {
            const isSubscriberIndex = context.isBusySubscribers
                .map((e) => e.key)
                .indexOf(key);

            if (isBusy == true && isSubscriberIndex < 0) {
                if (message) {
                    toast = context.toastr.info(message, "", { timeOut: 0, extendedTimeOut: 0 });
                }

                context.isBusySubscribers.push({
                    key: key,
                    isBusy: isBusy,
                    message: message,
                    toast: toast
                });
            } else if (isBusy == false && isSubscriberIndex >= 0) {
                const existing = context.isBusySubscribers[isSubscriberIndex];

                if (existing.toast) {
                    context.toastr.clear(existing.toast.toastId);
                }

                context.isBusySubscribers.splice(isSubscriberIndex, 1);
            }

            context.isBusyBs.next(context.isBusySubscribers.length > 0);
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }
}

export interface IsBusyValue {
    key: string;
    isBusy: boolean;
    message?: string;
    toast?: ActiveToast<any>;
}
