import { Component, Input, OnInit } from '@angular/core';
import Utilities from '../../../services/utilities';
import { ProfileSection, InspectionProfile, Question } from '../../../../model/model';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';

@Component({
    selector: 'section-tree-node-editor',
    inputs: ['node', 'i', 'inspectionProfile'],
    templateUrl: './tree-node-editor.component.html'
})

export class ProfileSectionTreeNode implements OnInit {
    @Input() node: ProfileSection;
    @Input() i: number;
    @Input() inspectionProfile: InspectionProfile;
    @Input() context: any;

    constructor() {
    }

    ngOnInit() {
        this.setActiveSection();
    }

    get currentNodes() {
        return this.node.childSections ? this.node.childSections.filter(m => !m.isDeleted).sort(Utilities.sortByDisplayOrder) : [];
    }

    setActiveSection() {
        this.context.setActiveSection(this.node);
    }

    toggleSection() {
        this.node.isCollapsed = !this.node.isCollapsed;
    }

    addSection() {
        this.context.addSection(this.node);
    }

    remove() {
        if (confirm('Are you sure you want to remove "' + this.node.name + '"?')) {
            this.context.removeSection(this.node);
        }
    }

    addQuestion() {
        this.setActiveSection();

        this.context.addQuestion();
    }

    editQuestion(question: Question) {
        this.setActiveSection();

        this.context.editQuestion(question);
    }

    removeQuestion(question: Question) {
        this.context.dialog.open(ConfirmationDialogComponent, {
            data: {
                title: "Confirm",
                content: `Are you sure you want to remove "${this.getDisplayText(question)}"?`
            }
        })
            .afterClosed().toPromise().then(response => {
                if (response == "Yes") {
                    this.context.removeQuestion(question);
                }
            });
    }

    duplicateQuestion(question: Question) {
        this.context.dialog.open(ConfirmationDialogComponent, {
            data: {
                title: "Confirm",
                content: `Are you sure you want to duplicate "${this.getDisplayText(question)}"?`
            }
        })
            .afterClosed().toPromise().then(response => {
                if (response == "Yes") {
                    this.context.duplicateQuestion(question);
                }
            });
    }

    getDisplayText(question: Question) {
        return question.name || question.description.substr(0, 40) + '...';
    }

    saveSectionChanges() {
        return this.context.saveSectionChanges(this.node);
    }
}
