import { Component, OnInit } from '@angular/core';
import { UnitOfWorkService } from '../../services/unit-of-work/unit-of-work.service';
import { ApplicationInsightsService } from '../../services/application-insights/application-insights.service';
import { Observable } from 'rxjs';
import { DialogHelper } from '../../services/dialog-helper';
import { IsBusyService } from '../../services/is-busy/is-busy.service';
import * as model from '../../../model/model';
import { GlobalErrorHandler } from '../../services/global-error-handler';

@Component({
    selector: 'app-inspection-team-roles',
    templateUrl: './inspection-team-roles.component.html',
})
export class InspectionTeamRolesAdminComponent implements OnInit {
    inspectionTeamRoles: model.InspectionTeamRole[] = [];
    
    newRoleName: string = null;
   
    get activeInspectionTeamRoles() {
        return this.inspectionTeamRoles.filter(m => !m.entityAspect.entityState.isDeleted() && !m.entityAspect.entityState.isDetached()).sort((a, b) => { return a.name.localeCompare(b.name); });
    }


    constructor(
		private uow: UnitOfWorkService,
        private appInsights: ApplicationInsightsService,
        private errorHandler: GlobalErrorHandler,
		public isBusy: IsBusyService,
		public dialogHelper: DialogHelper
	) {
        appInsights.logPageView();
    }

    ngOnInit() {
        this.isBusy.set('admin:loadData', true, 'Loading data');
        Promise.all([
            this.getInspectionTeamRoles()
        ])
        .catch(reason => {
            this.errorHandler.handleError(reason);
        })
        .finally(() => {
            this.isBusy.set('admin:loadData', false);
        });
    }

    canDeactivate(): Observable<boolean> | boolean {
        if (this.uow.hasChanges.value) {
            let proceed = this.dialogHelper.confirm();

            proceed.subscribe(result => {
                if (result) {
                    this.uow.rollback();
                }
            })

            return proceed;
        }

        return true;
    }
    

    getInspectionTeamRoles() {
        return this.uow.getInspectionTeamRoles(true)
            .then(result => {
                this.inspectionTeamRoles = result;
            });
    }
   

    saveInspectionTeamRoleChanges(role: model.InspectionTeamRole, message: string) {
        this.isBusy.set('admin:saveRole', true, message);
        this.uow.inspectionTeamRoles.saveChanges([role])
            .then(() => {
                this.newRoleName = null;
            })
            .catch(reason => {
                this.errorHandler.handleError(reason);
            })
            .finally(() => {
                this.isBusy.set('admin:saveRole', false);
            });
    }

    createRole() {
        if (this.newRoleName) {
            let newRole = this.uow.inspectionTeamRoles.createEntity();
            newRole.name = this.newRoleName.trim();
            this.inspectionTeamRoles.push(newRole);

            this.saveInspectionTeamRoleChanges(newRole, 'Saving role');
        }
    }

    updateRole(role: model.InspectionTeamRole) {
        this.saveInspectionTeamRoleChanges(role, 'Updating role "' + role.name + '"');
    }

    destroyRole(role: model.InspectionTeamRole) {
        if (role) {
            if (confirm('Are you sure you want to remove "' + role.name + '"?')) {
                this.uow.inspectionTeamRoles.cancelChanges(role);
                role.entityAspect.setDeleted();

                this.saveInspectionTeamRoleChanges(role, 'Removing role "' + role.name + '"');
            }
        }
    }
}
