import { Inject, Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';
import { AccountInfo } from '@azure/msal-common';

@Injectable({
    providedIn: 'root'
})
export class RequireAuthenticatedUserGuard implements CanActivate {
    constructor(private authService: MsalService) { }

    async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const account: AccountInfo = this.authService.instance.getAllAccounts()[0];
        if (account) {
            return Promise.resolve(true);
        } else {
            return Promise.resolve(false);
        }
    }
}
