import { Component, OnInit } from '@angular/core';
import { UnitOfWorkService } from '../../../services/unit-of-work/unit-of-work.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ApplicationInsightsService } from '../../../services/application-insights/application-insights.service';
import { Observable } from 'rxjs';
import { DialogHelper } from '../../../services/dialog-helper';
import { IsBusyService } from '../../../services/is-busy/is-busy.service';
import * as model from '../../../../model/model';
import * as modelDto from '../../../../model/modelDto';
import * as modelEnum from '../../../../model/modelEnums';
import { GlobalErrorHandler } from '../../../services/global-error-handler';
import { FileService } from '../../../services/file-service/file-service.service';

const loadingKey: string = 'inspection-profiles:loadData';
const saveKey: string = 'inspection-profiles:saveInspectionProfile';
const importKey: string = 'inspection-profiles:importQuestionnaire'
const defaultLimit: number = 15;

@Component({
    selector: 'app-inspection-profiles',
    templateUrl: './inspection-profiles.component.html',
})
export class InspectionProfilesComponent implements OnInit {
    inspectionProfileSummaries: modelDto.AllInspectionsSummaryDto[] = [];
    page: number = 1;
    limit: number = defaultLimit;
    pageCount: number = 1;
    pageLimitOptions = [15, 25, 50, 100];

    filters: modelDto.GridFilter[] = [];
    nameFilter: string = null;
    statusFilter: string = null;
    businessLineFilter: string = null;


    constructor(
		private uow: UnitOfWorkService,
        private appInsights: ApplicationInsightsService,
        private errorHandler: GlobalErrorHandler,
		private activeRoute: ActivatedRoute,
		public router: Router,
		public isBusy: IsBusyService,
		public dialogHelper: DialogHelper,
        public fileService: FileService
	) {
        appInsights.logPageView();
    }

    ngOnInit() {
        this.activeRoute.queryParams.subscribe(routeParams => {
            this.page = routeParams.page && !Number.isNaN(routeParams.page) ? parseInt(routeParams.page) : 1;
            this.limit = routeParams.limit && !Number.isNaN(routeParams.limit) ? parseInt(routeParams.limit) : defaultLimit;

            this.updateDataGrid();
        });
    }

    canDeactivate(): Observable<boolean> | boolean {
        if (this.uow.hasChanges.value) {
            const proceed = this.dialogHelper.confirm();

            proceed.subscribe(result => {
                if (result) {
                    this.uow.rollback();
                }
            })

            return proceed;
        }

        return true;
    }


    get activeInspectionProfiles() {
        return this.inspectionProfileSummaries.sort((a, b) => { return a.inspectionProfile.name.localeCompare(b.inspectionProfile.name); });
    }


    updateFilters(key: string, value: any) {
        if (this.filters.some(m => m.key == key)) {
            const filter = this.filters.find(m => m.key == key);
            filter.value = value;
        }
        else {
            this.filters.push({ key: key, value: value } as modelDto.GridFilter)
        }

        this.updateDataGrid();
    }

    clearFilters() {
        this.filters = [];
        this.nameFilter = null;
        this.statusFilter = null;
        this.businessLineFilter = null;

        this.updateDataGrid();
    }

    updateDataGrid() {
        this.isBusy.set(loadingKey, true, 'Loading data');
        Promise
            .all([
                this.getPageCount(this.limit),
                this.getInspectionProfiles(this.page, this.limit)])
            .catch(reason => {
                this.errorHandler.handleError(reason);
            })
            .finally(() => {
                this.isBusy.set(loadingKey, false);
            });
    }

    getStatusName(status: modelEnum.InspectionProfileStatus) {
        return this.uow.getStatusName(status);
    }

    getInspectionProfiles(page: number = 1, limit: number = defaultLimit) {
        return this.uow.getInspectionProfilesSummary(page, limit, JSON.stringify(this.filters))
            .then(result => {
                this.inspectionProfileSummaries = result;
            });
    }

    getPageCount(limit: number = defaultLimit) {
        return this.uow.getInspectionProfilesPageCount(limit, JSON.stringify(this.filters))
            .then(result => {
                this.pageCount = result[0];
            });
    }

    saveInspectionProfileChanges(profile: modelDto.AllInspectionsSummaryDto, message: string) {
        this.isBusy.set(saveKey, true, message);
        this.uow.inspectionProfiles.saveChanges([profile.inspectionProfile])
            .catch(reason => {
                this.errorHandler.handleError(reason);
            })
            .finally(() => {
                this.isBusy.set(saveKey, false);
            });
    }

    destroyInspectionProfile(profile: modelDto.AllInspectionsSummaryDto) {
        if (profile) {
            if (confirm('Are you sure you want to remove "' + profile.inspectionProfile.name + '"?')) {
                profile.inspectionProfile.entityAspect.setDeleted();
                const ind = this.inspectionProfileSummaries.findIndex(i => i.inspectionProfile.id == profile.inspectionProfile.id);
                this.inspectionProfileSummaries.splice(ind, 1);
                this.saveInspectionProfileChanges(profile, 'Removing questionnaire "' + profile.inspectionProfile.name + '"');
            }
        }
    }

    cloneInspectionProfile(profile: modelDto.AllInspectionsSummaryDto) {
        if (profile) {
            const newName: string = prompt('Please enter a name for the new questionnaire:', profile.inspectionProfile.name);
            if (newName && newName.trim() != '') {
                this.isBusy.set(saveKey, true, 'Saving copy of "' + profile.inspectionProfile.name + '" as "' + newName + '"');
                this.uow.cloneInspectionProfile(profile.inspectionProfile.id, newName)
                    .then((newProfile) => {
                        if (newProfile && newProfile.length > 0) {
                            this.inspectionProfileSummaries.push(newProfile[0]);
                        }
                    })
                    .catch(reason => {
                        this.errorHandler.handleError(reason);
                        this.isBusy.set(saveKey, false);
                    })
                    .finally(() => {
                        this.isBusy.set(saveKey, false);
                    });
            }
        }
    }

    import(files) {
        if (!files) {
            alert('No file selected');
            return;
        }

        if (files.length > 1) {
            alert('Unable to import using multiple files');
            return;
        }

        this.isBusy.set(importKey, true, 'Performing Import');

        var formData = new FormData();
        formData.append("file", files[0]);

        this.fileService.importQuestionnaire(formData)
        .then(() => {
            this.getInspectionProfiles();
        })
        .finally(() => {
            this.isBusy.set(importKey, false);
        });
    }
}
