<div class="header">
    <h3>Admin</h3>
</div>
<div class="row">
    <div class="col tab-menu">
        <admin-nav-menu [currentTab]="'security-roles'"></admin-nav-menu>
    </div>

    <div class="col col-constrained">
        <fieldset>
            <legend>Security Roles</legend>
            <div class="form-group row">
                <label class="form-label col-sm-3 required" for="engagement-inspection-lead">Search Employee</label>
                <div class="col">
                    <app-employee-search [employeeList]="employees"
                                         (onChange)="employeeChanged()"
                                         [(model)]="selectedEmployeeId"
                                         [errorMessage]="'Employee is invalid.'"
                                         (onSearch)="searchSpecificEmployee($event)">
                    </app-employee-search>
                </div>
                <div class="col text-center mt-1">
                    <input class="form-check-input"
                           type="checkbox"
                           [(ngModel)]="showCaptains"
                           (change)="searchAllEmployees()"
                           id="captain-checkbox"
                           name="captain-checkbox">
                    <label class="form-check-label" for="captain-checkbox">
                        Captain
                    </label>
                </div>
                <div class="col mt-1">
                    <input class="form-check-input"
                           type="checkbox"
                           [(ngModel)]="showAdmins"
                           (change)="searchAllEmployees()"
                           id="admin-checkbox"
                           name="admin-checkbox">
                    <label class="form-check-label" for="admin-checkbox">
                        Admin
                    </label>
                </div>
            </div>
            <div *ngIf="!!employeeSearchResults.length" class="form-group row">
                <div class="col">
                        <table class="table table-striped table-align-middle mb-0">
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Name</th>
                                    <th>User</th>
                                    <th>Captain</th>
                                    <th>Admin</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="!employeeSearchResults.length">
                                    <td colspan="3">
                                        <span class="d-block p-2">
                                            <i class="fa fa-ban text-danger"></i>
                                            No results&hellip;
                                        </span>
                                    </td>
                                </tr>
                                <tr *ngFor="let employee of employeeSearchResults; index as i">
                                    <td>
                                        {{employee.id}}
                                    </td>
                                    <td>
                                        {{employee.fullName}}
                                    </td>
                                    <td>
                                        <label class="switch" title="Enable/Disable" style="margin-top: 10px">
                                            <input type="checkbox"
                                                   id="new-industry"
                                                   (change)="save()"
                                                   [(ngModel)]="employee.canSignIn">
                                            <span class="slider round"></span>
                                        </label>
                                    </td>
                                    <td>
                                        <label class="switch" title="Enable/Disable" style="margin-top: 10px">
                                            <input type="checkbox"
                                                   id="new-industry"
                                                   (change)="save()"
                                                   [(ngModel)]="employee.isNationalQcAdmin">
                                            <span class="slider round"></span>
                                        </label>
                                    </td>
                                    <td>
                                        <label class="switch" title="Enable/Disable" style="margin-top: 10px">
                                            <input type="checkbox"
                                                   id="new-industry"
                                                   (change)="save()"
                                                   [(ngModel)]="employee.isAdmin">
                                            <span class="slider round"></span>
                                        </label>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
            </div>
        </fieldset>
    </div>
</div>
