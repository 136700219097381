import { Component, OnInit } from '@angular/core';
import { UnitOfWorkService } from '../../services/unit-of-work/unit-of-work.service';
import { ApplicationInsightsService } from '../../services/application-insights/application-insights.service';
import { Observable } from 'rxjs';
import { DialogHelper } from '../../services/dialog-helper';
import { IsBusyService } from '../../services/is-busy/is-busy.service';
import * as model from '../../../model/model';
import { GlobalErrorHandler } from '../../services/global-error-handler';

@Component({
    selector: 'app-offices',
    templateUrl: './offices.component.html',
})
export class OfficesAdminComponent implements OnInit {

    offices: model.Office[] = [];

    get activeOffices() {
        return this.offices.filter(m => !m.entityAspect.entityState.isDeleted() && !m.entityAspect.entityState.isDetached()).sort((a, b) => { return a.name.localeCompare(b.name); });
    }


    constructor(
		private uow: UnitOfWorkService,
        private appInsights: ApplicationInsightsService,
        private errorHandler: GlobalErrorHandler,
		public isBusy: IsBusyService,
		public dialogHelper: DialogHelper
	) {
        appInsights.logPageView();
    }

    ngOnInit() {
        this.isBusy.set('admin:loadData', true, 'Loading data');
        Promise.all([
            this.getOffices()
        ])
        .catch(reason => {
            this.errorHandler.handleError(reason);
        })
        .finally(() => {
            this.isBusy.set('admin:loadData', false);
        });
    }

    canDeactivate(): Observable<boolean> | boolean {
        if (this.uow.hasChanges.value) {
            let proceed = this.dialogHelper.confirm();

            proceed.subscribe(result => {
                if (result) {
                    this.uow.rollback();
                }
            })

            return proceed;
        }

        return true;
    }
    

    getOffices() {
        return this.uow.getOffices()
            .then(result => {
                this.offices = result;
            });
    }
}
