import { Component, Input, Output, EventEmitter, SimpleChanges, SimpleChange } from '@angular/core';
import { EngagementQuestion, EngagementSection } from '../../../../model/model';
import { QuestionFilters } from '../../../../model/modelDto';
import Utilities from '../../../services/utilities';
import { FilteredQuestionsService } from '../../../services/filtered-questions/filtered-questions.service';
import * as modelEnum from '../../../../model/modelEnums';

enum EngagementQuestionStatus {
    complete = 'complete',
    notComplete = 'not complete',
    saved = 'saved',
    withComments = 'with comments'
}

@Component({
    selector: 'inspection-tree-node-viewer',
    inputs: ['node', 'i'],
    templateUrl: './inspection-tree-node-viewer.component.html'
})
export class InspectionViewerTreeNode {
    @Input() node: EngagementSection;
    @Input() i: number;
    @Input() context: any;
    @Input() filters: QuestionFilters;
    @Input() filterIsActive: boolean;
    _filterIsActive: boolean;
    filteredQuestions: object = {};
    meetsFilterCriteris: boolean = true;

    constructor(private filteredQuestionsService: FilteredQuestionsService) {}
    
    get assignedActivatedNodes() {
        return this.node.childSections ? this.node.childSections.filter(m => m.isActivated && this.context.hasActionableContent(m)).sort(Utilities.sortByDisplayOrder) : [];
    }

    get questions() {
        return this.node.engagementQuestions ? this.node.engagementQuestions.sort(Utilities.sortByDisplayOrder) : [];
    }

    getfilteredQuestions(): void {
        this.filteredQuestions = this.filteredQuestionsService.getFilteredQuestions
    }

    ngOnChanges(changes: SimpleChanges) {
        this._filterIsActive = changes.filterIsActive ? changes.filterIsActive.currentValue : false;
    }

    getTooltip(question) {
        if (question.isComplete) {
            return 'Completed Question';
        }
        if (question.selectedOptionId != null && !question.isComplete) {
            return 'Question with a response not marked Complete';
        }
        return '';
    }

    toggleSection() {
        if (this.node) {
            this.node.isCollapsed = !this.node.isCollapsed;
        }
    }

    setActiveSection(node: EngagementSection) {
        this.context.setActiveSection(node);
    }

    setActiveQuestion(question: EngagementQuestion) {
        question.isSelected = true;
        this.context.setActiveQuestion(question);
    }

    getDisplayText(question: EngagementQuestion) {
        return question.name || question.description.substr(0, 40) + '...';
    }

    isAssignedToMe(node: EngagementSection) {
        return node.inspectionTeamMemberEngagementSections.some(o => o.inspectionTeamMember && o.inspectionTeamMember.employeeId == this.context.me.id);
    }

    questionHasChanges(question: EngagementQuestion) {
        return this.context.questionHasChanges(question);
    }

    questionIsFilteredAndActive(question: EngagementQuestion) {
        let filterCriteria = {
            isComplete: true,
            selectedOptionId: true,
            tag: true
        };
        for(const property in this.filters) {
            if(this.filters[property]) {
                filterCriteria[property] = this.checkQuestionAgainstFilter(question, property);
            }
            if(this._filterIsActive && filterCriteria[property]) {
                this.filteredQuestionsService.add(question);
            }
        }
        const doesNotMeetFiltercriteria = Object.values(filterCriteria).some(criteria => criteria == false || criteria == null);         
        return doesNotMeetFiltercriteria || question.isActivated == false ? false : true;
    }
    
    checkQuestionAgainstFilter(question: EngagementQuestion, property) {
        switch (property) {
            case 'isComplete':
                return this.checkQuestionStatus(question, property);
            
            case 'selectedOptionId':
                if(question.selectedOption != null) {
                    if(question.selectedOption.standardQuestionOption) {
                        return question.selectedOption.standardQuestionOption.name == this.filters[property];
                    } else {
                        return question.selectedOption.text == this.filters[property];
                    }
                } else {
                    return false;
                }
            case 'tag':
                const hasTag = question.question.questionTags.find(t => t.tag.name == this.filters[property]) ? true : false;
                return hasTag;
        }
    }

    checkQuestionStatus(question: EngagementQuestion, property) {
        const questionStatus = this.filters[property];
        let {complete, notComplete, saved, withComments} = EngagementQuestionStatus;
        switch (questionStatus) {
            case complete:
                return question.isComplete;
            case notComplete:
                return !question.isComplete && !question.selectedOptionId;
            case saved:
                return question.selectedOptionId && !question.isComplete;
            case withComments:
                return question.engagementQuestionComments.filter(x => !x.isDeleted).length > 0;
        }
    }

    showNode() {
        return this.node.isActivated && this.hasNestedFilteredQuestions(this.node);
    }

    hasNestedFilteredQuestions(node: EngagementSection) {
        let childNodes = node.childSections ? node.childSections.filter(m => m.isActivated): [];

        return node.engagementQuestions.some(q => this.questionIsFilteredAndActive(q)) || childNodes.some(cn => this.hasNestedFilteredQuestions(cn));
    }
}
