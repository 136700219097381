import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { MsalGuard, MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-common';
import { RequireAuthenticatedUserGuard } from '../auth/require-authenticated-user.guard';
import { BreezeSetupGuard } from '../breeze-setup/breeze-setup.guard';

@Injectable({
    providedIn: 'root'
})
export class AppGuard implements CanActivate {
    constructor(
        private msalGuard: MsalGuard,
        private breezeGuard: BreezeSetupGuard,
        private router: Router,
        private authService: MsalService
    ) { }

    private reroute() {
        //this.router.navigate(["/access-denied"]);
        return false;
    }

    public async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        let account: AccountInfo = this.authService.instance.getAllAccounts()[0];

        if (await this.msalGuard.canActivate(next, state).toPromise().catch(err => this.reroute()) == false) {
            return this.reroute();
        }

        if (await this.breezeGuard.canActivate(next, state).catch(err => this.reroute()) == false) {
            return this.reroute();
        }

        return true;
    }

}
