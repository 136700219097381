<div class="header">
    <h3>Reports</h3>
</div>
<div class="row">
    <div class="col tab-menu">
        <reports-nav-menu [currentTab]="'export-questions-and-responses'"></reports-nav-menu>
    </div>

    <div class="col col-constrained">
        <fieldset>
            <legend>Export Questions and Responses</legend>
            <div class="form-group col-md-2 mb-3">
                <label for="inspectionYear">Inspection Year </label>
                <select id="inspectionYear" name="inspectionYear" class="custom-select" [(ngModel)]="inspectionYear">
                    <option *ngFor="let year of inspectionYears" [value]="year">{{year}}</option>
                </select>
            </div>
            <button type="button" class="btn btn-primary" (click)="export()">
                <i class="fa fa-save"></i>
                Export
            </button>
        </fieldset>
    </div>
</div>
