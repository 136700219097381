import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UnitOfWorkService } from '../../services/unit-of-work/unit-of-work.service';
import { ApplicationInsightsService } from '../../services/application-insights/application-insights.service';
import { Observable } from 'rxjs';
import { DialogHelper } from '../../services/dialog-helper';
import { IsBusyService } from '../../services/is-busy/is-busy.service';
import * as model from '../../../model/model';
import { GlobalErrorHandler } from '../../services/global-error-handler';
import { SharedDataService } from '../../services/shared-data/shared-data.service';

@Component({
    selector: 'app-security-roles',
    templateUrl: './security-roles.component.html',
})
export class SecurityRolesAdminComponent implements OnInit {

    employees: model.Employee[] = [];
    employeeSearchResults: model.Employee[] = [];

    selectedEmployeeId: string = '';

    showCaptains: boolean = false;
    showAdmins: boolean = false;

    constructor(
        private uow: UnitOfWorkService,
        private appInsights: ApplicationInsightsService,
        private errorHandler: GlobalErrorHandler,
        public isBusy: IsBusyService,
        public dialogHelper: DialogHelper,
        private sharedData: SharedDataService,
        private router: Router
    ) {
        appInsights.logPageView();
    }

    ngOnInit() {
        this.handleSecurity();
    }

    searchAllEmployees() {
        return this.uow.getEmployees(null, null, this.showAdmins, this.showCaptains)
            .then(result => {
                this.employeeSearchResults = result.filter(m => m.isActive);
            });
    }

    searchSpecificEmployee(searchTerm: string) {
        return this.uow.getEmployees(searchTerm)
            .then(result => {
                this.employees = result.filter(m => m.isActive);
            });
    }

    employeeChanged() {
        this.employeeSearchResults = this.employees.filter(x => x.id === this.selectedEmployeeId);
    }

    save() {
        this.isBusy.set('admin:saveSecurityRole', true, "Saving Changes");
        this.uow.employees.saveChanges()
            .catch(reason => {
                this.errorHandler.handleError(reason);
            })
            .finally(() => {
                this.isBusy.set('admin:saveSecurityRole', false);
                this.handleSecurity();
            });
    }

    handleSecurity() {
        if (!this.sharedData.me.value.isAdmin) {
            this.router.navigate(['']);
        }
    }
}
