import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UnitOfWorkService } from '../../../services/unit-of-work/unit-of-work.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ApplicationInsightsService } from '../../../services/application-insights/application-insights.service';
import { ServerSideConfigDataService } from '../../../services/server-side-config-data/server-side-config-data.service';
import { Observable, Subscription } from 'rxjs';
import { DialogHelper } from '../../../services/dialog-helper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IsBusyService } from '../../../services/is-busy/is-busy.service';
import Utilities from '../../../services/utilities';
import * as moment from 'moment';
import { SharedDataService } from '../../../services/shared-data/shared-data.service';
import * as model from '../../../../model/model';
import { QuestionFilters } from '../../../../model/modelDto';
import * as modelEnum from '../../../../model/modelEnums';
import { GlobalErrorHandler } from '../../../services/global-error-handler';
import { EngagementQuestion } from '../../../../model/model';
import { FilteredQuestionsService } from '../../../services/filtered-questions/filtered-questions.service';
import 'isomorphic-fetch';
import { HttpClient } from '@angular/common/http';

const LOADING_KEY: string = 'inspection:loadData';
const SAVING_KEY: string = 'inspection:saveData';
const UPDATING_KEY: string = 'inspection:updateData';
const NO_ANSWER_NEEDED_NAME: string = 'No answer needed';

@Component({
    selector: 'app-my-inspection-admin',
    templateUrl: './inspection.component.html',
    styleUrls: ['./inspection.component.less']
})
export class MyInspectionComponent implements OnInit {
    @ViewChild('existingAnswerModal', { static: true }) existingAnswerModal: TemplateRef<any>; // Note: TemplateRef

    me: model.Employee;

    isLoading = true;

    engagement: model.Engagement = {} as model.Engagement;
    engagementId: number = null;
    allEngagementOptions: Array<any>;
    sectionId: number = null;
    questionId: number = null;
    commentText: string = null;
    currentComment: model.EngagementQuestionComment = null;
    tags: model.Tag[] = [];
    availableTags: model.Tag[] = [];
    engagementQuestionActivatingTags: number[] = [];

    questionNumber: number = null;
    currentFilteredQuestionNumber: number = null;
    currentQuestion: model.EngagementQuestion = null;

    hoverSectionName: string = null;

    parentSection: model.EngagementSection = null;

    currentSection: model.EngagementSection = null
    previousSection: model.EngagementSection = null;
    nextSection: model.EngagementSection = null;
    currentFilteredSection: any = {};

    nextQuestionDisabled: boolean = false;
    prevQuestionDisabled: boolean = false;

    nextAssignedSection: model.EngagementSection = null;

    public questionFilters: QuestionFilters;
    public filterIsActive: boolean = false;
    filteredQuestions: object = {};
    typingTimer: NodeJS.Timeout;//timer identifier

    private subscriptions = new Subscription();

    constructor(
        private uow: UnitOfWorkService,
        private appInsights: ApplicationInsightsService,
        private errorHandler: GlobalErrorHandler,
        private activeRoute: ActivatedRoute,
        public router: Router,
        public isBusy: IsBusyService,
        public dialogHelper: DialogHelper,
        private modalService: NgbModal,
        private serverData: ServerSideConfigDataService,
        private sharedData: SharedDataService,
        private filteredQuestionsService: FilteredQuestionsService,
        private http: HttpClient
    ) {
        this.appInsights.logPageView();
        this.questionFilters = {
            isComplete: null,
            selectedOptionId: null,
            tag: null
        }
        this.allEngagementOptions = [];
        this.currentFilteredSection.filteredEngagementQuestions = [];
    }

    ngOnInit() {
        this.subscriptions.add(this.serverData.configDataLoaded$.subscribe(() => {
            this.me = this.serverData.configData.me;
        }));

        this.activeRoute.queryParams.subscribe(routeParams => {
            const cachedData = this.sharedData.myEngagements.value;
            if (!cachedData || cachedData.id != routeParams.id) {
                this.isBusy.set(LOADING_KEY, true, 'Loading engagement data');
                Promise
                    .all([
                        this.getEngagementById(routeParams.id, routeParams.sid, routeParams.qid),
                    ])
                    .then(() => {
                        this.findSectionById();
                        this.getAllEngagementOptions();
                    })
                    .catch(reason => {
                        this.errorHandler.handleError(reason);
                    })
                    .finally(() => {
                        this.isBusy.set(LOADING_KEY, false);
                    });
            } else {
                this.engagement = cachedData;
                this.getTags()
                this.getAllEngagementOptions();
            }
        });
        this.setNextQuestionDisable();
        this.setPrevQuestionDisable();

    }

    commentKeyDown() {

        clearTimeout(this.typingTimer);

    }

    commentKeyUp() {
      var doneTypingInterval = 1000  //time in ms, 5 second for example
        var self = this as any;
        clearTimeout(this.typingTimer);
        this.commentEdit()
        this.typingTimer = setTimeout(function () { self.saveComment() }, doneTypingInterval);
    }

    get orderedEngagementQuestionOptions() {
        const temp = this.currentQuestion.engagementQuestionOptions ? this.currentQuestion.engagementQuestionOptions.filter(o => !o.isDeleted && (!o.standardQuestionOption || o.standardQuestionOption.name !== NO_ANSWER_NEEDED_NAME)).sort(Utilities.sortByDisplayOrder) : [];
        return temp;
    }

    ngDoCheck() {
        if (Object.keys(this.filteredQuestions).length !== 0) {
            this.setCurrentFilteredSection();
        }
        if (this.filterIsActive && this.currentQuestion) {
            this.setCurrentFilteredQuestionNumber();
            this.setNextQuestionDisable();
            this.setPrevQuestionDisable();
        }
    }

    ngAfterViewChecked() {
        if (this.filterIsActive) {
            this.getFilteredQuestions();
        }
    }

    getFilteredQuestions(): void {
        this.filteredQuestionsService.getFilteredQuestions()
            .subscribe(filteredQuestions => this.filteredQuestions = filteredQuestions);
    }

    canDeactivate(): Observable<boolean> | boolean {
        if (this.uow.hasChanges.value) {
            const proceed = this.dialogHelper.confirm();

            proceed.subscribe(result => {
                if (result) {
                    this.uow.rollback();
                }
            })

            return proceed;
        }

        return true;
    }

    setAvailableTags() {
            this.availableTags = this.tags;       
    }


    getEngagementById(id: number, sid?: number, qid?: number) {
        this.isBusy.set(LOADING_KEY, true, 'Loading engagement data');
        return this.uow.getMyEngagementNodeViewerById(id)
            .then(result => {
                this.getTags()
                if (result && result.length > 0) {
                    this.engagement = result[0];
                    this.setAvailableTags();
                    this.sharedData.setMyEngagements(this.engagement);

                    this.engagementId = id;
                    this.sectionId = sid;
                    this.questionId = qid;
                } else {
                    this.router.navigate(['./inspections'], { relativeTo: this.activeRoute.parent });
                }
                this.isBusy.set(LOADING_KEY, false);
            });
    }


    getEngagementStatusName(status: modelEnum.EngagementStatus) {
        return this.uow.getEngagementStatusName(status);
    }

    getProgressStatusName(status: modelEnum.ProgressStatus) {
        return this.uow.getProgressStatusName(status);
    }

    formatDate(value: any): string {
        return moment(value).format('YYYY-MM-DD hh:mm A Z');
    }


    calculatePercentComplete(numerator: number, denominator: number): number {
        let value = 0.0;

        if (denominator > 0) {
            value = numerator / denominator;
        }

        return value;
    }


    expandAll() {
        if (this.engagement) {
            if (this.engagement.engagementSections && this.engagement.engagementSections.length) {
                this.engagement.engagementSections.forEach(m => m.isCollapsed = false);
            }
        }
    }

    collapseAll() {
        if (this.engagement) {
            if (this.engagement.engagementSections && this.engagement.engagementSections.length) {
                this.engagement.engagementSections.forEach(m => m.isCollapsed = true);
            }
        }
    }

    get teamMember(): model.InspectionTeamMember {
        return this.engagement && this.engagement.inspectionTeamMembers && this.engagement.inspectionTeamMembers.filter(t => t.employeeId == this.me.id)
            ? this.engagement.inspectionTeamMembers.filter(t => t.employeeId == this.me.id)[0] : null;
    }

    get isTeamCaptain(): boolean {
        return this.engagement ? this.engagement.inspectionLeadId == this.me.id : false;
    }

    get canSubmitResponse(): boolean {
        return this.engagement && (this.engagement.status == modelEnum.EngagementStatus.active || this.isTeamCaptain);
    }


    get rootEngagementSections() {
        return this.engagement && this.engagement.engagementSections && this.engagement.engagementSections.length ? this.engagement.engagementSections
            .filter(m => m.parentSectionId == null && m.isActivated)
            .sort(Utilities.sortByDisplayOrder) : [];
    }

    get myRootSections() {
        return this.engagement && this.engagement.engagementSections && this.engagement.engagementSections.length ? this.engagement.engagementSections
            .filter(m => m.parentSectionId == null && m.isActivated && m.inspectionTeamMemberEngagementSections.filter(s => s.inspectionTeamMemberId == (this.teamMember.id || null)) && this.hasActionableContent(m))
            .sort(Utilities.sortByDisplayOrder) : [];
    }

    get currentQuestionOptions() {
        return this.currentQuestion && this.currentQuestion.engagementQuestionOptions ? this.currentQuestion.engagementQuestionOptions.sort(Utilities.sortByDisplayOrder) : [];
    }

    get currentComments() {
        return this.currentQuestion && this.currentQuestion.engagementQuestionComments ? this.currentQuestion.engagementQuestionComments.filter(c => !c.isDeleted && !c.entityAspect.entityState.isAdded()).sort(Utilities.sortByDisplayOrder) : [];
    }

    get hasChanges() {
        const a = this.uow.engagements.hasChanges.value;
        const b = this.uow.engagementSections.hasChanges.value;
        const c = this.uow.engagementQuestionComments.hasChanges.value;
        const d = this.uow.engagementQuestions.hasChanges.value;

        return a || b || c || d;
    }

    get prevSectionNavigationDisabled() {
        let disable = true;
        if (this.currentSection.parentSection && this.myRootSections.length > 0) {
            this.myRootSections[0].childSections.forEach(filteredSection => {
                if (this.currentSection.displayOrder > filteredSection.displayOrder || this.myRootSections.some(rootSection => rootSection.id == this.currentSection.parentSection.id)) {
                    disable = false;
                }
            });
        } else if(this.myRootSections.length > 0 && this.myRootSections.filter(rs => rs.isActivated).findIndex(rs => rs.id == this.currentSection.id) > 0) {
            disable = false;
        } else {
            disable = true;
        }

        return disable;
    }

    get numberOfActiveQuestionsInSection() {
        return this.currentSection.engagementQuestions.filter(q => q.isActivated).length;
    }

    undoChanges() {
        if (this.hasChanges) {
            this.uow.rollback();
        }
    }

    hasActionableContent(section: model.EngagementSection): boolean {
        let hasContent = false;

        if (section.isActivated) {
            if (section.engagement.inspectionLeadId == this.me.id) { // show all sections if user is team lead
                hasContent = true;
            } else if (section.engagementQuestions && section.engagementQuestions.length > 0) {
                hasContent = section.inspectionTeamMemberEngagementSections.some(s =>  { // show section if user is assigned to the section
                    if( s.inspectionTeamMember != null) {
                        return s.inspectionTeamMember.employeeId == this.me.id
                    } else {
                        return false;
                    }
                });
            }
            else {
                hasContent = section.childSections && section.childSections.some(m => this.hasActionableContent(m));
            }
        }

        return hasContent;
    }


    reloadEngagementTree() {
        this.getEngagementById(this.engagementId);
    }

    findSectionById() {
        if (this.sectionId) {
            if (this.engagement.engagementSections && this.engagement.engagementSections.length) {
                this.setActiveSection(this.engagement.engagementSections.find(m => m.id == this.sectionId));
            }
            else {
                this.setActiveSection(null);
            }
        }
        else {
            this.setActiveSection(null);
        }
    }

    findNearestActiveParentSection(section: model.EngagementSection) {
        if (section) {
            this.parentSection = section.parentSection;
            if (this.parentSection) {
                if (!this.parentSection.isActivated) {
                    this.parentSection = this.findNearestActiveParentSection(this.parentSection);
                }
            }
        }
        else {
            this.parentSection = null;
        }

        return this.parentSection;
    }

    getFocusSection() {
        if (this.currentSection == null || this.currentSection.parentSection == null) {
            return "";
        }

        var temp = this.currentSection;
        while (temp.parentSection.parentSection != null) {
            temp = temp.parentSection;
        }
        return temp.name;

    }

    setActiveSection(section: model.EngagementSection) {
        this.resetComment();
        this.setPreviousSection(section);
        this.currentSection = section;
        this.setCurrentFilteredSection();
        this.setHoverText(null);



        this.clearQuestionSelection();
        if (this.engagement.engagementSections && this.engagement.engagementSections.length) {
            if (section) {
                var element = document.getElementById('Node_' + section.id.toString());
                if (element != null) {
                    element.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'start' });
                    $('#question-scrollbar').scrollLeft(0);
                }
            }
            if (this.currentSection) {
                this.sectionId = this.currentSection.id;

                // Check if there is an active question. How is there an active question?
                // 1. User selected a Question from either list - left nav (inspection-tree-node-viewer) or center nav (section-list-item)
                // 2. User clicked on the section navigator arrow
                if (this.questionId) {
                    if (this.currentSection.engagementQuestions && this.currentSection.engagementQuestions.length) {
                        const currentQ = this.currentSection.engagementQuestions.find(m => m.id == this.questionId);
                        // if clicking on a different section while question is selected
                        if (!currentQ) {
                            this.questionNumber = 1;
                            const firstActiveQuestionInSection = this.currentSection.engagementQuestions.find(q => q.isActivated);
                            if (firstActiveQuestionInSection) {
                                this.getQuestion(firstActiveQuestionInSection.id)
                                    .then(() => {
                                        this.checkParentSection();
                                        this.setNextQuestionDisable();
                                        this.setPrevQuestionDisable();
                                    });
                            } else {
                                this.checkParentSection();
                                this.setNextQuestionDisable();
                                this.setPrevQuestionDisable();
                            }
                        } else {
                            // clicking on a question
                            this.getQuestion(currentQ.id)
                                .then(() => {
                                    this.questionNumber = this.currentSection.engagementQuestions.filter(q => q.isActivated).indexOf(this.currentQuestion) + 1;
                                    this.checkParentSection();
                                    this.setNextQuestionDisable();
                                    this.setPrevQuestionDisable();
                                });
                        }
                    } else {
                        this.checkParentSection();
                    }
                } else {
                    // if no question is currently selected
                    if (this.currentSection.engagementQuestions && this.currentSection.engagementQuestions.length) {
                        // user clicks on a Section that has questions
                        this.getQuestion(this.currentSection.engagementQuestions.find(q => q.isActivated).id)
                            .then(() => {
                                this.questionNumber = 1;
                                this.checkParentSection();
                                this.setNextQuestionDisable();
                                this.setPrevQuestionDisable();
                            });
                    } else {
                        // user clicks on a section that has no immediate child question
                        this.checkParentSection();
                    }
                }



            } else {
                this.resetComment();
                this.sectionId = null;
                this.questionId = null;

                this.parentSection = null;
                this.currentQuestion = null;
                this.previousSection = null;
                this.nextSection = null;
                this.nextAssignedSection = null;
            }
        } else {
            this.resetComment();
            this.sectionId = null;
            this.questionId = null;

            this.parentSection = null;
            this.currentQuestion = null;
            this.previousSection = null;
            this.nextSection = null;
            this.nextAssignedSection = null;
        }
    }

    checkQuestionForNoAnswerOption() {
        if (this.currentQuestion
            && (!this.currentQuestion.selectedOptionId || !this.currentQuestion.isComplete)
            && this.hasNoAnswerNeededQuestionOption()) {
            let naOption = this.currentQuestion.engagementQuestionOptions.find(x => x.standardQuestionOption.name === NO_ANSWER_NEEDED_NAME);
            naOption.commentIsRequired = false;
            this.currentQuestion.selectedOptionId = naOption.id;
            this.saveResponseForm(true);
        }
    }

    setPreviousSection(section: model.EngagementSection) {
        if (section && section.parentSection) {
            // check for sibling children and iteravily check children of children
            const siblings = this.findNearestActiveParentSection(section).childSections.filter(m => m.isActivated).sort(Utilities.sortByDisplayOrder);
            const currentSectionIndex = siblings.findIndex(sibling => sibling.id == section.id);

            if (siblings.length > 1 && currentSectionIndex != 0) {
                const previousSibling = siblings[currentSectionIndex - 1];

                if (previousSibling.childSections.filter(m => m.isActivated).length == 0) {
                    this.previousSection = previousSibling;
                } else {
                    let childSections = previousSibling.childSections.filter(m => m.isActivated).sort(Utilities.sortByDisplayOrder);
                    let sectionHasChildren = childSections.length > 0;

                    // iteravily check children of children
                    while (sectionHasChildren) {
                        let childrenOfChildSections = childSections[childSections.length - 1].childSections.filter(m => m.isActivated);

                        if (childrenOfChildSections.length > 0) {
                            childSections = childrenOfChildSections
                        } else {
                            sectionHasChildren = false;
                        }
                    }
                    this.previousSection = childSections[childSections.length - 1];
                }
            } else if (currentSectionIndex == 0) {
                this.previousSection = section.parentSection;

            }
        }
    }

    checkChildSections() {
        const childSections = this.currentSection.childSections.filter(m => m.isActivated).sort(Utilities.sortByDisplayOrder);
        this.nextSection = childSections[0];
    }

    checkSiblingSections() {
        const siblings = this.parentSection.childSections.filter(m => m.isActivated).sort(Utilities.sortByDisplayOrder);

        if (siblings) {
            const i = siblings.indexOf(this.currentSection);
            const lastIndex = siblings.length - 1;

            if (i == lastIndex) {
                this.checkParentSectionForSiblings();
            } else {
                if (i > 0) {
                    if (this.filterIsActive) {
                        this.checkPrevSiblingSectForFilteredQuests(siblings, i);
                    }
                }

                if (i < lastIndex) {
                    if (this.filterIsActive) {
                        this.checkNextSiblingSectForFilteredQuests(siblings, i);
                    } else {
                        this.nextSection = siblings[i + 1];
                    }
                } else {
                    this.nextSection = null;
                }
            }
        } else if (this.currentSection.childSections.length > 0) {
            this.checkChildSections();
        }
    }

    checkParentSectionForSiblings() {
        if (this.parentSection.parentSection) {
            const siblingsOfParent = this.findNearestActiveParentSection(this.parentSection).childSections.filter(m => m.isActivated).sort(Utilities.sortByDisplayOrder);
            const indexOfParent = siblingsOfParent.findIndex(sibling => sibling.id == this.currentSection.parentSectionId);

            if (indexOfParent < siblingsOfParent.length - 1) {
                this.nextSection = siblingsOfParent[indexOfParent + 1];
            }
        } else {
            this.nextSection = null;
        }
    }

    checkParentSection() {
        this.parentSection = this.findNearestActiveParentSection(this.currentSection);
        const rootSectionIndex = this.rootEngagementSections.filter(rs => rs.isActivated).findIndex(rs => rs.id == this.currentSection.id);

        if (this.parentSection) {
            if (this.currentSection.parentSectionId == this.parentSection.id) {
                if (this.currentSection.childSections.filter(m => m.isActivated).length > 0) {
                    this.checkChildSections();
                } else if (this.parentSection.childSections.filter(m => m.isActivated).length > 1) {
                    this.checkSiblingSections();
                } else {
                    this.checkParentSectionForSiblings();
                }
            } else {
                this.previousSection = null;
                this.nextSection = null;
            }
        } else if (rootSectionIndex >= 0 && !this.currentSection.childSections.filter(s => s.isActivated).length) {
            this.previousSection = this.rootEngagementSections.filter(rs => rs.isActivated)[rootSectionIndex - 1];
            this.nextSection = this.rootEngagementSections.filter(rs => rs.isActivated)[rootSectionIndex + 1];
        } else {
            // if rootsection and does have children
            if (rootSectionIndex >= 0) {
                this.previousSection = rootSectionIndex ? this.rootEngagementSections.filter(rs => rs.isActivated)[rootSectionIndex - 1] : null;
                this.nextSection = this.rootEngagementSections.filter(rs => rs.isActivated)[rootSectionIndex + 1];
            }
            this.checkChildSections();
        }
    }

    checkPrevSiblingSectForFilteredQuests(siblings, i) {
        let j = i - 1;
        let sectionHasFilteredQuestions = false;
        while (j >= 0 && !sectionHasFilteredQuestions) {
            for (let key in this.filteredQuestions) {
                if (siblings[j].id.toString() == key) {
                    sectionHasFilteredQuestions = true;
                }
            }

            if (sectionHasFilteredQuestions) {
                this.previousSection = siblings[j];
            } else if (j == 0 && !sectionHasFilteredQuestions) {
                this.previousSection = null;
            } if (j > -1 && !sectionHasFilteredQuestions) {
                j--;
            }
        }
    }

    checkNextSiblingSectForFilteredQuests(siblings, i) {
        let j = i + 1;
        let sectionHasFilteredQuestions = false;
        while (j < siblings.length && !sectionHasFilteredQuestions) {
            for (let key in this.filteredQuestions) {
                if (siblings[j].id.toString() == key) {
                    sectionHasFilteredQuestions = true;
                }
            }

            if (sectionHasFilteredQuestions) {
                this.previousSection = siblings[j];
            } else if (j == siblings.length - 1 && !sectionHasFilteredQuestions) {
                this.nextSection = null;
            } if (j < siblings.length && !sectionHasFilteredQuestions) {
                j++;
            }
        }
    }

    closeSection() {
        this.setActiveSection(null);
    }

    setHoverText(section: model.EngagementSection) {
        if (section) {
            if (section.name.length > 40) {
                this.hoverSectionName = section.name.substr(0, 40) + '...';
            } else {
                this.hoverSectionName = section.name;
            }
        }
        else {
            this.hoverSectionName = null;
        }
    }

    getQuestion(id: number): Promise<model.EngagementQuestion> {
        this.questionId = id;
        const questSection = this.engagement.engagementSections.find(s => s.engagementQuestions && s.engagementQuestions.filter(q => q.id == id).length > 0);
        const questionLoaded = questSection.engagementQuestions.find(q => q.id == id && q.isActivated);
        if (questionLoaded && questionLoaded.isFullyLoaded) {
            this.currentQuestion = questionLoaded;
            this.currentQuestion.isSelected = true;
            this.setNextQuestionDisable();
            this.setPrevQuestionDisable();
            this.loadActiveComment();
            this.checkQuestionForNoAnswerOption();
            return new Promise<model.EngagementQuestion>((resolve) => {
                resolve(this.currentQuestion);
            });
        } else {
            this.isBusy.set(LOADING_KEY, true);
            return this.uow.getEngagementQuestionById(id)
                .then((result) => {
                    this.isBusy.set(LOADING_KEY, false);
                    if (result[0] && result[0].isActivated) {
                        const question = result[0];
                        let eq = this.engagement.engagementSections.find(s => s.id == question.engagementSectionId).engagementQuestions.find(q => q.id == question.id);
                        eq = question;
                        this.currentQuestion = eq;
                        this.currentQuestion.isSelected = true;
                        this.currentQuestion.isFullyLoaded = true;
                        this.loadActiveComment();
                        this.sharedData.setMyEngagements(this.engagement);
                        if (this.filterIsActive) {
                            this.setCurrentFilteredQuestionNumber();
                        }
                        this.setNextQuestionDisable();
                        this.setPrevQuestionDisable();

                        this.checkQuestionForNoAnswerOption();
                        return eq;
                    }
                    return null;
                });
        }
    }

    loadActiveComment() {
        if (this.currentQuestion && this.currentQuestion.engagementQuestionComments && this.currentQuestion.engagementQuestionComments.length) {
            const activeComment = this.currentQuestion.engagementQuestionComments.find(c => c.entityAspect.entityState.isAddedModifiedOrDeleted());
            if (activeComment) {
                this.currentComment = activeComment;
                this.commentText = this.currentComment.text;
            }
        }
    }

    loadQuestion(questNumber: number) {
        this.resetComment();
        if (this.currentQuestion) {
            this.clearQuestionSelection();
        }
        if (this.filterIsActive) {
            const engagementSectionId = this.currentSection.id;
            const engagementSectFilteredQuestions = this.filteredQuestions[engagementSectionId];

            let questNumberIncremented = questNumber;
            let foundNextFilteredQuestion = false;
            // nextFilteredQuestionId should be displayOrder = questionNumberIncrememted - 1
            // displayOrder should be the question.displayOrder not engagementQuestion.displayOrder
            // else this.current this.currentSection.engagementQuestions[questNumberIncremented - 1].id
            let nextFilteredQuestionId = this.currentSection.engagementQuestions.filter(q => q.isActivated)[questNumberIncremented - 1].id;
            let i = 0;

            while (i < engagementSectFilteredQuestions.length) {
                questNumberIncremented++;
                i++;
                foundNextFilteredQuestion = engagementSectFilteredQuestions.some(q => q.id == nextFilteredQuestionId && q.isActivated);
            }

            var element = document.getElementById('Q_' + nextFilteredQuestionId.toString());
            if (element != null) {
                //console.log("Moved to question " + nextFilteredQuestionId);
                element.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'start' });
                $('#question-scrollbar').scrollLeft(0);

            }

            return this.getQuestion(nextFilteredQuestionId)
                .then(() => {
                    this.questionNumber = questNumber;

                });
        }
        if (this.currentSection.engagementQuestions.filter(q => q.isActivated)[questNumber - 1] && !this.filterIsActive) {
            this.getQuestion(this.currentSection.engagementQuestions.filter(q => q.isActivated)[questNumber - 1].id)
                .then(() => {

                    var id = this.currentSection.engagementQuestions.filter(q => q.isActivated)[questNumber - 1].id
                    var element = document.getElementById('Q_' + id.toString());
                    if (element != null) {
                        //console.log("Moved to question " + id);
                        element.scrollIntoView();
                    }

                    this.questionNumber = questNumber;
                    this.setNextQuestionDisable();
                    this.setPrevQuestionDisable();
                });
        }
    }

    setActiveQuestion(engQuestion: model.EngagementQuestion) {
        this.resetComment();

        if (this.currentQuestion) {
            this.clearQuestionSelection();
        }

        this.questionId = engQuestion.id;
        this.sectionId = engQuestion.engagementSectionId;
        this.findSectionById();
    }

    clearQuestionSelection() {
        if (this.engagement.engagementSections && this.engagement.engagementSections.length) {
            this.engagement.engagementSections.forEach(s => {
                if (s.engagementQuestions && s.engagementQuestions.length) {
                    s.engagementQuestions.map(q => q.isSelected = false);
                }
            });
        }
    }

    getCurrentQuestionOptions() {
        return this.currentQuestion ? this.currentQuestion.engagementQuestionOptions.sort(Utilities.sortByDisplayOrder) : [];
    }

    updateCommentLabel(option: model.EngagementQuestionOption) {
        if (this.currentQuestion) {
            this.currentQuestion.selectedOption = option;
            this.currentQuestion.commentRequired = option.commentIsRequired;
        }
    }

    commentEdit() {
        // create new comment entity if new comment
        if (!this.currentComment) {
            this.currentComment = this.uow.engagementQuestionComments.createEntity();
            this.currentComment.engagementQuestionId = this.currentQuestion.id;
            this.currentComment.engagementQuestion = this.currentQuestion;
            this.currentComment.inspectionTeamMemberId = this.teamMember.id || null;
            this.currentComment.inspectionTeamMember = this.teamMember || null;
            this.currentQuestion.engagementQuestionComments.push(this.currentComment);
        } 
        if (this.commentText != null) {
           this.currentComment.text = this.commentText;
        }
    }

    editComment(comment: model.EngagementQuestionComment) {
        this.currentComment = comment;
        this.commentText = comment.text;
    }

    deleteComment(comment: model.EngagementQuestionComment) {
        const proceed = this.dialogHelper.confirm('Are you sure you would like to delete the comment?');

        proceed.subscribe(result => {
            if (result) {
                comment.entityAspect.setDeleted()
                this.uow.engagementQuestionComments.saveChanges([comment]).then(() => {
                    if (comment.id == this.currentComment.id) {
                        this.resetComment();
                    }
                });
            }
        })

    }

    clearClicked() {
        this.clearResponseForm();

        this.saveResponseForm(this.hasNoAnswerNeededQuestionOption());
    }

    saveResponseForm(markAsComplete: boolean) {
        this.isBusy.set(SAVING_KEY, true, 'Verifying response');

        return this.uow.checkQuestionForExistingResponse(this.currentQuestion.id, this.currentQuestion.selectedOptionId)
            .then(result => {
                if (result[0] == true) {
                    this.modalService.open(this.existingAnswerModal, { ariaLabelledBy: 'modal-basic-title' })
                        .result
                        .then((response) => {
                            if (response == 'save') {
                                return this.saveQuestionResponse(markAsComplete, true);
                            }
                        });
                }
                else {
                    return this.saveQuestionResponse(markAsComplete, false);
                }
            })
            .catch(reason => {
                this.errorHandler.handleError(reason);
            })
            .finally(() => {
                this.isBusy.set(SAVING_KEY, false);
            });
    }

    showExistingAnswerModal(): any {
        this.modalService.open(this.existingAnswerModal, { ariaLabelledBy: 'modal-basic-title' })
            .result
            .then((result) => {
                return result;
            });
    }



    resetQuestion() {
        this.uow.engagementQuestions.cancelChanges(this.currentQuestion);
    }

    private async saveComment() {
        this.isBusy.set('saving_comment', true);
        const commentChanges = this.currentQuestion.engagementQuestionComments.filter(c => c && c.entityAspect.entityState.isAddedModifiedOrDeleted());
        if (commentChanges) {
            //this.currentQuestion.engagementQuestionComments[1].
            commentChanges.forEach(c => c.originalText = c.text);
            await this.uow.engagementQuestionComments.saveChanges(this.currentQuestion.engagementQuestionComments)
                .then(() => /*this.resetComment()*/ { })
                .catch(reason => {
                    this.errorHandler.handleError(reason);
                })
                .finally(() => {
                    this.isBusy.set('saving_comment', false);
                });
        } else {
            this.isBusy.set('saving_comment', false);
        }
    }
    private async saveQuestionResponse(markAsComplete: boolean, replaceExisting: boolean) {
        this.isBusy.set(SAVING_KEY, true, 'Saving your response');

        this.currentQuestion.isComplete = markAsComplete;
        this.currentQuestion.respondingTeamMemberId = this.teamMember.id;
        this.currentQuestion.respondingTeamMember = this.teamMember;
        this.currentQuestion.responseDate = new Date();
        this.currentQuestion.completedDate = markAsComplete ? new Date() : null;

        await this.uow.getSelectedOptionActivatingTags(this.engagement.id, this.currentQuestion.id)
            .then(results => {
                this.engagementQuestionActivatingTags = results.map(tag => tag.tagId);
            })
            .catch(error => {
                console.log(error);
            });

        await this.uow.engagementQuestions.saveChanges([this.currentQuestion])
            .catch(reason => {
                this.errorHandler.handleError(reason);
            });

        const commentChanges = this.currentQuestion.engagementQuestionComments.filter(c => c && c.entityAspect.entityState.isAddedModifiedOrDeleted());
        if (commentChanges) {
            commentChanges.forEach(c => c.originalText = c.text);
            await this.uow.engagementQuestionComments.saveChanges(this.currentQuestion.engagementQuestionComments)
                .then(() => /*this.resetComment()*/ { })
                .catch(reason => {
                    this.errorHandler.handleError(reason);
                });
        }

        const updatedSections: model.EngagementSection[] = [];
        // Update triggered sections
        if (this.currentQuestion.engagementQuestionOptions && this.currentQuestion.engagementQuestionOptions.length) {
            const allEQO = this.currentQuestion.engagementQuestionOptions.filter(o => o.engagementSectionEngagementQuestionOptions && o.engagementSectionEngagementQuestionOptions.length);
            if (allEQO && allEQO.length) {
                allEQO.forEach(o => {
                    o.engagementSectionEngagementQuestionOptions.forEach(qo => {
                        if (this.currentQuestion.selectedOptionId == qo.engagementQuestionOptionId) {
                            qo.engagementSection.isActivated = true;
                        } else {
                            qo.engagementSection.isActivated = false;
                        }
                        if (!updatedSections.find(s => s.id == qo.engagementSection.id)) {
                            updatedSections.push(qo.engagementSection);
                        }
                    });
                });
            }
        }

        if (this.currentSection && this.currentSection.id == this.currentQuestion.engagementSectionId) {
            if (this.currentSection.engagementQuestions.every(q => q.isComplete)) {
                if (!updatedSections.find(s => s.id == this.currentSection.id)) {
                    updatedSections.push(this.currentSection);
                }
            }
        }

        if (updatedSections.length > 0) {
            await this.uow.engagementSections.saveChanges(updatedSections)
                .catch(reason => {
                    this.errorHandler.handleError(reason);
                });
        }

        if (replaceExisting) {
            return this.uow.updateQuestionResponse(this.currentQuestion.id, markAsComplete, new Date(), this.currentQuestion.selectedOptionId, this.commentText, replaceExisting)
                .then(result => {
                    this.resetComment();

                    const question = result[0];

                    if (question && question.id > 0) {
                        this.currentQuestion.isComplete = question.isComplete;
                        this.currentQuestion.selectedOptionId = question.selectedOptionId;
                        this.currentQuestion.respondingTeamMemberId = question.respondingTeamMemberId;
                        this.currentQuestion.respondingTeamMember = question.respondingTeamMember;
                        this.currentQuestion.responseDate = question.responseDate;
                        this.currentQuestion.engagementQuestionComments = question.engagementQuestionComments;
                    }

                    // Reload the sidebar tree-view, since new sections may have ben activated
                    this.reloadEngagementTree();
                })
                .catch(reason => {
                    this.errorHandler.handleError(reason);
                })
                .finally(() => {
                    this.isBusy.set(SAVING_KEY, false);
                });
        }

        if (this.engagementQuestionActivatingTags.length) {
            this.isBusy.set(UPDATING_KEY, true, 'Updating active questions');
            this.uow.updateActivatedEngagementQuestionsByActivatingTags(this.engagement.id, this.engagementQuestionActivatingTags)
                .then((result) => {
                    this.reloadEngagementTree();
                })
                .catch(reason => {
                    this.errorHandler.handleError(reason);
                })
                .finally(() => {
                    this.isBusy.set(UPDATING_KEY, false);
                })
        } else {
            this.reloadEngagementTree();
        }
    }

    resetComment() {
        this.currentComment = null;
        this.commentText = null;
    }

    questionHasChanges(question = this.currentQuestion): boolean {
        if (question) {
            const a = question.entityAspect.entityState.isModified();
            const b = question.engagementQuestionComments && question.engagementQuestionComments.length
                ? question.engagementQuestionComments.some(c => c.entityAspect.entityState.isAddedModifiedOrDeleted() || c == this.currentComment)
                : false;
            const c = this.currentComment && this.currentComment.engagementQuestionId == question.id ? this.currentComment.entityAspect.entityState.isAddedModifiedOrDeleted() : false;
            return a || b || c;
        }
        return false;
    }

    cancelChanges(question = this.currentQuestion) {
        if (question) {
            if (!this.hasNoAnswerNeededQuestionOption()) {
                question.entityAspect.rejectChanges();
            }
            
            if (question.engagementQuestionComments && question.engagementQuestionComments.length) {
                question.engagementQuestionComments.forEach(c => {
                    if (c && c.entityAspect.entityState.isAddedModifiedOrDeleted()) {
                        c.entityAspect.rejectChanges();
                    }
                });
            }
            if (this.currentComment && this.currentComment.entityAspect.entityState.isAddedModifiedOrDeleted()) {
                this.currentComment.entityAspect.rejectChanges();
            }
            this.currentComment = null;
            this.commentText = null;
        }
    }

    clearResponseForm() {
        this.cancelChanges();

        if (!this.hasNoAnswerNeededQuestionOption()) {
            this.currentQuestion.selectedOptionId = null;
        }
    }

    hasNoAnswerNeededQuestionOption() {
        return this.currentQuestion
            && this.currentQuestion.engagementQuestionOptions
            && this.currentQuestion.engagementQuestionOptions
                .filter(x => x.standardQuestionOption && x.standardQuestionOption.name === NO_ANSWER_NEEDED_NAME).length > 0;
    }

    populateFilterObject(eventValue, property) {
        switch (property) {
            case 'isComplete':
                this.questionFilters.isComplete = eventValue == false ? null : eventValue;
                break;

            case 'selectedOptionId':
                this.questionFilters.selectedOptionId = eventValue == false ? null : eventValue;
                break;

            case 'tag':
                this.questionFilters.tag = eventValue == false ? null : eventValue;
                break;

            default:
                break;
        }

        this.setFilterIsActive();
    }

    setFilterIsActive() {
        let filterIsNotNull = false;
        for (const property in this.questionFilters) {
            if (this.questionFilters[property]) {
                filterIsNotNull = true;
            }
        }

        this.filterIsActive = filterIsNotNull ? true : false

        if (!this.filterIsActive && this.currentQuestion) {
            this.checkParentSection();
        }
    }

    setCurrentFilteredSection() {
        if (this.currentSection != null) {
            this.currentFilteredSection = {
                ...this.currentSection,
                filteredEngagementQuestions: this.filteredQuestions[this.currentSection.id] == undefined ? [] : this.filteredQuestions[this.currentSection.id].filter(q => q.isActivated)
            }
        }
    }

    setCurrentFilteredQuestionNumber() {
        this.currentFilteredQuestionNumber = this.getIndexOfFilteredQuestion() + 1;
    }

    getIndexOfFilteredQuestion() {
        return this.currentFilteredSection.filteredEngagementQuestions.findIndex((question, index) => {
            return question.id == this.currentQuestion.id;
        });
    }

    setNextQuestionDisable() {
        if (this.currentSection == null) {
            this.nextQuestionDisabled = true;
        } else if (!this.filterIsActive) {
            this.nextQuestionDisabled = this.questionNumber == this.currentSection.engagementQuestions.filter(q => q.isActivated).length;
        } else {
            const filteredQuestLength = this.currentFilteredSection.filteredEngagementQuestions.filter(q => q.isActivated).length;
            this.nextQuestionDisabled = this.currentQuestion.displayOrder == filteredQuestLength;
        }
    }

    setPrevQuestionDisable() {
        if (!this.filterIsActive) {
            this.prevQuestionDisabled = this.questionNumber == 1
        } else {
            const indexOfCurrentQuestion = this.getIndexOfFilteredQuestion();
            this.prevQuestionDisabled = indexOfCurrentQuestion == 0 ? true : false;
        }
    }

    getTags() {
        return this.uow.getActiveTags()
            .then(result => {
                this.tags = result;
            });
    }

    getAllEngagementOptions() {
        for (let section of this.engagement.engagementSections) {
            for (let question of section.engagementQuestions) {
                for (let option of question.engagementQuestionOptions) {
                    if (!this.allEngagementOptions.find(o => o.standardQuestionOptionId == option.standardQuestionOptionId)) {
                        this.allEngagementOptions.push(option);
                    }
                }
            }
        }
    }

    filterQuestions(question: EngagementQuestion) {
        const engagementSectionId = question.engagementSectionId;

        if (!this.filteredQuestions[question.engagementSectionId]) {
            this.filteredQuestions = {
                ...this.filteredQuestions,
                [engagementSectionId]: [question],
            }
        } else if (!this.filteredQuestions[question.engagementSectionId].some(q => q.id == question.id)) {
            this.filteredQuestions[question.engagementSectionId].push(question)
        }
    }

    printReport() {
        const engagementId = this.engagement.id;
        const statusFilter = this.questionFilters.isComplete == 'null' ? '' : this.questionFilters.isComplete
        const answerFilter = this.questionFilters.selectedOptionId == 'null' ? '' : this.questionFilters.selectedOptionId
        const tagFilter = this.questionFilters.tag == 'null' ? '' : this.questionFilters.tag;
        const currentUrl = window.location.origin;

        this.http.get(
            `${currentUrl}/breeze/getEngagementReport?engagementId=${engagementId}&statusFilter=${statusFilter}&answerFilter=${answerFilter}&tagFilter=${tagFilter}`,
            { responseType: 'blob' }
        )
            .toPromise()
            .then(response => {
                var link = document.createElement('a');

                //use if user is on IE
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    link.target = "_blank";
                    var fileName = `${this.engagement.name}.pdf`;
                    link.click();
                    window.navigator.msSaveOrOpenBlob(response, fileName);
                } else {
                    window.open(URL.createObjectURL(response), '_blank');
                }
            }).catch(e => {
                console.error(e);
            });
    }
}
