import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'home-grid-pager',
    inputs: ['page', 'limit', 'pageCount', 'limits'],
    templateUrl: './home-pager.component.html'
})
export class HomeGridPager implements OnInit {
    @Input() page: number;
    @Input() limit: number;
    @Input() pageCount: number;
    @Input() limits: number[];

    ngOnInit() {
    }
}
