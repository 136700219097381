import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'grid-pager',
    inputs: ['actionName', 'page', 'limit', 'pageCount', 'limits'],
    templateUrl: './grid-pager.component.html'
})
export class GridPager implements OnInit {
    @Input() actionName: string;
    @Input() page: number;
    @Input() limit: number;
    @Input() pageCount: number;
    @Input() limits: number[];

    ngOnInit() {
    }
}
