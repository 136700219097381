<div class="header">
    <h3>Engagement Review Questionnaires</h3>
</div>
<div class="row">
    <div class="col tab-menu">
        <profile-nav-menu [currentTab]="'profile-sections'"></profile-nav-menu>
    </div>

    <div class="col">
        <h4 class="mb-3">Questionnaire Sections</h4>

        <grid-pager [actionName]="'../profile-sections'" [page]="page" [limit]="limit" [pageCount]="pageCount" [limits]="pageLimitOptions"></grid-pager>

        <div class="table-responsive">
            <table id="sections-grid" class="table table-striped table-align-middle my-2">
                <thead>
                    <tr class="headers-row">
                        <th class="col-action"></th>
                        <th style="width: 35%;">Name</th>
                        <th>Questionnaire</th>
                        <th style="width: 6rem;">Sections</th>
                        <th style="width: 6rem;">Questions</th>
                    </tr>
                    <tr class="filters-row">
                        <td>
                            <button type="button"
                                    class="btn btn-sm btn-block btn-secondary text-nowrap"
                                    (click)="clearFilters()">
                                <i class="fa fa-times"></i>
                                Clear
                            </button>
                        </td>
                        <td><input type="text" class="form-control form-control-sm" placeholder="Filter by..." [(ngModel)]="nameFilter" (change)="updateFilters('name', nameFilter)" /></td>
                        <td><input type="text" class="form-control form-control-sm" placeholder="Filter by..." [(ngModel)]="profileFilter" (change)="updateFilters('profileName', profileFilter)" /></td>
                        <td></td>
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="activeProfileSections.length == 0">
                        <td colspan="5">
                            <span class="d-block p-2 text-center">
                                <i class="fa fa-ban text-danger"></i>
                                No data to display&hellip;
                            </span>
                        </td>
                    </tr>
                    <tr *ngFor="let section of activeProfileSections">
                        <td></td>
                        <td>{{section.name}}</td>
                        <td>
                            <a [routerLink]="['../inspection-profile']" [queryParams]="{ id: section.inspectionProfileId }">{{section.profileName()}}</a>
                        </td>
                        <td class="text-right">{{section.sectionCount()}}</td>
                        <td class="text-right">{{section.questionCount()}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <grid-pager [actionName]="'../profile-sections'" [page]="page" [limit]="limit" [pageCount]="pageCount" [limits]="pageLimitOptions"></grid-pager>
    </div>
</div>
