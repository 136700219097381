<button type="button"
        class="btn btn-link p-0 bdo-action-link"
        (click)="setActiveSection(node)"
        *ngIf="node.isActivated">
    {{node.name}}
</button>

<ul *ngIf="node.isActivated && (activatedNodes.length > 0)">
    <li *ngFor="let n of activatedNodes">
        <section-list-item [node]="n" [context]="context"></section-list-item>
    </li>
</ul>
