import { Component, OnInit } from '@angular/core';
import { UnitOfWorkService } from '../../services/unit-of-work/unit-of-work.service';
import { ApplicationInsightsService } from '../../services/application-insights/application-insights.service';
import { Observable } from 'rxjs';
import { DialogHelper } from '../../services/dialog-helper';
import { IsBusyService } from '../../services/is-busy/is-busy.service';
import * as model from '../../../model/model';
import { GlobalErrorHandler } from '../../services/global-error-handler';

@Component({
    selector: 'app-tags',
    templateUrl: './tags.component.html',
})
export class TagsAdminComponent implements OnInit {

    tags: model.Tag[] = [];

    newTagName: string = null;

    get activeTags() {
        return this.tags.filter(m => !m.entityAspect.entityState.isDeleted() && !m.entityAspect.entityState.isDetached()).sort((a, b) => { return a.name.localeCompare(b.name); });
    }

    constructor(
		private uow: UnitOfWorkService,
        private appInsights: ApplicationInsightsService,
        private errorHandler: GlobalErrorHandler,
		public isBusy: IsBusyService,
		public dialogHelper: DialogHelper
	) {
        appInsights.logPageView();
    }

    ngOnInit() {
        this.isBusy.set('admin:loadData', true, 'Loading data');
        Promise.all([
            this.getTags()
        ])
        .catch(reason => {
            this.errorHandler.handleError(reason);
        })
        .finally(() => {
            this.isBusy.set('admin:loadData', false);
        });
    }

    canDeactivate(): Observable<boolean> | boolean {
        if (this.uow.hasChanges.value) {
            let proceed = this.dialogHelper.confirm();

            proceed.subscribe(result => {
                if (result) {
                    this.uow.rollback();
                }
            })

            return proceed;
        }

        return true;
    }


    getTags() {
        return this.uow.getTags()
            .then(result => {
                this.tags = result;
            });
    }


    saveTagChanges(tag: model.Tag, message: string) {
        this.isBusy.set('admin:saveTag', true, message);
        this.uow.tags.saveChanges([tag])
            .then(() => {
                this.newTagName = null;
            })
            .catch(reason => {
                this.errorHandler.handleError(reason);
            })
            .finally(() => {
                this.isBusy.set('admin:saveTag', false);
            });
    }

    createTag() {
        if (this.newTagName) {
            const newTag = this.uow.tags.createEntity();
            newTag.name = this.newTagName.trim();
            this.tags.push(newTag);

            this.saveTagChanges(newTag, 'Saving tag');
        }
    }

    updateTag(tag: model.Tag) {
        this.saveTagChanges(tag, 'Updating tag "' + tag.name + '"');
    }

    destroyTag(tag: model.Tag) {
        if (tag) {
            if (confirm('Are you sure you want to remove "' + tag.name + '"?')) {
                this.uow.tags.cancelChanges(tag);
                tag.entityAspect.setDeleted();

                this.saveTagChanges(tag, 'Removing tag "' + tag.name + '"');
            }
        }
    }
}
