import { Observable, of } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable()
export class DialogHelper {
    confirm(message?: string): Observable<boolean> {
        const confirmation = window.confirm(message || 'There are unsaved changes. Continue without saving?');

        return of(confirmation);
    }
}
