import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import * as model from '../../../../model/model';
import { UnitOfWorkService } from '../../../services/unit-of-work/unit-of-work.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-employee-search',
  templateUrl: './employee-search.component.html',
  styleUrls: ['./employee-search.component.less']
})
export class EmployeeSearchComponent implements OnInit, OnDestroy {
  private _model: any;
    list: model.Employee[] = [];
    debouncer = new Subject<string>();
    subs = new Array<Subscription>();

  @Input()
    set employeeList(data: model.Employee[]) {
      if (data.length > 0) {
        this.list = data;
      }
    }
  @Input() showButton: boolean = false;
  @Input() required: boolean = false;
  @Input() errorMessage: string = null;
    @Input() buttonScreenReaderText: string = null;
    @Input() debounceTime = 500;
  @Input() 
    get model(): any { return this._model; }
    set model(data: any) {
      this._model = data;
      this.uow.getEmployees(null, data).then(response => {
        this.employeeList = response;
      });
    }
  @Output() modelChange: EventEmitter<any> = new EventEmitter();
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  @Output() buttonClick: EventEmitter<any> = new EventEmitter();
  @Output() onSearch: EventEmitter<any> = new EventEmitter();

  filteredList: model.Employee[] = [];

    constructor(private uow: UnitOfWorkService) {
        this.subs.push(this.debouncer
            .pipe(debounceTime(this.debounceTime))
            .subscribe(
                (value: string) => { this.onSearch.emit(value); },
                (error) => { console.log(error); }
        ));
    }

  ngOnInit() {
  }

    ngOnDestroy() {
        for (const sub of this.subs) {
            sub.unsubscribe();
        }
    }

  emitChange() {
    this.modelChange.emit(this.model);
    this.onChange.emit();
  }

  buttonClicked() {
    this.buttonClick.emit();
  }

    search(e) {
        this.debouncer.next(e.term);
    //this.onSearch.emit(e.term);
  }
}
