<div class="header">
    <h3>Admin</h3>
</div>
<div class="row">
    <div class="col tab-menu">
        <admin-nav-menu [currentTab]="'clients'"></admin-nav-menu>
    </div>

    <div class="col col-constrained">
        <fieldset>
            <legend>Clients</legend>
            <p class="text-nowrap">
                <b>{{rowCount}}</b> {{(rowCount == 1 ? 'record' : 'records')}} found
            </p>

            <grid-pager [actionName]="'./clients'" [page]="page" [limit]="limit" [pageCount]="pageCount" [limits]="pageLimitOptions"></grid-pager>

            <table class="table table-sm table-align-middle">
                <thead>
                    <tr class="headers-row">
                        <th style="width: 4rem;"></th>
                        <th style="width: 1rem;">Id</th>
                        <th>Name</th>
                    </tr>
                    <tr class="filters-row">
                        <td>
                            <button type="button" class="btn btn-sm btn-block btn-secondary text-nowrap" (click)="clearFilters()">
                                <i class="fa fa-times"></i>
                                Clear
                            </button>
                        </td>
                        <td><input type="text" class="form-control form-control-sm" placeholder="Filter by..." [(ngModel)]="idFilter" (change)="updateDataGrid()" /></td>
                        <td><input type="text" class="form-control form-control-sm" placeholder="Filter by..." [(ngModel)]="nameFilter" (change)="updateDataGrid()" /></td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="activeClients.length == 0">
                        <td colspan="3">
                            <span class="d-block p-2 text-center">
                                <i class="fa fa-ban text-danger"></i>
                                No data to display&hellip;
                            </span>
                        </td>
                    </tr>
                    <tr *ngFor="let client of activeClients">
                        <td></td>
                        <td class="px-3 font-weight-bold">{{client.id}}</td>
                        <td>{{client.name}}</td>
                    </tr>
                </tbody>
            </table>

            <grid-pager [actionName]="'./clients'" [page]="page" [limit]="limit" [pageCount]="pageCount" [limits]="pageLimitOptions"></grid-pager>
        </fieldset>
    </div>
</div>
