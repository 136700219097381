import { Component, OnInit } from '@angular/core';
import { UnitOfWorkService } from '../../../services/unit-of-work/unit-of-work.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ApplicationInsightsService } from '../../../services/application-insights/application-insights.service';
import { ServerSideConfigDataService } from '../../../services/server-side-config-data/server-side-config-data.service';
import { Observable, Subscription } from 'rxjs';
import { DialogHelper } from '../../../services/dialog-helper';
import { IsBusyService } from '../../../services/is-busy/is-busy.service';
import { Employee } from '../../../../model/model';
import { MyInspectionsSummaryDto, GridFilter } from '../../../../model/modelDto';
import { ProgressStatus } from '../../../../model/modelEnums';
import { GlobalErrorHandler } from '../../../services/global-error-handler';

const LOADING_KEY: string = 'my-inspections:loadData';
const SAVING_KEY: string = 'my-inspections:saveData';
const DEFAULT_LIMIT: number = 15;

@Component({
    selector: 'app-my-inspections',
    templateUrl: './home.component.html',
})
export class MyInspectionsComponent implements OnInit {
    me: Employee;

    isLoading: boolean = false;
    currentYear = new Date().getFullYear();

    page: number = 1;
    limit: number = DEFAULT_LIMIT;
    pageCount: number = 1;
    rowCount: number = 0;
    engagementsCount: number = 0;
    pageLimitOptions = [15, 25, 50, 100];
    inspectionYears: number[] = [];
    inspectionYear: number = this.currentYear;

    filters: GridFilter[] = [];

    myInspectionsSummary: MyInspectionsSummaryDto[] = [];

    clientNames: string = null;
    engagementCodes: string = null;

    private subscriptions = new Subscription();

    constructor(private uow: UnitOfWorkService,
        private appInsights: ApplicationInsightsService,
        private errorHandler: GlobalErrorHandler,
        private activeRoute: ActivatedRoute,
        public router: Router,
		public isBusy: IsBusyService,
        public dialogHelper: DialogHelper,
        private serverData: ServerSideConfigDataService
    ) {
        appInsights.logPageView();
    }

    ngOnInit() {
        this.subscriptions.add(this.serverData.configDataLoaded$.subscribe(() => {
            this.me = this.serverData.configData.me;
        }));
        this.activeRoute.queryParams.subscribe(routeParams => {
            this.page = routeParams.page && !Number.isNaN(routeParams.page) ? parseInt(routeParams.page) : 1;
            this.limit = routeParams.limit && !Number.isNaN(routeParams.limit) ? parseInt(routeParams.limit) : DEFAULT_LIMIT;
            this.updateFilters('year', this.inspectionYear);
            this.updateDataGrid();
        });
    }

    canDeactivate(): Observable<boolean> | boolean {
        if (this.uow.hasChanges.value) {
            const proceed = this.dialogHelper.confirm();

            proceed.subscribe(result => {
                if (result) {
                    this.uow.rollback();
                }
            })

            return proceed;
        }

        return true;
    }


    get activeInspections() {
        return this.myInspectionsSummary.sort((a, b) => { return a.engagementClient.localeCompare(b.engagementClient); });
    }


    updateDataGrid() {
        this.isLoading = true;
        this.isBusy.set(LOADING_KEY, true, 'Loading your inspections');
        Promise
            .all([
                this.getRowCount(),
                this.getItems(this.page, this.limit)])
            .then(() => {
                this.getPageCount(this.limit);
                this.getInspectionYears();
            })
            .catch(reason => {
                this.errorHandler.handleError(reason);
            })
            .finally(() => {
                this.isBusy.set(LOADING_KEY, false);
                this.isLoading = false;
            });
    }

    updateFilters(key: string, value: any) {
        if (key) {
            if (!value || value == '') {
                this.removeFilter(key);
            } else {
                if (this.filters.some(m => m.key == key)) {
                    const filter = this.filters.find(m => m.key == key);
                    filter.value = value;
                }
                else {
                    this.filters.push({ key: key, value: value } as GridFilter)
                }
            }
        }
    }

    removeFilter(key: string) {
        const i = this.filters.findIndex(m => m.key == key);
        if (i >= 0) {
            this.filters.splice(i, 1);
        }
    }

    getPageCount(limit: number = DEFAULT_LIMIT) {
        if (this.rowCount > 0) {
            this.pageCount = Math.ceil(this.rowCount / limit);
            if (this.pageCount == 0) {
                this.pageCount = 1;
            }
        } else {
            this.pageCount = 1;
        }
    }

    getRowCount() {
        this.updateFilters('code', this.engagementCodes);
        if (this.inspectionYear != 0) {
            this.updateFilters('year', this.inspectionYear);
        }
        else {
            this.removeFilter('year');
        }
        return this.uow.getMyInspectionsCount(JSON.stringify(this.filters))
            .then(result => {
                this.rowCount = result[0];
            });
    }

    getItems(page: number = 1, limit: number = DEFAULT_LIMIT) {
        if (this.inspectionYear == 0) this.removeFilter('year');
        return this.uow.getMyInspections(page, limit, JSON.stringify(this.filters))
            .then(result => {
                this.myInspectionsSummary = result;
            });
    }

    getInspectionYears() {
        return this.uow.getInspectionYears()
            .then(result => {
                this.inspectionYears = result;
            });
    }

    applyPrimaryFilters() {
        this.updateFilters('code', this.engagementCodes);
        this.updateFilters('year', this.inspectionYear);
        this.updateDataGrid();
    }

    clearPrimaryFilters() {
        this.engagementCodes = '';
        this.inspectionYear = this.currentYear;
        this.updateDataGrid();
    }

    getProgressStatusName(status: ProgressStatus) {
        return this.uow.getProgressStatusName(status);
    }

    calculatePercentComplete(inspection: MyInspectionsSummaryDto, assignedToMeOnly: boolean): number {
        if (assignedToMeOnly && inspection.myAssignedSections > 0) {
            return inspection.myCompletedSections / inspection.myAssignedSections;
        }
        if (inspection.totalSections > 0) {
            return inspection.completedSections / inspection.totalSections;
        }
        return 0.0
    }
}
