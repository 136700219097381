import { Component, OnInit } from '@angular/core';
import { UnitOfWorkService } from '../../../services/unit-of-work/unit-of-work.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ApplicationInsightsService } from '../../../services/application-insights/application-insights.service';
import { Observable } from 'rxjs';
import { DialogHelper } from '../../../services/dialog-helper';
import { IsBusyService } from '../../../services/is-busy/is-busy.service';
import * as model from '../../../../model/model';
import * as modelDto from '../../../../model/modelDto';
import { GlobalErrorHandler } from '../../../services/global-error-handler';

const loadingKey: string = 'profile-questions:loadData';
const saveKey: string = 'profile-questions:saveChanges';
const defaultLimit: number = 15;

@Component({
    selector: 'app-profile-questions',
    templateUrl: './profile-questions.component.html',
})
export class ProfileQuestionsComponent implements OnInit {

    questions: model.Question[] = [];
    page: number = 1;
    limit: number = defaultLimit;
    pageCount: number = 1;
    pageLimitOptions = [15, 25, 50, 100];

    filters: modelDto.GridFilter[] = [];
    nameFilter: string = null;
    profileFilter: string = null;
    sectionFilter: string = null;
    tagsFilter: string = null;


    constructor(
		private uow: UnitOfWorkService,
        private appInsights: ApplicationInsightsService,
        private errorHandler: GlobalErrorHandler,
		private activeRoute: ActivatedRoute,
		public router: Router,
		public isBusy: IsBusyService,
		public dialogHelper: DialogHelper
	) {
        appInsights.logPageView();
    }

    ngOnInit() {
        this.activeRoute.queryParams.subscribe(routeParams => {
            this.page = routeParams.page && !Number.isNaN(routeParams.page) ? parseInt(routeParams.page) : 1;
            this.limit = routeParams.limit && !Number.isNaN(routeParams.limit) ? parseInt(routeParams.limit) : defaultLimit;

            this.updateDataGrid();
        });
    }

    canDeactivate(): Observable<boolean> | boolean {
        if (this.uow.hasChanges.value) {
            let proceed = this.dialogHelper.confirm();

            proceed.subscribe(result => {
                if (result) {
                    this.uow.rollback();
                }
            })

            return proceed;
        }

        return true;
    }


    get activeProfileQuestions() {
        return this.questions.filter(m => !m.entityAspect.entityState.isDeleted() && !m.entityAspect.entityState.isDetached());
    }

    getQuestions(page: number = 1, limit: number = defaultLimit) {
        return this.uow.getQuestions(page, limit, JSON.stringify(this.filters))
            .then(result => {
                this.questions = result;
            });
    }

    getPageCount(limit: number = defaultLimit) {
        return this.uow.getQuestionsPageCount(limit, JSON.stringify(this.filters))
            .then(result => {
                this.pageCount = result[0];
            });
    }

    updateFilters(key: string, value: any) {
        if (this.filters.some(m => m.key == key)) {
            let filter = this.filters.find(m => m.key == key);
            filter.value = value;
        }
        else {
            this.filters.push({ key: key, value: value } as modelDto.GridFilter)
        }

        this.updateDataGrid();
    }

    clearFilters() {
        this.filters = [];
        this.nameFilter = null;
        this.profileFilter = null;
        this.sectionFilter = null;
        this.tagsFilter = null;

        this.updateDataGrid();
    }

    updateDataGrid() {
        this.isBusy.set(loadingKey, true, 'Loading data');
        Promise
            .all([
                this.getPageCount(this.limit),
                this.getQuestions(this.page, this.limit)
            ])
            .catch(reason => {
                this.errorHandler.handleError(reason);
            })
            .finally(() => {
                this.isBusy.set(loadingKey, false);
            });
    }

    getDisplayText(question: model.Question) {
        return question.name || question.description.substr(0, 40) + '...';
    }

    saveProfileQuestionChanges(question: model.Question, message: string) {
        this.isBusy.set(saveKey, true, message);
        this.uow.questions.saveChanges([question])
            .then(() => {
            })
            .catch(reason => {
                this.errorHandler.handleError(reason);
            })
            .finally(() => {
                this.isBusy.set(saveKey, false);
            });
    }

    destroyProfileQuestion(question: model.Question) {
        if (question) {
            if (confirm('Are you sure you want to remove "' + question.name + '"?')) {
                question.entityAspect.setDeleted();
                this.saveProfileQuestionChanges(question, 'Removing question "' + question.name + '"');
            }
        }
    }

    cloneProfileQuestion(question: model.Question) {
        let uow = this.uow;
        if (question) {
            let newName: string = prompt('Please enter a name for the new question:', question.name);
            if (newName && newName.trim() != '') {
                let clone = uow.questions.createEntity();
                clone.name = question.name;
                clone.description = question.description;
                clone.helpText = question.helpText;
                clone.profileSection = question.profileSection;

                if (question.questionOptions) {
                    question.questionOptions.forEach(function (opt) {
                        let option = uow.questionOptions.createEntity();
                        option.text = opt.text;
                        option.standardQuestionOptionId = opt.standardQuestionOptionId;
                        option.question = clone;

                        opt.profileSectionQuestionOptions.forEach(function (psqo) {
                            let pqo = uow.profileSectionQuestionOptions.createEntity();
                            pqo.questionOption = option;
                            pqo.profileSection = psqo.profileSection;

                            option.profileSectionQuestionOptions.push(pqo);
                        });

                        clone.questionOptions.push(option);
                    });
                }

                if (question.questionTags) {
                    question.questionTags.forEach(function (qt) {
                        let questionTag = uow.questionTags.createEntity();
                        questionTag.question = clone;
                        questionTag.tag = qt.tag;
                        question.questionTags.push(questionTag);
                    });
                }
                
                this.isBusy.set(saveKey, true, 'Saving copy of "' + question.name + '" as "' + clone.name + '"');
                this.uow.questions.saveChanges([clone])
                    .then(() => {
                        this.questions.push(clone);
                    })
                    .catch(reason => {
                        this.errorHandler.handleError(reason);
                    })
                    .finally(() => {
                        this.isBusy.set(saveKey, false);
                    });
            }
        }
    }
}
