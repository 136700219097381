import { Injectable } from '@angular/core';
import { MetadataStore, Entity } from 'breeze-client';
import { Employee, ProfileSection, InspectionProfile, Question, QuestionTag, QuestionOption, Engagement, EngagementSection, EngagementQuestionComment, EngagementQuestionOption, Project, Client, AptCaw } from '../../../model/model';
import { ProgressStatus } from '../../../model/modelEnums';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ModelBuilderService {
    private metadataStore: MetadataStore;

    /**
     * Implement extended model properties here.
     * Declare them in modelExtended.d.ts
     */
    constructor() { }

    private computed<T extends Entity, Y>(entity: T, propNames: string[], func: (entity: T) => Y): BehaviorSubject<Y> {
        const subject = new BehaviorSubject<Y>(func(entity));

        entity.entityAspect.propertyChanged.subscribe(p => {
            if (propNames.find(n => n == p.propertyName)) {
                subject.next(func(entity));
            }
        });

        return subject;
    }

    private extendEmployee() {
        const myEntityCtor = function () { };

        const myEntityInitializer = (myEntity: Employee) => {
            myEntity.fullName = `${myEntity.firstName} ${myEntity.lastName}`;
        }
        this.metadataStore.registerEntityTypeCtor('Employee', myEntityCtor, myEntityInitializer);
    }
    
    private extendInspectionProfile() {
        const myEntityCtor = function () { };

        function sumQuestions(array: ProfileSection[]): number {
            let count = 0;

            if (array.length > 0) {
                array.forEach(function (section) {
                    count += section.questions.filter(m => !m.isDeleted).length;
                });
            }

            return count;
        }

        const myEntityInitializer = (myEntity: InspectionProfile) => {
            myEntity.businessLineName = () => {
                return myEntity.businessLine ? `${myEntity.businessLine.name}` : `---`;
            }
            myEntity.sectionCount = () => {
                if (myEntity.profileSections && myEntity.profileSections.length > 0) {
                    // All sections have a reference to the profile, regardless of how deep the section -> child section reference is,
                    // so just count the profile sections
                    return myEntity.profileSections.filter(m => !m.isDeleted).length;
                }

                return 0;
            }
            myEntity.questionCount = () => {
                if (myEntity.profileSections && myEntity.profileSections.length > 0) {
                    return sumQuestions(myEntity.profileSections.filter(m => !m.isDeleted));
                }

                return 0;
            }
        }

        this.metadataStore.registerEntityTypeCtor('InspectionProfile', myEntityCtor, myEntityInitializer);
    }

    private extendProfileSection() {
        const myEntityCtor = function () { };
        function sumQuestions(array: ProfileSection[]): number {
            let count = 0;

            if (array.length > 0) {
                array.forEach(function (section) {
                    // Increment by one for current section
                    count += section.questions != null ? section.questions.filter(m => !m.isDeleted).length : 0;
                    // Get count of this section's children
                    if (section.childSections) {
                        count += sumQuestions(section.childSections);
                    }
                });
            }

            return count;
        }

        function sumSections(array: ProfileSection[]): number {
            let count = 0;

            if (array.length > 0) {
                array.forEach(function (section) {
                    // Increment by one for current section
                    count += 1;
                    // Get count of this section's children
                    if (section.childSections) {
                        count += sumSections(section.childSections);
                    }
                });
            }

            return count;
        }

        const myEntityInitializer = (myEntity: ProfileSection) => {
            myEntity.profileName = () => {
                return myEntity.inspectionProfile ? `${myEntity.inspectionProfile.name}` : 'Unassigned';
            }
            myEntity.sectionCount = () => {
                if (myEntity.childSections) {
                    return sumSections(myEntity.childSections.filter(m => !m.isDeleted));
                }

                return 0;
            }
            myEntity.questionCount = () => {
                let count: number = myEntity.questions ? myEntity.questions.length : 0;
                if (myEntity.childSections && myEntity.childSections.length > 0) {
                    count += sumQuestions(myEntity.childSections.filter(m => !m.isDeleted));
                }

                return count;
            }
        }

        this.metadataStore.registerEntityTypeCtor('ProfileSection', myEntityCtor, myEntityInitializer);
    }

    private extendQuestion() {
        const myEntityCtor = function () { };

        const myEntityInitializer = (myEntity: Question) => {
            myEntity.profileName = () => {
                return myEntity.profileSection ? myEntity.profileSection.inspectionProfile ? `${myEntity.profileSection.inspectionProfile.name}` : `Unassigned` : `Unassigned`;
            }
            myEntity.sectionName = () => {
                return myEntity.profileSection ? `${myEntity.profileSection.name}` : `---`;
            }
        }

        this.metadataStore.registerEntityTypeCtor('Question', myEntityCtor, myEntityInitializer);
    }

    private extendQuestionTag() {
        const myEntityCtor = function () { };

        const myEntityInitializer = (myEntity: QuestionTag) => {
            myEntity.tagName = () => {
                return myEntity && myEntity.tag ? myEntity.tag.name : '';
            }
            myEntity.name = myEntity && myEntity.tag ? myEntity.tag.name: '';
        }

        this.metadataStore.registerEntityTypeCtor('QuestionTag', myEntityCtor, myEntityInitializer);
    }

    private extendQuestionOption() {
        const myEntityCtor = function () { };

        const myEntityInitializer = (myEntity: QuestionOption) => {
            myEntity.canSave = () => {
                return myEntity.standardQuestionOptionId > 0 || (myEntity.text && myEntity.text.trim() != '');
            }
        }

        this.metadataStore.registerEntityTypeCtor('QuestionOption', myEntityCtor, myEntityInitializer);
    }

    private extendEngagement() {
        const myEntityCtor = function () { };

        const myEntityInitializer = (myEntity: Engagement) => {
            myEntity.leadInspector = () => {
                return myEntity.inspectionLead ? myEntity.inspectionLead.fullName : '---';
            }
        }

        this.metadataStore.registerEntityTypeCtor('Engagement', myEntityCtor, myEntityInitializer);
    }

    private extendEngagementSection() {
        const myEntityCtor = function () { };

        const myEntityInitializer = (myEntity: EngagementSection) => {
            myEntity.status = () => {
                let questionStatus = ProgressStatus.na;
                let sectionStatus = ProgressStatus.na;

                if (myEntity.isActivated) {
                    if (myEntity.engagementQuestions && myEntity.engagementQuestions.length) {
                        if (myEntity.percentComplete() == 1) {
                            questionStatus = ProgressStatus.completed;
                        }
                        else if (myEntity.percentComplete() == 0) {
                            questionStatus = ProgressStatus.notStarted;
                        }
                        else {
                            questionStatus = ProgressStatus.inProgress;
                        }
                    }

                    if (myEntity.childSections && myEntity.childSections.filter(s => s.isActivated).length) {
                        if (myEntity.childSections.every(s => s.isActivated && s.status() == ProgressStatus.completed)) {
                            sectionStatus = ProgressStatus.completed;
                        }
                        else if (myEntity.childSections.some(s => s.isActivated && s.status() > ProgressStatus.notStarted)) {
                            sectionStatus = ProgressStatus.inProgress;
                        }
                        else if (myEntity.childSections.some(s => s.isActivated && s.status() > ProgressStatus.na)) {
                            sectionStatus = ProgressStatus.notStarted;
                        }

                        if (questionStatus > ProgressStatus.na) {
                            if (questionStatus > ProgressStatus.notStarted) {
                                if (questionStatus < sectionStatus) {
                                    sectionStatus = questionStatus;
                                }
                            }
                            else {
                                if (sectionStatus > ProgressStatus.notStarted) {
                                    if (sectionStatus != ProgressStatus.inProgress) {
                                        sectionStatus = ProgressStatus.inProgress;
                                    }
                                }
                            }
                        }
                    }
                    else {
                        sectionStatus = questionStatus;
                    }
                }

                return sectionStatus;
            }

            myEntity.percentComplete = () => {
                if (myEntity.engagementQuestions && myEntity.engagementQuestions.length) {
                    const completed = myEntity.engagementQuestions.filter(q => q.isComplete).length;
                    const total = myEntity.engagementQuestions.length;

                    if (total) {
                        return completed / total;
                    }
                }
                return null;
            }

            myEntity.completedQuestions = () => {
                if (myEntity.engagementQuestions && myEntity.engagementQuestions.length) {
                    return myEntity.engagementQuestions.filter(q => q.isComplete).length;
                }
                return null;
            }

            myEntity.totalQuestions = () => {
                if (myEntity.engagementQuestions && myEntity.engagementQuestions.length) {
                    return myEntity.engagementQuestions.length;
                }
                return null;
            }

            myEntity.nrfs = () => {
                if (myEntity.engagementQuestions && myEntity.engagementQuestions.length) {
                    return myEntity.engagementQuestions.filter(q => q.selectedOption && q.selectedOption.isNRF).length;
                }
                return null;
            }

            myEntity.oberservations = () => {
                if (myEntity.engagementQuestions && myEntity.engagementQuestions.length) {
                    return myEntity.engagementQuestions.filter(q => q.selectedOption && q.selectedOption.commentIsRequired).length;
                }
                return null;
            }
        }

        this.metadataStore.registerEntityTypeCtor('EngagementSection', myEntityCtor, myEntityInitializer);
    }
    
    private extendEngagementQuestionComment() {
        const myEntityCtor = function () { };

        const myEntityInitializer = (myEntity: EngagementQuestionComment) => {
            myEntity.originalText = myEntity.text;
        }

        this.metadataStore.registerEntityTypeCtor('EngagementQuestionComment', myEntityCtor, myEntityInitializer);
    }

    private extendEngagementQuestionOption() {
        const myEntityCtor = function () { };

        const myEntityInitializer = (myEntity: EngagementQuestionOption) => {
            myEntity.displayValue = () => {
                return myEntity.text == null ? myEntity.standardQuestionOption.value : myEntity.text;
            }
        }

        this.metadataStore.registerEntityTypeCtor('EngagementQuestionOption', myEntityCtor, myEntityInitializer);
    }

    private extendProject() {
        const myEntityCtor = function () { };

        const myEntityInitializer = (myEntity: Project) => {
            myEntity.label = myEntity.id + ' - ' + myEntity.name;
        }

        this.metadataStore.registerEntityTypeCtor('Project', myEntityCtor, myEntityInitializer);
    }

    private extendClient() {
        const myEntityCtor = function () { };

        const myEntityInitializer = (myEntity: Client) => {
            myEntity.label = myEntity.id + ' - ' + myEntity.name;
        }

        this.metadataStore.registerEntityTypeCtor('Client', myEntityCtor, myEntityInitializer);
    }

    private extendAptCaw() {
        const myEntityCtor = function () { };

        const myEntityInitializer = (myEntity: AptCaw) => {
            myEntity.label = myEntity.name + ' (' + myEntity.id + ')';
        }

        this.metadataStore.registerEntityTypeCtor('AptCaw', myEntityCtor, myEntityInitializer);
    }

    extendMetadata(metadataStore: MetadataStore) {
        this.metadataStore = metadataStore;

        this.extendEmployee();
        this.extendInspectionProfile();
        this.extendProfileSection();
        this.extendQuestion();
        this.extendQuestionTag();
        this.extendQuestionOption();
        this.extendEngagement();
        this.extendEngagementSection();
        this.extendEngagementQuestionComment();
        this.extendEngagementQuestionOption();
        this.extendProject();
        this.extendClient();
        this.extendAptCaw();
    }
}
