import { Component, OnInit, Injector } from '@angular/core';
import { UnitOfWorkService } from '../../../services/unit-of-work/unit-of-work.service';
import Utilities from '../../../services/utilities';
import { Router, ActivatedRoute } from '@angular/router';
import { ApplicationInsightsService } from '../../../services/application-insights/application-insights.service';
import { DialogHelper } from '../../../services/dialog-helper';
import { Observable } from 'rxjs';
import { IsBusyService } from '../../../services/is-busy/is-busy.service';
import * as model from '../../../../model/model';
import * as modelEnum from '../../../../model/modelEnums';
import { GlobalErrorHandler } from '../../../services/global-error-handler';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { SharedDataService } from '../../../services/shared-data/shared-data.service';
import { FileService } from '../../../services/file-service/file-service.service';

const LOADING_KEY: string = 'inspection-profile:loadData';
const SAVING_KEY: string = 'inspection-profile:saveData';

@Component({
    selector: 'app-inspection-profile',
    templateUrl: './inspection-profile-editor.component.html',
})
export class InspectionProfileAdminComponent implements OnInit {

    inspectionProfileStatuses: { status: modelEnum.InspectionProfileStatus, title: string }[] = [];
    industries: model.Industry[] = [];
    businessLines: model.BusinessLine[] = [];
    standardOptions: model.StandardQuestionOption[] = [];
    unassignedQuestions: model.Question[] = [];
    tags: model.Tag[] = [];
    activatingTags: model.EngagementQuestionActivatingTag[] = [];

    questionToCloneId: number = null;
    isExistingQuestion: boolean = false;

    id: string = null;
    inspectionProfile: model.InspectionProfile = {} as model.InspectionProfile;
    isLoading: boolean = true;
    reorderQuestions: boolean = false;
    reorderSections: boolean = false;

    constructor(
        private activeRoute: ActivatedRoute,
        private uow: UnitOfWorkService,
        private appInsights: ApplicationInsightsService,
        private errorHandler: GlobalErrorHandler,
        public router: Router,
        public isBusy: IsBusyService,
        public dialogHelper: DialogHelper,
        private injector: Injector,
        private dialog: MatDialog,
        private sharedData: SharedDataService,
        private fileService: FileService
    ) {
        appInsights.logPageView();
        this.dialog = this.injector.get(MatDialog);
        this.inspectionProfileStatuses = uow.inspectionProfileStatuses;
    }

    ngOnInit() {
        this.activeRoute.queryParams.subscribe(routeParams => {
            this.isLoading = true;
            this.isBusy.set(LOADING_KEY, true, 'Loading questionnaire data');
            Promise
                .all([
                    this.getBusinessLines(),
                    this.getStandardQuestionOptions(),
                    this.getUnassignedQuestions(),
                    this.getTags(),
                ])
                .then(() => {
                    this.getInspectionProfile(routeParams.id);
                })
                .catch(reason => {
                    this.errorHandler.handleError(reason);
                })
                .finally(() => {
                    this.isBusy.set(LOADING_KEY, false);
                    this.isLoading = false;
                });
        });
    }

    canDeactivate(): Observable<boolean> | boolean {
        if (this.uow.hasChanges.value) {
            console.log(this.uow.getChanges());
            let proceed = this.dialogHelper.confirm();

            proceed.subscribe(result => {
                if (result) {
                    this.uow.rollback();
                }
            })

            return proceed;
        }

        return true;
    }

    get rootProfileSections() {
        return this.inspectionProfile && this.inspectionProfile.profileSections ? this.inspectionProfile.profileSections.filter(m => !m.parentSectionId).sort(Utilities.sortByDisplayOrder) : [];
    }

    get currentUnassignedQuestions() {
        return this.unassignedQuestions ? this.unassignedQuestions.filter(m => !m.profileSectionId).sort(Utilities.sortByName) : [];
    }

    get activeSection(): model.ProfileSection {
        return this.inspectionProfile && this.inspectionProfile.profileSections ? this.inspectionProfile.profileSections.find(m => m.isEditing) : {} as model.ProfileSection;
    }

    get activeQuestion(): model.Question {
        return this.activeSection && this.activeSection.questions ? this.activeSection.questions.find(m => m.isEditing) : {} as model.Question;
    }

    get activeQuestionOptions() {
        return this.activeQuestion.questionOptions.filter(m => !m.isDeleted).sort(Utilities.sortByDisplayOrder);
    }

    get currentTags() {
        return this.activeQuestion && this.activeQuestion.questionTags ? this.activeQuestion.questionTags.filter(m => m.tag).sort(Utilities.sortByName) : [];
    }

    get canChangeStatus() {
        return this.inspectionProfile.id > 0 && (!this.inspectionProfile.engagements || !this.inspectionProfile.engagements.some(m => !m.isDeleted));
    }

    get nextQuestion(): model.Question {
        const psQuestions = this.activeSection ? this.activeSection.questions : null;
        if (psQuestions && this.activeQuestion.id !== psQuestions[psQuestions.length - 1].id) {
            const currentQuestionIndex = psQuestions.findIndex(q => q.id == this.activeQuestion.id);
            const nextQuest = currentQuestionIndex > -1 ? psQuestions[currentQuestionIndex + 1] : null;
            return nextQuest;
        } else if (psQuestions && this.activeQuestion.id == psQuestions[psQuestions.length - 1].id) {
            const currentPSIndex = this.inspectionProfile.profileSections.findIndex(ps => ps.id == this.activeSection.id);
            const newPS = currentPSIndex + 1 <= this.inspectionProfile.profileSections.length - 1 ? this.inspectionProfile.profileSections[currentPSIndex + 1] : null;
            if (newPS && newPS.questions.length > 0) {
                return newPS.questions[0];
            }
        }
        return null;
    }

    get previousQuestion(): model.Question {
        const psQuestions = this.activeSection ? this.activeSection.questions : null;
        if (psQuestions && this.activeQuestion.id !== psQuestions[0].id) {
            const currentQuestionIndex = psQuestions.findIndex(q => q.id == this.activeQuestion.id);
            const previousQuest = currentQuestionIndex > -1 ? psQuestions[currentQuestionIndex - 1] : null;
            return previousQuest;
        } else if (psQuestions && this.activeQuestion.id == psQuestions[0].id) {
            const currentPSIndex = this.inspectionProfile.profileSections.findIndex(ps => ps.id == this.activeSection.id);
            const newPS = currentPSIndex - 1 >= 0 ? this.inspectionProfile.profileSections[currentPSIndex - 1] : null;
            if (newPS && newPS.questions.length > 0) {
                return newPS.questions[newPS.questions.length - 1];
            }
        }
        return null;
    }

    get hasChanges() {
        const a = this.uow.inspectionProfiles.hasChanges.value;
        const b = this.uow.questions.hasChanges.value;
        const c = this.uow.profileSections.hasChanges.value;
        const d = this.uow.profileSectionQuestionOptions.hasChanges.value;
        const e = this.uow.questionTags.hasChanges.value;
        const f = this.uow.questionOptions.hasChanges.value;

        return a || b || c || d || e || f;
    }

    undoChanges() {
        if (this.hasChanges) {
            this.uow.rollback();
        }
    }

    getBusinessLines() {
        return this.uow.getBusinessLines()
            .then(result => {
                this.businessLines = result;
            });
    }

    getStandardQuestionOptions() {
        return this.uow.getStandardQuestionOptions()
            .then(result => {
                this.standardOptions = result;
            });
    }

    getUnassignedQuestions() {
        return this.uow.getUnassignedQuestions()
            .then(result => {
                this.unassignedQuestions = result;
            });
    }

    getTags() {
        return this.uow.getActiveTags()
            .then(result => {
                this.tags = result;
            });
    }

    getInspectionProfile(id: string) {
        this.id = id;

        if (id == 'new') {
            this.inspectionProfile = this.uow.inspectionProfiles.createEntity();
            this.inspectionProfile.inspectionProfileStatus = modelEnum.InspectionProfileStatus.draft;
        } else {
            this.isBusy.set('questData', true, 'Loading questions');
            return this.uow.getInspectionProfileById(parseInt(id))
                .then(result => {
                    if (result && result.length > 0) {
                        this.inspectionProfile = result[0];
                        if (this.inspectionProfile.profileSections && this.inspectionProfile.profileSections.length) {
                            this.inspectionProfile.profileSections.sort(Utilities.sortByDisplayOrder).forEach(s => s.questions.sort(Utilities.sortByDisplayOrder));
                        }
                        this.clearQuestionEditor();
                    }
                    else {
                        this.router.navigate(['./inspection-profiles'], { relativeTo: this.activeRoute.parent });
                    }
                })
                .catch((reason) => {
                    this.errorHandler.handleError(reason);
                })
                .finally(() => {
                    this.isBusy.set('questData', false);
                });
        }
    }


    addRootSection() {
        let name: string = null;
        if (name = prompt('Please enter new section name:')) {
            const newSection = this.uow.profileSections.createEntity();
            newSection.name = name;
            newSection.displayOrder = (this.maxNodeOrder(this.inspectionProfile.profileSections) || 0) + 1;
            newSection.inspectionProfile = this.inspectionProfile;

            this.uow.profileSections.saveChanges([newSection])
                .then(() => {
                    this.setActiveSection(newSection);

                    this.uow.propagateProfileSectionChanges(newSection.id);
                });
        }
    }

    addSection(parentSection: model.ProfileSection) {
        let name: string = prompt('Please enter new section name:');
        if (name != null) {
            const section = this.uow.profileSections.createEntity();
            section.name = name;
            section.parentSection = parentSection;
            section.displayOrder = (this.maxNodeOrder(parentSection.childSections) || 0) + 1;
            section.inspectionProfile = this.inspectionProfile;

            this.uow.profileSections.saveChanges([section])
                .then(() => {
                    this.setActiveSection(section);

                    this.uow.propagateProfileSectionChanges(section.id);
                });
        }
    }

    saveSectionChanges(section: model.ProfileSection) {
        return this.uow.profileSections.saveChanges([section])
            .then(() => {
                this.uow.propagateProfileSectionChanges(section.id);
            });
    }

    removeSection(section: model.ProfileSection) {
        if (section.id > 0) {
            section.entityAspect.setDeleted();

            this.uow.profileSections.saveChanges([section])
                .then(() => {
                    this.uow.propagateProfileSectionChanges(section.id);
                });
        }
        else {
            this.uow.profileSections.cancelChanges(section);
        }
    }

    setActiveSection(section?: model.ProfileSection) {
        this.inspectionProfile.profileSections.forEach(m => {
            m.questions.forEach(q => q.isEditing = false);
            m.isEditing = false;
        });

        if (section) {
            section.isEditing = true;
        }

        this.clearQuestionEditor();
    }


    expandAll() {
        if (this.inspectionProfile && this.inspectionProfile.profileSections) {
            this.inspectionProfile.profileSections.forEach((m) => { m.isCollapsed = false; })
        }
    }

    collapseAll() {
        if (this.inspectionProfile && this.inspectionProfile.profileSections) {
            this.inspectionProfile.profileSections.forEach((m) => { m.isCollapsed = true; })
        }
    }

    openReorderQuestionComp() {
        this.reorderQuestions = true;
    }

    openReorderSectionComp() {
        this.reorderSections = true;
    }

    maxNodeOrder(nodes: model.ProfileSection[]): number {
        return nodes && nodes.length > 0 ? Math.max.apply(Math, nodes.map(function (o) { return o.displayOrder; })) : 0;
    }

    maxQuestionOrder(questions: model.Question[]): number {
        return questions && questions.length > 0 ? Math.max.apply(Math, questions.map(function (o) { return o.displayOrder; })) : 0;
    }

    maxOptionOrder(options: model.QuestionOption[]): number {
        return options && options.length > 0 ? Math.max.apply(Math, options.map(function (o) { return o.displayOrder; })) : 0;
    }


    addOption() {
        if (!this.activeQuestion.questionOptions) {
            this.activeQuestion.questionOptions = [];
        }

        const option = this.uow.questionOptions.createEntity();
        option.standardQuestionOptionId = null;
        option.displayOrder = (this.maxOptionOrder(this.activeQuestion.questionOptions) || 0) + 1;
        option.question = this.activeQuestion;
    }

    removeOption(option: model.QuestionOption) {
        if (confirm('Are you sure you want to remove this option?')) {
            if (option.id > 0) {
                option.isDeleted = true;
            }
            else {
                this.uow.questionOptions.cancelChanges(option);
            }
        }
    }

    checkOption(option: model.QuestionOption, section: model.ProfileSection) {
        if (option) {
            return this.activeSection && this.activeSection.id == section.id;
        }

        return false;
    }


    addTriggeredSection(m: string, option: model.QuestionOption) {
        const $select = $(m);
        const id = $select.val();

        if (!option.profileSectionQuestionOptions) {
            option.profileSectionQuestionOptions = [];
        }

        if (!option.profileSectionQuestionOptions.some(m => m.profileSectionId == id)) {
            const psqo = this.uow.profileSectionQuestionOptions.createEntity();
            const section = this.inspectionProfile.profileSections.find(m => m.id == id);
            if (section) {
                psqo.profileSection = section
                psqo.questionOption = option;
            }

            option.profileSectionQuestionOptions.push(psqo);
        }

        $select.find('option:selected').prop('disabled', true);

        return $select.find('option:selected').val();
    }

    addTriggeredQuestionTag(tag: string, option: model.QuestionOption) {
        const $select = $(tag);
        const selectedTagId = $select.val();

        if (!option.profileQuestionActivatingTags) {
            option.profileQuestionActivatingTags = [];
        }

        if (!option.profileQuestionActivatingTags.some(m => m.tagId == selectedTagId)) {
            const pqat = this.uow.profileQuestionActivatingTags.createEntity();
            const question = option.question;
            const tag = this.tags.find(t => t.id == selectedTagId);

            pqat.question = question;
            pqat.questionOption = option;
            pqat.tag = tag;

            option.profileQuestionActivatingTags.push(pqat);
        }

        $select.find('option:selected').prop('disabled', true);

        return $select.find('option:selected').val();
    }

    removeSectionFromOption(m: string, option: model.QuestionOption, section: model.ProfileSectionQuestionOption) {
        const $select = $(m);
        $select.find('option[value="' + section.profileSectionId + '"]').prop('disabled', false);

        if (section.id > 0) {
            const associatedSectionActivatingOption = section.profileSection.engagementSections.forEach(es => es.engagementSectionEngagementQuestionOptions.forEach(eseqo => {
                if (eseqo.engagementSectionId == es.id && eseqo.engagementQuestionOption.questionOptionId == section.questionOptionId) {
                    eseqo.engagementSection.isActivated = true;
                    eseqo.engagementSection.entityAspect.setModified();
                    eseqo.entityAspect.setDeleted();
                }
                section.entityAspect.setDeleted();
            }));
        }
        else {
            this.uow.profileSectionQuestionOptions.cancelChanges(section);
        }
    }

    removeTagFromOption(m: string, option: model.QuestionOption, tag: model.ProfileQuestionActivatingTag) {
        const $select = $(m);
        $select.find('option[value="' + tag.id + '"]').prop('disabled', false);

        if (tag.id > 0) {
            const associatedEngagementQuestionActivatingTag = tag.tag.engagementQuestionActivatingTags.find(eqat => eqat.tagId == tag.tagId && tag.questionOptionId == eqat.engagementQuestionOption.questionOptionId);
            if (associatedEngagementQuestionActivatingTag) {
                associatedEngagementQuestionActivatingTag.entityAspect.setDeleted();
            }
            tag.entityAspect.setDeleted();
        }
        else {
            this.uow.profileQuestionActivatingTags.cancelChanges(tag);
        }
    }

    addTag(tagId: number) {
        if (!this.activeQuestion.questionTags) {
            this.activeQuestion.questionTags = [];
        }

        if (!this.activeQuestion.questionTags.some(m => m.tagId == tagId)) {
            const qt = this.uow.questionTags.createEntity();
            const tag = this.tags.find(m => m.id == tagId);
            qt.tag = tag;
            qt.question = this.activeQuestion;
        }

        return null;
    }

    removeTag(tag: model.QuestionTag) {
        if (tag.id > 0) {
            tag.entityAspect.setDeleted();
        }
        else {
            this.uow.questionTags.cancelChanges(tag);
        }
    }


    clearQuestionEditor() {
        if (this.activeQuestion) {
            this.activeQuestion.isEditing = false;
        }

        this.isExistingQuestion = false;
    }

    cancelQuestionChanges() {
        if (this.activeQuestion) {
            this.uow.rollback();
        }

        this.clearQuestionEditor();
    }

    saveQuestionChanges() {
        this.isBusy.set(SAVING_KEY, true, 'Saving changes to current question');

        return this.uow.commit()
            .then(() => {
                this.isBusy.set(SAVING_KEY, false);
                this.isBusy.set(SAVING_KEY, true, 'Done! Propagating question updates to engagements');

                return this.uow.propagateQuestionChanges(this.activeQuestion.id)
                    .then(() => {
                        this.clearQuestionEditor();
                    })
                    .catch(reason => {
                        this.errorHandler.handleError(reason);
                    })
                    .finally(() => {
                        this.isBusy.set(SAVING_KEY, false);
                    });
            })
            .catch(reason => {
                this.errorHandler.handleError(reason);
            })
            .finally(() => {
                this.isBusy.set(SAVING_KEY, false);
                this.sharedData.setMyEngagements(null);
            });
    }

    addQuestion() {
        this.clearQuestionEditor();

        const question = this.uow.questions.createEntity();
        question.isEditing = true;
        question.displayOrder = (this.maxQuestionOrder(this.activeSection.questions) || 0) + 1;
        question.profileSection = this.activeSection;
    }

    editQuestion(question: model.Question) {
        this.clearQuestionEditor();
        question.isEditing = true;
        this.isExistingQuestion = true;
    }

    editNavigatedQuestion(question: model.Question) {
        if (this.activeQuestion.entityAspect.entityState.isModified()) {
            this.dialog.open(ConfirmationDialogComponent, {
                data: {
                    title: "Confirm",
                    content: `There are unsaved changes. Continue without saving?`
                }
            })
                .afterClosed().toPromise().then(response => {
                    if (response == "Yes") {
                        this.uow.rollback();
                        this.toNextQuestion(question);
                    }
                });
        } else {
            this.toNextQuestion(question);
        }
    }

    toNextQuestion(question: model.Question) {
        if (question.profileSectionId !== this.activeSection.id) {
            this.setActiveSection(question.profileSection);
        }
        this.editQuestion(question);
    }

    removeQuestion(question: model.Question) {
        this.isBusy.set('deleteQuestion', true, 'Deleting question');
        if (question.questionOptions.length) {
            question.questionOptions.forEach((opt) => {
                if (opt.profileSectionQuestionOptions.length) {
                    while (opt.profileSectionQuestionOptions.length) {
                        opt.profileSectionQuestionOptions[0].entityAspect.setDeleted();
                    }
                }
            });
            while (question.questionOptions.length) {
                question.questionOptions[0].entityAspect.setDeleted();
            }
        }

        question.entityAspect.setDeleted();
        this.clearQuestionEditor();
        this.uow.commit(true)
            .then(() => {
                this.isBusy.set('deleteQuestion', false);
            });;
    }

    duplicateQuestion(question: model.Question) {
        this.isBusy.set('copyQuestion', true, 'Duplicating question');
        const newQuestion = this.uow.questions.createEntity();
        newQuestion.name = question.name;
        newQuestion.description = question.description;
        newQuestion.helpText = question.helpText;
        newQuestion.displayOrder = question.displayOrder;
        newQuestion.profileSectionId = question.profileSectionId;

        // duplicate all question options
        if (question.questionOptions.length) {
            question.questionOptions.forEach((option) => {
                const newOpt = this.uow.questionOptions.createEntity();
                newOpt.text = option.text;
                newOpt.displayOrder = option.displayOrder;
                newOpt.questionId = newQuestion.id;
                newOpt.standardQuestionOptionId = option.standardQuestionOptionId;
                newOpt.isNRF = option.isNRF;
                newOpt.commentIsRequired = option.commentIsRequired;

                if (option.profileSectionQuestionOptions.length) {
                    option.profileSectionQuestionOptions.forEach((psqo) => {
                        const newPSQO = this.uow.profileSectionQuestionOptions.createEntity();
                        newPSQO.profileSectionId = psqo.profileSectionId;
                        newPSQO.questionOptionId = newOpt.id;
                    });
                }
            });
        }

        // duplicate all question tags
        if (question.questionTags.length) {
            question.questionTags.forEach((tag) => {
                const newQTag = this.uow.questionTags.createEntity();
                newQTag.questionId = newQuestion.id;
                newQTag.tagId = tag.id;
            });
        }

        const section = this.inspectionProfile.profileSections.find(s => s.id == question.profileSectionId);
        section.questions.push(newQuestion);
        section.questions.sort(Utilities.sortByDisplayOrder);
        newQuestion.isNewQuestion = true;

        this.uow.commit(true)
            .then(() => {
                this.isBusy.set('copyQuestion', false);
                setTimeout(() => newQuestion.isNewQuestion = false, 1000);
            });
    }

    loadQuestionFromExisting() {
        if (this.questionToCloneId) {
            if (this.activeQuestion) {
                if (this.activeQuestion.name || this.activeQuestion.description || this.activeQuestion.helpText || this.activeQuestion.questionOptions) {
                    if (confirm('Selecting an unassigned question will override the current values. Continue?')) {
                        this.loadUnassignedQuestion(this.questionToCloneId);
                    }
                }
                else {
                    this.loadUnassignedQuestion(this.questionToCloneId);
                }
            }
            else {
                this.loadUnassignedQuestion(this.questionToCloneId);
            }
        }
    }

    loadUnassignedQuestion(id: number) {
        this.uow.questions.cancelChanges(this.activeQuestion);
        const questionToClone = this.unassignedQuestions.find(m => m.id == id);
        if (questionToClone) {
            questionToClone.profileSection = this.activeSection;
            questionToClone.isEditing = true;
            questionToClone.displayOrder = (this.maxQuestionOrder(this.activeSection.questions) || 0) + 1;
            this.isExistingQuestion = false;
        }
    }


    getStatusName(status: modelEnum.InspectionProfileStatus) {
        const match = this.inspectionProfileStatuses.find(m => m.status == status);
        return match ? match.title : 'N/A';
    }


    saveChanges(silent: boolean = false) {
        this.uow.inspectionProfiles.saveChanges([this.inspectionProfile])
            .then(() => {
                if (!confirm('Questionnaire saved! Continue editing "' + this.inspectionProfile.name + '"?')) {
                    this.router.navigate(['./inspection-profiles'], { relativeTo: this.activeRoute.parent });
                }
                else {
                    if (this.id == 'new') {
                        this.router.navigate(['./edit'], { queryParams: { id: this.inspectionProfile.id }, relativeTo: this.activeRoute.parent });
                    }
                }
            })
            .catch(reason => {
                this.errorHandler.handleError(reason);
            });
    }

    goHome() {
        this.router.navigate(['./inspection-profiles'], { relativeTo: this.activeRoute.parent });
    }
}
