<div class="form-row">
    <div class="col-auto">
        <button type="button"
                data-toggle="button"
                class="btn btn-secondary tree-node-toggle active"
                aria-pressed="true"
                (click)="toggleSection()">
            <i class="fa fa-tree-state text-primary"></i>
        </button>
    </div>
    <div class="col mb-0">
        <div class="tree-node-header">
            <div class="input-group">
                <input type="text"
                       class="form-control editor-lg tree-node-name"
                       name="section-name-{{i}}"
                       [(ngModel)]="node.name"
                       (focus)="this.setActiveSection()"
                       required />
                <div class="input-group-append">
                    <button class="btn btn-outline-primary"
                            type="button"
                            (click)="this.saveSectionChanges()">
                        <i class="fa fa-fw fa-save"></i>
                    </button>
                    <button class="btn btn-outline-secondary"
                            type="button"
                            *ngIf="node.childSections.length == 0 && node.questions.length == 0"
                            (click)="this.remove()">
                        <i class="fa fa-fw fa-trash"></i>
                    </button>
                    <span *ngIf="node.childSections.length > 0 || node.questions.length > 0"
                          class="input-group-text"
                          [placement]="['top', 'auto']"
                          ngbTooltip="To be removed, this section must have no child sections or questions" container="body">
                        <i class="fa fa-fw fa-info-circle text-info"></i>
                    </span>
                </div>
            </div>
            <small class="form-text text-danger validation-message">Section name is required.</small>
        </div>
        <div class="tree-node-content collapse" [class.show]="!node.isCollapsed">
            <div class="tree-node-menu">
                <button type="button" class="mr-2 btn btn-link" (click)="this.addSection()">
                    <i class="fa fa-plus"></i>
                    Add section
                </button>
                <button type="button" class="btn btn-link" (click)="this.addQuestion()">
                    <i class="fa fa-plus"></i>
                    Add question
                </button>
            </div>
            <table class="question-list mb-0">
                <tr class="question-node" *ngFor="let question of node.questions" [class.new-question]="question.isNewQuestion">
                    <td>
                        <button type="button"
                                class="btn btn-secondary mx-1 p-1"
                                [disabled]="question.engagementQuestions && question.engagementQuestions.length > 0"
                                title="Clone question"
                                (click)="duplicateQuestion(question)">
                            <i class="fa fa-clone"></i>
                        </button>
                        <button type="button"
                                class="btn btn-link text-danger my-0 p-0"
                                [disabled]="question.engagementQuestions && question.engagementQuestions.length > 0"
                                title="Delete question"
                                (click)="this.removeQuestion(question)">
                            <i class="fa fa-trash"></i>
                        </button>
                    </td>
                    <td><button type="button" (click)="this.editQuestion(question)" class="btn btn-link my-0 p-0">{{getDisplayText(question)}}</button></td>
                </tr>
            </table>
            <ol class="tree-node section-node mt-2" *ngIf="node.childSections && node.childSections.length > 0">
                <li class="tree-node-item open" *ngFor="let n of currentNodes; index as i">
                    <section-tree-node-editor [node]="n" [i]="i" [inspectionProfile]="inspectionProfile" [context]="context"></section-tree-node-editor>
                </li>
            </ol>
        </div>
    </div>
</div>
