<div class="header">
    <h3>My Assignments</h3>
</div>

<div class="p-3 mb-3 bg-bdo-secondary">
    <fieldset>
        <legend>Search My Inspections</legend>

        <div class="form-group">       
            <div class="form-inline">
                <label for="code">Engagement Code</label>
                <input type="text"
                       class="form-control editor-sm ml-2"
                       name="code"
                       id="code"
                       [(ngModel)]="engagementCodes" />
                <div class="form-group">
                    <label for="inspectionYear" class="ml-2">Inspection Year</label>
                    <select id="inspectionYear" name="inspectionYear" class="custom-select ml-2" [(ngModel)]="inspectionYear">
                        <option [value]="0">Select All</option>
                        <option *ngFor="let year of inspectionYears" [value]="year">{{year}}</option>
                    </select>
                </div>
                <button type="button"
                        class="btn btn-primary text-nowrap ml-2"
                        (click)="applyPrimaryFilters()">
                    <i class="fa fa-search"></i>
                    Search
                </button>
                <button type="button"
                        class="btn btn-secondary text-nowrap ml-2"
                        (click)="clearPrimaryFilters()">
                    <i class="fa fa-times"></i>
                    Clear
                </button>
            </div>
        </div>
    </fieldset>
</div>

<div class="row">
    <div class="col">
        <p class="text-nowrap">
            <b>{{rowCount}}</b> {{(rowCount == 1 ? 'record' : 'records')}} found
        </p>

        <home-grid-pager [page]="page" [limit]="limit" [pageCount]="pageCount" [limits]="pageLimitOptions"></home-grid-pager>

        <div class="">
            <table id="engagements-grid" class="table table-striped table-align-middle my-2">
                <thead>
                    <tr class="headers-row">
                        <th style="width: 6rem;">Engagement Code</th>
                        <th style="min-width: 18rem;">Client Name</th>

                        <th style="width: 6rem;">Year</th>
                        <th style="width: 10rem;">Status</th>

                        <th rowspan="2" style="width: 10rem;">My Assigned Sections</th>
                        <th rowspan="2" style="width: 10rem;">My Completed Sections</th>
                        <!--<th rowspan="2" style="width: 50px;" class="text-nowrap" colspan="2">% Complete</th>

                        <th rowspan="2" style="width: 10rem;">Total Sections</th>
                        <th rowspan="2" style="width: 10rem;">Completed Sections</th>
                        <th rowspan="2" style="width: 50px;" class="text-nowrap" colspan="2">% Complete</th>-->

                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="activeInspections.length == 0">
                        <td colspan="10">
                            <span class="d-block p-2 text-center" *ngIf="!isLoading">
                                <i class="fa fa-ban text-danger"></i> No data to display&hellip;
                            </span>
                            <span class="d-block p-2 text-center" *ngIf="isLoading">
                                <i class="fa fa-spin fa-refresh text-primary"></i> Please wait while we load your inspections&hellip;
                            </span>
                        </td>
                    </tr>
                    <tr *ngFor="let inspection of activeInspections">
                        <td class="text-nowrap">
                            <a [routerLink]="['./inspection']" [queryParams]="{ id: inspection.engagementId }" class="engagment-link">
                                {{inspection.engagementCode}}
                            </a>
                        </td>
                        <td>
                            <a [routerLink]="['./inspection']" [queryParams]="{ id: inspection.engagementId }" class="engagment-link">
                                {{inspection.engagementClient}}
                            </a>
                        </td>
                        <td class="text-nowrap">{{inspection.inspectionYear}}</td>
                        <td class="text-nowrap">{{getProgressStatusName(inspection.status)}}</td>

                        <td class="text-center" *ngIf="inspection.myAssignedSections > 0">{{inspection.myAssignedSections | number : '1.0-0'}}</td>
                        <td class="text-center" *ngIf="inspection.myAssignedSections == 0">---</td>

                        <td class="text-center" *ngIf="inspection.myAssignedSections > 0">{{inspection.myCompletedSections | number : '1.0-0'}}</td>
                        <td class="text-center" *ngIf="inspection.myAssignedSections == 0">---</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <home-grid-pager [page]="page" [limit]="limit" [pageCount]="pageCount" [limits]="pageLimitOptions"></home-grid-pager>
    </div>
</div>
