import { Injectable } from '@angular/core';
import { add } from 'ngx-bootstrap/chronos';
import { EngagementQuestion } from '../../../model/model';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilteredQuestionsService {
  filteredQuestions: object = {};

  constructor() { }

  getFilteredQuestions(): Observable<object> {
    return of(this.filteredQuestions);
  }

  add(question: EngagementQuestion) {
    const engagementSectionId = question.engagementSectionId;

    if(!this.filteredQuestions[question.engagementSectionId])  {
        this.filteredQuestions = {
            ...this.filteredQuestions,
            [engagementSectionId]: [question],
        }
    } else if(!this.filteredQuestions[question.engagementSectionId].some(q => q.id == question.id)) {
        this.filteredQuestions[question.engagementSectionId].push(question)
    }
  }
}
