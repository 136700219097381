import { Component, OnInit } from '@angular/core';
import { UnitOfWorkService } from '../../services/unit-of-work/unit-of-work.service';
import { ApplicationInsightsService } from '../../services/application-insights/application-insights.service';
import { Observable } from 'rxjs';
import { DialogHelper } from '../../services/dialog-helper';
import { IsBusyService } from '../../services/is-busy/is-busy.service';
import * as model from '../../../model/model';
import { GlobalErrorHandler } from '../../services/global-error-handler';

const tabKey: string = 'admin:active-tab';

@Component({
    selector: 'app-business-lines',
    templateUrl: './business-lines.component.html',
})
export class BusinessLinesAdminComponent implements OnInit {
    businessLines: model.BusinessLine[] = [];

    newBusinessLineName: string = null;

    get activeBusinessLines() {
        return this.businessLines.filter(m => !m.entityAspect.entityState.isDeleted() && !m.entityAspect.entityState.isDetached()).sort((a, b) => { return a.name.localeCompare(b.name); });
    }

    constructor(
		private uow: UnitOfWorkService,
        private appInsights: ApplicationInsightsService,
        private errorHandler: GlobalErrorHandler,
		public isBusy: IsBusyService,
		public dialogHelper: DialogHelper
	) {
        appInsights.logPageView();
    }

    ngOnInit() {
        this.isBusy.set('admin:loadData', true, 'Loading data');
        Promise.all([
            this.getBusinessLines()
        ])
        .catch(reason => {
            this.errorHandler.handleError(reason);
        })
        .finally(() => {
            this.isBusy.set('admin:loadData', false);
        });
    }

    canDeactivate(): Observable<boolean> | boolean {
        if (this.uow.hasChanges.value) {
            let proceed = this.dialogHelper.confirm();

            proceed.subscribe(result => {
                if (result) {
                    this.uow.rollback();
                }
            })

            return proceed;
        }

        return true;
    }

    
    getBusinessLines() {
        return this.uow.getBusinessLines(true)
            .then(result => {
                this.businessLines = result;
            });
    }
  

    saveBusinessLineChanges(businessLine: model.BusinessLine, message: string) {
        this.isBusy.set('admin:saveBusinessLine', true, message);
        this.uow.businessLines.saveChanges([businessLine])
            .then(() => {
                this.newBusinessLineName = null;
            })
            .catch(reason => {
                this.errorHandler.handleError(reason);
            })
            .finally(() => {
                this.isBusy.set('admin:saveBusinessLine', false);
            });
    }

    createBusinessLine() {
        if (this.newBusinessLineName) {
            const newBusinessLine = this.uow.businessLines.createEntity();
            newBusinessLine.name = this.newBusinessLineName.trim();
            this.businessLines.push(newBusinessLine);

            this.saveBusinessLineChanges(newBusinessLine, 'Saving business line');
        }
    }

    updateBusinessLine(businessLine: model.BusinessLine) {
        this.saveBusinessLineChanges(businessLine, 'Updating business line');
    }

    destroyBusinessLine(businessLine: model.BusinessLine) {
        if (businessLine) {
            if (confirm('Are you sure you want to remove "' + businessLine.name + '"?')) {
                this.uow.businessLines.cancelChanges(businessLine);
                businessLine.entityAspect.setDeleted();

                this.saveBusinessLineChanges(businessLine, 'Removing business line "' + businessLine.name + '"');
            }
        }
    }
}
