import { Component, OnInit } from '@angular/core';
import { UnitOfWorkService } from '../../services/unit-of-work/unit-of-work.service';
import { ApplicationInsightsService } from '../../services/application-insights/application-insights.service';
import { Observable } from 'rxjs';
import { DialogHelper } from '../../services/dialog-helper';
import { ActivatedRoute } from '@angular/router';
import { IsBusyService } from '../../services/is-busy/is-busy.service';
import * as model from '../../../model/model';
import { GlobalErrorHandler } from '../../services/global-error-handler';

const defaultLimit: number = 100;

@Component({
    selector: 'app-projects',
    templateUrl: './projects.component.html',
})
export class ProjectsAdminComponent implements OnInit {

    projects: model.Project[] = [];
    page: number = 1;
    limit: number = defaultLimit;
    rowCount: number = 0;
    pageCount: number = 1;
    pageLimitOptions = [15, 25, 50, 100];

    idFilter: string = null;
    nameFilter: string = null;
    clientFilter: string = null;

    get activeProjects() {
        return this.projects.filter(m => !m.entityAspect.entityState.isDeleted() && !m.entityAspect.entityState.isDetached()).sort((a, b) => { return a.name.localeCompare(b.name); });
    }


    constructor(
		private uow: UnitOfWorkService,
        private appInsights: ApplicationInsightsService,
        private errorHandler: GlobalErrorHandler,
		private activeRoute: ActivatedRoute,
		public isBusy: IsBusyService,
		public dialogHelper: DialogHelper
	) {
        appInsights.logPageView();
    }

    ngOnInit() {
        this.activeRoute.params.subscribe(routeParams => {
            this.page = routeParams.page && !Number.isNaN(routeParams.page) ? parseInt(routeParams.page) : 1;
            this.limit = routeParams.limit && !Number.isNaN(routeParams.limit) ? parseInt(routeParams.limit) : defaultLimit;

            this.updateDataGrid();
        });
    }

    canDeactivate(): Observable<boolean> | boolean {
        if (this.uow.hasChanges.value) {
            let proceed = this.dialogHelper.confirm();

            proceed.subscribe(result => {
                if (result) {
                    this.uow.rollback();
                }
            })

            return proceed;
        }

        return true;
    }

    clearFilters() {
        this.idFilter = null;
        this.nameFilter = null;
        this.clientFilter = null;

        this.updateDataGrid();
    }

    updateDataGrid(){
        this.isBusy.set('admin:loadData', true, 'Loading data');
        Promise.all([
            this.getProjectsCount(),
            this.getProjects()
        ])
        .catch(reason => {
            this.errorHandler.handleError(reason);
        })
        .finally(() => {
            this.isBusy.set('admin:loadData', false);
        });
    }

    getProjects() {
        return this.uow.getProjects(this.idFilter, this.nameFilter, null, this.clientFilter, this.page, this.limit)
            .then(result => {
                this.projects = result;
            });
    }

    getProjectsCount() {
        return this.uow.getProjectsCount(this.idFilter, this.nameFilter, null, this.clientFilter)
            .then(result => {
                this.rowCount = result[0];
                this.pageCount = Math.ceil(this.rowCount / this.limit);
                if (this.pageCount < 1) {
                    this.pageCount = 1;
                }
            });
    }
}
