<div class="modal-header">
    <h5 class="modal-title" id="conf_dialog_title">{{title}}</h5>
    <button id="conf_dialog_close" type="button" class="close" aria-label="Close" (click)="dialogRef.close(DIALOG_CLOSE)">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" id="conf_dialog_body">{{content}}</div>
<div class="modal-footer">
    <div class="modal-footer-section">
        <button id="conf_dialog_cancel" *ngIf="!onlyShowOk" class="btn btn-primary" aria-label="Close" (click)="dialogRef.close(DIALOG_CANCEL)">Cancel</button>
        <button *ngIf="debug" id="conf_dialog_debug" class="btn btn-danger" aria-label="Debug" (click)="downloadDebugData()">Download HTTP Error Data</button>
    </div>
    <div class="modal-footer-section">
        <button *ngIf="!onlyShowOk" id="conf_dialog_no" class="btn btn-secondary" aria-label="No" (click)="dialogRef.close(DIALOG_NO);">No</button>
        <button *ngIf="!onlyShowOk" id="conf_dialog_yes" class="btn btn-danger" aria-label="Yes" (click)="dialogRef.close(DIALOG_YES);">Yes</button>

        <button *ngIf="onlyShowOk" id="conf_dialog_ok" class="btn btn-primary" aria-label="Ok" (click)="dialogRef.close(DIALOG_OK);">Ok</button>
    </div>
</div>
