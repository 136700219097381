import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { SaveOptions, QueuedSaveFailedError, SaveResult, EntityChangedEventArgs } from 'breeze-client';
import { Repository } from '../repository';
import { BridgeService } from '../bridge/bridge.service';
import { EntityManagerProviderService } from '../entity-manager-provider/entity-manager-provider.service';
import * as model from '../../../model/model';
import * as modelEnum from '../../../model/modelEnums';
import { ToastrService } from 'ngx-toastr';
import { GlobalErrorHandler } from '../../services/global-error-handler';
import { MetaEntity } from '../../../model/metaEntity';
import Strings from '../strings';

const defaultLimit: number = 15;

@Injectable({
    providedIn: 'root'
})
export class UnitOfWorkService implements OnDestroy {
    hasChanges = new BehaviorSubject(false);
    entityChanged = new Subject<EntityChangedEventArgs>();

    inspectionProfileStatuses = [
        { isSelected: false, status: modelEnum.InspectionProfileStatus.draft, title: 'Draft' },
        { isSelected: false, status: modelEnum.InspectionProfileStatus.active, title: 'Active' },
        { isSelected: false, status: modelEnum.InspectionProfileStatus.archived, title: 'Archived' }
    ];

    engagementStatuses = [
        { isSelected: false, status: modelEnum.EngagementStatus.draft, title: 'Draft' },
        { isSelected: false, status: modelEnum.EngagementStatus.active, title: 'Active' },
        { isSelected: false, status: modelEnum.EngagementStatus.changesRequired, title: 'Changes Required' },
        { isSelected: false, status: modelEnum.EngagementStatus.teamCaptainReviewComplete, title: 'Team Captain Review Complete' },
        { isSelected: false, status: modelEnum.EngagementStatus.archived, title: 'Archived' }
    ];

    progressStatuses = [
        { isSelected: false, status: modelEnum.ProgressStatus.na, title: '---' },
        { isSelected: false, status: modelEnum.ProgressStatus.notActivated, title: 'Not Activated' },
        { isSelected: false, status: modelEnum.ProgressStatus.notStarted, title: 'Not Started' },
        { isSelected: false, status: modelEnum.ProgressStatus.inProgress, title: 'In Progress' },
        { isSelected: false, status: modelEnum.ProgressStatus.completed, title: 'Completed' }
    ];

    engagementRatings = [
        { isSelected: false, status: modelEnum.EngagementRating.pending, title: 'Pending' },
        { isSelected: false, status: modelEnum.EngagementRating.veryPoor, title: 'Very Poor' },
        { isSelected: false, status: modelEnum.EngagementRating.poor, title: 'Poor' },
        { isSelected: false, status: modelEnum.EngagementRating.acceptable, title: 'Acceptable' },
        { isSelected: false, status: modelEnum.EngagementRating.veryGood, title: 'Very Good' },
        { isSelected: false, status: modelEnum.EngagementRating.excellent, title: 'Excellent' }
    ];

    // Declare repositories for entity types you need to create/read/update/delete
    employees: Repository<model.Employee>;
    tags: Repository<model.Tag>;
    inspectionTeamRoles: Repository<model.InspectionTeamRole>;
    industries: Repository<model.Industry>;
    offices: Repository<model.Office>;
    businessLines: Repository<model.BusinessLine>;
    standardQuestionOptions: Repository<model.StandardQuestionOption>;
    inspectionProfiles: Repository<model.InspectionProfile>;
    profileSections: Repository<model.ProfileSection>;
    questions: Repository<model.Question>;
    questionOptions: Repository<model.QuestionOption>;
    profileSectionQuestionOptions: Repository<model.ProfileSectionQuestionOption>;
    profileQuestionActivatingTags: Repository<model.ProfileQuestionActivatingTag>;
    questionTags: Repository<model.QuestionTag>;
    clients: Repository<model.Client>;
    projects: Repository<model.Project>;
    aptCaws: Repository<model.AptCaw>;

    engagements: Repository<model.Engagement>;
    engagementQuestions: Repository<model.EngagementQuestion>;
    engagementQuestionComments: Repository<model.EngagementQuestionComment>;
    engagementQuestionOptions: Repository<model.EngagementQuestionOption>;
    engagementQuestionTags: Repository<model.EngagementQuestionTag>;
    engagementQuestionActivatingTags: Repository<model.EngagementQuestionActivatingTag>;
    engagementSections: Repository<model.EngagementSection>;
    engagementSectionEngagementQuestionOptions: Repository<model.EngagementSectionEngagementQuestionOption>;
    engagementTeamMembers: Repository<model.EngagementTeamMember>;
    inspectionTeamMemberEngagementSections: Repository<model.InspectionTeamMemberEngagementSection>;
    inspectionTeamMemberProfileSections: Repository<model.InspectionTeamMemberProfileSection>;
    inspectionTeamMembers: Repository<model.InspectionTeamMember>;
    engagementAptCaws: Repository<model.EngagementAptCaw>;
    engagementIndustries: Repository<model.EngagementIndustry>;
    engagementProjects: Repository<model.EngagementProject>;
    answerSetOptions: Repository<model.AnswerSetOption>;
    specialtyReviewerTypes: Repository<model.SpecialtyReviewerType>;

    private initialized = false;

    constructor(
        private bridge: BridgeService,
        private provider: EntityManagerProviderService,
        private toastr: ToastrService,
        private errorHandler: GlobalErrorHandler
    ) {
        this.provider.manager().hasChangesChanged.subscribe((evt) => {
            this.hasChanges.next(evt.hasChanges);
        });

        this.provider.manager().entityChanged.subscribe((evt) => {
            this.entityChanged.next(evt);
        });
    }

    init() {
        if (this.initialized) {
            return;
        }

        // Initialize the above repositories
        this.employees = this.createRepo('Employee', 'employees');
        this.tags = this.createRepo('Tag', 'tags');
        this.inspectionTeamRoles = this.createRepo('InspectionTeamRole', 'inspectionTeamRoles');
        this.industries = this.createRepo('Industry', 'industries');
        this.offices = this.createRepo('Office', 'offices');
        this.businessLines = this.createRepo('BusinessLine', 'businessLines');
        this.standardQuestionOptions = this.createRepo('StandardQuestionOption', 'standardQuestionOptions');
        this.inspectionProfiles = this.createRepo('InspectionProfile', 'inspectionProfiles');
        this.profileSections = this.createRepo('ProfileSection', 'profileSections');
        this.questions = this.createRepo('Question', 'questions');
        this.questionOptions = this.createRepo('QuestionOption', 'questionOptions');
        this.profileSectionQuestionOptions = this.createRepo('ProfileSectionQuestionOption', 'profileSectionQuestionOptions');
        this.profileQuestionActivatingTags = this.createRepo('ProfileQuestionActivatingTag', 'profileQuestionActivatingTags');
        this.questionTags = this.createRepo('QuestionTag', 'questionTags');
        this.clients = this.createRepo('Client', 'clients');
        this.projects = this.createRepo('Project', 'projects');
        this.aptCaws = this.createRepo('AptCaw', 'aptCaws');
        this.engagements = this.createRepo('Engagement', 'engagements');
        this.engagementQuestions = this.createRepo('EngagementQuestion', 'engagementQuestions');
        this.engagementQuestionComments = this.createRepo('EngagementQuestionComment', 'engagementQuestionComments');
        this.engagementQuestionOptions = this.createRepo('EngagementQuestionOption', 'engagementQuestionOptions');
        this.engagementQuestionTags = this.createRepo('EngagementQuestionTag', 'engagementQuestionTags');
        this.engagementQuestionActivatingTags = this.createRepo('EngagementQuestionActivatingTag', 'engagementQuestionActivatingTags');
        this.engagementSections = this.createRepo('EngagementSection', 'engagementSections');
        this.engagementSectionEngagementQuestionOptions = this.createRepo('EngagementSectionEngagementQuestionOption', 'engagementSectionEngagementQuestionOptions');
        this.engagementTeamMembers = this.createRepo('EngagementTeamMember', 'engagementTeamMembers');
        this.inspectionTeamMemberEngagementSections = this.createRepo('InspectionTeamMemberEngagementSection', 'inspectionTeamMemberEngagementSections');
        this.inspectionTeamMemberProfileSections = this.createRepo('InspectionTeamMemberProfileSection', 'inspectionTeamMemberProfileSections');
        this.inspectionTeamMembers = this.createRepo('InspectionTeamMember', 'inspectionTeamMembers');
        this.engagementAptCaws = this.createRepo('EngagementAptCaw', 'engagementAptCaws');;
        this.engagementIndustries = this.createRepo('EngagementIndustry', 'engagementIndustries');
        this.engagementProjects = this.createRepo('EngagementProject', 'engagementProjects');
        this.answerSetOptions = this.createRepo('AnswerSetOption', 'answerSetOptions');
        this.specialtyReviewerTypes = this.createRepo('SpecialtyReviewerType', 'specialtyReviewerTypes');

        this.initialized = true;
    }

    private createRepo<T extends MetaEntity>(entityTypeName: string, resourceName: string) {
        return new Repository<T>(this.provider, this.toastr, this.errorHandler, entityTypeName, resourceName);
    }

    commit(silent = false) {
        const saveOptions = new SaveOptions({ resourceName: 'savechanges' });

        return this.provider.manager()
            .saveChanges(null, saveOptions)
            .then((saveResult: SaveResult) => {
                if (!silent) {
                    this.toastr.success(Strings.toasts.savedData);
                }
            })
            .catch((error: QueuedSaveFailedError) => {
                // Http request errors are handled in add-authorization-header-interceptor.ts
                if (!error.innerError.httpResponse) {
                    this.errorHandler.handleError(error);
                }
            });
    }

    rollback() {
        this.provider.manager().rejectChanges();
    }


    ngOnDestroy() {
        if (this.hasChanges) {
            this.hasChanges.unsubscribe();
        }

        if (this.entityChanged) {
            this.entityChanged.unsubscribe();
        }
    }

    clear() {
        this.provider.manager().clear();
    }

    getChanges() {
        return this.provider.manager().getChanges();
    }

    private clearCache(entityName, entityId = null) {
        var cachedItems = this.provider.manager().getEntities(entityName);
        // Todo: this should be a function of the Breeze EntityManager itself
        if (cachedItems == null || cachedItems.length == 0) return;

        if (entityId != null) {
            cachedItems = cachedItems.filter(x => x['id'] == entityId);
        }

        cachedItems.forEach((entity) => {
            this.provider.manager().detachEntity(entity);
        });
    }

    getStatusName(status: modelEnum.InspectionProfileStatus) {
        var inspectionStatus = this.inspectionProfileStatuses.filter(s => s.status == Number(status))[0];
        return inspectionStatus ? inspectionStatus.title : 'UNDEFINED';
    }

    getEngagementStatusName(status: modelEnum.EngagementStatus) {
        var engagementStatus = this.engagementStatuses.filter(s => s.status == Number(status))[0];
        return engagementStatus ? engagementStatus.title : 'UNDEFINED';
    }

    getEngagementRatingName(status: modelEnum.EngagementRating) {
        var engagementRating = this.engagementRatings.filter(s => s.status == Number(status))[0];
        return engagementRating ? engagementRating.title : 'UNDEFINED';
    }

    getProgressStatusName(status: modelEnum.ProgressStatus) {
        var progressStatus = this.progressStatuses.filter(s => s.status == Number(status))[0];
        return progressStatus ? progressStatus.title : 'UNDEFINED';
    }

    getEmployees(searchTerm: string, id: string = null, showOnlyAdmins: boolean = false, showOnlyCaptains: boolean = false) {
        return this.bridge.getEmployees(searchTerm, id, showOnlyAdmins, showOnlyCaptains, 1, 50);
    }

    getActiveTags(includeAll: boolean = false) {
        return this.bridge.getActiveTags(includeAll, null);
    }
    getTags(includeAll: boolean = false) {
        return this.bridge.getTags(includeAll, null);
    }

    getInspectionTeamRoles(includeAll: boolean = false) {
        return this.bridge.getInspectionTeamRoles(includeAll, null);
    }

    getActiveIndustries(includeAll: boolean = false) {
        return this.bridge.getActiveIndustries(includeAll, null);
    }
    getIndustries(includeAll: boolean = false) {
        return this.bridge.getIndustries(includeAll, null);
    }

    getOffices() {
        return this.bridge.getOffices(null);
    }

    getRegions() {
        return this.bridge.getRegions(null);
    }

    getBusinessLines(includeAll: boolean = false) {
        return this.bridge.getBusinessLines(includeAll, null);
    }

    getClients(id: string = null, q: string = null, page: number = 1, limit: number = 500) {
        return this.bridge.getClients(id, q, page, limit, null);
    }

    getClientsCount(id: string = null, q: string = null) {
        return this.bridge.getClientsCount(id, q, null);
    }

    getProjects(id: string = null, name: string = null, clientId: string = null, clientName: string = null, page: number = 1, limit: number = 500) {
        return this.bridge.getProjects(id, name, clientId, clientName, page, limit, null);
    }

    getProjectsCount(id: string = null, name: string = null, clientId: string = null, clientName: string = null) {
        return this.bridge.getProjectsCount(id, name, clientId, clientName, null);
    }

    getAptCaws(id: string = null, name: string = null, clientId: string = null, clientName: string = null, page: number = 1, limit: number = 500) {
        return this.bridge.getAptCaws(id, name, clientId, clientName, page, limit, null);
    }

    getAptCawsCount(id: string = null, name: string = null, clientId: string = null, clientName: string = null) {
        return this.bridge.getAptCawsCount(id, name, clientId, clientName, null);
    }

    getStandardQuestionOptions(includeAll: boolean = false) {
        return this.bridge.getStandardQuestionOptions(includeAll, null);
    }

    getInspectionProfileById(id: number) {
        return this.bridge.getInspectionProfile(id);
    }

    getInspectionProfilesForLookup() {
        return this.bridge.getInspectionProfilesForLookup(null);
    }

    getInspectionProfilesForEngagement() {
        return this.bridge.getInspectionProfilesForEngagement(null);
    }

    getInspectionProfileForEngagementById(id: number) {
        return this.bridge.getInspectionProfileForEngagementById(id);
    }

    getInspectionProfilesSummary(page: number = 1, limit: number = defaultLimit, gridFilters: string = null) {
        return this.bridge.getInspectionProfilesSummary(page, limit, gridFilters, null);
    }

    getInspectionProfilesPageCount(limit: number = defaultLimit, gridFilters: string = null) {
        return this.bridge.getInspectionProfilesPageCount(limit, gridFilters, null);
    }

    getProfileSections(page: number = 1, limit: number = defaultLimit, gridFilters: string = null) {
        return this.bridge.getProfileSections(page, limit, gridFilters, null);
    }

    getProfileSectionsPageCount(limit: number = defaultLimit, gridFilters: string = null) {
        return this.bridge.getProfileSectionsPageCount(limit, gridFilters, null);
    }

    getProfileSectionsForLookup() {
        return this.bridge.getProfileSectionsForLookup(null);
    }

    getSectionsForInspectionProfile(profileId: number) {
        return this.bridge.getSectionsForInspectionProfile(profileId, null);
    }

    getProfileSectionsForSection(id: number) {
        return this.bridge.getProfileSectionsForSection(id, null);
    }

    getQuestions(page: number = 1, limit: number = defaultLimit, gridFilters: string = null) {
        return this.bridge.getQuestions(page, limit, gridFilters, null);
    }

    getQuestionsPageCount(limit: number = defaultLimit, gridFilters: string = null) {
        return this.bridge.getQuestionsPageCount(limit, gridFilters, null);
    }

    getQuestionById(id: number) {
        return this.bridge.getQuestion(id);
    }

    getQuestionOptions() {
        return this.bridge.getQuestionOptions(null);
    }

    getUnassignedQuestions() {
        return this.bridge.getUnassignedQuestions(null);
    }

    getEngagementById(id: number) {
        return this.bridge.getEngagement(id);
    }

    getAllEngagementsSummary(page: number = 1, limit: number = defaultLimit, gridFilters: string = null) {
        return this.bridge.getAllEngagementsSummary(page, limit, gridFilters, null);
    }

    getEngagements(page: number = 1, limit: number = defaultLimit, gridFilters: string = null) {
        return this.bridge.getEngagements(page, limit, gridFilters, null);
    }

    getEngagementsPageCount(limit: number = defaultLimit, gridFilters: string = null) {
        return this.bridge.getEngagementsPageCount(limit, gridFilters, null);
    }


    getMyTeamsEngagementById(id: number) {
        return this.bridge.getMyTeamsEngagementById(id);
    }

    getEngagementsForMyTeams(page: number = 1, limit: number = defaultLimit, gridFilters: string = null) {
        return this.bridge.getEngagementsForMyTeams(page, limit, gridFilters, null);
    }

    getEngagementsCountForMyTeams(gridFilters: string = null) {
        return this.bridge.getEngagementsCountForMyTeams(gridFilters, null);
    }

    publishEngagement(id: number) {
        return this.bridge.publishEngagement(id, null);
    }

    getEngagementSectionsForMyTeams(page: number = 1, limit: number = defaultLimit, gridFilters: string = null) {
        return this.bridge.getEngagementSectionsForMyTeams(page, limit, gridFilters, null);
    }

    getEngagementSectionsCountForMyTeams(gridFilters: string = null) {
        return this.bridge.getEngagementSectionsCountForMyTeams(gridFilters, null);
    }


    getEngagementQuestionsForMyTeams(page: number = 1, limit: number = defaultLimit, gridFilters: string = null) {
        return this.bridge.getEngagementQuestionsForMyTeams(page, limit, gridFilters, null);
    }

    getEngagementQuestionsCountForMyTeams(gridFilters: string = null) {
        return this.bridge.getEngagementQuestionsCountForMyTeams(gridFilters, null);
    }


    getMyInspections(page: number = 1, limit: number = defaultLimit, gridFilters: string = null) {
        return this.bridge.getMyInspections(page, limit, gridFilters);
    }

    getMyInspectionsCount(gridFilters: string = null) {
        return this.bridge.getMyInspectionsCount(gridFilters, null);
    }

    getExportEngagementQuestionsByYear(inspectionYear: number) {
        return this.bridge.getExportEngagementQuestionsByYear(inspectionYear);
    }

    getEngagementQuestionById(id: number) {
        return this.bridge.getEngagementQuestionById(id);
    }

    getMyEngagementNodeViewerById(id: number) {
        return this.bridge.getMyEngagementNodeViewerById(id);
    }

    getMyEngagementById(id: number) {
        return this.bridge.getMyEngagementById(id);
    }

    getSelectedOptionActivatingTags(id: number, selectedOptionId: number) {
        return this.bridge.getSelectedOptionActivatingTags(id, selectedOptionId);
    }

    checkQuestionForExistingResponse(id: number, optionId: number) {
        return this.bridge.checkQuestionForExistingResponse(id, optionId);
    }

    updateAssignedSections(sectionId: number, assignChildSections: boolean, teamMemberIds: number[], teamMemberWasRemoved: boolean) {
        if (teamMemberWasRemoved) {
            this.clearCache('EngagementSection', sectionId);
        }
        const params = { sectionId: sectionId, assignChildSections: assignChildSections, teamMemberIds: teamMemberIds };
        return this.bridge.updateAssignedSections(params);
    }

    updateQuestionResponse(id: number, isComplete: boolean, completedDate: Date, optionId?: number, comment?: string, replaceExisting?: boolean) {
        return this.bridge.updateQuestionResponse(id, isComplete, completedDate, optionId, comment, replaceExisting);
    }

    saveQuestionComment(id: number, text: string) {
        return this.bridge.saveQuestionComment(id, text, null);
    }


    updateEngagementStatus(status: number, engagementIds: number[]) {
        const params = { status: status, engagementIds: engagementIds };
        return this.bridge.updateEngagementStatus(params);
    }

    updateActivatedEngagementQuestions(id: number) {
        return this.bridge.updateActivatedEngagementQuestions(id);
    }

    updateActivatedEngagementQuestionsByActivatingTags(engagementId: number, engagementQuestionActivatingTags: number[]) {
        const params = { engagementId: engagementId, engagementQuestionActivatingTags: engagementQuestionActivatingTags};
        return this.bridge.updateActivatedEngagementQuestionsByActivatingTags(params);
    }

    propagateProfileSectionChanges(id: number) {
        return this.bridge.propagateProfileSectionChanges(id, null);
    }

    propagateQuestionChanges(id: number) {
        return this.bridge.propagateQuestionChanges(id, null);
    }


    getMaxProfileSectionDisplayOrder(id: number, parentSectionId?: number) {
        return this.bridge.getMaxProfileSectionDisplayOrder(id, parentSectionId, null);
    }

    getMaxQuestionDisplayOrder(id: number) {
        return this.bridge.getMaxQuestionDisplayOrder(id, null);
    }


    cleanupEngagements() {
        return this.bridge.cleanupEngagements(null);
    }


    testApplicationException() {
        return this.bridge.testApplicationException();
    }

    testException() {
        return this.bridge.testException();
    }

    cloneInspectionProfile(origProfileId: number, newName: string) {
        return this.bridge.cloneInspectionProfile(origProfileId, newName);
    }

    getEngagementReport(engagementId: number) {
        return this.bridge.getEngagementReport(engagementId);
    }

    getTotalEmployeeProjectHours(projectIds: string[], employeeId: string) {
        const params = { projectIds: projectIds, employeeId: employeeId};
        return this.bridge.getTotalEmployeeProjectHours(params);
    }

    getEmployeeProjectHours(projectId: string) {
        return this.bridge.getEmployeeProjectHours(projectId);
    }

    getTeamCaptains() {
        return this.bridge.getTeamCaptains();
    }

    getInspectionYears() {
        return this.bridge.getInspectionYears();
    }

    getSpecialtyReviewerTypes(includeAll: boolean = false) {
        return this.bridge.getSpecialtyReviewerTypes(includeAll, null);
    }

    isReviewerTypeAssigned(reviewerTypeId: number) {
        return this.bridge.isReviewerTypeAssigned(reviewerTypeId);
    }

    getInspectionTeamMemberProfileSections(engagementId: number) {
        return this.bridge.getInspectionTeamMemberProfileSections(engagementId);
    }

    getInspectionTeamMemberEngagementSections(engagementId: number) {
        return this.bridge.getInspectionTeamMemberEngagementSections(engagementId);
    }

    getHighLevelInspectionTeamMemberProfileSections(engagementId: number) {
        return this.bridge.getHighLevelInspectionTeamMemberProfileSections(engagementId);
    }

    getHighLevelInspectionTeamMemberEngagementSections(engagementId: number) {
        return this.bridge.getHighLevelInspectionTeamMemberEngagementSections(engagementId);
    }

    updateChildInspectionTeamMemberProfileSections(engagementId: number) {
        return this.bridge.updateChildInspectionTeamMemberProfileSections(engagementId);
    }
}
