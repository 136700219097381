export default class Strings {
    static toasts = {
        changesPending: "Please save or cancel your changes before leaving the page",
        errorSavingData: "Data could not be saved",
        errorGettingData: "toasts_errorGettingData",
        invalidRoute: "Could not retrieve data",
        welcomeMsg: "Welcome, {0}!",
        retrievedData: "Successfully Loaded {0}!",
        savedData: "Data saved successfully",
        canceledData: "Changes reverted back to original values",
        invalidData: "Some fields have invalid data, please correct them before continuing",
        entityAdded: "{0} Added",
        entityDeleted: "{0} Deleted"
    };

    static errors = {
        nocallback: "callback must be specified.",
        noroute: "No route was indicated.",
        queryfailed: "Query failed:",
        savefailed: "Save failed:",
    };

    static actions = {
        undoDelete: "action_undo_delete"
    };

    static validation = {
        required: "'%displayName%' is required",
        date: "'%displayName%' must be a date",
        "string": "'%displayName%' must be a string",
        "bool": "'%displayName%' must be a a 'true' or 'false' value",
        guid: "'%displayName%' must be a GUID",
        "number": "'%displayName%' must be a number",
        integer: "'%displayName%' must be an integer",
        integerRange: "'%displayName%' must be an integer between the values of %minValue% and %maxValue%",
        maxLength: "'%displayName%' must be a string with less than %maxLength% characters",
        stringLength: "'%displayName%' must be a string with between %minLength% and %maxLength% characters"
    };

    static labels = {
        pager: "Page {0} of {1}",
        cancel: "Cancel",
        save: "Save",
        submit: "Submit",
        rollback: "Rollback Status",
        validationSummary: "Validation Summary",
        view: "View:",
        back: "Back",
        copy: "Copy",
        cancelCopy: "Cancel",
        newLabel: "New",
        add: "Add",
        undo: "(Undo)",
        noUsersAuthorized: "label_noUsersAuthorized",
        name: "Name",
        noUsersFound: "label_noUsersFound",
        new: "New",
        yes: "Yes",
        no: "No",
        footer: "&amp;copy; Copyright BDO USA, P.C., a Virginia Professional Service Corporation {0}. All Rights Reserved."
    };

    static titles = {
        home: "My Projects",
        admin: "Admin"
    };

    static loadingMsgs = {};

    static reports = {
        reportname: {
            name: "report_controller_action",
            title: "Report_MyReportName_Title",
            description: "Report_MyReportName_Description"
        }
    };
}
