import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import Config from '../config';
import { ReplaySubject } from 'rxjs';
import Utilities from '../utilities';
import { ServerSideConfigData } from '../../../typings';
import { SharedDataService } from '../shared-data/shared-data.service';

@Injectable({
    providedIn: 'root'
})
export class ServerSideConfigDataService {
    configData: ServerSideConfigData;
    configDataLoaded$ = new ReplaySubject<boolean>(1);

    constructor(
        private http: HttpClient,
        private sharedData: SharedDataService
    ) { }

    loadServerSideData(): Promise<boolean> {
        if (!this.configData) {
            return this.http.get<ServerSideConfigData>(`${Config.serviceName}/getServerSideConfigData`)
                .toPromise()
                .then((result) => {
                    this.configData = result;

                    Config.hashes.currentMetadataHash = result.hashes[0].metadataHash;
                    Config.hashes.currentAnnotatedMetadataHash = result.hashes[0].annotatedMetadataHash;
                    Config.hashes.currentLookupsHash = result.hashes[0].lookupsHash;

                    this.sharedData.setMe(this.configData.me);
                    this.configDataLoaded$.next(true);
                    return true;
                })
                .catch(err => Promise.reject(err));
        } else {
            return Utilities.valueAsPromise(true);
        }
    }
}
