<div class="tree-node tree-node-sm">
    <h5 class="tree-name mb-1">
        <i (click)="toggleSection()" style="color:red" class="fa fa-lg" [ngClass]="{'fa-caret-down':!node?.isCollapsed, 'fa-caret-right': node?.isCollapsed}">  </i>

        <a class="tree-node-toggle" (click)="setActiveSection(node)">{{node.name}}</a>
    </h5>

    <div class="tree-content collapse" [class.show]="!node.isCollapsed">
        <p class="mb-1" *ngIf="questions.length == 0"><i class="fa fa-fw fa-ban text-danger"></i> Section contains no questions</p>
        <ol *ngIf="questions.length > 0" class="question-list mb-2">
            <li *ngFor="let question of questions" [class.completed]="question.isComplete">
                <a class="question-toggle" [class.active]="question.isSelected" (click)="setActiveQuestion(question)">
                    {{getDisplayText(question)}}
                </a>
            </li>
        </ol>
        <ol class="node" *ngIf="currentNodes.length > 0">
            <li class="tree-branch" *ngFor="let n of currentNodes; index as i">
                <engagement-tree-node-viewer [node]="n" [i]="i" [context]="context"></engagement-tree-node-viewer>
            </li>
        </ol>
    </div>
</div>
