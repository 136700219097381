
<div class="p-3 my-5 text-center">
    <h3>
        <i class="fa fa-exclamation-triangle text-danger"></i>
        User Not Found
    </h3>
    <p>
        We're sorry. We could not find an active account matching your credentials. Please contact your administrator if you believe this to be incorrect.
    </p>
</div>

