import { Component, OnInit } from '@angular/core';
import { UnitOfWorkService } from '../../../services/unit-of-work/unit-of-work.service';
import Utilities from '../../../services/utilities';
import { Router, ActivatedRoute } from '@angular/router';
import { ApplicationInsightsService } from '../../../services/application-insights/application-insights.service';
import { Observable } from 'rxjs';
import { DialogHelper } from '../../../services/dialog-helper';
import { IsBusyService } from '../../../services/is-busy/is-busy.service';
import * as moment from 'moment';
import * as modelDto from '../../../../model/modelDto';
import * as modelEnum from '../../../../model/modelEnums';
import { GlobalErrorHandler } from '../../../services/global-error-handler';

const LOADING_KEY: string = 'inspection:loadData';
const SAVING_KEY: string = 'inspection:saveData';
const NO_ANSWER_NEEDED_NAME: string = 'No answer needed';

@Component({
    selector: 'app-team-inspection-admin',
    templateUrl: './inspection.component.html',
})
export class TeamCaptainInspectionComponent implements OnInit {

    isLoading: boolean = true;

    engagement: modelDto.EngagementDto = {} as modelDto.EngagementDto;
    engagementId: number = null;
    sectionId: number = null;
    questionId: number = null;
    commentText: string = null;

    questionNumber: number = null;
    currentQuestion: modelDto.EngagementQuestionDto = null;

    hoverSectionName: string = null;

    parentSection: modelDto.EngagementSectionDto = null;

    currentSection: modelDto.EngagementSectionDto = null;
    previousSection: modelDto.EngagementSectionDto = null;
    nextSection: modelDto.EngagementSectionDto = null;

    constructor(private uow: UnitOfWorkService,
        private appInsights: ApplicationInsightsService,
        private errorHandler: GlobalErrorHandler,
        private activeRoute: ActivatedRoute,
        public router: Router,
        public isBusy: IsBusyService,
        public dialogHelper: DialogHelper) {
        appInsights.logPageView();
    }

    ngOnInit() {
        this.activeRoute.queryParams.subscribe(routeParams => {
            this.isLoading = true;
            this.isBusy.set(LOADING_KEY, true, 'Loading engagement data');
            Promise
                .all([this.getMyTeamsEngagementById(routeParams.id, routeParams.sid, routeParams.qid)])
                .then(() => {
                    this.findSectionById();
                })
                .catch(reason => {
                    this.errorHandler.handleError(reason);
                })
                .finally(() => {
                    this.isBusy.set(LOADING_KEY, false);
                    this.isLoading = false;
                });
        });
    }

    canDeactivate(): Observable<boolean> | boolean {
        if (this.uow.hasChanges.value) {
            let proceed = this.dialogHelper.confirm();

            proceed.subscribe(result => {
                if (result) {
                    this.uow.rollback();
                }
            })

            return proceed;
        }

        return true;
    }


    get rootEngagementSections() {
        return this.engagement && this.engagement.sections ? this.engagement.sections.filter(m => !m.parentSectionId).sort(Utilities.sortByDisplayOrder) : [];
    }

    get currentQuestionOptions() {
        return this.currentQuestion && this.currentQuestion.options ? this.currentQuestion.options.filter(o => o.text !== NO_ANSWER_NEEDED_NAME).sort(Utilities.sortByDisplayOrder) : [];
    }

    get hasChanges() {
        const a = this.uow.engagements.hasChanges.value;
        const b = this.uow.engagementTeamMembers.hasChanges.value;
        const c = this.uow.engagementSections.hasChanges.value;

        return a || b || c;
    }

    undoChanges() {
        if (this.hasChanges) {
            this.uow.rollback();
        }
    }

    getMyTeamsEngagementById(id: number, sid?: number, qid?: number) {
        return this.uow.getMyTeamsEngagementById(id)
            .then(result => {
                if (result && result.length > 0) {
                    this.engagement = result[0];

                    this.engagementId = id;
                    this.sectionId = sid;
                    this.questionId = qid;
                }
                else {
                    this.router.navigate(['./inspections'], { relativeTo: this.activeRoute.parent });
                }
            });
    }


    getEngagementStatusName(status: modelEnum.EngagementStatus) {
        return this.uow.getEngagementStatusName(status);
    }

    getProgressStatusName(status: modelEnum.ProgressStatus) {
        return this.uow.getProgressStatusName(status);
    }

    formatDate(value: any): string {
        return moment(value).format('YYYY-MM-DD hh:mm A Z');
    }

    calculatePercentComplete(numerator: number, denominator: number): number {
        let value = 0.0;

        if (denominator > 0) {
            value = numerator / denominator;
        }

        return value;
    }


    reloadSections() {
        return this.uow.getMyTeamsEngagementById(this.engagementId)
            .then(result => {
                if (result && result.length > 0) {
                    this.engagement = result[0];
                    if (this.engagement.sections) {
                        this.engagement.sections.forEach(s => {
                            if (s.questions) {
                                s.questions.forEach(q => q.isSelected = q.id == this.questionId);
                            }
                        });
                    }
                }
            });
    }

    findSectionById() {
        if (this.sectionId && this.engagement.sections) {
            this.setActiveSection(this.engagement.sections.find(m => m.id == this.sectionId));
        }
        else {
            this.setActiveSection(null);
        }
    }

    findNearestActiveParentSection(section: modelDto.EngagementSectionDto) {
        if (section) {
            this.parentSection = section.parentSection;
            if (this.parentSection) {
                if (!this.parentSection.isActivated) {
                    this.parentSection = this.findNearestActiveParentSection(this.parentSection);
                }
            }
        }
        else {
            this.parentSection = null;
        }

        return this.parentSection;
    }

    setActiveSection(section: modelDto.EngagementSectionDto) {
        this.currentSection = section;
        this.setHoverText(null);

        this.clearQuestionSelection();

        if (this.engagement.sections) {
            if (section) {
                this.sectionId = this.currentSection.id;

                if (this.questionId) {
                    if (this.currentSection.questions) {
                        this.currentQuestion = this.currentSection.questions.find(m => m.id == this.questionId);
                        if (!this.currentQuestion) {
                            this.questionNumber = 1;

                            this.currentQuestion = this.currentSection.questions[0];
                            if (this.currentQuestion) {
                                this.questionId = this.currentQuestion.id;
                                this.currentQuestion.isSelected = true;
                            }
                            else {
                                this.clearQuestionSelection();
                            }
                        }
                        else {
                            this.questionNumber = this.currentSection.questions.indexOf(this.currentQuestion) + 1;
                            this.currentQuestion.isSelected = true;
                        }
                    }
                    else {
                        this.clearQuestionSelection();
                    }
                }
                else {
                    if (this.currentSection.questions) {
                        this.currentQuestion = this.currentSection.questions[0];
                        this.questionNumber = 1;

                        if (this.currentQuestion) {
                            this.questionId = this.currentQuestion.id;
                            this.currentQuestion.isSelected = true;
                        }
                        else {
                            this.clearQuestionSelection();
                        }
                    }
                    else {
                        this.clearQuestionSelection();
                    }
                }

                this.checkQuestionForNoAnswerOption();

                this.parentSection = this.findNearestActiveParentSection(this.currentSection);

                if (this.parentSection) {
                    if (this.currentSection.parentSectionId == this.parentSection.id) {
                        const siblings = this.parentSection.childSections.filter(m => m.isActivated).sort(Utilities.sortByDisplayOrder);
                        const i = siblings.indexOf(this.currentSection);

                        if (i > 0) {
                            this.previousSection = siblings[i - 1];
                        }
                        else {
                            this.previousSection = null;
                        }

                        if (i < siblings.length - 1) {
                            this.nextSection = siblings[i + 1];
                        }
                        else {
                            this.nextSection = null;
                        }
                    }
                    else {
                        this.previousSection = null;
                        this.nextSection = null;
                    }
                }
                else {
                    const siblings = this.engagement.sections.filter(m => m.isActivated).sort(Utilities.sortByDisplayOrder);
                    const i = siblings.indexOf(this.currentSection);

                    if (i > 0) {
                        this.previousSection = siblings[i - 1];
                    }
                    else {
                        this.previousSection = null;
                    }

                    if (i < siblings.length - 1) {
                        this.nextSection = siblings[i + 1];
                    }
                    else {
                        this.nextSection = null;
                    }
                }
            }
            else {
                this.sectionId = null;
                this.questionId = null;

                this.parentSection = null;
                this.currentQuestion = null;
                this.previousSection = null;
                this.nextSection = null;
            }
        }
        else {
            this.sectionId = null;
            this.questionId = null;

            this.parentSection = null;
            this.currentQuestion = null;
            this.previousSection = null;
            this.nextSection = null;
        }
    }

    checkQuestionForNoAnswerOption() {
        if (this.currentQuestion
            && this.currentQuestion.options
            && this.currentQuestion.options.filter(x => x.text === NO_ANSWER_NEEDED_NAME).length > 0) {
            let naOption = this.currentQuestion.options.find(x => x.text === NO_ANSWER_NEEDED_NAME);
            this.currentQuestion.selectedOptionId = naOption.id;
        }
    }

    closeSection() {
        this.setActiveSection(null);
    }

    setHoverText(section: modelDto.EngagementSectionDto) {
        if (section) {
            if (section.name.length > 40) {
                this.hoverSectionName = section.name.substr(0, 40) + '...';
            } else {
                this.hoverSectionName = section.name;
            }
        }
        else {
            this.hoverSectionName = null;
        }
    }


    loadQuestion(i: number) {
        if (this.currentQuestion) {
            this.currentQuestion.isSelected = false;
        }

        if (this.currentSection.questions) {
            if (this.currentSection.questions[i - 1]) {
                this.currentQuestion = this.currentSection.questions[i - 1];
                this.questionId = this.currentQuestion.id;
                this.questionNumber = i;
                this.currentQuestion.isSelected = true;

                this.checkQuestionForNoAnswerOption();
            }
        }
    }

    setActiveQuestion(question: modelDto.EngagementQuestionDto) {
        this.questionId = question.id;
        this.sectionId = question.sectionId;

        this.findSectionById();
    }

    clearQuestionSelection() {
        if (this.engagement.sections) {
            this.engagement.sections.forEach(s => {
                if (s.questions) {
                    s.questions.forEach(q => q.isSelected = false);
                }
            });
        }
    }


    expandAll() {
        if (this.engagement && this.engagement.sections) {
            this.engagement.sections.forEach((m) => { m.isCollapsed = false; });
        }
    }

    collapseAll() {
        if (this.engagement && this.engagement.sections) {
            this.engagement.sections.forEach((m) => { m.isCollapsed = true; });
        }
    }
}
