<div class="tree-node">
    <h5 class="tree-name mb-1"><a class="tree-node-toggle" (click)="toggleSection()">{{node.name}}</a></h5>
    <div class="tree-content collapse" [class.show]="!node.isCollapsed">
        <p class="mb-1" *ngIf="questions.length == 0"><i class="fa fa-fw fa-ban text-danger"></i> Section contains no questions</p>
        <ol *ngIf="questions.length > 0" class="question-list mb-2">
            <li *ngFor="let question of questions">{{getDisplayText(question)}}</li>
        </ol>
        <ol class="node" *ngIf="currentNodes.length > 0">
            <li class="tree-branch" *ngFor="let n of currentNodes; index as i">
                <engagement-section-tree-node-viewer [node]="n" [i]="i" [context]="context"></engagement-section-tree-node-viewer>
            </li>
        </ol>
    </div>
</div>
