<div class="tree-node tree-node-sm" *ngIf="showNode()">
    <h5 class="tree-name mb-1" [class.section-assigned-to-me]="isAssignedToMe(node)">
        <a class="tree-node-toggle" >
            <i (click)="toggleSection()" class="fa fa-lg" [ngClass]="{'fa-caret-down':!node?.isCollapsed, 'fa-caret-right': node?.isCollapsed}" >  </i>
            <i (click)="toggleSection()" class="fa fa-star" *ngIf="isAssignedToMe(node)" title="Assigned to me"></i>
            <span (click)="setActiveSection(node)">{{node.name}}</span>
        </a>
    </h5>
    <div class="tree-content collapse" [class.show]="!node.isCollapsed">
        <ol *ngIf="questions.length > 0" class="question-list mb-2">
            <ng-container *ngFor="let question of questions">
                <li id="Q_{{question?.id}}" *ngIf="questionIsFilteredAndActive(question)" [title]="getTooltip(question)" [class.completed]="question.isComplete" [class.in-progress]="question.selectedOptionId != null && !question.isComplete" [class.question-has-changes]="questionHasChanges(question)">
                    <a class="question-toggle" [class.active]="question.isSelected" (click)="setActiveQuestion(question)">
                        {{getDisplayText(question)}}
                    </a>
                </li>
            </ng-container>
        </ol>
        <ol class="node" *ngIf="assignedActivatedNodes.length > 0">
            <li class="tree-branch" *ngFor="let n of assignedActivatedNodes; index as i">
                <inspection-tree-node-viewer id="Node_{{n?.id}}" [node]="n" [i]="i" [context]="context" [filters]="filters" [filterIsActive]="_filterIsActive"></inspection-tree-node-viewer>
            </li>
        </ol>
    </div>
</div>
