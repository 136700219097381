import { Component, OnInit } from '@angular/core';
import { UnitOfWorkService } from '../../services/unit-of-work/unit-of-work.service';
import { ApplicationInsightsService } from '../../services/application-insights/application-insights.service';
import { Observable } from 'rxjs';
import { DialogHelper } from '../../services/dialog-helper';
import { IsBusyService } from '../../services/is-busy/is-busy.service';
import * as model from '../../../model/model';
import { GlobalErrorHandler } from '../../services/global-error-handler';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-specialty-reviewer-types',
    templateUrl: './specialty-reviewer-types.component.html',
})
export class SpecialtyReviewerTypesAdminComponent implements OnInit {

    reviewerTypes: model.SpecialtyReviewerType[] = [];
    newReviewerTypeName: string = null;
    newDescription: string = null;

    constructor(
        private uow: UnitOfWorkService,
        private appInsights: ApplicationInsightsService,
        private errorHandler: GlobalErrorHandler,
        public isBusy: IsBusyService,
        public dialogHelper: DialogHelper,
        private toastr: ToastrService
    ) {
        appInsights.logPageView();
    }

    ngOnInit() {
        this.isBusy.set('admin:loadData', true, 'Loading data');
        Promise.all([
            this.getSpecialtyReviewerTypes()
        ])
            .catch(reason => {
                this.errorHandler.handleError(reason);
            })
            .finally(() => {
                this.isBusy.set('admin:loadData', false);
            });
    }

    getSpecialtyReviewerTypes() {
        return this.uow.getSpecialtyReviewerTypes(true)
            .then(result => {
                this.reviewerTypes = result;
            });
    }

    createReviewerType() {
        if (this.newReviewerTypeName) {
            const newReviewerType = this.uow.specialtyReviewerTypes.createEntity();

            newReviewerType.name = this.newReviewerTypeName.trim();
            newReviewerType.description = this.newDescription.trim();

            this.reviewerTypes.push(newReviewerType);

            this.saveReviewerTypeChanges(newReviewerType, 'Saving Specialty Reviewer Type');
        }
    }

    updateReviewerType(reviewerType: model.SpecialtyReviewerType) {
        this.saveReviewerTypeChanges(reviewerType, 'Updating Specialty Reviewer Type "' + reviewerType.name + '"');
    }

    saveReviewerTypeChanges(reviewerType: model.SpecialtyReviewerType, message: string) {
        this.isBusy.set('admin:saveSpecialtyReviewerType', true, message);
        this.uow.specialtyReviewerTypes.saveChanges([reviewerType])
            .then(() => {
                this.newReviewerTypeName = null;
                this.newDescription = null;
            })
            .catch(reason => {
                this.errorHandler.handleError(reason);
            })
            .finally(() => {
                this.isBusy.set('admin:saveSpecialtyReviewerType', false);
            });
    }

    destroyReviewerType(reviewerType: model.SpecialtyReviewerType) {
        if (reviewerType) {

            this.isReviewerTypeAssigned(reviewerType)
                .then(result => {
                    if (result[0]) {
                        this.toastr.error('You can\'t delete assigned Specialty Reviewer Types.');
                        return;
                    }

                    if (confirm('Are you sure you want to remove "' + reviewerType.name + '"?')) {
                        let index = this.reviewerTypes.indexOf(reviewerType);
                        this.reviewerTypes.splice(index, 1);

                        this.uow.specialtyReviewerTypes.cancelChanges(reviewerType);
                        reviewerType.entityAspect.setDeleted();

                        this.saveReviewerTypeChanges(reviewerType, 'Removing Specialty Reviewer Type "' + reviewerType.name + '"');


                    }
                });

            
        }
    }

    isReviewerTypeAssigned(reviewerType: model.SpecialtyReviewerType) {
        return this.uow.isReviewerTypeAssigned(reviewerType.id);
    }
}
