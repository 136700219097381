<div class="header">
    <h3>Admin</h3>
</div>
<div class="row">
    <div class="col tab-menu">
        <admin-nav-menu [currentTab]="'inspection-team-roles'"></admin-nav-menu>
    </div>

    <div class="col col-constrained">
        <fieldset>
            <legend>Inspection Team Roles</legend>
            <div class="form-group">
                <label for="new-engagement-team-role">Add new role?</label>
                <input type="text"
                       id="new-engagement-team-role"
                       [(ngModel)]="newRoleName"
                       (keypress)="$event.keyCode == 13 ? createRole() : null"
                       class="form-control editor-lg"
                       placeholder="Enter new role name" />
            </div>
            <button type="button" class="btn btn-primary" (click)="createRole()">
                <i class="fa fa-save"></i>
                Save
            </button>
            <table class="table table-sm table-align-middle my-3">
                <thead>
                    <tr>
                        <th class="col-action"></th>
                        <th class="col-action"></th>
                        <th style="width: 1rem;">Id</th>
                        <th>Name</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="activeInspectionTeamRoles.length == 0">
                        <td colspan="4">
                            <span class="d-block p-2 text-center">
                                <i class="fa fa-ban text-danger"></i>
                                No data to display&hellip;
                            </span>
                        </td>
                    </tr>
                    <tr *ngFor="let role of activeInspectionTeamRoles; index as i">
                        <td class="text-center">
                            <button [disabled]="role.engagementTeamMembers.length > 0"
                                    type="button"
                                    class="btn btn-sm btn-secondary"
                                    (click)="destroyRole(role)">
                                <i class="fa fa-trash"></i>
                                <span class="sr-only">Remove</span>
                            </button>
                        </td>
                        <td>
                            <button type="button"
                                    class="btn btn-sm btn-primary"
                                    (click)="updateRole(role)">
                                <i class="fa fa-save"></i>
                                <span class="sr-only">Save</span>
                            </button>
                        </td>
                        <td class="px-3 font-weight-bold">{{role.id}}</td>
                        <td>
                            <input type="text" class="form-control form-control-sm editor-lg d-inline-block mr-2" name="role-{{i}}" [(ngModel)]="role.name" required />
                            <small class="form-text validation-message">Name is required.</small>
                        </td>
                    </tr>
                </tbody>
            </table>
        </fieldset>
    </div>
</div>
