<div class="input-select-group">
    <ng-select [items]="list"
                bindLabel="fullName"
                bindValue="id"
                placeholder="Select one..."
                [(ngModel)]="model"
                (change)="emitChange()"
                [required]="required"
                (search)="search($event)">
    </ng-select>
    <small *ngIf="errorMessage" class="form-text text-danger validation-message">{{errorMessage}}</small>
    <div class="input-group-append">
        <button *ngIf="showButton"
                type="button"
                class="btn btn-primary"
                (click)="buttonClicked()">
            <i class="fa fa-plus"></i>
            <span class="sr-only">{{buttonScreenReaderText}}</span>
        </button>
    </div>
</div>
