import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { AccountInfo } from "@azure/msal-common";
import { ServerSideConfigDataService } from '../../services/server-side-config-data/server-side-config-data.service';
import { SharedDataService } from '../../services/shared-data/shared-data.service';
import * as model from '../../../model/model';

interface Account extends AccountInfo {
    idTokenClaims?: {
        roles?: string[]
    }
}

@Injectable({
    providedIn: 'root'
})
export class RoleGuard implements CanActivate {
    constructor(private msalService: MsalService, private router: Router, private serverSide: ServerSideConfigDataService, private sharedData: SharedDataService)
    {
        this.serverSide.loadServerSideData();
    }

    canActivate(route: ActivatedRouteSnapshot): boolean {
        const { roles } = route.data;
        const account: Account = this.msalService.instance.getAllAccounts()[0];
        const me: model.Employee = this.sharedData.me.value;
        

        let hasAccess = false;

        if (me && me.isActive) {

            if (me.isAdmin) {
                hasAccess = true;
            } else {
                roles.forEach(role => {

                    if (role == 'NationalQcAdmin' && me.isNationalQcAdmin) {
                        hasAccess = true;
                    } else if (role == 'Basic' && (me.canSignIn || me.isNationalQcAdmin)) {
                        hasAccess = true;
                    }

                });
            }            
        }
        if (!hasAccess) {
            this.router.navigate(['/access-denied'])
        }
        return hasAccess;
    }
}
