import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  rootUrl = window.location.origin;

  constructor(private http: HttpClient) { }

  importQuestionnaire(formData: FormData) {
      return this.http.post<boolean>(`${this.rootUrl}/file/importQuestionnaire`, formData).toPromise();
  }
}
