import { Component, OnInit, Injector } from '@angular/core';
import { UnitOfWorkService } from '../../../services/unit-of-work/unit-of-work.service';
import Utilities from '../../../services/utilities';
import { Router, ActivatedRoute } from '@angular/router';
import { ApplicationInsightsService } from '../../../services/application-insights/application-insights.service';
import { Observable } from 'rxjs';
import { DialogHelper } from '../../../services/dialog-helper';
import { NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { IsBusyService } from '../../../services/is-busy/is-busy.service';
import * as moment from 'moment';
import * as model from '../../../../model/model';
import * as modelEnum from '../../../../model/modelEnums';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { GlobalErrorHandler } from '../../../services/global-error-handler';
import { SharedDataService } from '../../../services/shared-data/shared-data.service';
import { MsalService } from '@azure/msal-angular';
import { Profile } from '../../../../typings';

const LOADING_KEY: string = 'engagement-editor:loadData';
const SAVING_KEY: string = 'engagement-editor:saveChangesStageOne';
const CLONING_KEY: string = 'engagement-editor:saveChangesStageTwo';

const MIN_SEARCH_LENGTH: number = 3;
const BUFFER_SIZE: number = 50;
const NUMBER_OF_ITEMS_FROM_END_BEFORE_FETCHING_MORE: number = 10;
const SEARCH_TIMEOUT: number = 300;

@Component({
    selector: 'app-engagement-editor',
    templateUrl: './engagement-editor.component.html',
    providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class EngagementAdminComponent implements OnInit {

    clientsLoading: boolean = false;
    clientIdLoading: boolean = false;
    clientName: string = null;
    clientsCount: number = 0;
    clientsPage: number = 0;
    clientSearchTimer = null;
    clients: model.Client[] = [];
    clientIds: model.Client[] = [];

    projectsLoading: boolean = false;
    projectName: string = null;
    projectsCount: number = 0;
    projectsPage: number = 0;
    projectSearchTimer = null;
    projects: model.Project[] = [];
    availableProjects: model.Project[] = [];

    aptCawsLoading: boolean = false;
    aptCawName: string = null;
    aptCawsCount: number = 0;
    aptCawsPage: number = 0;
    aptCawSearchTimer = null;
    aptCaws: model.AptCaw[] = [];
    availableAptCaws: model.AptCaw[] = [];

    engagementStatuses: { status: modelEnum.EngagementStatus, title: string }[] = [];

    industries: model.Industry[] = [];
    availableIndustries: model.Industry[] = [];

    businessLines: model.BusinessLine[] = [];
    offices: model.Office[] = [];
    regions: string[] = [];
    tags: model.Tag[] = [];
    availableTags: model.Tag[] = [];
    inspectionProfiles: model.InspectionProfile[] = [];
    employees: model.Employee[] = [];
    availableInspectionEmployees: model.Employee[] = [];
    availableEngagementEmployees: model.Employee[] = [];
    oldInspectionEmployeeId: string = null;
    teamMemberRoles: model.InspectionTeamRole[] = [];
    specialtyReviewerTypes: model.SpecialtyReviewerType[] = [];
    selectedEngagementSections: model.EngagementSection[] = [];
    highLevelEngagementSections: model.EngagementSection[] = [];
    highLevelProfileSections: model.ProfileSection[] = [];
    selectedProfileSections: model.ProfileSection[] = [];


    id: string = null;
    engagement: model.Engagement = {} as model.Engagement;
    isLoading: boolean = true;
    cloneProfileNow: boolean = false;
    disableBusinessLine: boolean = false;

    showQuestionViewer: boolean = false;
    previewEngagement: boolean = false;
    isPublishing = false; 

    selectedProject: model.Project = null;
    selectedAptCaw: model.AptCaw = null;
    selectedIndustry: model.Industry = null;
    selectedTag: model.Tag = null;
    selectedInspectionTeamId: string = null;
    selectedEngagementTeamId: string = null;

    inspectionTeamMemberProfileSections: model.InspectionTeamMemberProfileSection[] = [];
    inspectionTeamMemberEngagementSections: model.InspectionTeamMemberEngagementSection[] = [];

    constructor(
        private activeRoute: ActivatedRoute,
        private uow: UnitOfWorkService,
        private appInsights: ApplicationInsightsService,
        private errorHandler: GlobalErrorHandler,
        public router: Router,
        public isBusy: IsBusyService,
        public dialogHelper: DialogHelper,
        private injector: Injector,
        private dialog: MatDialog,
        private sharedData: SharedDataService,
        private msalService: MsalService
    ) {
        appInsights.logPageView();
        this.dialog = this.injector.get(MatDialog);
        this.engagementStatuses = this.uow.engagementStatuses;
    }


    ngOnInit() {
        this.activeRoute.queryParams.subscribe(routeParams => {
            this.isLoading = true;
            this.isBusy.set(LOADING_KEY, true, 'Loading engagement data');
            Promise
                .all([
                    this.getInspectionProfiles(),
                    this.getBusinessLines(),
                    this.getOffices(),
                    this.getTags(),
                    this.getIndustries(),
                    this.getInspectionTeamRoles(),
                    this.getSpecialtyReviewerTypes(),
                ])
                .then(() => {
                    this.isBusy.set(LOADING_KEY, false);
                    this.getEngagement(routeParams.id);
                    this.getAvailableIndustries();
                })
                .catch(reason => {
                    this.errorHandler.handleError(reason);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        });
    }

    canDeactivate(): Observable<boolean> | boolean {
        if (this.uow.hasChanges.value) {
            const proceed = this.dialogHelper.confirm();

            proceed.subscribe(result => {
                if (result) {
                    this.uow.rollback();
                }
            })

            return proceed;
        }

        return true;
    }


    get rootProfileSections() {
        return this.engagement && this.engagement.inspectionProfile && this.engagement.inspectionProfile.profileSections ? this.engagement.inspectionProfile.profileSections.filter(m => !m.parentSectionId).sort(Utilities.sortByDisplayOrder) : [];
    }

    get rootEngagementSections() {
        return this.engagement && this.engagement.engagementSections ? this.engagement.engagementSections.filter(m => !m.parentSectionId).sort(Utilities.sortByDisplayOrder) : [];
    }


    get assignedInspectionTeamMembers() {
        return this.engagement && this.engagement.inspectionTeamMembers ? this.engagement.inspectionTeamMembers.filter(m => !m.isDeleted) : [];
    }

    get assignedEngagementTeamMembers() {
        return this.engagement && this.engagement.engagementTeamMembers ? this.engagement.engagementTeamMembers.filter(m => !m.isDeleted) : [];
    }

    get availableOffices() {
        return this.engagement && this.engagement.regionCode ? this.offices.filter(m => m.isActive && m.regionCode != 'NATL') : this.offices;
    }

    getHighLevelInspectionTeamMemberEngagementSections(inspectionTeamMemberId) {
        return this.inspectionTeamMemberEngagementSections
            .filter(es => es.inspectionTeamMemberId == inspectionTeamMemberId && (!es.engagementSection.parentSectionId || (es.engagementSection.parentSection && !es.engagementSection.parentSection.parentSectionId)))
            .sort((a, b) => { return a.engagementSection.name.localeCompare(b.engagementSection.name); });
    }

    getInspectionTeamMemberProfileSections(inspectionTeamMemberId) {
        return this.inspectionTeamMemberProfileSections
            .filter(es => es.inspectionTeamMemberId == inspectionTeamMemberId)
            .sort((a, b) => { return a.profileSection.name.localeCompare(b.profileSection.name); });
    }

    availableSpecialtyReviewerTypes(reviewerTypeId) {
        return this.specialtyReviewerTypes.filter(type => (reviewerTypeId == type.id || (!this.assignedInspectionTeamMembers.some(tm => tm.specialtyReviewerTypeId == type.id))));
    }

    get hasChanges() {
        const a = this.uow.engagements.hasChanges.value;
        const b = this.uow.engagementAptCaws.hasChanges.value;
        const c = this.uow.engagementIndustries.hasChanges.value;
        const d = this.uow.engagementProjects.hasChanges.value;
        const f = this.uow.engagementTeamMembers.hasChanges.value;

        return a || b || c || d || f;
    }

    undoChanges() {
        if (this.hasChanges) {
            this.uow.rollback();
        }
    }

    setAvailableProjects() {
        if (this.engagement && this.engagement.client && this.engagement.engagementProjects && this.projects) {
            this.availableProjects = this.projects.filter(p => !this.engagement.engagementProjects.find(ep => ep.projectId == p.id));
        } else {
            this.availableProjects = this.projects;
        }
    }

    setAvailableAptCaws() {
        if (this.engagement && this.engagement.client && this.engagement.engagementAptCaws && this.aptCaws) {
            this.availableAptCaws = this.aptCaws.filter(a => !this.engagement.engagementAptCaws.find(ea => ea.aptCawId == a.id));
        } else {
            this.availableAptCaws = this.aptCaws;
        }
    }

    getAvailableIndustries() {
        if (this.engagement && this.engagement.engagementIndustries) {
            this.availableIndustries = this.industries.filter(i => !this.engagement.engagementIndustries.find(ei => ei.industryId == i.id));
        } else {
            this.availableIndustries = this.industries;
        }
    }

    getBusinessLines() {
        return this.uow.getBusinessLines()
            .then(result => {
                this.businessLines = result;
            });
    }

    getIndustries() {
        return this.uow.getActiveIndustries()
            .then(result => {
                this.industries = result;
            });
    }

    getInspectionTeamRoles() {
        return this.uow.getInspectionTeamRoles()
            .then(result => {
                this.teamMemberRoles = result;
            });
    }

    getOffices() {
        return this.uow.getOffices()
            .then(result => {
                this.offices = result;
                this.getRegions();
            });
    }

    getRegions() {
        this.regions = this.offices.map(o => o.regionCode)
            .filter(Utilities.onlyUnique)
            .sort(Utilities.sortByValue);
    }

    getTags() {
        return this.uow.getActiveTags()
            .then(result => {
                this.tags = result;
            });
    }

    getSpecialtyReviewerTypes() {
        return this.uow.getSpecialtyReviewerTypes()
            .then(result => {
                this.specialtyReviewerTypes = result;
            });
    }

    searchEmployees(searchTerm: string) {
        return this.uow.getEmployees(searchTerm)
            .then(result => {
                this.employees = result.filter(m => m.isActive)
                if (this.engagement && this.engagement.inspectionTeamMembers) {
                    this.availableInspectionEmployees = this.employees.filter(m => !m.isDeleted && m.id !== this.engagement.inspectionLeadId && !this.engagement.inspectionTeamMembers.some(n => n.employeeId == m.id));
                }
                else {
                    this.availableInspectionEmployees = this.employees.filter(m => !m.isDeleted && m.id !== this.engagement.inspectionLeadId);
                }

                if (this.engagement && this.engagement.engagementTeamMembers) {
                    this.availableEngagementEmployees = this.employees.filter(m => !m.isDeleted && !this.engagement.engagementTeamMembers.some(n => n.employeeId == m.id));
                }
                else {
                    this.availableEngagementEmployees = this.employees.filter(m => !m.isDeleted);
                }
            });
    }

    employeeSearch(e) {
        e.term = e.term.toLocaleLowerCase();

        return e.item.name.toLocaleLowerCase().indexOf(e.term) > -1 || e.item.id.toLocaleLowerCase() == e.term;
    }

    updateClientFields() {
        if (this.engagement) {
            if (this.engagement.clientId) {
                if (this.engagement.engagementProjects) {
                    this.engagement.engagementProjects.forEach(p => p.entityAspect.setDeleted());
                }

                if (this.engagement.engagementAptCaws) {
                    this.engagement.engagementAptCaws.forEach(a => a.entityAspect.setDeleted());
                }
            }

            this.getProjects();
            this.getAptCaws();
        }
    }

    getClients() {
        return this.uow.getClientsCount(this.engagement.clientId).then(count => {
            this.clientsCount = count[0];

            return this.uow.getClients(this.engagement.clientId, null, this.clientsPage, BUFFER_SIZE)
                .then(result => {
                    this.clients = result;
                });
        });
    }

    onClientsScrollToEnd() {
        this.fetchMoreClients();
    }

    onClientsScroll({ end }) {
        if (this.clientsLoading || this.clientsCount <= this.clients.length) {
            return;
        }

        if (end + NUMBER_OF_ITEMS_FROM_END_BEFORE_FETCHING_MORE >= this.clients.length) {
            this.fetchMoreClients();
        }
    }

    onClientsSearch(e) {
        //if (!e.items || e.items.length == 0) {
            this.clientName = e.term;
            this.searchClients();
        //}
    }

    searchClients(): void {
        if (this.clientSearchTimer) clearTimeout(this.clientSearchTimer);

        this.clientSearchTimer = setTimeout(() => {
            if (this.clientName && this.clientName.length >= MIN_SEARCH_LENGTH) {
                this.clientsPage = 0;

                this.clientsLoading = true;

                return this.uow.getClientsCount(null, this.clientName).then(count => {
                    this.clientsCount = count[0];

                    return this.uow.getClients(null, this.clientName, this.clientsPage, BUFFER_SIZE)
                        .then(result => {
                            this.clientsLoading = false;
                            this.clients = result;
                        });
                });
                
            }
        }, SEARCH_TIMEOUT);
    }

    private fetchMoreClients() {
        this.clientsPage++;

        this.clientsLoading = true;

        return this.uow.getClients(null, this.clientName, this.clientsPage, BUFFER_SIZE)
            .then(result => {
                this.clientsLoading = false;
                this.clients = this.clients.concat(result);
            });
    }


    getProjects() {
        return this.uow.getProjectsCount(null, null, this.engagement.clientId, null).then(count => {
            this.projectsCount = count[0];

            return this.uow.getProjects(null, null, this.engagement.clientId, null, this.projectsPage, BUFFER_SIZE)
                .then(result => {
                    this.projects = result;
                    this.setAvailableProjects();
                });
        });
    }

    onProjectsScrollToEnd() {
        this.fetchMoreProjects();
    }

    onProjectsScroll({ end }) {
        if (this.projectsLoading || this.projectsCount <= this.projects.length || this.projectsCount <= 50) {
            return;
        }

        if (end + NUMBER_OF_ITEMS_FROM_END_BEFORE_FETCHING_MORE >= this.projects.length) {
            this.fetchMoreProjects();
        }
    }

    onProjectsSearch(e) {
        //if (!e.items || e.items.length == 0) {
            this.projectName = e.term;
            this.searchProjects();
        //}
    }

    searchProjects(): void {
        clearTimeout(this.projectSearchTimer);

        this.projectSearchTimer = setTimeout(() => {
            if (this.projectName) {
                this.projectsPage = 0;

                this.projectsLoading = true;

                return this.uow.getProjectsCount(null, this.projectName, null).then(count => {
                    this.projectsCount = count[0];

                    return this.uow.getProjects(null, this.projectName, this.engagement.clientId, null, this.projectsPage, BUFFER_SIZE)
                        .then(result => {
                            this.projectsLoading = false;
                            this.projects = result;
                            this.setAvailableProjects();
                        });
                });
            }
        }, SEARCH_TIMEOUT);
    }

    //projectsSearch(term: string, item: model.Client) {
    //    term = term.toLocaleLowerCase();

    //    return item.name.toLocaleLowerCase().indexOf(term) > -1 || item.id.toLocaleLowerCase() == term;
    //}

    private fetchMoreProjects() {
        this.projectsPage++;

        this.projectsLoading = true;

        return this.uow.getProjects(null, this.projectName, this.engagement.clientId, null, this.projectsPage, BUFFER_SIZE)
            .then(result => {
                this.projectsLoading = false;
                this.projects = this.projects.concat(result);
                this.setAvailableProjects();
            });
    }


    getAptCaws() {
        this.aptCawsLoading = true;

        return this.uow.getAptCawsCount(null, null, this.engagement.clientId, null).then(count => {
            this.aptCawsCount = count[0];

            return this.uow.getAptCaws(null, null, this.engagement.clientId, null, this.aptCawsPage, BUFFER_SIZE)
                .then(result => {
                    this.aptCaws = result;
                    this.setAvailableAptCaws();
                    this.aptCawsLoading = false;
                });
        });
    }

    onAptCawsScrollToEnd() {
        this.fetchMoreAptCaws();
    }

    onAptCawsScroll(event) {
        if (this.aptCawsLoading || this.aptCawsCount <= this.aptCaws.length || this.aptCawsCount <= 50) {
            return;
        }

        if (event.end + NUMBER_OF_ITEMS_FROM_END_BEFORE_FETCHING_MORE >= this.aptCaws.length) {
            this.fetchMoreAptCaws();
        }
    }

    onAptCawsSearch(e) {
        //if (!e.items || e.items.length == 0) {
            this.aptCawName = e.term;
            this.searchAptCaws();
        //}
    }

    searchAptCaws(): void {
        clearTimeout(this.aptCawSearchTimer);

        this.aptCawSearchTimer = setTimeout(() => {
            if (this.aptCawName) {
                this.aptCawsPage = 0;

                this.aptCawsLoading = true;

                return this.uow.getAptCawsCount(null, this.aptCawName, this.engagement.clientId).then(count => {
                    this.aptCawsCount = count[0];

                    return this.uow.getAptCaws(null, this.aptCawName, this.engagement.clientId, null, this.aptCawsPage, BUFFER_SIZE)
                        .then(result => {
                            this.aptCawsLoading = false;
                            this.aptCaws = result;
                            this.setAvailableAptCaws();
                        });
                });
            }
        }, SEARCH_TIMEOUT);
    }

    private fetchMoreAptCaws() {
        this.aptCawsPage++;

        this.aptCawsLoading = true;

        return this.uow.getAptCaws(null, null, this.engagement.clientId, null, this.aptCawsPage, BUFFER_SIZE)
            .then(result => {
                this.aptCawsLoading = false;
                this.aptCaws = this.aptCaws.concat(result);
                this.setAvailableAptCaws();
            });
    }


    getInspectionProfiles() {
        return this.uow.getInspectionProfilesForEngagement()
            .then(result => {
                this.inspectionProfiles = result;
            });
    }

    getInspectionProfileById(previewEngagement: boolean = true) {
        if (this.engagement.inspectionProfileId) {
            this.isBusy.set(LOADING_KEY, true);
            return this.uow.getInspectionProfileForEngagementById(this.engagement.inspectionProfileId)
                .then(result => {
                    if (result) {
                        let ip = this.inspectionProfiles.find(i => i.id == result[0].id);
                        ip = result[0];
                        this.previewEngagement = previewEngagement;
                        this.engagement.inspectionProfile = ip;

                        this.setHighLevelProfileSections();
                    }
                })
                .finally(() => {
                    this.isBusy.set(LOADING_KEY, false);
                });
        }
    }

    getEngagement(id: string) {
        this.id = id;
        this.cloneProfileNow = false;

        this.isBusy.set(LOADING_KEY, true, 'Loading engagement');
        if (id == 'new') {
            this.engagement = this.uow.engagements.createEntity();
            this.engagement.inspectionYear = new Date().getFullYear();
            this.engagement.businessLineId = null;
            this.engagement.inspectionProfileId = null;
            this.engagement.inspectionLeadId = null;
            this.engagement.officeId = null;
            this.engagement.regionCode = null;
            this.engagement.clientId = null;
            this.engagement.taxReviewerId = null;
            this.engagement.isReviewerId = null;
            this.engagement.marketCap = null;
            this.engagement.totalEngagementHours = null;
            this.engagement.status = modelEnum.EngagementStatus.draft;
            this.engagement.rating = modelEnum.EngagementRating.pending;
            const def = this.businessLines.find(m => m.name == 'Assurance');
            if (def) {
                this.engagement.businessLineId = def.id;
                this.disableBusinessLine = true;
            }
            return this.getClients()
                .finally(() => {
                    this.isBusy.set(LOADING_KEY, false);
                });
        } else {
            return this.uow.getEngagementById(parseInt(id))
                .then(result => {
                    if (result && result.length > 0) {
                        this.engagement = result[0];
                        this.oldInspectionEmployeeId = this.engagement.inspectionLeadId;

                        const def = this.businessLines.find(m => m.name == 'Assurance');
                        if (def) {
                            this.engagement.businessLineId = def.id;
                            this.disableBusinessLine = true;
                        }

                        this.setHighLevelEngagementSections();

                        this.setHighLevelProfileSections();

                        return this.uow.getHighLevelInspectionTeamMemberProfileSections(this.engagement.id)
                            .then(result => {
                                this.inspectionTeamMemberProfileSections = result;

                                return Promise.resolve();
                                //return this.getClients()
                                //    .then(() => {
                                //        this.getProjects();
                                //        this.getAptCaws();
                                //    });
                            })
                            .then(() => this.uow.getHighLevelInspectionTeamMemberEngagementSections(this.engagement.id))
                            .then(result => {
                                this.inspectionTeamMemberEngagementSections = result;

                                return Promise.resolve();
                            })
                            .then(() => this.getClients())
                            .then(() => {
                                this.getProjects();
                                this.getAptCaws();
                            }).finally(() => {
                                this.isBusy.set(LOADING_KEY, false);
                            });                        
                    }
                    else {
                        this.router.navigate(['./engagements'], { relativeTo: this.activeRoute.parent });
                    }
                });
        }
    }

    getStatusName(status: modelEnum.EngagementStatus) {
        const match = this.engagementStatuses.find(m => m.status == status);
        return match ? match.title : 'N/A';
    }

    setHighLevelProfileSections() {
        this.highLevelProfileSections = this.engagement.inspectionProfile && this.engagement.inspectionProfile.profileSections
            ? this.engagement.inspectionProfile.profileSections.filter(m => !m.parentSectionId || !m.parentSection.parentSectionId)
                .sort(Utilities.sortByName)
            : [];
    }

    setHighLevelEngagementSections() {
        this.highLevelEngagementSections = this.engagement.engagementSections
            .filter(es => !es.parentSectionId || !es.parentSection.parentSectionId)
            .sort(Utilities.sortByName);
    }

    expandAll() {
        if (this.engagement) {
            if (this.engagement.status > modelEnum.EngagementStatus.draft) {
                if (this.engagement.engagementSections && this.engagement.engagementSections.length) {
                    this.engagement.engagementSections.forEach((m) => { m.isCollapsed = false; });
                }
            }
            else {
                if (this.engagement.inspectionProfile && this.engagement.inspectionProfile.profileSections) {
                    this.engagement.inspectionProfile.profileSections.forEach((m) => { m.isCollapsed = false; });
                }
            }
        }
    }

    collapseAll() {
        if (this.engagement) {
            if (this.engagement.status > modelEnum.EngagementStatus.draft) {
                if (this.engagement.engagementSections && this.engagement.engagementSections.length) {
                    this.engagement.engagementSections.forEach((m) => { m.isCollapsed = true; });
                }
            }
            else {
                if (this.engagement.inspectionProfile && this.engagement.inspectionProfile.profileSections) {
                    this.engagement.inspectionProfile.profileSections.forEach((m) => { m.isCollapsed = true; });
                }
            }
        }
    }


    syncMetadataFromProfile() {
        this.previewEngagement = false;
        const ip = this.inspectionProfiles.find(i => i.id == this.engagement.inspectionProfileId);
        if (ip.profileSections && ip.profileSections.some(p => p.questions && p.questions.length >= 0)) {
            //this.previewEngagement = true;
            //this.engagement.inspectionProfile = ip;

            this.getInspectionProfileById();
        } else {
            this.getInspectionProfileById(false);
        }
        if (this.engagement.inspectionProfileId) {
            if (!this.disableBusinessLine && !this.engagement.businessLineId) {
                this.engagement.businessLineId = this.engagement.inspectionProfile.businessLineId;
            }
        }
    }

    updateRegionCode() {
        if (this.engagement) {
            if (this.engagement.officeId) {
                const office = this.offices.find(m => m.id == this.engagement.officeId);
                if (office) {
                    this.engagement.regionCode = office.regionCode;
                }
                else {
                    this.engagement.regionCode = null;
                }
            }
            else {
                this.engagement.regionCode = null;
            }
        }
    }

    addTeamCaptainToTeamIfNecessary() {
        if (this.engagement) {
            if (this.engagement.inspectionLeadId) {
                if (!this.engagement.inspectionTeamMembers.some(m => m.employeeId == this.engagement.inspectionLeadId)) {
                    const itm = this.uow.inspectionTeamMembers.createEntity();
                    const employee = this.employees.find(m => m.id == this.engagement.inspectionLeadId);
                    if (employee) {
                        itm.employee = employee;
                        itm.employeeId = employee.id;
                        itm.engagement = this.engagement;
                        itm.engagementId = this.engagement.id;
                        this.engagement.inspectionTeamMembers.push(itm);
                    }
                }

                this.oldInspectionEmployeeId = this.engagement.inspectionLeadId;
            }
        }
    }

    addInspectionTeamMember() {
        if (this.selectedInspectionTeamId) {
            if (!this.engagement.inspectionTeamMembers.some(m => m.employeeId == this.selectedInspectionTeamId)) {
                const itm = this.uow.inspectionTeamMembers.createEntity();
                const employee = this.employees.find(m => m.id == this.selectedInspectionTeamId);
                if (employee) {
                    itm.employee = employee;
                    itm.employeeId = employee.id;
                    itm.engagement = this.engagement;
                    itm.engagementId = this.engagement.id;
                    this.engagement.inspectionTeamMembers.push(itm);
                }
                else {
                    this.uow.inspectionTeamMembers.cancelChanges(itm);
                }
            }
        }

        this.selectedInspectionTeamId = null;
    }

    removeInspectionTeamMember(teamMember: model.InspectionTeamMember) {
        if (confirm('Remove "' + teamMember.employee.fullName + '" from the inspection team?')) {
            if (teamMember.id > 0) {
                teamMember.entityAspect.setDeleted();
            }
            else {
                this.uow.inspectionTeamMembers.cancelChanges(teamMember);
            }
        }
    }


    async addEngagementTeamMember() {
        if (this.selectedEngagementTeamId) {
            if (!this.engagement.engagementTeamMembers.some(m => m.employeeId == this.selectedEngagementTeamId)) {
                const etm = this.uow.engagementTeamMembers.createEntity();
                const employee = this.employees.find(m => m.id == this.selectedEngagementTeamId);
                if (employee) {
                    let projectIds: string[] = [];
                    this.engagement.engagementProjects.forEach(engagementProject => projectIds.push(engagementProject.projectId));
                    let totalEmployeeProjectHours;

                    await this.uow.getTotalEmployeeProjectHours(projectIds, employee.id)
                        .then(result => totalEmployeeProjectHours = result[0])
                        .catch(error => console.log(error));

                    etm.employee = employee;
                    etm.hours = totalEmployeeProjectHours ? totalEmployeeProjectHours : null;
                    etm.inspectionTeamRoleId = null;
                    etm.engagement = this.engagement;
                }
                else {
                    this.uow.engagementTeamMembers.cancelChanges(etm);
                }
            }
        }

        this.selectedEngagementTeamId = null;
    }

    removeEngagementTeamMember(teamMember: model.EngagementTeamMember) {
        if (confirm('Remove "' + teamMember.employee.fullName + '" from the engagement team?')) {
            if (teamMember.id > 0) {
                teamMember.entityAspect.setDeleted();
            }
            else {
                this.uow.engagementTeamMembers.cancelChanges(teamMember);
            }
        }
    }

    addProject() {
        if (!this.selectedProject)
            return;
        if (!this.engagement.engagementProjects.find(p => p.projectId == this.selectedProject.id)) {
            if (!this.engagement.engagementProjects.length) {
                this.engagement.office = this.selectedProject.operatingUnit;
                this.engagement.regionCode = this.selectedProject.region;
                this.engagement.department = this.selectedProject.departmentName;
            }
            const engProj = this.uow.engagementProjects.createEntity();
            engProj.engagementId = this.engagement.id;
            engProj.engagement = this.engagement;
            engProj.projectId = this.selectedProject.id;
            this.engagement.engagementProjects.push(engProj);
        }
        if (this.selectedProject.entityAspect.entityState.isAddedModifiedOrDeleted()) {
            this.selectedProject.entityAspect.rejectChanges();
        }

        this.isBusy.set(LOADING_KEY, true, "Loading project hours");
        this.uow.getEmployeeProjectHours(this.selectedProject.id)
            .then(result => {
                if (result) {
                    result.forEach(eph => {
                        if (!this.engagement.engagementTeamMembers.find(tm => tm.employeeId == eph.employeeId)) {
                            const etm = this.uow.engagementTeamMembers.createEntity({
                                employeeId: eph.employeeId,
                                inspectionTeamRole: this.teamMemberRoles.find(e => e.name.toLowerCase() == 'other')
                            } as model.EngagementTeamMember);
                            this.engagement.engagementTeamMembers.push(etm);
                        }
                    });
                }
            })
            .then(() => {
                if (this.engagement.engagementTeamMembers.length > 0) {
                    this.engagement.totalEngagementHours = 0;
                    this.engagement.engagementTeamMembers.forEach(async teamMember => {
                        let projectIds: string[] = [];
                        this.engagement.engagementProjects.forEach(engagementProject => projectIds.push(engagementProject.projectId));

                        await this.uow.getTotalEmployeeProjectHours(projectIds, teamMember.employeeId)
                            .then(result => {
                                teamMember.hours = result[0];
                                this.engagement.totalEngagementHours += teamMember.hours;
                                this.engagement.totalEngagementHours = Math.round(this.engagement.totalEngagementHours * 100) / 100;
                            })
                            .catch(error => console.log(error));
                    })
                }
            })
            .catch(error => console.log(error))
            .finally(() => this.isBusy.set(LOADING_KEY, false));

        setTimeout(() => this.selectedProject = null, 1);
        this.setAvailableProjects();
    }

    addAptCaw() {
        if (!this.selectedAptCaw)
            return;

        if (!this.engagement.engagementAptCaws.find(a => a.aptCawId == this.selectedAptCaw.id)) {
            if (!this.engagement.engagementAptCaws.length) {
                this.engagement.engagementType = this.selectedAptCaw.engagementType;
                this.engagement.clientEntityType = this.selectedAptCaw.clientEntityType;
                this.engagement.clientFiscalYearEnd = this.selectedAptCaw.fiscalYearEnd;
            }
            const engApt = this.uow.engagementAptCaws.createEntity();
            engApt.engagementId = this.engagement.id;
            engApt.engagement = this.engagement;
            engApt.aptCawId = this.selectedAptCaw.id;
            this.engagement.engagementAptCaws.push(engApt);
        }
        if (this.selectedAptCaw.entityAspect.entityState.isAddedModifiedOrDeleted()) {
            this.selectedAptCaw.entityAspect.rejectChanges();
        }
        const inds = (this.selectedAptCaw.industry || '').split('|');
        inds.forEach(i => {
            if (i) {
                const ind = this.industries.find(e => e.name == i);
                if (ind) {
                    this.selectedIndustry = ind;
                    this.addIndustry();
                }
                else {
                    this.addIndustry(i);
                }
            }
        });
        setTimeout(() => this.selectedAptCaw = null, 1);
        this.setAvailableAptCaws();
    }

    addIndustry(name?: string) {
        let duplicateIndustry = this.engagement.engagementIndustries.filter(i => i.industryName == name && i.industryName != null)
        if (duplicateIndustry && duplicateIndustry.length > 0) {
            return;
        }
        if (name) {
            const engInd = this.uow.engagementIndustries.createEntity();
            engInd.engagementId = this.engagement.id;
            engInd.engagement = this.engagement;
            engInd.industryName = name;
            this.engagement.engagementIndustries.push(engInd);
        }
        else {
            if (!this.selectedIndustry)
                return;
            if (this.selectedIndustry && !this.engagement.engagementIndustries.find(i => i.industryId == this.selectedIndustry.id)) {
                const engInd = this.uow.engagementIndustries.createEntity();
                engInd.engagementId = this.engagement.id;
                engInd.engagement = this.engagement;
                engInd.industryId = this.selectedIndustry.id;
                this.engagement.engagementIndustries.push(engInd);
            }
            if (this.selectedIndustry.entityAspect.entityState.isAddedModifiedOrDeleted()) {
                this.selectedIndustry.entityAspect.rejectChanges();
            }
            setTimeout(() => this.selectedIndustry = null, 1);
        }
    }

    addEngagementQuestionActivatingTag(engagement, commitAfterAdd) {
        // add EngagementQuestionActivating tag when new EngagementTag is created
        engagement.engagementSections.forEach(engagementSection => {
            engagementSection.engagementQuestions.forEach(engagementQuestion => {
                engagementQuestion.question.profileQuestionActivatingTags.forEach(activatingTag => {
                    engagementQuestion.engagementQuestionOptions.forEach(option => {
                        this.tags.forEach(t => {
                            if (activatingTag.tagId == t.id && activatingTag.questionId == engagementQuestion.questionId && activatingTag.questionOptionId == option.questionOptionId) {
                                const eqat = this.uow.engagementQuestionActivatingTags.createEntity();

                                eqat.tag = t;
                                eqat.tagId = t.id;
                                eqat.engagementQuestionOption = option;
                                eqat.engagementQuestionOptionId = option.id;

                                option.engagementQuestionActivatingTags.push(eqat);
                            }
                        });
                    });
                });
            });
        });

        if (commitAfterAdd) {
            this.uow.commit();
        }
    }

    removeProject(engProj: model.EngagementProject) {
        const ind = this.engagement.engagementProjects.findIndex(p => p.projectId == engProj.projectId);
        if (ind >= 0) {
            this.uow.getEmployeeProjectHours(this.engagement.engagementProjects[ind].projectId)
                .then(result => {
                    if (result) {
                        result.forEach(eph => {
                            const tmidx = this.engagement.engagementTeamMembers.findIndex(tm => !tm.entityAspect.entityState.isDeleted() && tm.employeeId == eph.employeeId);
                            if (tmidx >= 0) {
                                this.engagement.engagementTeamMembers[tmidx].hours -= eph.hours;
                                this.engagement.totalEngagementHours -= eph.hours;
                                if (this.engagement.engagementTeamMembers[tmidx].hours <= 0) {
                                    this.engagement.engagementTeamMembers[tmidx].entityAspect.setDeleted();
                                }
                            }
                        });
                        if (this.engagement.totalEngagementHours < 0)
                            this.engagement.totalEngagementHours = 0;
                        this.engagement.totalEngagementHours = Math.round(this.engagement.totalEngagementHours * 100) / 100;
                    }
                })
            if (engProj.entityAspect.entityState.isAdded()) {
                this.engagement.engagementProjects[ind].entityAspect.rejectChanges();
            } else {
                this.engagement.engagementProjects[ind].entityAspect.setDeleted();
            }
            if (!this.engagement.engagementProjects.length) {
                this.engagement.officeId = null;
                this.engagement.regionCode = null;
                this.engagement.department = null;
                this.engagement.totalEngagementHours = 0;
            }
        }
        this.setAvailableProjects();
    }

    removeAptCaw(engAptCaw: model.EngagementAptCaw) {
        const ind = this.engagement.engagementAptCaws.findIndex(a => a.aptCawId == engAptCaw.aptCawId);
        if (ind >= 0) {
            if (engAptCaw.entityAspect.entityState.isAdded()) {
                this.engagement.engagementAptCaws[ind].entityAspect.rejectChanges();
            } else {
                this.engagement.engagementAptCaws[ind].entityAspect.setDeleted();
            }
            if (!this.engagement.engagementAptCaws.length) {
                this.engagement.engagementType = null;
                this.engagement.clientEntityType = null;
                this.engagement.clientFiscalYearEnd = null;
                const industryCount = this.engagement.engagementIndustries.length;
                for (let i = 0; i < industryCount; i++) {
                   this.removeIndustry(this.engagement.engagementIndustries[0]);
                }
            }
        }
        this.setAvailableAptCaws();
    }

    removeIndustry(engInd: model.EngagementIndustry) {
        const ind = this.engagement.engagementIndustries.findIndex(i => i.industryId == engInd.industryId);
        if (ind >= 0) {
            if (engInd.entityAspect.entityState.isAdded()) {
                this.engagement.engagementIndustries[ind].entityAspect.rejectChanges();
            } else {
                this.engagement.engagementIndustries[ind].entityAspect.setDeleted();
            }
        }
    }

    addInspectionTeamMemberEngagementSectionByIndex(index, inspectionTeamMemberId) {

        let selectedSection = this.selectedEngagementSections[index];

        this.addInspectionTeamMemberEngagementSection(selectedSection, inspectionTeamMemberId);

        setTimeout(() => this.selectedEngagementSections[index] = null, 1);
    }

    addInspectionTeamMemberEngagementSectionByProfileSectionId(profileSectionId, inspectionTeamMemberId) {
        let selectedSection = this.highLevelEngagementSections.find(es => es.profileSectionId == profileSectionId);

        this.addInspectionTeamMemberEngagementSection(selectedSection, inspectionTeamMemberId);
    }

    addInspectionTeamMemberEngagementSection(engagementSection: model.EngagementSection, inspectionTeamMemberId) {
        if (!engagementSection) {
            return;
        }

        if (engagementSection && !this.inspectionTeamMemberEngagementSections.find(es => es.engagementSectionId == engagementSection.id && es.inspectionTeamMemberId == inspectionTeamMemberId)) {
            const newSection = this.uow.inspectionTeamMemberEngagementSections.createEntity();
            newSection.inspectionTeamMemberId = inspectionTeamMemberId;
            newSection.engagementSectionId = engagementSection.id;

            this.inspectionTeamMemberEngagementSections.push(newSection);

            if (engagementSection.parentSectionId == null) {
                this.addFirstLevelChildInspectionTeamMemberEngagementSections(this.engagement.engagementSections.filter(es => es.parentSectionId == newSection.engagementSectionId), inspectionTeamMemberId);
            }
        }

        if (engagementSection.entityAspect.entityState.isAddedModifiedOrDeleted()) {
            engagementSection.entityAspect.rejectChanges();
        }
    }

    addInspectionTeamMemberProfileSection(index, inspectionTeamMemberId) {
        let selectedSection = this.selectedProfileSections[index];

        if (!selectedSection) {
            return;
        }

        if (selectedSection && !this.inspectionTeamMemberProfileSections.find(ps => ps.profileSectionId == selectedSection.id && ps.inspectionTeamMemberId == inspectionTeamMemberId)) {
            const newSection = this.uow.inspectionTeamMemberProfileSections.createEntity();
            newSection.inspectionTeamMemberId = inspectionTeamMemberId;
            newSection.profileSectionId = selectedSection.id;

            this.inspectionTeamMemberProfileSections.push(newSection);

            if (selectedSection.parentSectionId == null) {
                this.addFirstLevelChildInspectionTeamMemberProfileSections(this.engagement.inspectionProfile.profileSections.filter(ps => ps.parentSectionId == newSection.profileSectionId), inspectionTeamMemberId);
            }

        }

        if (selectedSection.entityAspect.entityState.isAddedModifiedOrDeleted()) {
            selectedSection.entityAspect.rejectChanges();
        }

        setTimeout(() => this.selectedProfileSections[index] = null, 1);
    }

    addFirstLevelChildInspectionTeamMemberProfileSections(sections: model.ProfileSection[], teamMemberId: number) {
        sections.forEach(section => {
            if (teamMemberId) {
                let existingInspectionTeamMemberSection = this.inspectionTeamMemberProfileSections.find(ps => ps.profileSectionId == section.id && ps.inspectionTeamMemberId == teamMemberId);

                if (!existingInspectionTeamMemberSection) {
                    const newSection = this.uow.inspectionTeamMemberProfileSections.createEntity();
                    newSection.inspectionTeamMemberId = teamMemberId;
                    newSection.profileSectionId = section.id;

                    this.inspectionTeamMemberProfileSections.push(newSection);
                }
            }
        });
    }

    addFirstLevelChildInspectionTeamMemberEngagementSections(sections: model.EngagementSection[], teamMemberId: number) {
        sections.forEach(section => {
            if (teamMemberId) {
                let existingInspectionTeamMemberSection = this.inspectionTeamMemberEngagementSections.find(es => es.engagementSectionId == section.id && es.inspectionTeamMemberId == teamMemberId);

                if (!existingInspectionTeamMemberSection) {
                    const newSection = this.uow.inspectionTeamMemberEngagementSections.createEntity();
                    newSection.inspectionTeamMemberId = teamMemberId;
                    newSection.engagementSectionId = section.id;

                    this.inspectionTeamMemberEngagementSections.push(newSection);
                }
            }
        });
    }

    removeChildInspectionTeamMemberEngegementSections(sections: model.EngagementSection[], teamMemberId) {
        sections.forEach(section => {

            this.removeChildInspectionTeamMemberEngegementSections(this.engagement.engagementSections.filter(es => !es.isDeleted && es.parentSectionId == section.id), teamMemberId);

            let index = this.inspectionTeamMemberEngagementSections.findIndex(es => es.engagementSectionId == section.id && es.inspectionTeamMemberId == teamMemberId);

            if (index >= 0) {
                if (this.inspectionTeamMemberEngagementSections[index].entityAspect.entityState.isAdded()) {
                    this.inspectionTeamMemberEngagementSections[index].entityAspect.rejectChanges();
                    this.inspectionTeamMemberEngagementSections.splice(index, 1);
                } else {
                    this.inspectionTeamMemberEngagementSections[index].entityAspect.setDeleted();
                    this.inspectionTeamMemberEngagementSections.splice(index, 1);
                }
            }
        });
    }

    removeInspectionTeamMemberEngagementSection(section: model.InspectionTeamMemberEngagementSection) {

        this.removeChildInspectionTeamMemberEngegementSections(this.engagement.engagementSections.filter(es => !es.isDeleted && es.parentSectionId == section.engagementSectionId), section.inspectionTeamMemberId);

        const index = this.inspectionTeamMemberEngagementSections.findIndex(es => es.engagementSectionId == section.engagementSectionId && es.inspectionTeamMemberId == section.inspectionTeamMemberId);

        if (index >= 0) {
            if (section.entityAspect.entityState.isAdded()) {
                this.inspectionTeamMemberEngagementSections[index].entityAspect.rejectChanges();
                this.inspectionTeamMemberEngagementSections.splice(index, 1);
            } else {
                this.inspectionTeamMemberEngagementSections[index].entityAspect.setDeleted();
                this.inspectionTeamMemberEngagementSections.splice(index, 1);
            }
        }
    }

    removeChildInspectionTeamMemberProfileSections(sections: model.ProfileSection[], teamMemberId) {
        sections.forEach(section => {

            this.removeChildInspectionTeamMemberProfileSections(this.engagement.inspectionProfile.profileSections.filter(ps => !ps.isDeleted && ps.parentSectionId == section.id), teamMemberId);

            let index = this.inspectionTeamMemberProfileSections.findIndex(ps => ps.profileSectionId == section.id && ps.inspectionTeamMemberId == teamMemberId);

            if (index >= 0) {
                if (this.inspectionTeamMemberProfileSections[index].entityAspect.entityState.isAdded()) {
                    this.inspectionTeamMemberProfileSections[index].entityAspect.rejectChanges();
                    this.inspectionTeamMemberProfileSections.splice(index, 1);
                } else {
                    this.inspectionTeamMemberProfileSections[index].entityAspect.setDeleted();
                    this.inspectionTeamMemberProfileSections.splice(index, 1);
                }
            }
        });
    }

    removeInspectionTeamMemberProfileSection(section) {

        this.removeChildInspectionTeamMemberProfileSections(this.engagement.inspectionProfile.profileSections.filter(ps => !ps.isDeleted && ps.parentSectionId == section.profileSectionId), section.inspectionTeamMemberId);

        const index = this.inspectionTeamMemberProfileSections.findIndex(ps => ps.profileSectionId == section.profileSectionId && ps.inspectionTeamMemberId == section.inspectionTeamMemberId);

        if (index >= 0) {
            if (section.entityAspect.entityState.isAdded()) {
                this.inspectionTeamMemberProfileSections[index].entityAspect.rejectChanges();
                this.inspectionTeamMemberProfileSections.splice(index, 1);
            } else {
                this.inspectionTeamMemberProfileSections[index].entityAspect.setDeleted();
                this.inspectionTeamMemberProfileSections.splice(index, 1);
            }
        }
    }

    specialtyReviewerTypeAdded() {
        setTimeout(() => this.updateTaxAndISReviewers(), 1);
    }

    updateTaxAndISReviewers() {
        let taxReviewer = this.assignedInspectionTeamMembers.find(tm => tm.specialtyReviewerType && tm.specialtyReviewerType.name == 'Tax Reviewer');
        let isReviewer = this.assignedInspectionTeamMembers.find(tm => tm.specialtyReviewerType && tm.specialtyReviewerType.name == 'IS Reviewer');

        if ((taxReviewer && this.engagement.taxReviewerId != taxReviewer.employeeId) || this.engagement.taxReviewerId) {
            this.engagement.taxReviewerId = taxReviewer ? taxReviewer.employeeId : null;
        }

        if ((isReviewer && this.engagement.isReviewerId != isReviewer.employeeId) || this.engagement.isReviewerId) {
            this.engagement.isReviewerId = isReviewer ? isReviewer.employeeId : null;
        }
    }

    convertInspectionTeamMemberProfileSectionsToEngagementSections() {
        let engagementSections = this.engagement.engagementSections;

        this.inspectionTeamMemberProfileSections
            .forEach(itmps => {
                let selectedSection = engagementSections.find(es => es.profileSectionId == itmps.profileSectionId);

                if (selectedSection && !this.inspectionTeamMemberEngagementSections.find(es => es.engagementSectionId == selectedSection.id && es.inspectionTeamMemberId == itmps.inspectionTeamMemberId)) {
                    const newSection = this.uow.inspectionTeamMemberEngagementSections.createEntity();
                    newSection.inspectionTeamMemberId = itmps.inspectionTeamMemberId;
                    newSection.engagementSectionId = selectedSection.id;

                    this.inspectionTeamMemberEngagementSections.push(newSection);

                }

                if (selectedSection && selectedSection.entityAspect.entityState.isAddedModifiedOrDeleted()) {
                    selectedSection.entityAspect.rejectChanges();
                }
            });
    }

    saveChanges() {
        // Make sure team captain is an inspection team member before saving
        this.addTeamCaptainToTeamIfNecessary();

        //const changes = this.uow.getChanges();
        //changes.forEach(change => {
        //    change.entityAspect.validateEntity();
        //    const errors = change.entityAspect.getValidationErrors();
        //});

        this.isBusy.set(SAVING_KEY, true, 'Saving engagement');
        
        this.uow.commit()
            .then(() => {

                this.uow.inspectionTeamMembers.saveChanges();
                return this.uow.commit()
                    .then(() => this.uow.updateChildInspectionTeamMemberProfileSections(this.engagement.id))
                    .then(() => {

                        if (this.id != 'new') {
                            this.uow.updateActivatedEngagementQuestions(this.engagement.id);
                            this.sharedData.setMyEngagements(null);
                        }
                        if (!confirm('Engagement saved! Continue editing this engagement?')) {
                            this.router.navigate(['./engagements'], { relativeTo: this.activeRoute.parent });
                        }
                        else {
                            if (this.id == 'new') {
                                this.router.navigate(['./edit'], { queryParams: { id: this.engagement.id }, relativeTo: this.activeRoute.parent });
                            }
                        }
                    });
            })
            .catch(reason => {
                console.error(reason);
                this.errorHandler.handleError(reason);
            })
            .finally(() => {
                this.isBusy.set(SAVING_KEY, false);
            });
    }

    publishEngagement() {
        if (confirm("Publish now? This will apply the selected questionnaire to this engagement and set the engagement as Active.")) {
            this.cloneProfileNow = true;

            this.cloneInspectionProfileIntoEngagement();
        }
    }

    cloneInspectionProfileIntoEngagement() {
        if (this.engagement && this.engagement.inspectionProfile) {
            // Make sure team captain is an inspection team member before saving
            this.addTeamCaptainToTeamIfNecessary();

            this.isBusy.set(CLONING_KEY, true, 'Applying questionnaire "' + this.engagement.inspectionProfile.name + '" to this engagement');
            // Save any changes to the engagement, then clone the inspection profile
            return this.uow.commit()
                .then(() => {
                    this.isPublishing = true;
                    return this.uow.publishEngagement(this.engagement.id)
                        .then((result) => {
                            const engagement = result[0];
                            this.addEngagementQuestionActivatingTag(engagement, true);
                            this.uow.updateActivatedEngagementQuestions(this.engagement.id);

                            this.setHighLevelEngagementSections();

                            this.setHighLevelProfileSections();

                            this.convertInspectionTeamMemberProfileSectionsToEngagementSections();
                            return this.uow.commit();
                        })
                        .then(() => this.uow.updateChildInspectionTeamMemberProfileSections(this.engagement.id))
                        .then(() => {
                            if (!confirm('Engagement published! Continue editing this engagement?')) {
                                this.router.navigate(['./engagements'], { relativeTo: this.activeRoute.parent });
                            }
                            else {
                                this.router.navigate(['./edit'], { queryParams: { id: this.engagement.id }, relativeTo: this.activeRoute.parent });
                            }
                        })
                        .catch(reason => {
                            console.error(reason);
                            this.errorHandler.handleError(reason);
                        })
                        .finally(() => {
                            this.isPublishing = false;
                            this.isBusy.set(CLONING_KEY, false);
                        });
                })
                .catch(reason => {
                    console.error(reason);
                    this.errorHandler.handleError(reason);
                })
                .finally(() => {
                    this.isBusy.set(CLONING_KEY, false);
                });
        }
    }

    markReviewComplete() {
        this.dialog.open(ConfirmationDialogComponent, {
            data: {
                title: "Confirm",
                content: "Changes cannot be undone. Would you like to continue?"
            }
        })
            .afterClosed().toPromise().then(response => {
                if (response == "Yes") {
                    this.engagement.status = modelEnum.EngagementStatus.teamCaptainReviewComplete;
                    this.engagement.signOffEmployeeId = (this.msalService.instance.getAllAccounts()[0] as Profile).idTokenClaims.employeeID;
                    this.engagement.signOffDate = Utilities.getCurrentDate();
                    this.uow.engagements.saveChanges([this.engagement]);
                }
            });
    }

    markRequiresChanges() {
        this.dialog.open(ConfirmationDialogComponent, {
            data: {
                title: "Confirm",
                content: "Changes cannot be undone. Would you like to continue?"
            }
        })
            .afterClosed().toPromise().then(response => {
                if (response == "Yes") {
                    this.engagement.status = modelEnum.EngagementStatus.changesRequired;
                    this.engagement.signOffEmployeeId = null;
                    this.engagement.signOffDate = null;
                    this.uow.engagements.saveChanges([this.engagement]);
                }
            });
    }

    markArchived() {
        this.dialog.open(ConfirmationDialogComponent, {
            data: {
                title: "Confirm",
                content: "Changes cannot be undone. Would you like to continue?"
            }
        })
            .afterClosed().toPromise().then(response => {
                if (response == "Yes") {
                    this.engagement.status = modelEnum.EngagementStatus.archived;
                    this.uow.engagements.saveChanges([this.engagement]);
                }
            });
    }


    formatDate(value: any): string {
        return moment(value).format('YYYY-MM-DD hh:mm A Z');
    }


    goHome() {
        this.router.navigate(['./engagements'], { relativeTo: this.activeRoute.parent });
    }
}
