import { Component, Input, OnInit } from '@angular/core';
import Utilities from '../../../services/utilities';
import { EngagementSectionDto, EngagementQuestionDto } from '../../../../model/modelDto';

@Component({
    selector: 'engagement-tree-node-viewer',
    inputs: ['node', 'i', 'context'],
    templateUrl: './engagement-tree-node-viewer.component.html'
})
export class EngagementViewerTreeNode implements OnInit {
    @Input() node: EngagementSectionDto;
    @Input() i: number;
    @Input() context: any;

    constructor() {
    }

    ngOnInit() {
    }

    get currentNodes() {
        return this.node.childSections ? this.node.childSections.filter(m => m.isActivated).sort(Utilities.sortByDisplayOrder) : [];
    }

    get questions() {
        return this.node.questions ? this.node.questions.sort(Utilities.sortByDisplayOrder) : [];
    }

    getDisplayText(question: EngagementQuestionDto) {
        return question.name || question.description.substr(0, 40) + '...';
    }

    toggleSection() {
        if (this.node) {
            this.node.isCollapsed = !this.node.isCollapsed;
        }
    }


    setActiveSection(node: EngagementSectionDto) {
        this.context.setActiveSection(node);
    }

    setActiveQuestion(question: EngagementQuestionDto) {
        question.isSelected = true;
        this.context.setActiveQuestion(question);
    }
}
