import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../components/shared/confirmation-dialog/confirmation-dialog.component';
import { ServerSideConfigDataService } from '../services/server-side-config-data/server-side-config-data.service';
import { QueuedSaveFailedError } from 'breeze-client';
import * as moment from 'moment';
import { environment } from '../../environments/environment';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    private serverSideConfig: ServerSideConfigDataService;

    constructor(private injector: Injector) {
        this.serverSideConfig = this.injector.get(ServerSideConfigDataService);
    }

    private previousErrors: TrackedError[] = [];

    handleError(error: any): void {
        const dialog: MatDialog = this.injector.get(MatDialog);
        let showDialog = true;

        let message = "";
        let httpResponse = null;

        if (error instanceof HttpErrorResponse) {
            // Errors from HTTP Request
            const http = this.parseHttpError(error);
            message = http.message;
            if (http) {
                httpResponse = http.httpResponse;
            }
        } else if (error instanceof QueuedSaveFailedError) {
            // Errors from breeze
            message = this.parseQueuedSaveFailedError(error);
        } else {
            // Assuming these will be typical javascript errors
            // User most likely does not need to be notified
            // of these so we are not showing the dialog, but
            // will still pass the error on to the console as
            // normal
            const e: Error = (<Error>error);
            showDialog = false;
            // showDialog = !this.errorAlreadyOccurred(e);
            // message = e.message;
            console.error(e.message, e.name, e.stack);
        }

        if (showDialog) {
            dialog.open(ConfirmationDialogComponent, {
                disableClose: true,
                data: {
                    title: 'An Error Has Occurred',
                    content: message,
                    onlyShowOk: true,
                    debug: httpResponse
                }
            });
        }
    }

    private parseHttpError(response: HttpErrorResponse): { message: string; httpResponse?: HttpErrorResponse; } {
        if (this.serverSideConfig.configData
            && ['Local', 'Development'].indexOf(this.serverSideConfig.configData.environment) > -1
            && typeof response.error == 'string'
            && response.error.indexOf('<!DOCTYPE html>') > -1
        ) {
            const regex = new RegExp(/<div class="titleerror">(.*?)<\/div>/);
            const match = response.error.match(regex);

            return {
                message: match && match.length ? match[1] : "An Exception has occurred",
                httpResponse: response
            };
        }

        const properMessage = !environment.production && !response.error
            ? "Check console log for details"
            : response.error;

        return { message: properMessage };
    }

    private parseQueuedSaveFailedError(error: QueuedSaveFailedError): string {
        return error.innerError.entityErrors.map(e => e.errorMessage).join('\n');
    }

    // The following two methods can be used to avoid handling repeated errors.
    // If a binding is wrong or something is misconfigured, Angular like to
    // throw an infinite number of errors in the console. The code below is
    // really just meant to mitigate that incase you want to show a dialog with
    // said error(s)
    private removeOldErrors() {
        if (this.previousErrors.length) {
            const oldestErrorTimestamp = moment().add(-2, 'seconds');
            this.previousErrors = this.previousErrors.filter(e => e.timestamp.isSameOrAfter(oldestErrorTimestamp));
        }
    }

    private errorAlreadyOccurred(error: Error) {
        this.removeOldErrors();

        const prevError = this.previousErrors.filter(e => e.error.message == error.message)[0];
        const i = this.previousErrors.indexOf(prevError);

        if (i > -1) {
            this.previousErrors[i].timestamp = moment();
            return true;
        } else {
            this.previousErrors.push({
                error: error,
                timestamp: moment()
            });
            return false;
        }
    }
}

interface TrackedError {
    error: Error;
    timestamp: moment.Moment;
}
