<div class="modal-header">
    <h5 class="modal-title">Are you still there?</h5>
</div>
<div class="modal-body">
    <p>Your session will expire in {{timeLeft}}. Please select an option:</p>
</div>
<div class="modal-footer">
    <button class="btn btn-secondary" (click)="dialogRef.close(false);">I'm done for now</button>
    <button class="btn btn-danger" (click)="dialogRef.close(true);">I'm still working</button>
</div>
