<div class="header">
    <h3>Engagement Review Questionnaires</h3>
</div>
<div class="row">
    <div class="col tab-menu">
        <profile-nav-menu [currentTab]="'inspection-profiles'"></profile-nav-menu>
    </div>

    <div class="col">
        <h4>Questionnaires</h4>
        <div class="my-3">
            <a [routerLink]="['../inspection-profile']" [queryParams]="{ id: 'new' }" class="btn btn-primary text-nowrap">
                <i class="fa fa-plus"></i>
                Create
            </a>
            <button type="button"
                class="btn btn-secondary ml-2"
                (click)="uploader.click()">
                <i class="fa fa-file-excel-o" aria-hidden="true"></i>
                Import
            </button>
            <input
                hidden
                type="file"
                #uploader
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                (change)="import($event.target.files)"
            />
        </div>

        <grid-pager [actionName]="'../inspection-profiles'" [page]="page" [limit]="limit" [pageCount]="pageCount" [limits]="pageLimitOptions"></grid-pager>

        <div class="table-responsive">
            <table id="inspection-profiles-grid" class="table table-striped table-align-middle my-2">
                <thead>
                    <tr class="headers-row">
                        <th style="width: 7rem;"></th>
                        <th style="width: 35%;">Name</th>
                        <th>Status</th>
                        <th style="width: 14rem;">Business Line</th>
                        <th style="width: 6rem;">Sections</th>
                        <th style="width: 6rem;">Questions</th>
                    </tr>
                    <tr class="filters-row">
                        <td>
                            <button type="button" class="btn btn-sm btn-block btn-secondary text-nowrap" (click)="clearFilters()">
                                <i class="fa fa-times"></i>
                                Clear
                            </button>
                        </td>
                        <td><input type="text" class="form-control form-control-sm" placeholder="Filter by..." [(ngModel)]="nameFilter" (change)="updateFilters('name', nameFilter)" /></td>
                        <td><input type="text" class="form-control form-control-sm" placeholder="Filter by..." [(ngModel)]="statusFilter" (change)="updateFilters('status', statusFilter)" /></td>
                        <td><input type="text" class="form-control form-control-sm" placeholder="Filter by..." [(ngModel)]="businessLineFilter" (change)="updateFilters('businessLine', businessLineFilter)" /></td>
                        <td></td>
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="activeInspectionProfiles.length == 0">
                        <td colspan="7">
                            <span class="d-block p-2 text-center">
                                <i class="fa fa-ban text-danger"></i>
                                No data to display&hellip;
                            </span>
                        </td>
                    </tr>
                    <tr *ngFor="let profile of activeInspectionProfiles">
                        <td>
                            <div class="btn-group btn-group-sm" *ngIf="profile.inspectionProfile.id > 0">
                                <a class="btn btn-primary mr-1"
                                   [routerLink]="['../inspection-profile']" [queryParams]="{ id: profile.inspectionProfile.id }"
                                   ngbTooltip="Edit profile"
                                   [placement]="['top', 'auto']"
                                   container="body">
                                    <i class="fa fa-pencil"></i>
                                    <span class="sr-only">Edit</span>
                                </a>
                                <button type="button" class="btn btn-secondary mr-1"
                                        (click)="cloneInspectionProfile(profile)"
                                        ngbTooltip="Clone profile"
                                        [placement]="['top', 'auto']"
                                        container="body">
                                    <i class="fa fa-clone"></i>
                                    <span class="sr-only">Clone</span>
                                </button>
                                <button type="button"
                                        class="btn btn-secondary"
                                        *ngIf="profile.inspectionProfile.engagements == null || profile.inspectionProfile.engagements.length == 0"
                                        (click)="destroyInspectionProfile(profile)"
                                        ngbTooltip="Remove profile"
                                        [placement]="['top', 'auto']"
                                        container="body">
                                    <i class="fa fa-trash"></i>
                                    <span class="sr-only">Remove</span>
                                </button>
                            </div>
                        </td>
                        <td>{{profile.inspectionProfile.name}}</td>
                        <td>{{getStatusName(profile.inspectionProfile.inspectionProfileStatus)}}</td>
                        <td>{{profile.inspectionProfile.businessLineName()}}</td>
                        <td class="text-right">{{profile.sectionsCount}}</td>
                        <td class="text-right">{{profile.questionsCount}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <grid-pager [actionName]="'../inspection-profiles'" [page]="page" [limit]="limit" [pageCount]="pageCount" [limits]="pageLimitOptions"></grid-pager>
    </div>
</div>
