import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ApplicationInsightsService } from '../../services/application-insights/application-insights.service';
import { UnitOfWorkService } from '../../services/unit-of-work/unit-of-work.service';
import { IsBusyService } from '../../services/is-busy/is-busy.service';
import { ExportEngagementQuestionsDto } from '../../../model/modelDto';
import { GlobalErrorHandler } from '../../services/global-error-handler';

@Component({
    selector: 'app-export-questions-and-responses',
    templateUrl: './export-questions-and-responses.component.html',
})
export class ExportQuestionsAndResponsesComponent implements OnInit {

    inspectionYears: number[] = [];
    inspectionYear: number = new Date().getFullYear();

    constructor(
        private uow: UnitOfWorkService,
        private appInsights: ApplicationInsightsService,
        public isBusy: IsBusyService,
        private errorHandler: GlobalErrorHandler
    ) {
        appInsights.logPageView();
    }

    ngOnInit() {
        this.getInspectionYears();
    }

    getInspectionYears() {
        return this.uow.getInspectionYears()
            .then(result => {
                this.inspectionYears = result;
            });
    }

    createExportFile(questions: ExportEngagementQuestionsDto[]) {

        var fileName = "Questionnaire " + this.inspectionYear + ".csv";

        var csv = "sep=| \r\n\"Database\"|\"EngagementCode\"|\"Client\"|\"TeamCaptainId\"|\"TeamCaptain\"|" +
            "\"Office\"|\"Region\"|\"ClientFiscalYearEnd\"|\"InspectionYear\"|\"Project\"|\"Section\"|\"Name\"|" +
            "\"Question\"|\"Answer\"|\"AnsweredBy\"|\"Comment\"|\"QuestionId\"|\"Tag\"\r\n";

        questions.forEach(q => {
            csv += this.csvField('NationalAssuranceInspections');
            csv += this.csvField(q.engagementCode);
            csv += this.csvField(q.clientName);
            csv += this.csvField(q.teamCaptainId);
            csv += this.csvField(q.teamCaptainName);
            csv += this.csvField(q.office);
            csv += this.csvField(q.region);
            csv += this.csvField(q.clientFiscalYearEnd ? moment(q.clientFiscalYearEnd).format('YYYY-MM-DD') : null);
            csv += this.csvField(q.inspectionYear);
            csv += this.csvField(q.project);
            csv += this.csvField(q.section);
            csv += this.csvField(q.name);
            csv += this.csvField(q.question);
            csv += this.csvField(q.answer);
            csv += this.csvField(q.answeredBy);
            csv += this.csvField(q.comment);
            csv += this.csvField(q.id);
            csv += this.csvField(q.tag, true);         
        });

        var link = document.createElement("a");

        if ((<any>link).download !== undefined) { //Non IE
            var url = URL.createObjectURL(new Blob([csv], { type: 'text/csv;charset=utf-8' }));
            link.setAttribute("href", url);
            link.setAttribute("download", fileName);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

        return csv;
    }

    export() {

        this.isBusy.set('admin:loadData', true, 'Loading data');
        this.uow.getExportEngagementQuestionsByYear(this.inspectionYear)
            .then(questions => {
                return this.createExportFile(questions);
            })
            .catch(reason => {
                this.errorHandler.handleError(reason);
            })
            .finally(() => {
                this.isBusy.set('admin:loadData', false);
            });
    }

    csvField(val: string, endLine?: boolean) {
        if (!val && endLine) {
            return "\"NULL\"\r\n";
        }

        if (!val) {
            return "\"NULL\"|";
        }

        return '"' + val + '"' + (endLine ? "\r\n" : "|");
    }
}
