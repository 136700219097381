<div class="header">
    <h3>Engagement Review Questionnaires</h3>
</div>
<div class="row">
    <div class="col tab-menu">
        <profile-nav-menu [currentTab]="'profile-questions'"></profile-nav-menu>
    </div>

    <div class="col">
        <h4>Questions</h4>
        <div class="my-3">
            <a [routerLink]="['../profile-question']" [queryParams]="{ id: 'new' }" class="btn btn-primary text-nowrap">
                <i class="fa fa-plus"></i>
                Create
            </a>
        </div>

        <grid-pager [actionName]="'../profile-questions'" [page]="page" [limit]="limit" [pageCount]="pageCount" [limits]="pageLimitOptions"></grid-pager>

        <div class="table-responsive">
            <table id="questions-grid" class="table table-striped table-align-middle my-2">
                <thead>
                    <tr class="headers-row">
                        <th style="width: 4rem;"></th>
                        <th style="width: 25%;">Name</th>
                        <th style="width: 25%;">Questionnaire</th>
                        <th style="width: 25%;">Section</th>
                        <th>Tags</th>
                    </tr>
                    <tr class="filters-row">
                        <td>
                            <button type="button" class="btn btn-sm btn-block btn-secondary text-nowrap" (click)="clearFilters()">
                                <i class="fa fa-times"></i>
                                Clear
                            </button>
                        </td>
                        <td><input type="text" class="form-control form-control-sm" placeholder="Filter by..." [(ngModel)]="nameFilter" (change)="updateFilters('name', nameFilter)" /></td>
                        <td><input type="text" class="form-control form-control-sm" placeholder="Filter by..." [(ngModel)]="profileFilter" (change)="updateFilters('profileName', profileFilter)" /></td>
                        <td><input type="text" class="form-control form-control-sm" placeholder="Filter by..." [(ngModel)]="sectionFilter" (change)="updateFilters('sectionName', sectionFilter)" /></td>
                        <td><input type="text" class="form-control form-control-sm" placeholder="Filter by..." [(ngModel)]="tagsFilter" (change)="updateFilters('tags', tagsFilter)" /></td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="activeProfileQuestions.length == 0">
                        <td colspan="5">
                            <span class="d-block p-2 text-center">
                                <i class="fa fa-ban text-danger"></i>
                                No data to display&hellip;
                            </span>
                        </td>
                    </tr>
                    <tr *ngFor="let question of activeProfileQuestions">
                        <td>
                            <div class="btn-group btn-group-sm" *ngIf="question.id > 0">
                                <a class="btn btn-primary mr-1"
                                   [routerLink]="['../profile-question']" [queryParams]="{ id: question.id }"
                                   ngbTooltip="Edit question"
                                   [placement]="['top', 'auto']"
                                   container="body">
                                    <i class="fa fa-pencil"></i>
                                    <span class="sr-only">Edit</span>
                                </a>
                                <button type="button"
                                        class="btn btn-secondary"
                                        *ngIf="question.engagementQuestions == null || question.engagementQuestions.length == 0"
                                        (click)="destroyProfileQuestion(question)"
                                        ngbTooltip="Remove question"
                                        [placement]="['top', 'auto']"
                                        container="body">
                                    <i class="fa fa-trash"></i>
                                    <span class="sr-only">Remove</span>
                                </button>
                            </div>
                        </td>
                        <td>{{getDisplayText(question)}}</td>
                        <td *ngIf="question.profileSection">
                            <a [routerLink]="['../inspection-profile']" [queryParams]="{ id: question.profileSection.inspectionProfileId }">{{question.profileName()}}</a>
                        </td>
                        <td *ngIf="!question.profileSection">
                            ---
                        </td>
                        <td>{{question.sectionName()}}</td>
                        <td>
                            <span class="bdo-tag bdo-tag-dark" *ngFor="let qt of question.questionTags">
                                {{qt.tag.name}}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <grid-pager [actionName]="'../profile-questions'" [page]="page" [limit]="limit" [pageCount]="pageCount" [limits]="pageLimitOptions"></grid-pager>
    </div>
</div>
