<div class="error-section">
    <div class="error-title">
        <h1>Oh no!</h1>
        <div class="emoji">
            <h1>(∩╹□╹∩)</h1>
        </div>
    </div>
    <div class="error-message">
        <h2>It looks like you don't have access to this {{accessType}}. Please contact the site administrator to request access.</h2>
    </div>
</div>
