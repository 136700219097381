import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AppInsights } from 'applicationinsights-js';
import { Profile } from '../../../typings';
import { ServerSideConfigDataService } from '../server-side-config-data/server-side-config-data.service';

@Injectable({
    providedIn: 'root'
})
export class ApplicationInsightsService {
    private env: { [name: string]: string } = {};

    constructor(private serverSide: ServerSideConfigDataService, private msalService: MsalService) {
        this.serverSide.configDataLoaded$.subscribe(loaded => {
            if (loaded) {
                const config: Microsoft.ApplicationInsights.IConfig = {
                    instrumentationKey: this.serverSide.configData.appInsightsKey
                };

                const envProps = this.serverSide.configData.appInsightsEnv.split(';');
                envProps.forEach(p => {
                    const e: string[] = p.split(':');
                    this.env[e[0]] = e[1];
                });

                if (!AppInsights.config) {
                    AppInsights.downloadAndSetup(config);
                }

                const employeeId = (this.msalService.instance.getAllAccounts()[0] as Profile).idTokenClaims.employeeID;
                if (employeeId) {
                    AppInsights.setAuthenticatedUserContext(employeeId);
                }
            }
        });

    }

    private getProps(properties?: { [name: string]: string }) {
        const props = this.env;
        if (properties) {
            for (const p in properties) {
                if (properties.hasOwnProperty(p)) {
                    props[p] = properties[p];
                }
            }
        }

        return props;
    }

    logPageView(name?: string, url?: string, properties?: { [name: string]: string }, measurements?: { [name: string]: number }, duration?: number) {
        AppInsights.trackPageView(name, url, this.getProps(properties), measurements, duration);
    }

    logEvent(name: string, properties?: { [name: string]: string }, measurements?: { [name: string]: number }) {
        AppInsights.trackEvent(name, this.getProps(properties), measurements);
    }

    logError(exception: Error, handledAt?: string, properties?: { [name: string]: string }, measurements?: { [name: string]: number }, severityLevel?: AI.SeverityLevel) {
        AppInsights.trackException(exception, handledAt, this.getProps(properties), measurements, severityLevel);
    }

    logTrace(message: string, properties?: { [name: string]: string }, severityLevel?: AI.SeverityLevel) {
        AppInsights.trackTrace(message, this.getProps(properties), severityLevel);
    }
}
