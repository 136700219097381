import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Predicate, EntityQuery, FetchStrategy } from "breeze-client";
import { EntityManagerProviderService } from "../entity-manager-provider/entity-manager-provider.service";
import * as model from '../../../model/model';
import * as modelDto from '../../../model/modelDto';

@Injectable({
    providedIn: 'root'
})
export class BridgeService {
    constructor(private _http: HttpClient, private _entityManagerProvider: EntityManagerProviderService) { }

    private isEmpty(obj: Object): boolean {
        for(const prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false;
            }
        }

        return true;
    }

    private httpPost<T>(resourceName: string, params: Object): Promise<Object> {
        const url = this._entityManagerProvider.manager().serviceName + resourceName;
        return this._http.post<T>(url, params).toPromise();
    }

    private httpGet<T>(resourceName: string, params: Object, sort?: string, filter?: Predicate) {
        let query = EntityQuery
            .from(resourceName);

        if (!this.isEmpty(params)) {
            query = query.withParameters(params);
        }

        return this.executeQuery<T>(query, sort, filter);
    }

    private postWithParams<T>(resourceName: string, params: Object, sort?: string, filter?: Predicate) {
        const postParams = { $method: 'POST', $encoding: 'JSON', $data: params };
        return this.getWithParams<T>(resourceName, postParams, sort, filter);
    }

    private getWithParams<T>(resourceName: string, params: Object, sort?: string, filter?: Predicate) {
        const query = EntityQuery
            .from(resourceName)
            .withParameters(params);

        return this.executeQuery<T>(query, sort, filter);
    }

    private executeQuery<T>(query: EntityQuery, sort?: string, filter?: Predicate) {
        if (filter) {
            query = query.where(filter);
        }

        if (sort) {
            query = query.orderBy(sort);
        }

        return this._entityManagerProvider.manager()
            .executeQuery(query.using(FetchStrategy.FromServer))
            .then((data) => {
                return <T[]><any>data.results;
            });
    }
    
    lookups( sort?: string, filter?: Predicate) {
        const params = {  };
        return this.httpGet<any>('lookups', params, sort, filter);
    }

    getEmployees(searchValue: string, id: string, showOnlyAdmins: boolean, showOnlyCaptains: boolean,page: number,limit: number, sort?: string, filter?: Predicate) {
        const params = { searchValue: searchValue, id: id, showOnlyAdmins: showOnlyAdmins, showOnlyCaptains: showOnlyCaptains, page: page, limit: limit };
        return this.httpGet<model.Employee>('getEmployees', params, sort, filter);
    }

    getActiveTags(includeAll: boolean, sort?: string, filter?: Predicate) {
        const params = { includeAll: includeAll };
        return this.httpGet<model.Tag>('getActiveTags', params, sort, filter);
    }

    getTags(includeAll: boolean, sort?: string, filter?: Predicate) {
        const params = { includeAll: includeAll };
        return this.httpGet<model.Tag>('getTags', params, sort, filter);
    }

    getInspectionTeamRoles(includeAll: boolean, sort?: string, filter?: Predicate) {
        const params = { includeAll: includeAll };
        return this.httpGet<model.InspectionTeamRole>('getInspectionTeamRoles', params, sort, filter);
    }

    getActiveIndustries(includeAll: boolean, sort?: string, filter?: Predicate) {
        const params = { includeAll: includeAll };
        return this.httpGet<model.Industry>('getActiveIndustries', params, sort, filter);
    }

    getIndustries(includeAll: boolean, sort?: string, filter?: Predicate) {
        const params = { includeAll: includeAll };
        return this.httpGet<model.Industry>('getIndustries', params, sort, filter);
    }

    getOffices( sort?: string, filter?: Predicate) {
        const params = {  };
        return this.httpGet<model.Office>('getOffices', params, sort, filter);
    }

    getRegions( sort?: string, filter?: Predicate) {
        const params = {  };
        return this.httpGet<string>('getRegions', params, sort, filter);
    }

    getBusinessLines(includeAll: boolean, sort?: string, filter?: Predicate) {
        const params = { includeAll: includeAll };
        return this.httpGet<model.BusinessLine>('getBusinessLines', params, sort, filter);
    }

    getStandardQuestionOptions(includeAll: boolean, sort?: string, filter?: Predicate) {
        const params = { includeAll: includeAll };
        return this.httpGet<model.StandardQuestionOption>('getStandardQuestionOptions', params, sort, filter);
    }

    getClients(id: string,q: string,page: number,limit: number, sort?: string, filter?: Predicate) {
        const params = { id: id, q: q, page: page, limit: limit };
        return this.httpGet<model.Client>('getClients', params, sort, filter);
    }

    getClientsCount(id: string,q: string, sort?: string, filter?: Predicate) {
        const params = { id: id, q: q };
        return this.httpGet<number>('getClientsCount', params, sort, filter);
    }

    getProjects(id: string,name: string,clientId: string,clientName: string,page: number,limit: number, sort?: string, filter?: Predicate) {
        const params = { id: id, name: name, clientId: clientId, clientName: clientName, page: page, limit: limit };
        return this.httpGet<model.Project>('getProjects', params, sort, filter);
    }

    getProjectsCount(id: string,name: string,clientId: string,clientName: string, sort?: string, filter?: Predicate) {
        const params = { id: id, name: name, clientId: clientId, clientName: clientName };
        return this.httpGet<number>('getProjectsCount', params, sort, filter);
    }

    getAptCaws(id: string,name: string,clientId: string,clientName: string,page: number,limit: number, sort?: string, filter?: Predicate) {
        const params = { id: id, name: name, clientId: clientId, clientName: clientName, page: page, limit: limit };
        return this.httpGet<model.AptCaw>('getAptCaws', params, sort, filter);
    }

    getAptCawsCount(id: string,name: string,clientId: string,clientName: string, sort?: string, filter?: Predicate) {
        const params = { id: id, name: name, clientId: clientId, clientName: clientName };
        return this.httpGet<number>('getAptCawsCount', params, sort, filter);
    }

    getInspectionProfile(id: number, sort?: string, filter?: Predicate) {
        const params = { id: id };
        return this.httpGet<model.InspectionProfile>('getInspectionProfile', params, sort, filter);
    }

    getInspectionProfilesSummary(page: number,limit: number,gridFilters: string, sort?: string, filter?: Predicate) {
        const params = { page: page, limit: limit, gridFilters: gridFilters };
        return this.httpGet<modelDto.AllInspectionsSummaryDto>('getInspectionProfilesSummary', params, sort, filter);
    }

    getInspectionProfilesPageCount(limit: number,gridFilters: string, sort?: string, filter?: Predicate) {
        const params = { limit: limit, gridFilters: gridFilters };
        return this.httpGet<number>('getInspectionProfilesPageCount', params, sort, filter);
    }

    getInspectionProfilesForLookup( sort?: string, filter?: Predicate) {
        const params = {  };
        return this.httpGet<modelDto.InspectionProfileDto>('getInspectionProfilesForLookup', params, sort, filter);
    }

    getInspectionProfilesForEngagement( sort?: string, filter?: Predicate) {
        const params = {  };
        return this.httpGet<model.InspectionProfile>('getInspectionProfilesForEngagement', params, sort, filter);
    }

    getInspectionProfileForEngagementById(id: number, sort?: string, filter?: Predicate) {
        const params = { id: id };
        return this.httpGet<model.InspectionProfile>('getInspectionProfileForEngagementById', params, sort, filter);
    }

    getProfileSections(page: number,limit: number,gridFilters: string, sort?: string, filter?: Predicate) {
        const params = { page: page, limit: limit, gridFilters: gridFilters };
        return this.httpGet<model.ProfileSection>('getProfileSections', params, sort, filter);
    }

    getProfileSectionsPageCount(limit: number,gridFilters: string, sort?: string, filter?: Predicate) {
        const params = { limit: limit, gridFilters: gridFilters };
        return this.httpGet<number>('getProfileSectionsPageCount', params, sort, filter);
    }

    getProfileSectionsForLookup( sort?: string, filter?: Predicate) {
        const params = {  };
        return this.httpGet<model.ProfileSection>('getProfileSectionsForLookup', params, sort, filter);
    }

    getSectionsForInspectionProfile(profileId: number, sort?: string, filter?: Predicate) {
        const params = { profileId: profileId };
        return this.httpGet<model.ProfileSection>('getSectionsForInspectionProfile', params, sort, filter);
    }

    getProfileSectionsForSection(id: number, sort?: string, filter?: Predicate) {
        const params = { id: id };
        return this.httpGet<model.ProfileSection>('getProfileSectionsForSection', params, sort, filter);
    }

    getQuestions(page: number,limit: number,gridFilters: string, sort?: string, filter?: Predicate) {
        const params = { page: page, limit: limit, gridFilters: gridFilters };
        return this.httpGet<model.Question>('getQuestions', params, sort, filter);
    }

    getQuestionsPageCount(limit: number,gridFilters: string, sort?: string, filter?: Predicate) {
        const params = { limit: limit, gridFilters: gridFilters };
        return this.httpGet<number>('getQuestionsPageCount', params, sort, filter);
    }

    getQuestion(id: number, sort?: string, filter?: Predicate) {
        const params = { id: id };
        return this.httpGet<model.Question>('getQuestion', params, sort, filter);
    }

    getUnassignedQuestions( sort?: string, filter?: Predicate) {
        const params = {  };
        return this.httpGet<model.Question>('getUnassignedQuestions', params, sort, filter);
    }

    getQuestionOptions( sort?: string, filter?: Predicate) {
        const params = {  };
        return this.httpGet<model.QuestionOption>('getQuestionOptions', params, sort, filter);
    }

    getSelectedOptionActivatingTags(id: number,selectedOptionId: number, sort?: string, filter?: Predicate) {
        const params = { id: id, selectedOptionId: selectedOptionId };
        return this.httpGet<model.EngagementQuestionActivatingTag>('getSelectedOptionActivatingTags', params, sort, filter);
    }

    getEngagement(id: number, sort?: string, filter?: Predicate) {
        const params = { id: id };
        return this.httpGet<model.Engagement>('getEngagement', params, sort, filter);
    }

    getAllEngagementsSummary(page: number,limit: number,gridFilters: string, sort?: string, filter?: Predicate) {
        const params = { page: page, limit: limit, gridFilters: gridFilters };
        return this.httpGet<modelDto.AllEngagementsSummaryDto>('getAllEngagementsSummary', params, sort, filter);
    }

    getEngagements(page: number,limit: number,gridFilters: string, sort?: string, filter?: Predicate) {
        const params = { page: page, limit: limit, gridFilters: gridFilters };
        return this.httpGet<model.Engagement>('getEngagements', params, sort, filter);
    }

    getEngagementsPageCount(limit: number,gridFilters: string, sort?: string, filter?: Predicate) {
        const params = { limit: limit, gridFilters: gridFilters };
        return this.httpGet<number>('getEngagementsPageCount', params, sort, filter);
    }

    publishEngagement(id: number, sort?: string, filter?: Predicate) {
        const params = { id: id };
        return this.httpGet<model.Engagement>('publishEngagement', params, sort, filter);
    }

    getMyTeamsEngagementById(id: number, sort?: string, filter?: Predicate) {
        const params = { id: id };
        return this.httpGet<modelDto.EngagementDto>('getMyTeamsEngagementById', params, sort, filter);
    }

    getEngagementsForMyTeams(page: number,limit: number,gridFilters: string, sort?: string, filter?: Predicate) {
        const params = { page: page, limit: limit, gridFilters: gridFilters };
        return this.httpGet<modelDto.EngagementSummaryDto>('getEngagementsForMyTeams', params, sort, filter);
    }

    getEngagementsCountForMyTeams(gridFilters: string, sort?: string, filter?: Predicate) {
        const params = { gridFilters: gridFilters };
        return this.httpGet<number>('getEngagementsCountForMyTeams', params, sort, filter);
    }

    getEngagementSectionsForMyTeams(page: number,limit: number,gridFilters: string, sort?: string, filter?: Predicate) {
        const params = { page: page, limit: limit, gridFilters: gridFilters };
        return this.httpGet<modelDto.EngagementSectionSummaryDto>('getEngagementSectionsForMyTeams', params, sort, filter);
    }

    getEngagementSectionsCountForMyTeams(gridFilters: string, sort?: string, filter?: Predicate) {
        const params = { gridFilters: gridFilters };
        return this.httpGet<number>('getEngagementSectionsCountForMyTeams', params, sort, filter);
    }

    getEngagementQuestionsForMyTeams(page: number,limit: number,gridFilters: string, sort?: string, filter?: Predicate) {
        const params = { page: page, limit: limit, gridFilters: gridFilters };
        return this.httpGet<modelDto.EngagementQuestionSummaryDto>('getEngagementQuestionsForMyTeams', params, sort, filter);
    }

    getEngagementQuestionsCountForMyTeams(gridFilters: string, sort?: string, filter?: Predicate) {
        const params = { gridFilters: gridFilters };
        return this.httpGet<number>('getEngagementQuestionsCountForMyTeams', params, sort, filter);
    }

    getMyInspections(page: number,limit: number,gridFilters: string, sort?: string, filter?: Predicate) {
        const params = { page: page, limit: limit, gridFilters: gridFilters };
        return this.httpGet<modelDto.MyInspectionsSummaryDto>('getMyInspections', params, sort, filter);
    }

    getMyInspectionsCount(gridFilters: string, sort?: string, filter?: Predicate) {
        const params = { gridFilters: gridFilters };
        return this.httpGet<number>('getMyInspectionsCount', params, sort, filter);
    }

    getEngagementQuestionById(id: number, sort?: string, filter?: Predicate) {
        const params = { id: id };
        return this.httpGet<model.EngagementQuestion>('getEngagementQuestionById', params, sort, filter);
    }

    getExportEngagementQuestionsByYear(inspectionYear: number, sort?: string, filter?: Predicate) {
        const params = { inspectionYear: inspectionYear };
        return this.httpGet<modelDto.ExportEngagementQuestionsDto>('getExportEngagementQuestionsByYear', params, sort, filter);
    }

    getMyEngagementNodeViewerById(id: number, sort?: string, filter?: Predicate) {
        const params = { id: id };
        return this.httpGet<model.Engagement>('getMyEngagementNodeViewerById', params, sort, filter);
    }

    getMyEngagementById(id: number, sort?: string, filter?: Predicate) {
        const params = { id: id };
        return this.httpGet<modelDto.MyInspectionFormDto>('getMyEngagementById', params, sort, filter);
    }

    updateQuestionResponse(id: number,isComplete: boolean,completedDate: Date,optionId: number,comment: string,replaceExisting: boolean, sort?: string, filter?: Predicate) {
        const params = { id: id, isComplete: isComplete, completedDate: completedDate, optionId: optionId, comment: comment, replaceExisting: replaceExisting };
        return this.httpGet<model.EngagementQuestion>('updateQuestionResponse', params, sort, filter);
    }

    checkQuestionForExistingResponse(id: number,optionId: number, sort?: string, filter?: Predicate) {
        const params = { id: id, optionId: optionId };
        return this.httpGet<boolean>('checkQuestionForExistingResponse', params, sort, filter);
    }

    saveQuestionComment(id: number,text: string, sort?: string, filter?: Predicate) {
        const params = { id: id, text: text };
        return this.httpGet<modelDto.EngagementQuestionCommentDto>('saveQuestionComment', params, sort, filter);
    }

    propagateProfileSectionChanges(id: number, sort?: string, filter?: Predicate) {
        const params = { id: id };
        return this.httpGet<modelDto.ProfileSectionDto>('propagateProfileSectionChanges', params, sort, filter);
    }

    propagateQuestionChanges(id: number, sort?: string, filter?: Predicate) {
        const params = { id: id };
        return this.httpGet<modelDto.QuestionDto>('propagateQuestionChanges', params, sort, filter);
    }

    getMaxProfileSectionDisplayOrder(id: number,parentSectionId: number, sort?: string, filter?: Predicate) {
        const params = { id: id, parentSectionId: parentSectionId };
        return this.httpGet<number>('getMaxProfileSectionDisplayOrder', params, sort, filter);
    }

    getMaxQuestionDisplayOrder(id: number, sort?: string, filter?: Predicate) {
        const params = { id: id };
        return this.httpGet<number>('getMaxQuestionDisplayOrder', params, sort, filter);
    }

    updateActivatedEngagementQuestions(id: number, sort?: string, filter?: Predicate) {
        const params = { id: id };
        return this.httpGet<boolean>('updateActivatedEngagementQuestions', params, sort, filter);
    }

    cleanupEngagements( sort?: string, filter?: Predicate) {
        const params = {  };
        return this.httpGet<boolean>('cleanupEngagements', params, sort, filter);
    }

    testApplicationException( sort?: string, filter?: Predicate) {
        const params = {  };
        return this.httpGet<boolean>('testApplicationException', params, sort, filter);
    }

    testException( sort?: string, filter?: Predicate) {
        const params = {  };
        return this.httpGet<boolean>('testException', params, sort, filter);
    }

    cloneInspectionProfile(origProfileId: number,newName: string, sort?: string, filter?: Predicate) {
        const params = { origProfileId: origProfileId, newName: newName };
        return this.httpGet<modelDto.AllInspectionsSummaryDto>('cloneInspectionProfile', params, sort, filter);
    }

    getEmployeeProjectHours(projectId: string, sort?: string, filter?: Predicate) {
        const params = { projectId: projectId };
        return this.httpGet<model.EmployeeProjectHours>('getEmployeeProjectHours', params, sort, filter);
    }

    getTeamCaptains( sort?: string, filter?: Predicate) {
        const params = {  };
        return this.httpGet<model.Employee>('getTeamCaptains', params, sort, filter);
    }

    getInspectionYears( sort?: string, filter?: Predicate) {
        const params = {  };
        return this.httpGet<number>('getInspectionYears', params, sort, filter);
    }

    updateAssignedSections(assignedSections: modelDto.UpdateAssignedSectionsDto, sort?: string, filter?: Predicate) {
        const params = assignedSections;
        return this.httpPost<number>('updateAssignedSections', params);
    }
    
    updateEngagementStatus(engagementStatus: modelDto.UpdateEngagementStatusDto, sort?: string, filter?: Predicate) {
        const params = engagementStatus;
        return this.httpPost<boolean>('updateEngagementStatus', params);
    }
    
    updateActivatedEngagementQuestionsByActivatingTags(updateActivatedEngagementQuestionsByActivatingTagsDto: modelDto.UpdateActivatedEngagementQuestionsByActivatingTagsDto, sort?: string, filter?: Predicate) {
        const params = updateActivatedEngagementQuestionsByActivatingTagsDto;
        return this.httpPost<boolean>('updateActivatedEngagementQuestionsByActivatingTags', params);
    }
    
    getTotalEmployeeProjectHours(totalEmployeeProjectHours: modelDto.TotalEmployeeProjectHoursDto, sort?: string, filter?: Predicate) {
        const params = totalEmployeeProjectHours;
        return this.httpPost<number>('getTotalEmployeeProjectHours', params);
    }
    

    getEngagementReport(engagementId: number) {
        const params = { engagementId: engagementId };
        return this.httpGet<modelDto.EngagementReportDto>('getEngagementReport', params);
    }

    getSpecialtyReviewerTypes(includeAll: boolean, sort?: string, filter?: Predicate) {
        const params = { includeAll: includeAll };
        return this.httpGet<model.SpecialtyReviewerType>('getSpecialtyReviewerTypes', params, sort, filter);
    }

    isReviewerTypeAssigned(reviewerTypeId: number, sort?: string, filter?: Predicate) {
        const params = { reviewerTypeId: reviewerTypeId };
        return this.httpGet<boolean>('isReviewerTypeAssigned', params, sort, filter);
    }

    getInspectionTeamMemberProfileSections(engagementId: number, sort?: string, filter?: Predicate) {
        const params = { engagementId: engagementId };
        return this.httpGet<model.InspectionTeamMemberProfileSection>('getInspectionTeamMemberProfileSections', params, sort, filter);
    }

    getInspectionTeamMemberEngagementSections(engagementId: number, sort?: string, filter?: Predicate) {
        const params = { engagementId: engagementId };
        return this.httpGet<model.InspectionTeamMemberEngagementSection>('getInspectionTeamMemberEngagementSections', params, sort, filter);
    }

    getHighLevelInspectionTeamMemberProfileSections(engagementId: number, sort?: string, filter?: Predicate) {
        const params = { engagementId: engagementId };
        return this.httpGet<model.InspectionTeamMemberProfileSection>('getHighLevelInspectionTeamMemberProfileSections', params, sort, filter);
    }

    getHighLevelInspectionTeamMemberEngagementSections(engagementId: number, sort?: string, filter?: Predicate) {
        const params = { engagementId: engagementId };
        return this.httpGet<model.InspectionTeamMemberEngagementSection>('getHighLevelInspectionTeamMemberEngagementSections', params, sort, filter);
    }

    updateChildInspectionTeamMemberProfileSections(engagementId: number, sort?: string, filter?: Predicate) {
        const params = { engagementId };
        return this.httpGet<boolean>('updateChildInspectionTeamMemberProfileSections', params, sort, filter);
    }
}
