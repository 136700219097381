import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Role } from '../model/role';
import { AptCawsAdminComponent } from './components/admin/apt-caws.component';
import { BusinessLinesAdminComponent } from './components/admin/business-lines.component';
import { ClientsAdminComponent } from './components/admin/clients.component';
import { ImpersonateAdminComponent } from './components/admin/impersonate.component';
import { IndustriesAdminComponent } from './components/admin/industries.component';
import { InspectionTeamRolesAdminComponent } from './components/admin/inspection-team-roles.component';
import { OfficesAdminComponent } from './components/admin/offices.component';
import { ProjectsAdminComponent } from './components/admin/projects.component';
import { StandardQuestionOptionsAdminComponent } from './components/admin/standard-question-options.component';
import { TagsAdminComponent } from './components/admin/tags.component';
import { EngagementsComponent } from './components/engagement-management/data-grids/engagements.component';
import { EngagementAdminComponent } from './components/engagement-management/forms/engagement-editor.component';
import { NoLoginFoundComponent } from './components/error/not-active.component';
import { MyInspectionsComponent } from './components/home/data-grids/home.component';
import { MyInspectionComponent } from './components/home/forms/inspection.component';
import { InspectionProfilesComponent } from './components/profile-management/data-grids/inspection-profiles.component';
import { ProfileQuestionsComponent } from './components/profile-management/data-grids/profile-questions.component';
import { ProfileSectionsComponent } from './components/profile-management/data-grids/profile-sections.component';
import { InspectionProfileAdminComponent } from './components/profile-management/forms/inspection-profile-editor.component';
import { ProfileQuestionAdminComponent } from './components/profile-management/forms/question-editor.component';
import { AccessDeniedComponent } from './components/shared/access-denied/access-denied.component';
import { SignoutComponent } from './components/signout/signout-component';
import { TeamInspectionsComponent } from './components/team-inspections/data-grids/team-inspections.component';
import { TeamCaptainInspectionComponent } from './components/team-inspections/forms/inspection.component';
import { ExportQuestionsAndResponsesComponent } from './components/reports/export-questions-and-responses.component';
import { AppGuard } from './guards/app-guard/app.guard';
import { RoleGuard } from './guards/auth/role-guard';
import { GenericCanDeactivateGuard } from './guards/generic-can-deactivate/generic-can-deactivate.guard';
import { SecurityRolesAdminComponent } from './components/admin/security-roles.component';
import { SpecialtyReviewerTypesAdminComponent } from './components/admin/specialty-reviewer-types.component';

const routes: Routes = [
    {
        path: '',
        canActivate: [AppGuard],
        children: [
            {
                path: 'engagement-management',
                canActivate: [RoleGuard],
                data: { order: 3, name: 'Inspection Code Setup', requiresQcAdmin: true, roles: [Role.nationalQcAdmin, Role.admin] },
                children: [
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: 'engagements'
                    },
                    {
                        path: 'engagements',
                        component: EngagementsComponent
                    },
                    {
                        path: 'edit',
                        component: EngagementAdminComponent,
                        canDeactivate: [GenericCanDeactivateGuard]
                    }
                ]
            },
            {
                path: 'profile-management',
                canActivate: [RoleGuard],
                data: { order: 4, name: 'Engagement Review Questionnaires', requiresAdmin: true, roles: [Role.admin] },
                children: [
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: 'inspection-profiles'
                    },
                    {
                        path: 'inspection-profiles',
                        component: InspectionProfilesComponent
                    },
                    {
                        path: 'inspection-profile',
                        component: InspectionProfileAdminComponent,
                        canDeactivate: [GenericCanDeactivateGuard]
                    },
                    {
                        path: 'profile-sections',
                        component: ProfileSectionsComponent
                    },
                    {
                        path: 'profile-questions',
                        component: ProfileQuestionsComponent
                    },
                    {
                        path: 'profile-question',
                        component: ProfileQuestionAdminComponent,
                        canDeactivate: [GenericCanDeactivateGuard]
                    }
                ]
            },
            {
                path: 'admin',
                canActivate: [RoleGuard],
                data: { order: 5, name: 'Admin', requiresAdmin: true, roles: [Role.admin] },
                children: [
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: 'business-lines'
                    },
                    {
                        path: 'apt-caws',
                        component: AptCawsAdminComponent
                    },
                    {
                        path: 'business-lines',
                        component: BusinessLinesAdminComponent
                    },
                    {
                        path: 'clients',
                        component: ClientsAdminComponent
                    },
                    {
                        path: 'industries',
                        component: IndustriesAdminComponent
                    },
                    {
                        path: 'offices',
                        component: OfficesAdminComponent
                    },
                    {
                        path: 'projects',
                        component: ProjectsAdminComponent
                    },
                    {
                        path: 'standard-question-options',
                        component: StandardQuestionOptionsAdminComponent
                    },
                    {
                        path: 'inspection-team-roles',
                        component: InspectionTeamRolesAdminComponent
                    },
                    {
                        path: 'tags',
                        component: TagsAdminComponent
                    },
                    {
                        path: 'impersonate',
                        component: ImpersonateAdminComponent
                    },
                    {
                        path: 'security-roles',
                        component: SecurityRolesAdminComponent
                    },
                    {
                        path: 'specialty-reviewer-types',
                        component: SpecialtyReviewerTypesAdminComponent
                    }
                ]
            },
            {
                path: 'reports',
                canActivate: [RoleGuard],
                data: { order: 5, name: 'Reports', requiresAdmin: true, roles: [Role.admin] },
                children: [
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: 'export-questions-and-responses'
                    },
                    {
                        path: 'export-questions-and-responses',
                        component: ExportQuestionsAndResponsesComponent
                    }
                ]
            },
            {
                path: 'my-assignments',
                canActivate: [RoleGuard],
                data: { order: 1, name: 'My Assignments', requiresAdmin: false, requiresQcAdmin: false, roles: [Role.basic, Role.nationalQcAdmin, Role.admin] },
                children: [
                    {
                        path: '',
                        pathMatch: 'full',
                        component: MyInspectionsComponent
                    },
                    {
                        path: 'inspection',
                        component: MyInspectionComponent,
                        canDeactivate: [GenericCanDeactivateGuard]
                    }
                ]
            },
            { path: '', redirectTo: 'my-assignments', pathMatch: 'full' }
        ]
    },
    { path: 'access-denied', component: AccessDeniedComponent },
    { path: 'signout', component: SignoutComponent },
    { path: 'no-login-found', component: NoLoginFoundComponent },
    { path: '**', redirectTo: 'my-assignments', pathMatch: 'full' },
]

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
