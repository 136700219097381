import { Component, Input, OnInit } from '@angular/core';
import { SharedDataService } from '../../services/shared-data/shared-data.service';

declare var window: any;
@Component({
    selector: 'admin-nav-menu',
    inputs: ['currentTab'],
    templateUrl: './admin-nav.component.html'
})
export class AdminNavMenu implements OnInit {
    @Input() currentTab: string;

    navItems = [
        { id: 'apt-caws', title: 'APT CAW' },
        { id: 'business-lines', title: 'Business Lines' },
        { id: 'clients', title: 'Clients' },
        { id: 'industries', title: 'Industries' },
        { id: 'inspection-team-roles', title: 'Engagement Team Roles' },
        { id: 'offices', title: 'Offices' },
        { id: 'projects', title: 'Projects' },
        { id: 'standard-question-options', title: 'Standard Question Options' },
        { id: 'tags', title: 'Tags' },
        { id: 'security-roles', title: 'Security Roles' },
        { id: 'specialty-reviewer-types', title: 'Specialty Reviewer Types' }
    ];

    constructor(
        private sharedData: SharedDataService
    ) { }

    ngOnInit() {
        const env = window.location.host;
        //if ((!env.includes('inspections') || !env.includes('prod')) && this.sharedData.me.value.isNationalQcAdmin) {
        //    this.navItems.push({ id: 'impersonate', title: 'Impersonate' });
        //}

    }
}
