export enum InspectionProfileStatus {
    draft = 1,
    active = 2,
    archived = 3,
}

export enum EngagementRating {
    pending = 0,
    veryPoor = 1,
    poor = 2,
    acceptable = 3,
    veryGood = 4,
    excellent = 5,
}

export enum EngagementStatus {
    draft = 0,
    active = 1,
    changesRequired = 2,
    teamCaptainReviewComplete = 3,
    archived = 4,
}

export enum ProgressStatus {
    na = 0,
    notActivated = 1,
    notStarted = 2,
    inProgress = 3,
    completed = 4,
}


