<div class="header">
    <h3>Engagement Review Questionnaires</h3>
</div>
<div class="row">
    <div class="col tab-menu">
        <profile-nav-menu [currentTab]="'inspection-profiles'"></profile-nav-menu>
    </div>

    <div class="col form-wrapper">
        <h4>Manage Questionnaire</h4>
        <form #profileForm="ngForm">
            <p class="my-4 form-text">Please note: <b class="required px-2">*</b> indicates a required field</p>
            <fieldset class="mb-3" [disabled]="isLoading">
                <legend>Metadata</legend>
                <div class="form-group row">
                    <label class="form-label col-sm-3 required" for="profile-name">Name</label>
                    <div class="col">
                        <input type="text"
                               class="form-control"
                               id="profile-name"
                               name="profile-name"
                               [(ngModel)]="inspectionProfile.name"
                               required />
                        <small class="form-text text-danger validation-message">Questionnaire name is required.</small>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="form-label col-sm-3" for="business-line">Business line</label>
                    <div class="col">
                        <select class="custom-select editor-md"
                                id="business-line"
                                name="business-line"
                                [(ngModel)]="inspectionProfile.businessLineId">
                            <option [ngValue]="null">Select one...</option>
                            <option *ngFor="let businessLine of businessLines" [value]="businessLine.id">{{businessLine.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="form-label col-sm-3 required" for="inspection-status">Status</label>
                    <div class="col">
                        <select class="custom-select editor-sm"
                                id="inspection-status"
                                name="inspection-status"
                                [(ngModel)]="inspectionProfile.inspectionProfileStatus"
                                [disabled]="!canChangeStatus"
                                required>
                            <option *ngFor="let status of inspectionProfileStatuses" [value]="status.status">{{status.title}}</option>
                        </select>
                        <span class="ml-2 align-middle" ngbTooltip="Profile must be Active to be available for engagements">
                            <i class="fa fa-lg fa-info-circle text-info"></i>
                        </span>
                    </div>
                </div>
                <div class="mb-2">
                    <button type="submit"
                            class="btn btn-primary mr-2"
                            [disabled]="!profileForm.valid || (activeQuestion && activeQuestion.isEditing)"
                            (click)="saveChanges(true)">
                        <i class="fa fa-save"></i>
                        Save
                    </button>
                    <button type="button"
                            class="btn btn-secondary mr-2"
                            [disabled]="activeQuestion && activeQuestion.isEditing"
                            (click)="goHome()">
                        <i class="fa fa-times"></i>
                        Cancel
                    </button>
                </div>
            </fieldset>
            <hr />
            <div *ngIf="inspectionProfile.id < 1">
                <p>
                    <i class="fa fa-info-circle text-info"></i>
                    Questionnaire sections and questions can be added once Metadata has been saved.
                </p>
            </div>
            <fieldset *ngIf="inspectionProfile.id > 0" [disabled]="isLoading">
                <legend>Sections &amp; Questions</legend>

                <div class="form-group mt-2 mb-4">
                    <button type="button"
                            class="btn btn-primary mr-3"
                            (click)="addRootSection()"
                            [disabled]="(activeQuestion && activeQuestion.isEditing) || reorderQuestions">
                        <i class="fa fa-plus"></i>
                        Add root section
                    </button>

                    <button type="button"
                            class="btn btn-secondary mr-1"
                            (click)="expandAll()"
                            [disabled]="activeQuestion && activeQuestion.isEditing">
                        <i class="fa fa-expand"></i>
                        Expand all
                    </button>
                    <button type="button"
                            class="btn btn-secondary mr-1"
                            (click)="collapseAll()"
                            [disabled]="activeQuestion && activeQuestion.isEditing">
                        <i class="fa fa-compress"></i>
                        Collapse all
                    </button>
                    <button type="button"
                            class="btn btn-secondary mr-1"
                            (click)="openReorderQuestionComp()"
                            [disabled]="(activeQuestion && activeQuestion.isEditing) || reorderQuestions || reorderSections">
                        <i class="fa fa-arrows"></i>
                        Reorder Questions
                    </button>
                    <button type="button"
                            class="btn btn-secondary"
                            (click)="openReorderSectionComp()"
                            [disabled]="(activeQuestion && activeQuestion.isEditing) || reorderSections || reorderQuestions">
                        <i class="fa fa-arrows"></i>
                        Reorder Sections
                    </button>
                </div>

                <div class="tree-container" *ngIf="(!activeQuestion || !activeQuestion.isEditing) && !reorderQuestions && !reorderSections">
                    <ol id="inspection-sections" class="tree-node section-node">
                        <li class="tree-node-item open" *ngFor="let node of rootProfileSections; index as i">
                            <section-tree-node-editor [node]="node" [i]="i" [inspectionProfile]="inspectionProfile" [context]="this"></section-tree-node-editor>
                        </li>
                    </ol>
                </div>
            </fieldset>
        </form>
        <form #questionForm="ngForm">
            <fieldset class="question-editor" style="max-width: 1000px;" *ngIf="inspectionProfile.id > 0 && activeQuestion && activeQuestion.isEditing && !reorderQuestions">
                <h5>Edit question</h5>
                <div class="form-group row">
                    <label class="form-label col-sm-2 required">Section name</label>
                    <div class="col">
                        <input type="text" class="form-control" readonly value="{{activeSection ? activeSection.name : '---'
                        }}" />
                    </div>
                </div>
                <hr />
                <div class="form-group row">
                    <label class="form-label col-sm-2">Use existing</label>
                    <div class="col">
                        <select class="custom-select"
                                name="unassigned-questions"
                                [(ngModel)]="questionToCloneId"
                                (change)="loadQuestionFromExisting()"
                                [disabled]="currentUnassignedQuestions.length == 0 || isExistingQuestion">
                            <option [ngValue]="null">Select...</option>
                            <option *ngFor="let question of currentUnassignedQuestions"
                                    [ngValue]="question.id">
                                {{question.name}}
                            </option>
                        </select>
                        <small class="form-text text-muted">Use this option to assign an existing unassigned question to this section</small>
                    </div>
                </div>
                <hr />
                <div class="form-group row">
                    <label class="form-label col-sm-2">Title</label>
                    <div class="col">
                        <input type="text"
                               class="form-control"
                               name="question-name"
                               [(ngModel)]="activeQuestion.name" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="form-label col-sm-2 required">Text</label>
                    <div class="col">
                        <textarea class="form-control text-editor text-editor-md"
                                  name="question-description"
                                  required
                                  [(ngModel)]="activeQuestion.description"></textarea>
                        <small class="form-text text-danger validation-message">Question text is required.</small>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="form-label col-sm-2">Help text</label>
                    <div class="col">
                        <textarea class="form-control text-editor text-editor-md"
                                  name="question-help-text"
                                  [(ngModel)]="activeQuestion.helpText"></textarea>
                        <small class="form-text text-danger validation-message">Help text is invalid.</small>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="form-label col-sm-2">Options</label>
                    <div class="col">
                        <div class="form-group">
                            <button type="button"
                                    class="btn btn-primary"
                                    (click)="addOption()">
                                <i class="fa fa-plus"></i>
                                Add option
                            </button>
                        </div>
                        <div class="table-responsive">
                            <table class="table table-sm mb-0">
                                <thead>
                                    <tr>
                                        <th class="col-action"></th>
                                        <th style="width: 20%">Standard option</th>
                                        <th style="width: 20%">Text</th>
                                        <th style="width: 30%">Enables section</th>
                                        <th style="width: 30%">Enables questions with tag:</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngIf="!activeQuestion.questionOptions || activeQuestion.questionOptions.length == 0">
                                        <td colspan="6">
                                            <span class="d-block p-1 text-center">
                                                <i class="fa fa-ban text-danger"></i>
                                                Question contains no options
                                            </span>
                                        </td>
                                    </tr>
                                    <tr *ngFor="let qo of activeQuestionOptions; index as i" [class.d-none]="qo.isDeleted">
                                        <td>
                                            <button type="button"
                                                    class="btn btn-secondary"
                                                    (click)="removeOption(qo)">
                                                <i class="fa fa-trash"></i>
                                                <span class="sr-only">Remove</span>
                                            </button>
                                        </td>
                                        <td>
                                            <select class="custom-select"
                                                    [(ngModel)]="qo.standardQuestionOptionId"
                                                    name="option-std-opt-{{i}}"
                                                    [required]="qo.text == null || qo.text == ''">
                                                <option [ngValue]="null">Custom text</option>
                                                <option *ngFor="let option of standardOptions" [value]="option.id">{{option.value}}</option>
                                            </select>
                                            <small class="form-text text-danger validation-message">Select a standard option</small>
                                        </td>
                                        <td>
                                            <input type="text"
                                                   class="form-control"
                                                   placeholder="Enter text"
                                                   [(ngModel)]="qo.text"
                                                   name="option-text-{{i}}"
                                                   [disabled]="qo.standardQuestionOptionId != null"
                                                   [required]="qo.standardQuestionOptionId == null" />
                                            <small class="form-text text-danger validation-message">Required for custom text option</small>
                                        </td>
                                        <td>
                                            <div class="input-group">
                                                <select class="custom-select" id="section-selector-{{i}}" #selectedSection>
                                                    <option [ngValue]="null">Select one...</option>
                                                    <option *ngFor="let section of inspectionProfile.profileSections"
                                                            [value]="section.id"
                                                            [disabled]="checkOption(qo, section)">
                                                        {{section.name}}
                                                    </option>
                                                </select>
                                                <div class="input-group-append">
                                                    <button type="button"
                                                            class="btn btn-primary"
                                                            (click)="selectedSection.value = addTriggeredSection('#section-selector-'+i, qo)">
                                                        <i class="fa fa-plus"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <table class="table table-sm table-align-middle mb-1">
                                                <tr *ngFor="let section of qo.profileSectionQuestionOptions">
                                                    <td class="col-action">
                                                        <button type="button"
                                                                class="btn btn-sm btn-link text-danger p-0 m-0"
                                                                (click)="removeSectionFromOption('#section-selector-'+i, qo, section)">
                                                            <i class="fa fa-trash"></i>
                                                            <span class="sr-only">Remove</span>
                                                        </button>
                                                    </td>
                                                    <td>{{section.profileSection.name}}</td>
                                                </tr>
                                            </table>
                                        </td>
                                        <td>
                                            <div class="input-group">
                                                <select class="custom-select"
                                                        id="tag-selector-{{i}}"
                                                        name="tag-selector-{{i}}"
                                                        #selectedQuestionTag
                                                        [disabled]="tags.length == 0">
                                                    <option [ngValue]="null">Select one...</option>
                                                    <option *ngFor="let tag of tags"
                                                            [value]="tag.id">
                                                            {{tag.name}}
                                                    </option>
                                                </select>
                                                <div class="input-group-append">
                                                    <button type="button"
                                                            class="btn btn-primary"
                                                            (click)="selectedQuestionTag.value = addTriggeredQuestionTag('#tag-selector-'+i, qo)">
                                                        <i class="fa fa-plus"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <table class="table table-sm table-align-middle mb-1">
                                                <tr *ngFor="let activatingTag of qo.profileQuestionActivatingTags">
                                                    <td class="col-action">
                                                        <button type="button"
                                                                class="btn btn-sm btn-link text-danger p-0 m-0"
                                                                (click)="removeTagFromOption('#tag-selector-'+i, qo, activatingTag)">
                                                            <i class="fa fa-trash"></i>
                                                            <span class="sr-only">Remove</span>
                                                        </button>
                                                    </td>
                                                    <td>{{activatingTag.tag.name}}</td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="form-label col-sm-2">Tags</label>
                    <div class="col">
                        <div class="input-group mb-4">
                            <select class="custom-select editor-md" id="tag-selector" #selectedTag>
                                <option [ngValue]="null">Select one...</option>
                                <option *ngFor="let tag of tags" [value]="tag.id">
                                    {{tag.name}}
                                </option>
                            </select>
                            <div class="input-group-append">
                                <button type="button" class="btn btn-sm btn-primary" (click)="selectedTag.value = addTag(selectedTag.value)">
                                    <i class="fa fa-fw fa-plus"></i>
                                </button>
                            </div>
                        </div>
                        <ul class="list-inline">
                            <li *ngFor="let qt of currentTags" class="list-inline-item">
                                <span class="bdo-tag bdo-tag-dark">
                                    <button type="button" class="btn-none text-danger" (click)="removeTag(qt)"><i class="fa fa-times"></i></button>
                                    {{qt.tag.name}}
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
                <hr />
                <div>
                    <button type="button"
                            class="btn btn-primary mr-2"
                            (click)="saveQuestionChanges()"
                            [disabled]="!questionForm.valid">
                        <i class="fa fa-save"></i>
                        Save question
                    </button>
                    <button type="button"
                            class="btn btn-secondary"
                            (click)="cancelQuestionChanges()">
                        <i class="fa fa-times"></i>
                        Cancel
                    </button>
                    <button type="button"
                            class="btn btn-secondary float-right"
                            (click)="editNavigatedQuestion(nextQuestion)"
                            [disabled]="!nextQuestion">
                        <i class="fa fa-arrow-right"></i>
                        Next Question
                    </button>
                    <button type="button"
                            class="btn btn-secondary mr-2 float-right"
                            (click)="editNavigatedQuestion(previousQuestion)"
                            [disabled]="!previousQuestion">
                        <i class="fa fa-arrow-left"></i>
                        Previous Question
                    </button>
                </div>
            </fieldset>
        </form>
        <div #reorderQuestionComp>
            <div *ngIf="inspectionProfile.id > 0 && !activeQuestion?.isEditing && reorderQuestions">
                <inspection-dragdrop [node]="node" [i]="i" [inspectionProfile]="inspectionProfile" [context]="this" [isQuestion]="true"></inspection-dragdrop>
            </div>
        </div>
        <div #reorderSectionComp>
            <div *ngIf="inspectionProfile.id > 0 && !activeQuestion?.isEditing && reorderSections">
                <inspection-dragdrop [node]="node" [i]="i" [inspectionProfile]="inspectionProfile" [context]="this" [isQuestion]="false"></inspection-dragdrop>
            </div>
        </div>
    </div>
</div>
