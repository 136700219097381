import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { ApplicationInsightsService } from '../../../../services/application-insights/application-insights.service';
import { DialogHelper } from '../../../../services/dialog-helper';
import { IsBusyService } from '../../../../services/is-busy/is-busy.service';
import { UnitOfWorkService } from '../../../../services/unit-of-work/unit-of-work.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import Utilities from '../../../../services/utilities';
import { InspectionProfile, ProfileSection, Question } from '../../../../../model/model';
import { GlobalErrorHandler } from '../../../../services/global-error-handler';
import { Profile } from '../../../../../typings';

@Component({
    selector: 'inspection-dragdrop',
    inputs: ['node', 'i', 'inspectionProfile'],
    encapsulation: ViewEncapsulation.None,
    templateUrl: './inspection-dragdrop.component.html',
    styleUrls: ['./inspection-dragdrop.component.less']
})
export class InspectionDragdropComponent implements OnInit {
    @Input() node: ProfileSection;
    @Input() i: number;
    @Input() inspectionProfile: InspectionProfile;
    @Input() context: any;
    @Input() isQuestion: boolean;

    displayEditQuestion: Question = null;
    allProfileSectionIds: string[] = [];
    unsortedProfileSections: ProfileSection[] = [];

    constructor(
        private uow: UnitOfWorkService,
        private appInsights: ApplicationInsightsService,
        private errorHandler: GlobalErrorHandler,
        public isBusy: IsBusyService,
        public dialogHelper: DialogHelper
    ) { }

    ngOnInit() {
        if (this.inspectionProfile && this.inspectionProfile.profileSections) {
            this.inspectionProfile.profileSections.forEach(s => this.allProfileSectionIds.push(s.id.toString()));
        }

        this.unsortedProfileSections = this.rootProfileSections;
    }

    get rootProfileSections() {
        if (this.inspectionProfile && this.inspectionProfile.profileSections) {
            this.inspectionProfile.profileSections.forEach(s => this.sortSectionAndChildren(s));
            return this.inspectionProfile.profileSections.filter(m => m.parentSectionId == null).sort(Utilities.sortByDisplayOrder)
        }

        return [];
    }

    get hasChanges() {
        if (this.isQuestion) {
            return this.inspectionProfile.entityAspect.entityState.isAddedModifiedOrDeleted()
                || this.inspectionProfile.profileSections.filter(s => s.entityAspect.entityState.isAddedModifiedOrDeleted() ||
                    s.questions.some(q => q.entityAspect.entityState.isAddedModifiedOrDeleted() || q.initialIndex != null || q.initialSection != null)).length > 0;
        }
        else if (!this.isQuestion) {
            return this.inspectionProfile.entityAspect.entityState.isAddedModifiedOrDeleted()
                || this.inspectionProfile.profileSections.filter(s => s.entityAspect.entityState.isAddedModifiedOrDeleted() || s.initialIndex != null).length > 0;
        }
    }

    toggleSection(section: ProfileSection) {
        if (section) {
            section.isCollapsed = !section.isCollapsed;
        }
    }

    sortSectionAndChildren(section: ProfileSection) {
        if (section.childSections) {
            section.childSections.sort(Utilities.sortByDisplayOrder);
            section.childSections.forEach(s => this.sortSectionAndChildren(s));
        }
    }

    resetDisplayOrder(section: ProfileSection, container: ProfileSection[] = []) {
        if (this.isQuestion) {
            if (section && section.questions && section.questions.length) {
                let i = 1;
                section.questions.forEach(q => {
                    q.displayOrder = i;
                    i = i + 1;
                });
            }
        }
        else if (!this.isQuestion) {
            if (container) {
                let i = 1;
                container.forEach(c => {
                    console.log(c.name, c.displayOrder, i);
                    c.displayOrder = i;
                    i = i + 1;

                    this.resetDisplayOrder(null, c.childSections);
                });
            }
        }
    }

    checkMoved(section: ProfileSection, question: Question, i: number) {
        if (this.isQuestion) {
            const q = question.initialIndex != null && question.initialIndex != i && question.initialIndex >= 0;
            const s = question.initialSection != null && question.initialSection.id != section.id && section.id >= 0;
            return q || s;
        }
        else if (!this.isQuestion && section.initialIndex !== null && section.initialIndex >= 0) {
            return section.initialIndex !== i;
        }
    }

    undoMove(container: ProfileSection[], section: ProfileSection, question: Question, i: number) {
        if (this.isQuestion) {
            if (question && section && question.initialSection) {
                transferArrayItem(section.questions, question.initialSection.data, i, question.initialIndex);
            } else {
                moveItemInArray(section.questions, i, question.initialIndex);
            }
            question.initialIndex = null;
            question.initialSection = null;
            question.entityAspect.rejectChanges();
        }
        else if (!this.isQuestion) {
            if (container && section) {
                moveItemInArray(container, i, section.initialIndex);
            }

            section.initialIndex = null;
            section.entityAspect.rejectChanges();
        }
    }

    drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer == event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
            if (!event.item.data.initialIndex && event.item.data.initialIndex != 0) {
                event.item.data.initialIndex = event.previousIndex;
            }
        } else {
            transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
            if (!event.item.data.initialSection) {
                event.item.data.initialSection = event.previousContainer;
            }
            if (!event.item.data.initialIndex && event.item.data.initialIndex != 0) {
                event.item.data.initialIndex = event.previousIndex;
            }
            event.item.data.profileSectionId = event.container.id;
        }
    }

    // Undo move for all questions
    cancelReorderChanges() {
        const confirmation = this.dialogHelper.confirm('Undo all changes?');

        confirmation.subscribe(result => {
            if (result) {
                this.undoAllChanges();
            }
        });
    }

    undoAllChanges() {
        this.inspectionProfile.profileSections
            .filter(s => s.questions.some(q => q.initialIndex != null || q.initialSection != null))
            .forEach(s => {
                s.questions.filter(q => q.initialIndex != null || q.initialSection != null).forEach(q => {
                    const ind = s.questions.findIndex(sq => sq.id == q.id);
                    if (ind > -1) {
                        this.undoMove(null, s, q, ind);
                    }
                });
            });
    }

    closeReorderChanges() {
        this.context.reorderQuestions = false;
        this.context.reorderSections = false;
    }

    saveReorderChanges() {
        const confirmation = this.dialogHelper.confirm('Display orders will be reassigned in the current order for all modified sections. Would you like to save your changes?');
        confirmation.subscribe(result => {
            if (result && this.isQuestion) {
                this.isBusy.set('savingQuestions', true, 'Saving');
                const moved = this.inspectionProfile.profileSections.filter(s => s.entityAspect.entityState.isAddedModifiedOrDeleted() ||
                    s.questions.some(q => q.entityAspect.entityState.isAddedModifiedOrDeleted() || q.initialIndex != null || q.initialSection != null));
                moved.forEach(s => this.resetDisplayOrder(s));
                this.uow.commit()
                    .then(() => {
                        this.inspectionProfile.profileSections.forEach(s => {
                            s.questions.forEach(q => {
                                q.initialIndex = null;
                                q.initialSection = null;
                            });
                        });
                        this.isBusy.set('savingQuestions', false, 'Success');
                    })
                    .catch((reason) => {
                        this.errorHandler.handleError(reason);
                    })
                    .finally(() => {
                        this.isBusy.set('savingQuestions', false);
                    });
            }
            else if (result && !this.isQuestion) {
                this.isBusy.set('savingQuestions', true, 'Saving');
                const moved = this.inspectionProfile.profileSections.filter(s => s.entityAspect.entityState.isAddedModifiedOrDeleted() || s.initialIndex != null);
                moved.forEach(s => this.resetDisplayOrder(null, this.unsortedProfileSections));
                this.uow.commit()
                    .then(() => {
                        this.inspectionProfile.profileSections.forEach(s => {
                            s.initialIndex = null;
                        });
                        this.isBusy.set('savingQuestions', false, 'Success');
                    })
                    .catch((reason) => {
                        this.errorHandler.handleError(reason);
                    })
                    .finally(() => {
                        this.isBusy.set('savingQuestions', false);
                    });
            }
        });
    }

    setEditQuestion(question: Question) {
        if (question) {
            this.displayEditQuestion = question;
        }
    }

    saveDisplayQuestion(question: Question) {
        if (question && question.entityAspect.entityState.isModified()) {
            //console.log(question);
        }
        this.displayEditQuestion = null;
    }

    getDisplayText(question, max = 60) {
        if (question.name) {
            if (question.name.length > max) {
                return question.name.substr(0, max) + '...';
            }
            return question.name;
        }
        if (question.description) {
            if (question.description.length > max) {
                return question.description.substr(0, max) + '...';
            }
            return question.description;
        }
        return '';
    }

}
