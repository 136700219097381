<div class="header">
    <h3>Admin</h3>
</div>
<div class="row">
    <div class="col tab-menu">
        <admin-nav-menu [currentTab]="'tags'"></admin-nav-menu>
    </div>

    <div class="col col-constrained">
        <fieldset>
            <legend>Tags</legend>
            <label for="new-tag">Add new tag?</label>
            <div class="form-group">
                <input type="text"
                        id="new-tag"
                        [(ngModel)]="newTagName"
                        (keypress)="$event.keyCode == 13 ? createTag() : null"
                        class="form-control editor-md"
                        placeholder="Enter new tag name" />
            </div>
            <button type="button" class="btn btn-primary" (click)="createTag()">
                <i class="fa fa-save"></i>
                Save
            </button>
            <table class="table table-sm table-align-middle my-3">
                <thead>
                    <tr>
                        <th class="col-action"></th>
                        <th class="col-action"></th>
                        <th class="col-action"></th>
                        <th style="width: 1rem;">Id</th>
                        <th>Name</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="activeTags.length == 0">
                        <td colspan="5">
                            <span class="d-block p-2 text-center">
                                <i class="fa fa-ban text-danger"></i>
                                No data to display&hellip;
                            </span>
                        </td>
                    </tr>
                    <tr *ngFor="let tag of activeTags; index as i">
                        <td class="text-center">
                            <button [disabled]="tag.questionTags.length > 0 || tag.engagementQuestionTags.length > 0"
                                    type="button"
                                    class="btn btn-sm btn-secondary"
                                    (click)="destroyTag(tag)">
                                <i class="fa fa-trash"></i>
                                <span class="sr-only">Remove</span>
                            </button>
                        </td>
                        <td class="text-center">
                            <label class="switch" title="Enable/Disable" style="margin-top: 10px">
                                <input type="checkbox"
                                       id="tag-active"
                                       (change)="updateTag(tag)"
                                       [(ngModel)]="tag.isActive">
                                <span class="slider round"></span>
                            </label>
                        </td>
                        <td>
                            <button type="button"
                                    class="btn btn-sm btn-primary"
                                    (click)="updateTag(tag)">
                                <i class="fa fa-save"></i>
                                <span class="sr-only">Save</span>
                            </button>
                        </td>
                        <td class="px-3 font-weight-bold">{{tag.id}}</td>
                        <td>
                            <input type="text" class="form-control form-control-sm editor-lg d-inline-block mr-2" name="tag-{{i}}" [(ngModel)]="tag.name" required />
                            <small class="form-text validation-message">Name is required.</small>
                        </td>
                    </tr>
                </tbody>
            </table>
        </fieldset>
    </div>
</div>
