<div class="header">
    <h3>Admin</h3>
</div>
<div class="row">
    <div class="col tab-menu">
        <admin-nav-menu [currentTab]="'specialty-reviewer-types'"></admin-nav-menu>
    </div>

    <div class="col col-constrained">
        <fieldset>
            <legend>Specialty Reviewer Types</legend>
            <div class="form-group">
                <label for="reviewer-type">Option Value</label>
                <input type="text"
                       id="reviewer-type"
                       [(ngModel)]="newReviewerTypeName"
                       class="form-control editor-md"
                       placeholder="Reviewer Type" />
            </div>
            <div class="form-group">
                <label for="new-standard-option-name">Description</label>
                <textarea class="form-control text-editor-sm"
                          id="new-standard-option-name"
                          [(ngModel)]="newDescription"
                          placeholder="Short description of reviewer type"></textarea>
            </div>
            <button type="button" class="btn btn-primary" (click)="createReviewerType()">
                <i class="fa fa-save"></i>
                Save
            </button>
            <table class="table table-sm table-align-middle my-3">
                <thead>
                    <tr>
                        <th class="col-action"></th>
                        <th class="col-action"></th>
                        <th style="width: 1rem;">Id</th>
                        <th>Name</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="reviewerTypes.length == 0">
                        <td colspan="5">
                            <span class="d-block p-2 text-center">
                                <i class="fa fa-ban text-danger"></i>
                                No data to display&hellip;
                            </span>
                        </td>
                    </tr>
                    <tr *ngFor="let type of reviewerTypes; index as i">
                        <td class="text-center">
                            <button *ngIf="type.name != 'Tax Reviewer' && type.name != 'IS Reviewer'"
                                    type="button"
                                    class="btn btn-sm btn-secondary"
                                    (click)="destroyReviewerType(type)">
                                <i class="fa fa-trash"></i>
                                <span class="sr-only">Remove</span>
                            </button>
                        </td>
                        <td>
                            <button type="button"
                                    class="btn btn-sm btn-primary"
                                    (click)="updateReviewerType(type)">
                                <i class="fa fa-save"></i>
                                <span class="sr-only">Save</span>
                            </button>
                        </td>
                        <td class="px-3 font-weight-bold">{{type.id}}</td>
                        <td>
                            <input type="text" class="form-control form-control-sm editor-lg d-inline-block mr-2" name="type-{{i}}" [(ngModel)]="type.name" required />
                            <small class="form-text validation-message">Name is required.</small>
                        </td>
                    </tr>
                </tbody>
            </table>
        </fieldset>
    </div>
</div>
