<div class="header">
    <h3>My Team's Inspections</h3>
</div>
<div class="p-3 mb-3 bg-bdo-secondary">
    <fieldset>
        <legend>Search Inspections</legend>
        <div class="row">
            <div class="col-sm-2">
                <div class="form-group">
                    <label for="code">Engagement Code</label>
                    <input type="text" class="form-control" name="code" id="code" [(ngModel)]="engagementCodes" />
                </div>
                <div class="form-group">
                    <label for="group-by-mode">Group By</label>
                    <select id="group-by-mode" name="group-by-mode" class="custom-select" [(ngModel)]="groupByMode">
                        <option *ngFor="let mode of groupByModes" [value]="mode.id">{{mode.name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-sm-2">
                <div class="form-group">
                    <label for="inspection-year">Inspection Year</label>
                    <input type="text" class="form-control" name="inspection-year" id="inspection-year" [(ngModel)]="inspectionYears" />
                </div>
                <div class="form-group" *ngIf="isAdmin">
                    <label for="captain">Team Captain</label>
                    <select id="captain" name="captain" class="custom-select" [(ngModel)]="selectedCaptain">
                        <option *ngFor="let captain of teamCaptains" [value]="captain.id">{{captain.lastName}}, {{captain.firstName}}</option>
                    </select>
                </div>
            </div>
            <div class="col-sm-3">
                <div class="form-group">
                    <label for="offices">Office</label>
                    <select name="offices" id="offices" class="custom-select" multiple size="5" [(ngModel)]="selectedOffices">
                        <option *ngFor="let office of offices" [value]="office.id">{{office.id}} - {{office.name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-sm-2">
                <div class="form-group">
                    <label for="regions">Region</label>
                    <select name="regions" id="regions" class="custom-select" multiple size="5" [(ngModel)]="selectedRegions">
                        <option *ngFor="let region of regions" [value]="region">{{region}}</option>
                    </select>
                </div>
            </div>
            <div class="col-sm-3">
                <div class="form-group">
                    <label for="business-lines">Business Line</label>
                    <select name="business-lines" id="business-lines" class="custom-select" multiple size="5" [(ngModel)]="selectedBusinessLines">
                        <option *ngFor="let bl of businessLines" [value]="bl.id">{{bl.name}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div>
            <button type="button" class="btn btn-primary text-nowrap" (click)="applyPrimaryFilters()">
                <i class="fa fa-search"></i>
                Search
            </button>
            <button type="button" class="btn btn-secondary text-nowrap ml-1" (click)="clearPrimaryFilters()">
                <i class="fa fa-times"></i>
                Clear
            </button>
        </div>
    </fieldset>
</div>

<div class="row">
    <div class="col" *ngIf="groupBy == 'engagement-code'">
        <p class="text-nowrap">
            <b>{{rowCount}}</b> {{(rowCount == 1 ? 'record' : 'records')}} found
        </p>

        <grid-pager [actionName]="'../inspections'" [page]="page" [limit]="limit" [pageCount]="pageCount" [limits]="pageLimitOptions"></grid-pager>

        <div class="">
            <table id="engagements-grid" class="table table-striped my-2">
                <thead>
                    <tr class="headers-row">
                        <th style="width: 6rem; padding: 3px;">
                            <div class="dropdown">
                                <button class="btn btn-warning dropdown-toggle text-nowrap" type="button" id="engagement-actions" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Action
                                </button>
                                <div class="dropdown-menu" aria-labelledby="engagement-actions">
                                    <button class="dropdown-item text-nowrap" type="button" (click)="selectAll()">
                                        Select all
                                    </button>
                                    <div class="dropdown-divider"></div>
                                    <button class="dropdown-item text-nowrap" type="button" (click)="showSetStatusModal()" [disabled]="!hasSelections">
                                        Set status&hellip;
                                    </button>
                                </div>
                            </div>
                        </th>
                        <th style="width: 6rem;">Engagement Code</th>
                        <th>Client Name</th>
                        <th style="width: 8rem;">Inspection Status</th>
                        <th style="width: 8rem;">Progress Status</th>
                        <th style="width: 8rem;">Sections</th>
                        <th style="width: 8rem;">Completed Sections</th>
                        <th style="width: 8rem;">% Complete</th>
                        <th style="width: 8rem;">NRFs</th>
                        <th style="width: 8rem;">Observations</th>
                    </tr>
                    <tr class="filters-row">
                        <td>
                            <button type="button" class="btn btn-sm btn-block btn-secondary text-nowrap" (click)="clearSecondaryFilters()">
                                <i class="fa fa-times"></i>
                                Clear
                            </button>
                        </td>
                        <td><input type="text" class="form-control form-control-sm" placeholder="Filter by..." [(ngModel)]="engagementCodes" (change)="updateFilters('code', engagementCodes, true)" /></td>
                        <td><input type="text" class="form-control form-control-sm" placeholder="Filter by..." [(ngModel)]="clientNameFilter" (change)="updateFilters('clientName', clientNameFilter, true)" /></td>
                        <td>
                            <div ngbDropdown autoClose="outside" (openChange)="updateInspectionStatusFilters($event)">
                                <button class="btn btn-sm btn-block btn-faux-textbox d-flex text-left caret-off" id="engagement-status-filters" ngbDropdownToggle>
                                    <span class="selected-status-label text-truncate d-inline-block" style="min-width: 8rem; max-width: 8rem;">{{inspectionStatusesLabel}}</span>
                                </button>
                                <div ngbDropdownMenu aria-labelledby="engagement-status-filters">
                                    <div class="dropdown-item px-3 text-nowrap" *ngFor="let option of engagementStatuses; index as i">
                                        <div class="form-check" *ngIf="option.status > 0">
                                            <input [(ngModel)]="engagementStatuses[i].isSelected" type="checkbox" [value]="option.isSelected" id="engagement-status-{{i}}" class="form-check-input" />
                                            <label for="engagement-status-{{i}}" class="form-check-label">{{option.title}}</label>
                                        </div>
                                    </div>
                                    <div class="dropdown-divider"></div>
                                    <div class="dropdown-item px-3 text-nowrap">
                                        <button type="button" class="btn btn-sm btn-link p-0 m-0" (click)="clearInspectionStatusFilters()">
                                            <i class="fa fa-times"></i>
                                            Clear
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div ngbDropdown autoClose="outside" (openChange)="updateProgressStatusFilters($event)">
                                <button class="btn btn-sm btn-block btn-faux-textbox d-flex text-left caret-off" id="progress-status-filters" ngbDropdownToggle>
                                    <span class="selected-status-label text-truncate d-inline-block" style="min-width: 8rem; max-width: 8rem;">{{progressStatusesLabel}}</span>
                                </button>
                                <div ngbDropdownMenu aria-labelledby="progress-status-filters">
                                    <div class="dropdown-item px-3 text-nowrap" *ngFor="let option of progressStatuses; index as i">
                                        <div class="form-check">
                                            <input [(ngModel)]="progressStatuses[i].isSelected" type="checkbox" [value]="option.isSelected" id="progress-status-{{i}}" class="form-check-input" />
                                            <label for="progress-status-{{i}}" class="form-check-label">{{option.title}}</label>
                                        </div>
                                    </div>
                                    <div class="dropdown-divider"></div>
                                    <div class="dropdown-item px-3 text-nowrap">
                                        <button type="button" class="btn btn-sm btn-link p-0 m-0" (click)="clearProgressStatusFilters()">
                                            <i class="fa fa-times"></i>
                                            Clear
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                            <select id="question-is-nrf" name="question-is-nrf" class="custom-select custom-select-sm" [(ngModel)]="isNrfFilter">
                                <option [ngValue]="null">Any</option>
                                <option *ngFor="let option of yesNoOptions" [value]="option.value">{{option.name}}</option>
                            </select>
                        </td>
                        <td>
                            <select id="question-has-observations" name="question-has-observations" class="custom-select custom-select-sm" [(ngModel)]="hasObservationsFilter">
                                <option [ngValue]="null">Any</option>
                                <option *ngFor="let option of yesNoOptions" [value]="option.value">{{option.name}}</option>
                            </select>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="activeEngagements.length == 0">
                        <td colspan="10">
                            <span class="d-block p-2 text-center">
                                <i class="fa fa-ban text-danger"></i>
                                No data to display&hellip;
                            </span>
                        </td>
                    </tr>
                    <tr *ngFor="let engagement of activeEngagements">
                        <td class="text-center">
                            <input type="checkbox" value="true" [(ngModel)]="engagement.isSelected" />
                        </td>
                        <td class="text-nowrap">
                            <a [routerLink]="['../inspection']" [queryParams]="{ id: engagement.id }" class="engagment-link">
                                {{engagement.code}}
                            </a>
                        </td>
                        <td>
                            <a [routerLink]="['../inspection']" [queryParams]="{ id: engagement.id }" class="engagment-link">
                                {{engagement.clientName}}
                            </a>
                        </td>
                        <td class="text-nowrap">{{getEngagementStatusName(engagement.inspectionStatus)}}</td>
                        <td class="text-nowrap">{{getProgressStatusName(engagement.progressStatus)}}</td>
                        <td class="text-center" *ngIf="engagement.totalSections">{{engagement.totalSections | number : '1.0-0'}}</td>
                        <td class="text-center" *ngIf="!engagement.totalSections">---</td>
                        <td class="text-center" *ngIf="engagement.completedSections">{{engagement.completedSections | number : '1.0-0'}}</td>
                        <td class="text-center" *ngIf="!engagement.completedSections">---</td>
                        <td class="text-center text-nowrap" *ngIf="engagement.totalSections">
                            {{calculatePercentComplete(engagement.completedSections, engagement.totalSections) | percent : '1.1-2'}}
                            <small class="ml-1">({{engagement.completedSections}}/{{engagement.totalSections}})</small>
                        </td>
                        <td class="text-center" *ngIf="!engagement.totalSections">---</td>
                        <td class="text-center" *ngIf="engagement.nrfs">{{engagement.nrfs | number : '1.0-0'}}</td>
                        <td class="text-center" *ngIf="!engagement.nrfs">---</td>
                        <td class="text-center" *ngIf="engagement.observations">{{engagement.observations | number : '1.0-0'}}</td>
                        <td class="text-center" *ngIf="!engagement.observations">---</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <grid-pager [actionName]="'../team-inspections'" [page]="page" [limit]="limit" [pageCount]="pageCount" [limits]="pageLimitOptions"></grid-pager>
    </div>

    <div class="col" *ngIf="groupBy == 'section'">
        <p class="text-nowrap">
            <b>{{rowCount}}</b> {{(rowCount == 1 ? 'record' : 'records')}} found from <b>{{engagementsCount}}</b> {{(engagementsCount == 1 ? 'inspection' : 'inspections')}}
        </p>

        <grid-pager [actionName]="'../inspections'" [page]="page" [limit]="limit" [pageCount]="pageCount" [limits]="pageLimitOptions"></grid-pager>

        <div class="">
            <table id="engagement-sections-grid" class="table table-striped my-2">
                <thead>
                    <tr class="headers-row">
                        <th style="width: 6rem; padding: 3px;">
                            <div class="dropdown">
                                <button class="btn btn-warning dropdown-toggle text-nowrap" type="button" id="engagement-actions" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Action
                                </button>
                                <div class="dropdown-menu" aria-labelledby="engagement-actions">
                                    <button class="dropdown-item text-nowrap" type="button" (click)="selectAll()">
                                        Select all
                                    </button>
                                    <div class="dropdown-divider"></div>
                                    <button class="dropdown-item text-nowrap" type="button" (click)="showSetStatusModal()" [disabled]="!hasSelections">
                                        Set status&hellip;
                                    </button>
                                </div>
                            </div>
                        </th>
                        <th style="width: 10rem;">Assigned On</th>
                        <th style="width: 10rem;">Engagement Code</th>
                        <th style="width: 8rem;">Engagement Status</th>
                        <th>Section</th>
                        <th style="width: 8rem;">Progress Status</th>
                        <th style="width: 8rem;">% Complete</th>
                        <th style="width: 8rem;">NRFs</th>
                        <th style="width: 8rem;">Observations</th>
                        <th style="width: 14rem;">Assigned To</th>
                    </tr>
                    <tr class="filters-row">
                        <td>
                            <button type="button" class="btn btn-sm btn-block btn-secondary text-nowrap" (click)="clearSecondaryFilters()">
                                <i class="fa fa-times"></i>
                                Clear
                            </button>
                        </td>
                        <td><input type="text" class="form-control form-control-sm" placeholder="Filter by..." [(ngModel)]="assignedOnFilter" (change)="updateFilters('assignedOn', assignedOnFilter, true)" /></td>
                        <td><input type="text" class="form-control form-control-sm" placeholder="Filter by..." [(ngModel)]="engagementCodes" (change)="updateFilters('code', engagementCodes, true)" /></td>
                        <td>
                            <div ngbDropdown autoClose="outside" (openChange)="updateInspectionStatusFilters($event)">
                                <button class="btn btn-sm btn-block btn-faux-textbox d-flex text-left caret-off" id="engagement-status-filters" ngbDropdownToggle>
                                    <span class="selected-status-label text-truncate d-inline-block" style="min-width: 8rem; max-width: 8rem;">{{inspectionStatusesLabel}}</span>
                                </button>
                                <div ngbDropdownMenu aria-labelledby="engagement-status-filters">
                                    <div class="dropdown-item px-3 text-nowrap" *ngFor="let option of engagementStatuses; index as i">
                                        <div class="form-check" *ngIf="option.status > 0">
                                            <input [(ngModel)]="engagementStatuses[i].isSelected" type="checkbox" [value]="option.isSelected" id="engagement-status-{{i}}" class="form-check-input" />
                                            <label for="engagement-status-{{i}}" class="form-check-label">{{option.title}}</label>
                                        </div>
                                    </div>
                                    <div class="dropdown-divider"></div>
                                    <div class="dropdown-item px-3 text-nowrap">
                                        <button type="button" class="btn btn-sm btn-link p-0 m-0" (click)="clearInspectionStatusFilters()">
                                            <i class="fa fa-times"></i>
                                            Clear
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td><input type="text" class="form-control form-control-sm" placeholder="Filter by..." [(ngModel)]="sectionNameFilter" (change)="updateFilters('sectionName', sectionNameFilter, true)" /></td>
                        <td>
                            <div ngbDropdown autoClose="outside" (openChange)="updateProgressStatusFilters($event)">
                                <button class="btn btn-sm btn-block btn-faux-textbox d-flex text-left caret-off" id="progress-status-filters" ngbDropdownToggle>
                                    <span class="selected-status-label text-truncate d-inline-block" style="min-width: 8rem; max-width: 8rem;">{{progressStatusesLabel}}</span>
                                </button>
                                <div ngbDropdownMenu aria-labelledby="progress-status-filters">
                                    <div class="dropdown-item px-3 text-nowrap" *ngFor="let option of progressStatuses; index as i">
                                        <div class="form-check">
                                            <input [(ngModel)]="progressStatuses[i].isSelected" type="checkbox" [value]="option.isSelected" id="progress-status-{{i}}" class="form-check-input" />
                                            <label for="progress-status-{{i}}" class="form-check-label">{{option.title}}</label>
                                        </div>
                                    </div>
                                    <div class="dropdown-divider"></div>
                                    <div class="dropdown-item px-3 text-nowrap">
                                        <button type="button" class="btn btn-sm btn-link p-0 m-0" (click)="clearProgressStatusFilters()">
                                            <i class="fa fa-times"></i>
                                            Clear
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td></td>
                        <td>
                            <select id="question-is-nrf" name="question-is-nrf" class="custom-select custom-select-sm" [(ngModel)]="isNrfFilter" (change)="updateFilters('nrfs', isNrfFilter, true)">
                                >
                                <option [ngValue]="null">Any</option>
                                <option *ngFor="let option of yesNoOptions" [value]="option.value">{{option.name}}</option>
                            </select>
                        </td>
                        <td>
                            <select id="question-has-observations" name="question-has-observations" class="custom-select custom-select-sm" [(ngModel)]="hasObservationsFilter" (change)="updateFilters('observations', hasObservationsFilter, true)">
                                >
                                <option [ngValue]="null">Any</option>
                                <option *ngFor="let option of yesNoOptions" [value]="option.value">{{option.name}}</option>
                            </select>
                        </td>
                        <td><input type="text" class="form-control form-control-sm" placeholder="Filter by..." [(ngModel)]="assignedToFilter" (change)="updateFilters('assignedTo', assignedToFilter, true)" /></td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="activeEngagementSections.length == 0">
                        <td colspan="10">
                            <span class="d-block p-2 text-center">
                                <i class="fa fa-ban text-danger"></i>
                                No data to display&hellip;
                            </span>
                        </td>
                    </tr>
                    <tr *ngFor="let section of activeEngagementSections">
                        <td class="text-center">
                            <input type="checkbox" value="true" [(ngModel)]="section.isSelected" />
                        </td>
                        <td *ngIf="section.assignedTeamMembers && section.assignedTeamMembers.length > 0">{{section.assignedOn | date: short}}</td>
                        <td *ngIf="!section.assignedTeamMembers || section.assignedTeamMembers.length == 0">---</td>
                        <td class="text-nowrap">
                            <a [routerLink]="['../inspection']" [queryParams]="{ id: section.engagementId }" class="engagment-link">
                                {{section.engagementCode}}
                            </a>
                        </td>
                        <td class="text-nowrap">{{getEngagementStatusName(section.inspectionStatus)}}</td>
                        <td>
                            <a [routerLink]="['../inspection']" [queryParams]="{ id: section.engagementId, sid: section.sectionId }" class="engagment-link">
                                {{section.sectionName}}
                            </a>
                        </td>
                        <td class="text-nowrap">{{getProgressStatusName(section.progressStatus)}}</td>
                        <td class="text-center" *ngIf="section.totalQuestions">
                            {{calculatePercentComplete(section.completedQuestions, section.totalQuestions) | percent : '1.1-2'}}
                            <small class="ml-1">({{section.completedQuestions}}/{{section.totalQuestions}})</small>
                        </td>
                        <td class="text-center" *ngIf="!section.totalQuestions">---</td>

                        <td class="text-center" *ngIf="section.nrfs">{{section.nrfs | number : '1.0-0'}}</td>
                        <td class="text-center" *ngIf="!section.nrfs"><i class="fa fa-fw fa-times text-danger"></i></td>

                        <td class="text-center" *ngIf="section.observations">{{section.observations | number : '1.0-0'}}</td>
                        <td class="text-center" *ngIf="!section.observations"><i class="fa fa-fw fa-times text-danger"></i></td>
                        <td>
                            <ul class="list-unstyled mb-1" *ngIf="section.assignedTeamMembers && section.assignedTeamMembers.length > 0">
                                <li class="mb-1" *ngFor="let teamMember of section.assignedTeamMembers">{{teamMember.fullName}}</li>
                            </ul>
                            <button *ngIf="section.inspectionStatus == 1" type="button" class="btn btn-link assign-team-member" (click)="showAssignSectionModal(section)">
                                Assign&hellip;
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <grid-pager [actionName]="'../inspections'" [page]="page" [limit]="limit" [pageCount]="pageCount" [limits]="pageLimitOptions"></grid-pager>
    </div>

    <div class="col" *ngIf="groupBy == 'question'">
        <p class="text-nowrap">
            <b>{{rowCount}}</b> {{(rowCount == 1 ? 'record' : 'records')}} found from <b>{{engagementsCount}}</b> {{(engagementsCount == 1 ? 'inspection' : 'inspections')}}
        </p>

        <grid-pager [actionName]="'../inspections'" [page]="page" [limit]="limit" [pageCount]="pageCount" [limits]="pageLimitOptions"></grid-pager>

        <div class="">
            <table id="engagement-questions-grid" class="table table-striped my-2">
                <thead>
                    <tr class="headers-row">
                        <th style="width: 6rem; padding: 3px;">
                            <div class="dropdown">
                                <button class="btn btn-warning dropdown-toggle text-nowrap" type="button" id="engagement-actions" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Action
                                </button>
                                <div class="dropdown-menu" aria-labelledby="engagement-actions">
                                    <button class="dropdown-item text-nowrap" type="button" (click)="selectAll()">
                                        Select all
                                    </button>
                                    <div class="dropdown-divider"></div>
                                    <button class="dropdown-item text-nowrap" type="button" (click)="showSetStatusModal()" [disabled]="!hasSelections">
                                        Set status&hellip;
                                    </button>
                                </div>
                            </div>
                        </th>
                        <th style="width: 10rem;">Engagement Code</th>
                        <th style="width: 10rem;">Engagement Status</th>
                        <th>Section</th>
                        <th>Name</th>
                        <th style="width: 8rem;">Status</th>
                        <th style="width: 8rem;">Comments?</th>
                    </tr>
                    <tr class="filters-row">
                        <td>
                            <button type="button" class="btn btn-sm btn-block btn-secondary text-nowrap" (click)="clearSecondaryFilters()">
                                <i class="fa fa-times"></i>
                                Clear
                            </button>
                        </td>
                        <td><input type="text" class="form-control form-control-sm" placeholder="Filter by..." [(ngModel)]="engagementCodes" (change)="updateFilters('code', engagementCodes, true)" /></td>
                        <td>
                            <div ngbDropdown autoClose="outside" (openChange)="updateInspectionStatusFilters($event)">
                                <button class="btn btn-sm btn-block btn-faux-textbox d-flex text-left caret-off" id="engagement-status-filters" ngbDropdownToggle>
                                    <span class="selected-status-label text-truncate d-inline-block" style="min-width: 8rem; max-width: 8rem;">{{inspectionStatusesLabel}}</span>
                                </button>
                                <div ngbDropdownMenu aria-labelledby="engagement-status-filters">
                                    <div class="dropdown-item px-3 text-nowrap" *ngFor="let option of engagementStatuses; index as i">
                                        <div class="form-check" *ngIf="option.status > 0">
                                            <input [(ngModel)]="engagementStatuses[i].isSelected" type="checkbox" [value]="option.isSelected" id="engagement-status-{{i}}" class="form-check-input" />
                                            <label for="engagement-status-{{i}}" class="form-check-label">{{option.title}}</label>
                                        </div>
                                    </div>
                                    <div class="dropdown-divider"></div>
                                    <div class="dropdown-item px-3 text-nowrap">
                                        <button type="button" class="btn btn-sm btn-link p-0 m-0" (click)="clearInspectionStatusFilters()">
                                            <i class="fa fa-times"></i>
                                            Clear
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td><input type="text" class="form-control form-control-sm" placeholder="Filter by..." [(ngModel)]="sectionNameFilter" (change)="updateFilters('sectionName', sectionNameFilter, true)" /></td>
                        <td><input type="text" class="form-control form-control-sm" placeholder="Filter by..." [(ngModel)]="questionNameFilter" (change)="updateFilters('questionName', questionNameFilter, true)" /></td>
                        <td>
                            <div ngbDropdown autoClose="outside" (openChange)="updateProgressStatusFilters($event)">
                                <button class="btn btn-sm btn-block btn-faux-textbox d-flex text-left caret-off" id="engagement-question-status-filters" ngbDropdownToggle>
                                    <span class="selected-status-label text-truncate d-inline-block" style="min-width: 8rem; max-width: 8rem;">{{progressStatusesLabel}}</span>
                                </button>
                                <div ngbDropdownMenu aria-labelledby="engagement-question-status-filters">
                                    <div class="dropdown-item px-3 text-nowrap" *ngFor="let option of progressStatuses; index as i">
                                        <div class="form-check" *ngIf="option.status > 0">
                                            <input [(ngModel)]="progressStatuses[i].isSelected" type="checkbox" [value]="option.isSelected" id="engagement-question-status-{{i}}" class="form-check-input" />
                                            <label for="engagement-question-status-{{i}}" class="form-check-label">{{option.title}}</label>
                                        </div>
                                    </div>
                                    <div class="dropdown-divider"></div>
                                    <div class="dropdown-item px-3 text-nowrap">
                                        <button type="button" class="btn btn-sm btn-link p-0 m-0" (click)="clearProgressStatusFilters()">
                                            <i class="fa fa-times"></i>
                                            Clear
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <select id="question-has-comment" name="question-has-comment" class="custom-select custom-select-sm" [(ngModel)]="hasCommentsFilter" (change)="updateFilters('hasComments', hasCommentsFilter, true)">
                                <option [ngValue]="null">Any</option>
                                <option *ngFor="let option of yesNoOptions" [value]="option.value">{{option.name}}</option>
                            </select>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="activeEngagementQuestions.length == 0">
                        <td colspan="9">
                            <span class="d-block p-2 text-center">
                                <i class="fa fa-ban text-danger"></i>
                                No data to display&hellip;
                            </span>
                        </td>
                    </tr>
                    <tr *ngFor="let question of activeEngagementQuestions">
                        <td class="text-center">
                            <input type="checkbox" value="true" [(ngModel)]="question.isSelected" />
                        </td>
                        <td class="text-nowrap">
                            <a [routerLink]="['../inspection']" [queryParams]="{ id: question.engagementId }" class="engagment-link">
                                {{question.engagementCode}}
                            </a>
                        </td>
                        <td class="text-nowrap">{{getEngagementStatusName(question.inspectionStatus)}}</td>
                        <td>
                            <a [routerLink]="['../inspection']" [queryParams]="{ id: question.engagementId, sid: question.sectionId }" class="section-link">
                                {{question.sectionName}}
                            </a>
                        </td>
                        <td>
                            <a [routerLink]="['../inspection']" [queryParams]="{ id: question.engagementId, sid: question.sectionId, qid: question.questionId }" class="question-link">
                                {{question.questionName}}
                            </a>
                        </td>
                        <td class="text-nowrap">{{getProgressStatusName(question.progressStatus)}}</td>
                        <td class="text-center">
                            <span *ngIf="!question.hasResponse">---</span>
                            <span *ngIf="question.hasResponse && question.isNrf" class="fa fa-fw fa-check-circle text-success"></span>
                            <span *ngIf="question.hasResponse && !question.isNrf" class="fa fa-fw fa-times text-danger"></span>
                        </td>
                        <td class="text-center">
                            <span *ngIf="!question.hasResponse">---</span>
                            <span *ngIf="question.hasResponse && question.hasObservations" class="fa fa-fw fa-check-circle text-success"></span>
                            <span *ngIf="question.hasResponse && !question.hasObservations" class="fa fa-fw fa-times text-danger"></span>
                        </td>
                        <td class="text-center">
                            <span *ngIf="question.hasComments" class="fa fa-fw fa-check-circle text-success"></span>
                            <span *ngIf="!question.hasComments" class="fa fa-fw fa-times text-danger"></span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <grid-pager [actionName]="'../inspections'" [page]="page" [limit]="limit" [pageCount]="pageCount" [limits]="pageLimitOptions"></grid-pager>
    </div>
</div>

<ng-template #teamMembersModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="modal-basic-title">Assign Team Member(s)</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p class="form-text mb-1">
            Please select one or more team members to assign to:
        </p>
        <p class="lead">
            <i>{{currentSection.sectionName}}</i>
        </p>
        <div class="form-group">
            <div class="form-check" *ngFor="let teamMember of teamMembers; index as i">
                <input class="form-check-input" type="checkbox" [value]="true" [(ngModel)]="teamMember.isSelected" id="team-member-{{i}}" name="team-member-{{i}}">
                <label class="form-check-label" for="team-member-{{i}}">
                    {{teamMember.fullName}}
                </label>
            </div>
        </div>
        <div class="form-group" *ngIf="currentSection.hasChildSections">
            <label>Section contains one or more child sections. Assign child sections as well?</label>
            <div class="form-check">
                <input type="checkbox" value="true" class="form-check-input" name="assign-child-sections" id="assign-child-sections" [(ngModel)]="assignChildSections" />
                <label class="form-check-label" for="assign-child-sections">Yes, assign all child sections</label>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="modal.close('save')">Save</button>
        <button type="button" class="btn btn-secondary" (click)="modal.close('cancel')">Cancel</button>
    </div>
</ng-template>


<ng-template #setStatusModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="modal-basic-title">Set Engagement Status</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p class="form-text mb-1">
            Please select a status to assign to the selected engagements:
        </p>
        <div class="form-group">
            <div class="form-check" *ngFor="let status of engagementStatusOptions; index as i">
                <input class="form-check-input" type="radio" [value]="status.status" [(ngModel)]="selectedEngagementStatus" id="engagement-status-option-{{i}}" name="engagement-status" />
                <label class="form-check-label" for="engagement-status-option-{{i}}">
                    {{status.title}}
                </label>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="modal.close('save')">Save</button>
        <button type="button" class="btn btn-secondary" (click)="modal.close('cancel')">Cancel</button>
    </div>
</ng-template>
