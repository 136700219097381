import { Component, OnInit } from '@angular/core';
import { UnitOfWorkService } from '../../services/unit-of-work/unit-of-work.service';
import { ApplicationInsightsService } from '../../services/application-insights/application-insights.service';
import { Observable } from 'rxjs';
import { DialogHelper } from '../../services/dialog-helper';
import { IsBusyService } from '../../services/is-busy/is-busy.service';
import * as model from '../../../model/model';
import { GlobalErrorHandler } from '../../services/global-error-handler';

@Component({
    selector: 'app-industries',
    templateUrl: './industries.component.html',
})
export class IndustriesAdminComponent implements OnInit {

    industries: model.Industry[] = [];
    
    newIndustryName: string = null;


    get activeIndustries() {
        return this.industries.filter(m => !m.entityAspect.entityState.isDeleted() && !m.entityAspect.entityState.isDetached()).sort((a, b) => { return a.name.localeCompare(b.name); });
    }


    constructor(
		private uow: UnitOfWorkService,
        private appInsights: ApplicationInsightsService,
        private errorHandler: GlobalErrorHandler,
		public isBusy: IsBusyService,
		public dialogHelper: DialogHelper
	) {
        appInsights.logPageView();
    }

    ngOnInit() {
        this.isBusy.set('admin:loadData', true, 'Loading data');
        Promise.all([
            this.getIndustries()
        ])
        .catch(reason => {
            this.errorHandler.handleError(reason);
        })
        .finally(() => {
            this.isBusy.set('admin:loadData', false);
            });
    }

    canDeactivate(): Observable<boolean> | boolean {
        if (this.uow.hasChanges.value) {
            let proceed = this.dialogHelper.confirm();

            proceed.subscribe(result => {
                if (result) {
                    this.uow.rollback();
                }
            })

            return proceed;
        }

        return true;
    }


    getIndustries() {
        return this.uow.getIndustries(true)
            .then(result => {
                this.industries = result;
            });
    }


    saveIndustryChanges(industry: model.Industry, message: string) {
        this.isBusy.set('admin:saveIndustry', true, message);
        this.uow.industries.saveChanges([industry])
            .then(() => {
                this.newIndustryName = null;
            })
            .catch(reason => {
                this.errorHandler.handleError(reason);
            })
            .finally(() => {
                this.isBusy.set('admin:saveIndustry', false);
            });
    }

    createIndustry() {
        if (this.newIndustryName) {
            const newIndustry = this.uow.industries.createEntity();
            newIndustry.name = this.newIndustryName.trim();
            this.industries.push(newIndustry);

            this.saveIndustryChanges(newIndustry, 'Saving industry');
        }
    }

    updateIndustry(industry: model.Industry) {
            this.saveIndustryChanges(industry, 'Updating industry');
    }

    destroyIndustry(industry: model.Industry) {
        if (industry) {
            if (confirm('Are you sure you want to remove "' + industry.name + '"?')) {
                this.uow.industries.cancelChanges(industry);
                industry.entityAspect.setDeleted();

                this.saveIndustryChanges(industry, 'Removing industry "' + industry.name + '"');
            }
        }
    }
}
