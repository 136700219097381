<div class="header">
    <h3>Admin</h3>
</div>
<div class="row">
    <div class="col tab-menu">
        <admin-nav-menu [currentTab]="'impersonate'"></admin-nav-menu>
    </div>

    <div class="col col-constrained">
        <fieldset>
            <legend>Impersonate</legend>
            <span>
                To impersonate a role, please copy the URL and paste it in a new tab or window.
            </span>
            <table class="table table-sm my-3">
                <thead>
                    <tr>
                        <th style="width: 30%;">Type of Role</th>
                        <th>URL</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <span>
                                Basic
                            </span>
                        </td>
                        <td>
                            <div class="input-group">
                                <input class="form-control" type="text" [ngModel]="basic" #basicTarget readonly />
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary btn-xs" [ngxClipboard]="basicTarget" (cbOnSuccess)="log($event, 'Basic')" title="Copy to clipboard">
                                        <i class="fa fa-copy"></i>
                                    </button>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span>
                                Admin
                            </span>
                        </td>
                        <td>
                            <div class="input-group">
                                <input class="form-control" type="text" [ngModel]="admin" #adminTarget readonly />
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary btn-xs" [ngxClipboard]="adminTarget" (cbOnSuccess)="log($event, 'Admin')" title="Copy to clipboard">
                                        <i class="fa fa-copy"></i>
                                    </button>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </fieldset>
    </div>
</div>
