<div class="header">
    <h3>Engagement Review Questionnaire Management</h3>
</div>
<div class="row">
    <div class="col tab-menu">
        <profile-nav-menu [currentTab]="'profile-questions'"></profile-nav-menu>
    </div>

    <div class="col form-wrapper form-wrapper-constrained">
        <h4>Manage Question</h4>
        <form #questionForm="ngForm">
            <fieldset [disabled]="isLoading">
                <div class="mt-2 mb-3">
                    <button type="submit"
                            class="btn btn-primary mr-2"
                            [disabled]="!questionForm.valid"
                            (click)="saveChanges()">
                        <i class="fa fa-save"></i>
                        Save
                    </button>
                    <button type="button"
                            class="btn btn-secondary mr-2"
                            (click)="goHome()">
                        <i class="fa fa-times"></i>
                        Cancel
                    </button>
                    <small class="form-text text-muted d-inline-flex">Please note: <b class="required px-2">*</b> indicates a required field</small>
                </div>
                <hr />
                <p>
                    <small class="form-text text-muted">Can be left empty if creating an unassigned question</small>
                </p>
                <div class="form-group row">
                    <label class="form-label col-sm-2">Questionnaire</label>
                    <div class="col">
                        <ng-select [items]="activeInspectionProfiles"
                                   [virtualScroll]="true"
                                   bindLabel="name"
                                   bindValue="id"
                                   placeholder="Select one..."
                                   id="question-profile-id"
                                   name="question-profile-id"
                                   [disabled]="wasPreviouslyUnassigned ? false : question.id > 0 && question.profileSectionId != null"
                                   [(ngModel)]="inspectionProfileId"
                                   (change)="getSectionsForProfile()">
                            <ng-template ng-option-tmp let-item="item" let-index="index">
                                {{item.name}}
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="form-label col-sm-2">Section</label>
                    <div class="col">
                        <ng-select [items]="activeProfileSections"
                                   [virtualScroll]="true"
                                   bindLabel="name"
                                   bindValue="id"
                                   placeholder="Select one..."
                                   id="question-section-id"
                                   name="question-section-id"
                                   [disabled]="wasPreviouslyUnassigned ? false : question.id > 0 && question.profileSectionId != null"
                                   [(ngModel)]="question.profileSectionId"
                                   (change)="getProfileSectionsForSection()">
                            <ng-template ng-option-tmp let-item="item" let-index="index">
                                {{item.name}}
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
                <hr />
                <div class="form-group row">
                    <label class="form-label col-sm-2">Title</label>
                    <div class="col">
                        <input type="text"
                               class="form-control"
                               name="question-name"
                               [(ngModel)]="question.name"
                               [disabled]="question == null" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="form-label col-sm-2 required">Text</label>
                    <div class="col">
                        <textarea type="text"
                                  class="form-control text-editor text-editor-md"
                                  name="question-description"
                                  required
                                  [(ngModel)]="question.description"
                                  [disabled]="question == null"></textarea>
                        <small class="form-text text-danger validation-message">Question text is required.</small>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="form-label col-sm-2">Help text</label>
                    <div class="col">
                        <textarea type="text"
                                  class="form-control text-editor text-editor-md"
                                  name="question-help-text"
                                  [(ngModel)]="question.helpText"
                                  [disabled]="question == null"></textarea>
                        <small class="form-text text-danger validation-message">Help text is invalid.</small>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="form-label col-sm-2">Options</label>
                    <div class="col">
                        <div class="form-group">
                            <button type="button"
                                    class="btn btn-primary"
                                    (click)="addOption()"
                                    [disabled]="question == null">
                                <i class="fa fa-plus"></i>
                                Add option
                            </button>
                        </div>
                        <div>
                            <table class="table table-sm mb-0">
                                <thead>
                                    <tr>
                                        <th class="col-action"></th>
                                        <th style="width: 25%">Standard option</th>
                                        <th style="width: 25%">Text</th>
                                        <th style="width: 50%">Enables section</th>
                                        <th style="width: 50%">Enables questions with tag:</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngIf="activeQuestionOptions.length == 0">
                                        <td colspan="6">
                                            <span class="d-block p-1 text-center">
                                                <i class="fa fa-ban text-danger"></i>
                                                Question contains no options
                                            </span>
                                        </td>
                                    </tr>
                                    <tr *ngFor="let qo of activeQuestionOptions; index as i" [class.d-none]="qo.isDeleted">
                                        <td>
                                            <button type="button" class="btn btn-secondary" (click)="removeOption(qo)">
                                                <i class="fa fa-trash"></i>
                                                <span class="sr-only">Remove</span>
                                            </button>
                                        </td>
                                        <td>
                                            <select class="custom-select"
                                                    [(ngModel)]="qo.standardQuestionOptionId"
                                                    name="option-std-opt-{{i}}"
                                                    [required]="qo.text == null"
                                                    [disabled]="question == null">
                                                <option [ngValue]="null">Custom text</option>
                                                <option *ngFor="let option of standardOptions"
                                                        [value]="option.id">
                                                    {{option.value}}
                                                </option>
                                            </select>
                                            <small class="form-text text-danger validation-message">Select a standard option</small>
                                        </td>
                                        <td>
                                            <input type="text"
                                                   class="form-control"
                                                   placeholder="Enter text"
                                                   [(ngModel)]="qo.text"
                                                   name="option-text-{{i}}"
                                                   [disabled]="question == null || qo.standardQuestionOptionId != null"
                                                   [required]="qo.standardQuestionOptionId == null" />
                                            <small class="form-text text-danger validation-message">Required for custom text option</small>
                                        </td>
                                        <td>
                                            <div class="input-group">
                                                <select class="custom-select"
                                                        id="section-selector-{{i}}"
                                                        name="section-selector-{{i}}"
                                                        #selectedSection
                                                        [disabled]="!availableProfileSections || availableProfileSections.length == 0">
                                                    <option [ngValue]="null">Select one...</option>
                                                    <option *ngFor="let section of availableProfileSections"
                                                            [value]="section.id"
                                                            [disabled]="checkOption(qo.profileSectionQuestionOptions, section)">
                                                        {{section.name}}
                                                    </option>
                                                </select>
                                                <div class="input-group-append">
                                                    <button type="button"
                                                            class="btn btn-primary"
                                                            (click)="selectedSection.value = addTriggeredSection('#section-selector-'+i, qo)">
                                                        <i class="fa fa-plus"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <table class="table table-sm table-align-middle mb-1">
                                                <tr *ngFor="let section of qo.profileSectionQuestionOptions">
                                                    <td class="col-action">
                                                        <button type="button"
                                                                class="btn btn-sm btn-link text-danger p-0 m-0"
                                                                (click)="removeSectionFromOption('#section-selector-'+i, qo, section)">
                                                            <i class="fa fa-trash"></i>
                                                            <span class="sr-only">Remove</span>
                                                        </button>
                                                    </td>
                                                    <td>{{section.profileSection.name}}</td>
                                                </tr>
                                            </table>
                                        </td>
                                        <td>
                                            <div class="input-group">
                                                <select class="custom-select"
                                                        id="tag-selector-{{i}}"
                                                        name="tag-selector-{{i}}"
                                                        #selectedTag
                                                        [disabled]="!availableProfileSections || availableProfileSections.length == 0">
                                                    <option [ngValue]="null">Select one...</option>
                                                    <option *ngFor="let section of availableProfileSections"
                                                            [value]="section.id"
                                                            [disabled]="checkOption(qo.profileSectionQuestionOptions, section)">
                                                        {{section.name}}
                                                    </option>
                                                </select>
                                                <div class="input-group-append">
                                                    <button type="button"
                                                            class="btn btn-primary"
                                                            (click)="selectedSection.value = addTriggeredSection('#section-selector-'+i, qo)">
                                                        <i class="fa fa-plus"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <table class="table table-sm table-align-middle mb-1">
                                                <tr *ngFor="let section of qo.profileSectionQuestionOptions">
                                                    <td class="col-action">
                                                        <button type="button"
                                                                class="btn btn-sm btn-link text-danger p-0 m-0"
                                                                (click)="removeSectionFromOption('#section-selector-'+i, qo, section)">
                                                            <i class="fa fa-trash"></i>
                                                            <span class="sr-only">Remove</span>
                                                        </button>
                                                    </td>
                                                    <td>{{section.profileSection.name}}</td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="form-label col-sm-2">Tags</label>
                    <div class="col">
                        <div class="input-group mb-4">
                            <select class="custom-select editor-md" id="tag-selector" #selectedTag>
                                <option [ngValue]="null">Select one...</option>
                                <option *ngFor="let tag of tags" [value]="tag.id">
                                    {{tag.name}}
                                </option>
                            </select>
                            <div class="input-group-append">
                                <button type="button"
                                        class="btn btn-primary"
                                        (click)="selectedTag.value = addTag(selectedTag.value)">
                                    <i class="fa fa-plus"></i>
                                </button>
                            </div>
                        </div>
                        <ul class="list-inline">
                            <li *ngFor="let qt of activeQuestionTags" class="list-inline-item">
                                <span class="bdo-tag bdo-tag-dark">
                                    <button type="button" class="btn-none text-danger" (click)="removeTag(qt)"><i class="fa fa-times"></i></button>
                                    {{qt.tag.name}}
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </fieldset>
        </form>
    </div>
</div>
