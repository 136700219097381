<div class="header">
    <h3>Admin</h3>
</div>
<div class="row">
    <div class="col tab-menu">
        <admin-nav-menu [currentTab]="'industries'"></admin-nav-menu>
    </div>

    <div class="col col-constrained">
        <fieldset>
            <legend>Industries</legend>
            <label for="new-industry">Add new industry?</label>
            <div class="form-group">
                <input type="text"
                       id="new-industry"
                       [(ngModel)]="newIndustryName"
                       (keypress)="$event.keyCode == 13 ? createIndustry() : null"
                       class="form-control editor-lg"
                       placeholder="Enter new industry name" />
            </div>
            <button type="button" class="btn btn-primary" (click)="createIndustry()">
                <i class="fa fa-save"></i>
                Save
            </button>
            <table class="table table-sm table-align-middle my-3">
                <thead>
                    <tr>
                        <th class="col-action"></th>
                        <th class="col-action"></th>
                        <th class="col-action"></th>
                        <th style="width: 1rem;">Id</th>
                        <th>Name</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="activeIndustries.length == 0">
                        <td colspan="4">
                            <span class="d-block p-2 text-center">
                                <i class="fa fa-ban text-danger"></i>
                                No data to display&hellip;
                            </span>
                        </td>
                    </tr>
                    <tr *ngFor="let industry of activeIndustries; index as i">
                        <td class="text-center">
                            <button [disabled]="industry.engagementIndustry"
                                    type="button"
                                    class="btn btn-sm btn-secondary"
                                    (click)="destroyIndustry(industry)">
                                <i class="fa fa-trash"></i>
                                <span class="sr-only">Remove</span>
                            </button>
                        </td>
                        <td class="text-center">
                            <label class="switch" title="Enable/Disable" style="margin-top: 10px">
                                <input type="checkbox"
                                       id="new-industry"
                                       (change)="updateIndustry(industry)"
                                       [(ngModel)]="industry.isActive">
                                <span class="slider round"></span>
                            </label>
                        </td>
                        <td>
                            <button type="button"
                                    class="btn btn-sm btn-primary"
                                    (click)="updateIndustry(industry)">
                                <i class="fa fa-save"></i>
                                <span class="sr-only">Save</span>
                            </button>
                        </td>
                        <td class="px-3 font-weight-bold">{{industry.id}}</td>
                        <td>
                            <input type="text" class="form-control form-control-sm editor-lg d-inline-block mr-2" name="industry-{{i}}" [(ngModel)]="industry.name" required />
                            <small class="form-text validation-message">Name is required.</small>
                        </td>
                    </tr>
                </tbody>
            </table>
        </fieldset>
    </div>
</div>
