import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { EntityManagerProviderService } from '../../services//entity-manager-provider/entity-manager-provider.service';
import { ServerSideConfigDataService } from '../../services/server-side-config-data/server-side-config-data.service';
import { UnitOfWorkService } from '../../services/unit-of-work/unit-of-work.service';

@Injectable({
    providedIn: 'root'
})
export class BreezeSetupGuard implements CanActivate {
    constructor(
        private serverSide: ServerSideConfigDataService,
        private entityManagerProvider: EntityManagerProviderService,
        private uowService: UnitOfWorkService,
    ) { }

    async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return this.serverSide
            .loadServerSideData()
            .then(() => this.entityManagerProvider.init())
            .then(() => {
                this.uowService.init();
                return true;
            });
    }

}
