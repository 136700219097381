<div class="header">
    <h3>Inspection Code Setup</h3>
</div>
<div class="row">
    <div class="col-md-2">
        <div class="mb-3">
            <a [routerLink]="['../edit']" [queryParams]="{ id: 'new' }" class="btn btn-primary text-nowrap">
                <i class="fa fa-plus"></i>
                Create
            </a>

            <!--<button type="button" class="btn btn-warning ml-2" (click)="runCleanup()">
                <i class="fa fa-database"></i> Run Cleanup Now
            </button>-->
        </div>
    </div>
    <div class="form-group col-md-2 mb-3">
        <label for="inspectionYear" class="ml-2">Inspection Year </label>
        <select id="inspectionYear" name="inspectionYear" class="custom-select ml-2" [(ngModel)]="inspectionYear" (change)="yearDropdownChange($event)">
            <option [value]="0">Select All</option>
            <option *ngFor="let year of inspectionYears" [value]="year">{{year}}</option>
        </select>
        </div>
    </div>

    <grid-pager [actionName]="'../engagements'" [page]="page" [limit]="limit" [pageCount]="pageCount" [limits]="pageLimitOptions"></grid-pager>

    <div class="">
        <table id="engagement-grid" class="table table-striped table-align-middle my-2">
            <thead>
                <tr class="headers-row">
                    <th class="col-action"></th>
                    <th style="width: 8rem;">Code</th>
                    <th style="width: 25%;">Name</th>
                    <th style="width: 20rem;">Team Captain</th>
                    <th style="width: 14rem;">Office</th>
                    <th style="width: 6rem;">Region</th>
                    <th style="width: 6rem;">Year</th>
                    <!--<th style="width: 10rem;">Progress</th>-->
                    <th style="width: 8rem;">Status</th>
                </tr>
                <tr class="filters-row">
                    <td>
                        <button type="button" class="btn btn-sm btn-block btn-secondary text-nowrap" (click)="clearFilters()">
                            <i class="fa fa-times"></i>
                            Clear
                        </button>
                    </td>
                    <td><input type="text" class="form-control form-control-sm" placeholder="Filter by..." [(ngModel)]="codeFilter" (change)="updateFilters('code', codeFilter)" /></td>
                    <td><input type="text" class="form-control form-control-sm" placeholder="Filter by..." [(ngModel)]="nameFilter" (change)="updateFilters('name', nameFilter)" /></td>
                    <td><input type="text" class="form-control form-control-sm" placeholder="Filter by..." [(ngModel)]="inspectorFilter" (change)="updateFilters('inspectionLead', inspectorFilter)" /></td>
                    <td><input type="text" class="form-control form-control-sm" placeholder="Filter by..." [(ngModel)]="officeFilter" (change)="updateFilters('office', officeFilter)" /></td>
                    <td><input type="text" class="form-control form-control-sm" placeholder="Filter by..." [(ngModel)]="regionFilter" (change)="updateFilters('region', regionFilter)" /></td>
                    <td><input type="text" class="form-control form-control-sm" placeholder="Filter by..." [(ngModel)]="yearFilter" (change)="updateFilters('year', yearFilter)" /></td>
                    <!--<td></td>-->
                    <td>
                        <div ngbDropdown autoClose="outside" [placement]="['bottom-left', 'bottom-right', 'top-left', 'top-right']" (openChange)="updateStatusFilters($event)">
                            <button class="btn btn-sm btn-block btn-faux-textbox d-flex text-left caret-off"
                                    id="engagement-status-filters"
                                    ngbDropdownToggle>
                                <span class="selected-status-label text-truncate d-inline-block" style="min-width: 8rem; max-width: 8rem;">{{engagementStatusesLabel}}</span>
                            </button>
                            <div ngbDropdownMenu aria-labelledby="engagement-status-filters">
                                <div class="dropdown-item px-3 text-nowrap" *ngFor="let option of statuses; index as i">
                                    <div class="form-check">
                                        <input [(ngModel)]="statuses[option.status].isSelected"
                                               type="checkbox"
                                               [value]="option.isSelected"
                                               id="engagement-status-{{i}}"
                                               class="form-check-input" />
                                        <label for="engagement-status-{{i}}" class="form-check-label">{{option.title}}</label>
                                    </div>
                                </div>
                                <div class="dropdown-divider"></div>
                                <div class="dropdown-item px-3 text-nowrap">
                                    <button type="button" class="btn btn-sm btn-link p-0 m-0" (click)="clearStatusFilters()">
                                        <i class="fa fa-times"></i>
                                        Clear
                                    </button>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr *ngIf="activeEngagementsDto.length == 0">
                    <td colspan="11">
                        <span class="d-block p-2 text-center" *ngIf="!isLoading">
                            <i class="fa fa-ban text-danger"></i>
                            No data to display&hellip;
                        </span>
                        <span class="d-block p-2 text-center" *ngIf="isLoading">
                            <i class="fa fa-spin fa-refresh text-primary"></i> Please wait while we load your inspections&hellip;
                        </span>
                        <span class="d-block p-2 text-center" *ngIf="isLockedForCleanup">
                            <i class="fa fa-spin fa-refresh text-primary"></i>
                            Please wait while we process your inspections &mdash; table will reload when process is complete.
                        </span>
                    </td>
                </tr>
                <tr *ngFor="let engagementDto of activeEngagementsDto">
                    <td>
                        <div class="btn-group btn-group-sm">
                            <a class="btn btn-primary mr-1" [routerLink]="['../edit']" [queryParams]="{ id: engagementDto.engagement.id }">
                                <i class="fa fa-pencil"></i>
                                <span class="sr-only">Edit</span>
                            </a>
                            <button *ngIf="canRemoveEngagement(engagementDto.engagement)"
                                    type="button"
                                    class="btn btn-secondary"
                                    (click)="destroyEngagement(engagementDto.engagement)">
                                <i class="fa fa-trash"></i>
                                <span class="sr-only">Remove</span>
                            </button>
                        </div>
                    </td>
                    <td>{{engagementDto.engagement.code}}</td>
                    <td>{{engagementDto.engagement.client.name}}</td>
                    <td>{{engagementDto.engagement.leadInspector()}}</td>
                    <td>{{engagementDto.engagement.officeId}} - {{engagementDto.engagement.office.name}}</td>
                    <td>{{engagementDto.engagement.regionCode}}</td>
                    <td class="text-right">{{engagementDto.engagement.inspectionYear}}</td>
                    <!--<td class="text-center" *ngIf="engagementDto.totalSections == 0">---</td>
                    <td class="text-center" *ngIf="engagementDto.totalSections > 0">{{calculatePercentComplete(engagementDto) | percent : '1.1-2'}}</td>-->
                    <td>{{getStatusName(engagementDto.engagement.status)}}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <grid-pager [actionName]="'../engagements'" [page]="page" [limit]="limit" [pageCount]="pageCount" [limits]="pageLimitOptions"></grid-pager>

<ng-template #confirmDeleteModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="modal-basic-title">
            <i class="fa fa-exclamation-triangle text-danger"></i>
            Please Confirm
        </h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>{{ confirmMessage }}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="modal.close('confirm')">Confirm</button>
        <button type="button" class="btn btn-secondary" (click)="modal.close('cancel')">Cancel</button>
    </div>
</ng-template>

<ng-template #confirmCleanupStepOneModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="modal-basic-title">
            <i class="fa fa-exclamation-triangle text-danger"></i>
            Please Confirm
        </h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>
            Use this tool to correct any inspections which may appear to be out of sync with
            their source engagement review questionnaires.
        </p>
        <h5>Warning:</h5>
        <p>
            Using this option will potentially remove sections from active inspections, if they are deemed to be
            duplicates or deleted from the source questionnaire.
        </p>
        <p><b>Continue?</b> You will be asked to confirm once more before cleanup proceeds.</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="modal.close('confirm')">Confirm</button>
        <button type="button" class="btn btn-secondary" (click)="modal.close('cancel')">Cancel</button>
    </div>
</ng-template>

<ng-template #confirmCleanupStepTwoModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="modal-basic-title">
            <i class="fa fa-exclamation-triangle text-danger"></i>
            Confirm Inspection Cleanup
        </h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>
            <b>Proceed with inspection cleanup?</b>
        </p>
        <p>
            Use the <b>Confirm</b> option below to continue, or use the <b>Cancel</b> option
            to abort this action.
        </p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="modal.close('confirm')">Confirm</button>
        <button type="button" class="btn btn-secondary" (click)="modal.close('cancel')">Cancel</button>
    </div>
</ng-template>
