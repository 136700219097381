<div class="header">
    <h3>Admin</h3>
</div>
<div class="row">
    <div class="col tab-menu">
        <admin-nav-menu [currentTab]="'standard-question-options'"></admin-nav-menu>
    </div>

    <div class="col col-constrained">
        <fieldset>
            <legend>Standard Question Options</legend>
            <div class="form-group">
                <label for="new-standard-option-value">Option value</label>
                <input type="text"
                        id="new-standard-option-value"
                        [(ngModel)]="newOptionValue"
                        class="form-control editor-sm"
                        placeholder="Option value" />
            </div>
            <div class="form-group">
                <label for="new-standard-option-name">Description</label>
                <textarea class="form-control text-editor-sm"
                            id="new-standard-option-name"
                            placeholder="Short description of option"
                            [(ngModel)]="newOptionName"></textarea>
            </div>
            <button type="button" class="btn btn-primary" (click)="createQuestionOption()">
                <i class="fa fa-save"></i>
                Save
            </button>
            <table class="table table-sm my-3">
                <thead>
                    <tr>
                        <th colspan="5"></th>
                        <th colspan="6">Associated Answer Set</th>

                    </tr>
                    <tr>
                        <th class="col-action"></th>
                        <th class="col-action"></th>
                        <th style="width: 1rem;">Id</th>
                        <th style="width: 16rem;">Name</th>
                        <th>Description</th>
                        <th class="text-center" style="width: 2rem;">A</th>
                        <th class="text-center" style="width: 2rem;">B</th>
                        <th class="text-center" style="width: 2rem;">C</th>
                        <th class="text-center" style="width: 2rem;">D</th>
                        <th class="text-center" style="width: 2rem;">E</th>
                        <th class="text-center" style="width: 2rem;">F</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="activeStandardOptions.length == 0">
                        <td colspan="5">
                            <span class="d-block p-2 text-center">
                                <i class="fa fa-ban text-danger"></i>
                                No data to display&hellip;
                            </span>
                        </td>
                    </tr>
                    <tr *ngFor="let option of activeStandardOptions; index as i">
                        <td class="text-center">
                            <button [disabled]="option.questionOptions.length > 0 || option.engagementQuestionOptions.length > 0 || option.name === noAnswerNeededName"
                                    type="button"
                                    class="btn btn-sm btn-secondary"
                                    (click)="destroyQuestionOption(option)">
                                <i class="fa fa-trash"></i>
                                <span class="sr-only">Remove</span>
                            </button>
                        </td>
                        <td class="text-center">
                            <button type="button"
                                    class="btn btn-sm btn-primary"
                                    [disabled] = "option.name === noAnswerNeededName"
                                    (click)="updateQuestionOption(option)">
                                <i class="fa fa-save"></i>
                                <span class="sr-only">Save</span>
                            </button>
                        </td>
                        <td class="px-3 font-weight-bold">{{option.id}}</td>
                        <td>
                            <input type="text"
                                   class="form-control form-control-sm d-inline-block mr-2"
                                   name="option-value-{{i}}"
                                   [(ngModel)]="option.value"
                                   [disabled] = "option.name === noAnswerNeededName"
                                   required />
                            <small class="form-text validation-message">Name is required.</small>
                        </td>
                        <td>
                            <textarea class="form-control form-control-sm text-editor-sm"
                                      name="option-name-{{i}}"
                                      placeholder="Short description of option"
                                      [disabled]="option.name === noAnswerNeededName"
                                      [(ngModel)]="option.name"></textarea>
                        </td>
                        <td *ngFor="let answerSet of answerSetOptions[option.id]; index as i">
                            <input type="checkbox"
                                   id="{{'answer-set-' + answerSet.Key}}"
                                   [disabled]="option.name === noAnswerNeededName"
                                   [(ngModel)]="answerSet.Value" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </fieldset>
    </div>
</div>
