import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UnitOfWorkService } from '../../../services/unit-of-work/unit-of-work.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ApplicationInsightsService } from '../../../services/application-insights/application-insights.service';
import { Observable } from 'rxjs';
import { DialogHelper } from '../../../services/dialog-helper';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { IsBusyService } from '../../../services/is-busy/is-busy.service';
import * as model from '../../../../model/model';
import * as modelDto from '../../../../model/modelDto';
import * as modelEnum from '../../../../model/modelEnums';
import { GlobalErrorHandler } from '../../../services/global-error-handler';

const loadingKey: string = 'engagements:loadData';
const saveKey: string = 'engagements:saveInspectionProfile';
const defaultLimit: number = 15;

@Component({
    selector: 'app-engagement-admin',
    templateUrl: './engagements.component.html',
})
export class EngagementsComponent implements OnInit {
    @ViewChild('confirmDeleteModal', { static: true }) confirmDeleteModal: TemplateRef<any>; // Note: TemplateRef
    @ViewChild('confirmCleanupStepOneModal', { static: true }) confirmCleanupStepOneModal: TemplateRef<any>; // Note: TemplateRef
    @ViewChild('confirmCleanupStepTwoModal', { static: true }) confirmCleanupStepTwoModal: TemplateRef<any>; // Note: TemplateRef

    isLoading: boolean = false;
    isLockedForCleanup: boolean = false;

    //engagements: model.Engagement[] = [];
    engagementDtos: modelDto.AllEngagementsSummaryDto[] = [];
    page: number = 1;
    limit: number = defaultLimit;
    pageCount: number = 1;
    pageLimitOptions = [15, 25, 50, 100];

    filters: modelDto.GridFilter[] = [];
    codeFilter: string = null;
    nameFilter: string = null;
    officeFilter: string = null;
    regionFilter: string = null;
    yearFilter: string = null;
    weekFilter: string = null;
    businessLineFilter: string = null;
    industryFilter: string = null;
    inspectorFilter: string = null;
    inspectionYears: number[] = [];
    inspectionYear: number = new Date().getFullYear();

    engagementStatusesLabel: string = 'Filter by...';
    engagementStatuses: modelEnum.EngagementStatus[] = [];

    confirmMessage: string = null;

    constructor(private uow: UnitOfWorkService,
        private appInsights: ApplicationInsightsService,
        private errorHandler: GlobalErrorHandler,
        private activeRoute: ActivatedRoute,
        public router: Router,
		public isBusy: IsBusyService,
        public dialogHelper: DialogHelper,
        private modalService: NgbModal) {
        appInsights.logPageView();
    }

    ngOnInit() {
        this.activeRoute.queryParams.subscribe(routeParams => {
            this.page = routeParams.page && !Number.isNaN(routeParams.page) ? parseInt(routeParams.page) : 1;
            this.limit = routeParams.limit && !Number.isNaN(routeParams.limit) ? parseInt(routeParams.limit) : defaultLimit;
            this.getInspectionYears();
            this.updateFilters('year', this.inspectionYear);
        });
    }

    canDeactivate(): Observable<boolean> | boolean {
        if (this.uow.hasChanges.value) {
            const proceed = this.dialogHelper.confirm();

            proceed.subscribe(result => {
                if (result) {
                    this.uow.rollback();
                }
            })

            return proceed;
        }

        return true;
    }


    get activeEngagementsDto() {
        return this.engagementDtos
            .sort((a, b) => { return a.engagement.client.name.localeCompare(b.engagement.client.name); });
    }

    get statuses() {
        return this.uow.engagementStatuses;
    }

    getInspectionYears() {
        return this.uow.getInspectionYears()
            .then(result => {
                this.inspectionYears = result;
            });
    }

    public yearDropdownChange(event) {
        if (event.target.value == 0) {
            this.removeFilter('year');
            this.updateDataGrid();
            return;
        }
        this.updateFilters('year', event.target.value);      
    }


    removeFilter(key: string) {
        const i = this.filters.findIndex(m => m.key == key);
        if (i >= 0) {
            this.filters.splice(i, 1);
        }
    }

    updateStatusFilters(e: any) {
        if (e == false) {
            if (this.statuses.some(m => m.isSelected)) {
                this.engagementStatusesLabel = this.statuses.filter(m => m.isSelected).map(m => m.title).join(', ');
                this.updateFilters('status', this.statuses.filter(m => m.isSelected).map(m => m.status).join(','));
            }
            else {
                this.engagementStatusesLabel = 'Filter by...';
                this.updateFilters('status', null);
            }
        }
    }

    clearStatusFilters() {
        this.statuses.filter(m => m.isSelected = false);
    }

    updateFilters(key: string, value: any) {
        if (this.filters.some(m => m.key == key)) {
            const filter = this.filters.find(m => m.key == key);
            filter.value = value;
        }
        else {
            this.filters.push({ key: key, value: value } as modelDto.GridFilter)
        }

        this.updateDataGrid();
    }

    clearFilters() {
        this.filters = [];
        this.codeFilter = null;
        this.nameFilter = null;
        this.officeFilter = null;
        this.regionFilter = null;
        this.yearFilter = null;
        this.weekFilter = null;
        this.engagementStatusesLabel = null;
        this.businessLineFilter = null;
        this.industryFilter = null;
        this.inspectorFilter = null;
        this.inspectionYear = 0;

        this.updateDataGrid();
    }

    updateDataGrid() {
        this.isBusy.set(loadingKey, true, 'Loading data');
        Promise
            .all([
                this.getPageCount(this.limit),
                this.getEngagements(this.page, this.limit)])
            .catch(reason => {
                this.errorHandler.handleError(reason);
            })
            .finally(() => {
                this.isBusy.set(loadingKey, false);
            });
    }

    getStatusName(status: modelEnum.EngagementStatus) {
        return this.uow.getEngagementStatusName(status);
    }

    getRatingName(rating: modelEnum.EngagementRating) {
        return this.uow.getEngagementRatingName(rating);
    }

    getEngagements(page: number = 1, limit: number = defaultLimit) {
        return this.uow.getAllEngagementsSummary(page, limit, JSON.stringify(this.filters))
            .then(result => {
                this.engagementDtos = result;
            });
    }

    getPageCount(limit: number = defaultLimit) {
        return this.uow.getEngagementsPageCount(limit, JSON.stringify(this.filters))
            .then(result => {
                this.pageCount = result[0];
            });
    }

    canRemoveEngagement(engagement: model.Engagement): boolean {
        let canRemove = true;

        if (engagement) {
            canRemove = engagement.status != modelEnum.EngagementStatus.archived;
        }

        return canRemove;
    }

    saveEngagementChanges(engagement: model.Engagement, message: string) {
        this.isBusy.set(saveKey, true, message);
        this.uow.engagements.saveChanges([engagement])
            .then(() => {
                this.updateDataGrid();
            })
            .catch(reason => {
                this.errorHandler.handleError(reason);
            })
            .finally(() => {
                this.isBusy.set(saveKey, false);
            });
    }

    destroyEngagement(engagement: model.Engagement) {
        if (engagement) {
            this.showConfirmDeleteModal(engagement);
        }
    }

    showConfirmDeleteModal(engagement: model.Engagement) {
        this.confirmMessage = 'Are you sure you want to remove "' + engagement.code + ' ' + engagement.client.name + '"?';

        this.modalService.open(this.confirmDeleteModal, { ariaLabelledBy: 'modal-basic-title' })
            .result
            .then((result) => {
                if (result == 'confirm') {
                    if (engagement.status > modelEnum.EngagementStatus.draft) {
                        this.confirmMessage = 'You are about to delete an active inspection. By confirming below, you acknowledge that there ' +
                            'is no longer a need for the assigned inspection team to complete this inspection ' +
                            '(e.g. the inspection was created as a test, or the inspection was setup erroneously and ' +
                            'a new inspection has been created in its place).';

                        this.modalService.open(this.confirmDeleteModal, { ariaLabelledBy: 'modal-basic-title' })
                            .result
                            .then((result) => {
                                if (result == 'confirm') {
                                    const i = this.engagementDtos.findIndex(e => e.engagement.id == engagement.id);
                                    this.engagementDtos.splice(i, 1);
                                    engagement.entityAspect.setDeleted();

                                    this.saveEngagementChanges(engagement, 'Removing engagement "' + engagement.code + '"');
                                }
                            });
                    }
                    else {
                        const i = this.engagementDtos.findIndex(e => e.engagement.id == engagement.id);
                        this.engagementDtos.splice(i, 1);
                        engagement.entityAspect.setDeleted();

                        this.saveEngagementChanges(engagement, 'Removing engagement "' + engagement.code + '"');
                    }
                }
            }, (reason) => {
                const closeResult = `Dismissed ${this.getDismissReason(reason)}`;

                console.log('Reason: ' + closeResult);
            });
    }


    runCleanup() {
        this.modalService.open(this.confirmCleanupStepOneModal, { ariaLabelledBy: 'modal-basic-title' })
            .result
            .then((result) => {
                if (result == 'confirm') {

                    this.modalService.open(this.confirmCleanupStepTwoModal, { ariaLabelledBy: 'modal-basic-title' })
                        .result
                        .then((result) => {
                            if (result == 'confirm') {
                                this.isLockedForCleanup = true;
                                this.engagementDtos = [];

                                this.uow.cleanupEngagements()
                                    .then(() => {
                                        this.uow.clear();
                                        this.updateDataGrid();
                                    })
                                    .catch(reason => {
                                        this.errorHandler.handleError(reason);
                                    })
                                    .finally(() => {
                                        this.isLockedForCleanup = false;
                                    });
                            }
                        });
                }
            }, (reason) => {
                const closeResult = `Dismissed ${this.getDismissReason(reason)}`;

                console.log('Reason: ' + closeResult);
            });
    }


    private getDismissReason(reason: any): string {
        if (reason == ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason == ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    calculatePercentComplete(engagementDto: modelDto.AllEngagementsSummaryDto): number {
        if (engagementDto.totalSections > 0) {
            return engagementDto.completedSections / engagementDto.totalSections;
        }
        return 0.0;
    }
}
