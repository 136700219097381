import { Component, Input, OnInit } from '@angular/core';
import Utilities from '../../../services/utilities';
import { EngagementSection, EngagementQuestion } from '../../../../model/model';

@Component({
    selector: 'engagement-section-tree-node-viewer',
    inputs: ['node', 'i'],
    templateUrl: './engagement-tree-node-viewer.component.html'
})
export class EngagementSectionViewTreeNode implements OnInit {
    @Input() node: EngagementSection;
    @Input() i: number;
    @Input() context: any;

    constructor() {
    }

    ngOnInit() {
    }

    get currentNodes() {
        return this.node.childSections ? this.node.childSections.filter(m => !m.isDeleted).sort(Utilities.sortByDisplayOrder) : [];
    }

    get questions() {
        return this.node.engagementQuestions ? this.node.engagementQuestions.filter(m => !m.isDeleted).sort(Utilities.sortByDisplayOrder) : [];
    }


    toggleSection() {
        if (this.node) {
            this.node.isCollapsed = !this.node.isCollapsed;
        }
    }


    viewQuestion(question: EngagementQuestion) {
        this.context.activeEngagementQuestion = question;

        this.context.showQuestionViewer = true;
    }


    getDisplayText(question: EngagementQuestion) {
        return question.name || question.description.substr(0, 40) + '...';
    }
}
