<div class="header">
    <h3>My Assignments</h3>
</div>

<div class="row">
    <div class="col sidebar sidebar-tree-view">
        <div class="sidebar-inner">
            <div class="form-group">
                <button type="button" class="btn btn-sm btn-primary mr-2" style="pointer-events: none;">
                    <i class="fa fa-refresh" [class.fa-spin]="isBusy.isBusy"></i>
                    <span class="sr-only">Reload sections</span>
                </button>
                <button type="button" class="btn btn-sm btn-secondary mr-1" (click)="expandAll()">
                    <i class="fa fa-expand"></i>
                    Expand All
                </button>
                <button type="button" class="btn btn-sm btn-secondary mr-1" (click)="collapseAll()">
                    <i class="fa fa-compress"></i>
                    Collapse All
                </button>
                <button type="button" class="btn btn-sm btn-primary" (click)="printReport()" target="_blank" [disabled]="isBusy.isBusy">
                    Print
                </button>
            </div>
            <div class="question-filters ">
                <label id="filter-label">Filter by:</label>
                <div class="question-filters-options form-group">
                    <select #isComplete (change)="populateFilterObject(isComplete.value, 'isComplete')">
                        <option selected value="">-Question Status-</option>
                        <option value="complete">Complete</option>
                        <option value="not complete">Not Complete</option>
                        <option value="saved">Saved</option>
                        <option value="with comments">With Comments</option>
                    </select>
                    <select #selectedOptionId (change)="populateFilterObject(selectedOptionId.value, 'selectedOptionId')">
                        <option selected value="">-Answer-</option>
                        <ng-container *ngFor="let option of allEngagementOptions">
                            <option *ngIf="option.standardQuestionOption" [value]="option.standardQuestionOption.name">{{ option.standardQuestionOption.name }}</option>
                            <option *ngIf="!option.standardQuestionOption" [value]="option.text">{{ option.text }}</option>
                        </ng-container>
                    </select>
                    <select #tag (change)="populateFilterObject(tag.value, 'tag')">
                        <option selected value="">-Tag-</option>
                        <ng-container *ngFor="let tag of tags">
                            <option [value]="tag.name">{{ tag.name }}</option>
                        </ng-container>
                    </select>
                </div>
            </div>
            <div id="question-scrollbar" class="inner-scroller flex-scroll">

                <ol id="engagement-sections" class="tree tree-sm">
                    <li class="tree-branch" *ngFor="let node of rootEngagementSections; index as i">
                        <inspection-tree-node-viewer [node]="node" [i]="i" [context]="this" [filters]="questionFilters" [filterIsActive]="filterIsActive" #child></inspection-tree-node-viewer>
                    </li>
                </ol>
            </div>
        </div>
    </div>
    <div class="col inspection-body">
        <header class="p-3 mb-3 bg-bdo-secondary">
            <table class="inspection-metadata">
                <tr>
                    <th class="pr-2">Engagement Code:</th>
                    <td colspan="3"><span class="h5">{{engagement.code}}</span></td>
                </tr>
                <tr>
                    <th class="pr-2">Client Name:</th>
                    <td colspan="3"><span class="h4">{{engagement.client?.name}}</span></td>
                </tr>
                <tr>
                    <td colspan="4">&nbsp;</td>
                </tr>
                <tr>
                    <th class="pr-2">Team Captain:</th>
                    <td colspan="3">{{engagement.inspectionLead?.fullName}}</td>
                </tr>
                <tr>
                    <th class="pr-2">Office:</th>
                    <td class="pr-4">{{engagement.office?.name}}</td>
                    <th class="pr-2">Year:</th>
                    <td class="pr-4">{{engagement.inspectionYear}}</td>
                </tr>
                <tr>
                    <th class="pr-2">Region:</th>
                    <td class="pr-4">{{engagement.regionCode}}</td>
                </tr>
                <tr>
                    <th class="pr-2">Focus Section:</th>
                    <td class="pr-4">{{getFocusSection()}}</td>
                </tr>
                
            </table>
        </header>

        <div *ngIf="currentSection == null">
            <p class="mb-2">Please select a section to complete. <span *ngIf="myRootSections.length > 0">Your actionable sections are:</span></p>
            <ul class="assigned-sections" *ngIf="myRootSections.length > 0">
                <li *ngFor="let node of myRootSections">
                    <section-list-item [node]="node" [context]="this"></section-list-item>
                </li>
            </ul>
            <hr />
            <a [routerLink]="['/my-assignments']" class="btn btn-primary" [class.disabled]="isBusy.isBusy">
                <i class="fa fa-reply"></i>
                Return to inspections list
            </a>
        </div>

        <form #engagementResponseForm="ngForm" class="engagement-response-form" *ngIf="currentSection != null">
            <h5>
                <span class="text-muted mr-2">Section:</span>{{currentSection.name}}
            </h5>
            <div class="form-group">
                <button type="button"
                        class="btn btn-sm btn-primary mr-1"
                        [disabled]="prevSectionNavigationDisabled"
                        (click)="setActiveSection(parentSection)">
                    <i class="fa fa-level-up"></i>
                    <span class="sr-only">Up one level</span>
                </button>
                <button type="button" class="btn btn-sm btn-secondary mr-2" (click)="closeSection()">
                    <i class="fa fa-times"></i>
                    Close section
                </button>
                <button type="button"
                        class="btn btn-sm btn-secondary mr-1"
                        [disabled]="prevSectionNavigationDisabled"
                        (mouseenter)="setHoverText(previousSection)"
                        (mouseleave)="setHoverText(null)"
                        (click)="setActiveSection(previousSection)">
                    <i class="fa fa-arrow-left"></i>
                    <span class="sr-only">Prev</span>
                </button>
                <button type="button"
                        class="btn btn-sm btn-secondary"
                        [disabled]="nextSection == null"
                        (mouseenter)="setHoverText(nextSection)"
                        (mouseleave)="setHoverText(null)"
                        (click)="setActiveSection(nextSection)">
                    <span class="sr-only">Next</span>
                    <i class="fa fa-arrow-right"></i>
                </button>
                <em class="mx-2">{{hoverSectionName}}</em>
            </div>

            <hr />

            <div *ngIf="currentQuestion?.isSelected">
                <div class="form-group" *ngIf="currentSection.engagementQuestions && currentSection.engagementQuestions.length > 0">
                    <button type="button"
                            class="btn btn-sm btn-secondary mr-1"
                            [disabled]="prevQuestionDisabled"
                            (click)="loadQuestion(questionNumber - 1)">
                        <i class="fa fa-arrow-left"></i>
                        <span class="sr-only">Prev</span>
                    </button>
                    <button type="button"
                            class="btn btn-sm btn-secondary"
                            [disabled]="nextQuestionDisabled"
                            (click)="loadQuestion(questionNumber + 1)">
                        <span class="sr-only">Next</span>
                        <i class="fa fa-arrow-right"></i>
                    </button>
                    <span class="mx-2" *ngIf="!filterIsActive; else elseBlock">
                        Question <b>{{questionNumber}}</b> of <b>{{numberOfActiveQuestionsInSection}}</b>
                    </span>
                    <ng-template #elseBlock>
                        <span class="mx-2">
                            Question <b>{{currentFilteredQuestionNumber}}</b> of <b>{{currentFilteredSection.filteredEngagementQuestions.length}}</b>
                        </span>
                    </ng-template>
                    <small *ngIf="currentQuestion.isComplete && currentQuestion.respondingTeamMember != null">
                        (<i class="fa fa-check text-success"></i> Completed by {{currentQuestion.respondingTeamMember?.employee?.fullName}} on {{formatDate(currentQuestion.responseDate)}})
                    </small>
                    <small *ngIf="!currentQuestion.isComplete && currentQuestion.selectedOptionId != null && currentQuestion.respondingTeamMember != null">
                        (<i class="fa fa-user"></i> Answered by {{currentQuestion.respondingTeamMember?.employee?.fullName}} on {{formatDate(currentQuestion.responseDate)}})
                    </small>

                    <span class="d-inline-block ml-2" *ngIf="nextAssignedSection != null">
                        <em class="mx-2">Next up:</em>
                        <button type="button"
                                class="btn btn-sm btn-primary"
                                (click)="setActiveSection(nextAssignedSection)">
                            {{nextAssignedSection.name}}
                            <i class="fa fa-mail-forward"></i>
                        </button>
                    </span>
                </div>

                <h4 *ngIf="currentQuestion.name != null">
                    {{currentQuestion.name}}
                </h4>

                <div class="lead">
                    <p>{{currentQuestion.description}}</p>
                </div>

                <fieldset class="mw-800" [disabled]="!canSubmitResponse">
                    <div class="form-group mb-3">
                        <div class="form-check form-check-inline" *ngFor="let option of orderedEngagementQuestionOptions; index as i">
                            <input class="form-check-input"
                                   type="radio"
                                   name="currentQuestion-selected-option"
                                   id="currentQuestion-option-{{i}}"
                                   (change)="saveResponseForm(true)"
                                   [(ngModel)]="currentQuestion.selectedOptionId"
                                   (click)="updateCommentLabel(option)"
                                   [value]="option.id"
                                   [disabled]="!canSubmitResponse"
                                   required />
                            <label class="form-check-label"
                                   [class.selected-option-highlight]="currentQuestion.selectedOptionId != null && option.id == currentQuestion.selectedOptionId"
                                   for="currentQuestion-option-{{i}}">{{option.text ? option.text : option.standardQuestionOption?.name}}</label>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="current-question-comment">
                            Comment
                            <span class="text-danger" [class.d-none]="hasNoAnswerNeededQuestionOption() || (!currentQuestion.commentIsRequired && orderedEngagementQuestionOptions.length > 0)">(required)</span>
                        </label>
                        <textarea class="form-control"
                                  rows="10"
                                  id="current-question-comment"
                                  name="current-question-comment"
                                  [disabled]="!canSubmitResponse"
                                  (keydown)="commentKeyDown()"
                                  (keyup)="commentKeyUp()"
                                  [required]="currentQuestion.commentIsRequired && !hasNoAnswerNeededQuestionOption()"
                                  [value]="commentText"
                                  [(ngModel)]="commentText"></textarea>
                        <small class="form-text text-danger validation-message">Comment is required.</small>
                    </div>

                    <div *ngIf="canSubmitResponse">
                        <button type="button"
                                style="width:200px"
                                class="btn btn-primary mr-2"
                                (click)="clearClicked();"
                                [disabled]="isBusy.isBusy">
                            <i class="fa fa-ban"></i>
                            Clear
                        </button>
                    </div>
                </fieldset>
            </div>
        </form>
    </div>
    <div class="col sidebar sidebar-help-text">
        <ngb-tabset type="pills" activeId="{{(currentQuestion && currentComments && currentComments.length ? 'comments-tab' : 'help-tab')}}">
            <ngb-tab id="comments-tab">
                <ng-template ngbTabTitle>
                    <i class="fa fa-comments"></i>
                    Comments ({{(currentQuestion && currentComments ? currentComments.length : 0)}})
                </ng-template>
                <ng-template ngbTabContent>
                    <div class="inner-scroller">
                        <ul class="comments-list" *ngIf="currentQuestion?.isSelected">
                            <li *ngFor="let comment of currentComments" [class.has-changes]="currentComment?.id == comment.id">
                                <div class="float-right">
                                    <button class="btn btn-primary btn-xs mx-1"  (click)="editComment(comment)">Edit</button>
                                    <button class="btn btn-xs mr-0 btn-outline-primary" (click)="deleteComment(comment)"><i class="fa fa-trash"></i></button>
                                </div>
                                <div>
                                    <p>{{comment.originalText}}</p>
                                </div>
                                <div class="text-sm">
                                    <p class="text-bdo-charcoal mb-1">{{comment.modifiedByUser?.fullName || comment.createdByUser?.fullName}}</p>
                                    <p class="text-muted font-italic"><small>{{formatDate(comment.modifiedDate || comment.createdDate)}}</small></p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </ng-template>
            </ngb-tab>
            <ngb-tab id="help-tab">
                <ng-template ngbTabTitle>
                    <i class="fa fa-question-circle"></i>
                    Help
                </ng-template>
                <ng-template ngbTabContent>
                    <div class="inner-scroller">
                        <div class="my-3">
                            <h5>
                                Questionnaire Navigation
                            </h5>
                            <p>
                                Use the left sidebar to view a section or question.
                            </p>
                            <p>
                                Your assigned sections are indicated by the <i class="fa fa-star text-bdo-primary"></i> icon.
                                Completed questions are indicated by the <i class="fa fa-check text-success"></i> icon.
                                The <i class="fa fa-asterisk text-bdo-info"></i> icon indicates a question with a response which is
                                not marked as complete.
                            </p>
                        </div>

                        <div *ngIf="currentQuestion != null && currentQuestion.helpText != null">
                            <h5>
                                Question Help
                            </h5>
                            <p>{{ currentQuestion.helpText }}</p>
                        </div>
                    </div>
                </ng-template>
            </ngb-tab>
        </ngb-tabset>
    </div>
</div>

<ng-template #existingAnswerModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="modal-basic-title">Please confirm</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>
            This question has an existing response from another team member. Continue saving? This will
            replace the existing response with your submission.
        </p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="modal.close('save')">Save</button>
        <button type="button" class="btn btn-secondary" (click)="modal.close('cancel'); resetQuestion()">Cancel</button>
    </div>
</ng-template>
