import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UnitOfWorkService } from '../../../services/unit-of-work/unit-of-work.service';
import Utilities from '../../../services/utilities';
import { Router, ActivatedRoute } from '@angular/router';
import { ApplicationInsightsService } from '../../../services/application-insights/application-insights.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { DialogHelper } from '../../../services/dialog-helper';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { IsBusyService } from '../../../services/is-busy/is-busy.service';
import * as model from '../../../../model/model';
import * as modelDto from '../../../../model/modelDto';
import * as modelEnum from '../../../../model/modelEnums';
import { GlobalErrorHandler } from '../../../services/global-error-handler';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { filter } from 'rxjs/operators';
import { Profile } from '../../../../typings';
import { Role } from '../../../../model/role';

const loadingKey: string = 'team-inspections:loadData';
const defaultLimit: number = 15;

@Component({
    selector: 'app-team-inspections-admin',
    templateUrl: './team-inspections.component.html',
})
export class TeamInspectionsComponent implements OnInit {
    @ViewChild('teamMembersModal', { static: true }) teamMembersModal: TemplateRef<any>; // Note: TemplateRef
    @ViewChild('setStatusModal', { static: true }) setStatusModal: TemplateRef<any>; // Note: TemplateRef

    isAdmin = new BehaviorSubject<boolean>(false);
    teamCaptains: model.Employee[] = [];
    selectedCaptain: string = "";

    page: number = 1;
    limit: number = defaultLimit;
    pageCount: number = 1;
    rowCount: number = 0;
    engagementsCount: number = 0;
    pageLimitOptions = [15, 25, 50, 100];
    groupBy: string = 'section';

    assignChildSections: boolean = false;

    offices: model.Office[] = [];
    regions: string[] = [];
    businessLines: model.BusinessLine[] = [];

    groupByMode: string = 'section';

    engagements: modelDto.EngagementSummaryDto[] = [];
    engagementSections: modelDto.EngagementSectionSummaryDto[] = [];
    engagementQuestions: modelDto.EngagementQuestionSummaryDto[] = [];

    currentSection: modelDto.EngagementSectionSummaryDto = {} as modelDto.EngagementSectionSummaryDto;
    teamMembers: modelDto.InspectionTeamMemberDto[] = [];

    selectedEngagementStatus: modelEnum.EngagementStatus;

    filters: modelDto.GridFilter[] = [];

    // shared filter
    engagementCodes: string = null;

    // top-level filters
    inspectionYears: string = null;
    selectedOffices: string[] = [];
    selectedRegions: string[] = [];
    selectedBusinessLines: number[] = [];

    inspectionStatusesLabel: string = 'Filter by...';

    progressStatusesLabel: string = 'Filter by...';

    // engagement filters
    clientNameFilter: string = null;

    // section filters
    sectionNameFilter: string = null;
    sectionStatusFilter: string = null;
    assignedOnFilter: string = null;
    assignedToFilter: string = null;

    // questionFilters
    questionNameFilter: string = null;
    hasCommentsFilter: string = null;
    isNrfFilter: string = null;
    hasObservationsFilter: string = null;

    groupByModes = [
        { id: 'engagement-code', name: 'Engagement Code' },
        { id: 'section', name: 'Questionnaire Section' },
        { id: 'question', name: 'Question' }
    ];

    yesNoOptions = [
        { isSelected: false, name: "Yes", value: "true" },
        { isSelected: false, name: "No", value: "false" }
    ];


    constructor(private uow: UnitOfWorkService,
        private appInsights: ApplicationInsightsService,
        private errorHandler: GlobalErrorHandler,
        private activeRoute: ActivatedRoute,
        public router: Router,
        public isBusy: IsBusyService,
        public dialogHelper: DialogHelper,
        private modalService: NgbModal,
        private broadcastService: MsalBroadcastService,
        private msalService: MsalService) {
        appInsights.logPageView();
    }

    ngOnInit() {
        this.activeRoute.queryParams.subscribe(routeParams => {
            this.page = routeParams.page && !Number.isNaN(routeParams.page) ? parseInt(routeParams.page) : 1;
            this.limit = routeParams.limit && !Number.isNaN(routeParams.limit) ? parseInt(routeParams.limit) : defaultLimit;

            this.isBusy.set(loadingKey, true, 'Loading search data');
            Promise
                .all([
                    this.getBusinessLines(),
                    this.getOffices(),
                    this.getTeamCaptains()
                ])
                .then(() => {
                    this.updateDataGrid();
                })
                .catch(reason => {
                    this.errorHandler.handleError(reason);
                })
                .finally(() => {
                    this.isBusy.set(loadingKey, false);
                });
        });

        this.broadcastService.inProgress$
            .pipe(filter((status: InteractionStatus) => status === InteractionStatus.None))
            .subscribe(() => {
                const account: Profile = this.msalService.instance.getAllAccounts()[0];

                this.isAdmin.next(account.idTokenClaims && account.idTokenClaims.roles && account.idTokenClaims.roles.includes(Role.admin));
            }
            );
    }

    canDeactivate(): Observable<boolean> | boolean {
        if (this.uow.hasChanges.value) {
            let proceed = this.dialogHelper.confirm();

            proceed.subscribe(result => {
                if (result) {
                    this.uow.rollback();
                }
            })

            return proceed;
        }

        return true;
    }


    get activeEngagements() {
        return this.engagements;
    }

    get activeEngagementSections() {
        return this.engagementSections;
    }

    get activeEngagementQuestions() {
        return this.engagementQuestions;
    }

    get hasSelections() {
        switch (this.groupBy) {
            case 'engagement-code':
                return this.activeEngagements.some(m => m.isSelected);

            case 'section':
                return this.activeEngagementSections.some(m => m.isSelected);

            case 'question':
                return this.activeEngagementQuestions.some(m => m.isSelected);
        }
    }

    get engagementStatusOptions() {
        return this.uow.engagementStatuses.filter(m => m.status > modelEnum.EngagementStatus.draft);
    }

    get engagementStatuses() {
        return this.uow.engagementStatuses.filter(m => m.status > modelEnum.EngagementStatus.draft);
    }

    get progressStatuses() {
        return this.uow.progressStatuses;
    }

    getTeamCaptains() {
        return this.uow.getTeamCaptains()
            .then(result => this.teamCaptains = result);
    }

    getBusinessLines() {
        return this.uow.getBusinessLines()
            .then(result => {
                this.businessLines = result;
            });
    }

    getOffices() {
        return this.uow.getOffices()
            .then(result => {
                this.offices = result;
                this.getRegions();
            });
    }

    getRegions() {
        this.regions = this.offices.map(o => o.regionCode)
            .filter(Utilities.onlyUnique)
            .sort((a, b) => { return a.localeCompare(b); });
    }


    selectAll() {
        let toggle: boolean = false;

        switch (this.groupBy) {
            case 'engagement-code':
                toggle = this.engagements.some(m => m.isSelected);
                this.engagements.forEach(m => m.isSelected = !toggle);
                break;

            case 'section':
                toggle = this.engagementSections.some(m => m.isSelected);
                this.engagementSections.forEach(m => m.isSelected = !toggle);
                break;

            case 'question':
                toggle = this.engagementQuestions.some(m => m.isSelected);
                this.engagementQuestions.forEach(m => m.isSelected = !toggle);
                break;
        }
    }

    setStatus() {
        alert("We're sorry. This feature has not been implemented yet.");
    }

    assignTo() {
        alert("We're sorry. This feature has not been implemented yet.");
    }


    showAssignSectionModal(section: modelDto.EngagementSectionSummaryDto) {
        this.currentSection = section;
        this.teamMembers = section.inspectionTeamMembers;

        this.teamMembers.forEach(m => { m.isSelected = section.assignedTeamMembers.some(n => n.id == m.id); });

        this.modalService.open(this.teamMembersModal, { ariaLabelledBy: 'modal-basic-title' })
            .result
            .then((result) => {
                if (result == 'save') {
                    const selected = this.teamMembers.filter(m => m.isSelected).map(m => m.id);
                    const previouslySelectedTeamMembers = section.assignedTeamMembers.length;
                    const teamMemberWasRemoved = selected.length < previouslySelectedTeamMembers ? true : false;

                    this.uow.updateAssignedSections(this.currentSection.sectionId, this.assignChildSections, selected, teamMemberWasRemoved)
                        .then(() => {
                            this.updateDataGrid();
                        });
                }
            }, (reason) => {
                const closeResult = `Dismissed ${this.getDismissReason(reason)}`;

                console.log('Reason: ' + closeResult);
            });
    }

    showSetStatusModal() {
        this.engagementStatusOptions.forEach(m => m.isSelected = false);
        this.modalService.open(this.setStatusModal, { ariaLabelledBy: 'modal-basic-title' })
            .result
            .then((result) => {
                if (result == 'save') {
                    let engagementsToUpdate: number[];

                    switch (this.groupBy) {
                        case 'engagement-code':
                            engagementsToUpdate = this.engagements.filter(m => m.isSelected == true).map(m => m.engagementId);
                            break;

                        case 'section':
                            engagementsToUpdate = this.engagementSections.filter(m => m.isSelected == true).map(m => m.engagementId);
                            break;

                        case 'question':
                            engagementsToUpdate = this.engagementQuestions.filter(m => m.isSelected == true).map(m => m.engagementId);
                            break;
                    }

                    if (engagementsToUpdate.length > 0) {
                        this.uow.updateEngagementStatus(this.selectedEngagementStatus, engagementsToUpdate)
                            .then(() => {
                                this.updateDataGrid();
                            });
                    }
                }
            }, (reason) => {
                const closeResult = `Dismissed ${this.getDismissReason(reason)}`;

                console.log('Reason: ' + closeResult);
            });
    }

    private getDismissReason(reason: any): string {
        if (reason == ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason == ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }


    updateInspectionStatusFilters(e: any) {
        if (e == false) {
            if (this.engagementStatuses.some(m => m.isSelected)) {
                this.inspectionStatusesLabel = this.engagementStatuses.filter(m => m.isSelected).map(m => m.title).join(', ');
                this.updateFilters('inspectionStatus', this.engagementStatuses.filter(m => m.isSelected).map(m => m.status).join(','), true);
            }
            else {
                this.inspectionStatusesLabel = 'Filter by...';
                this.updateFilters('inspectionStatus', null, true);
            }
        }
    }

    clearInspectionStatusFilters() {
        this.engagementStatuses.filter(m => m.isSelected = false);
    }


    updateProgressStatusFilters(e: any) {
        if (e == false) {
            if (this.progressStatuses.some(m => m.isSelected)) {
                this.progressStatusesLabel = this.progressStatuses.filter(m => m.isSelected).map(m => m.title).join(', ');
                this.updateFilters('progressStatus', this.progressStatuses.filter(m => m.isSelected).map(m => m.status).join(','), true);
            }
            else {
                this.inspectionStatusesLabel = 'Filter by...';
                this.updateFilters('progressStatus', null, true);
            }
        }
    }

    clearProgressStatusFilters() {
        this.progressStatuses.filter(m => m.isSelected = false);
    }


    updateFilters(key: string, value: any, applyNow: boolean = false) {
        if (key) {
            if (!value || value == '') {
                this.removeFilter(key);
            } else {
                if (this.filters.some(m => m.key == key)) {
                    const filter = this.filters.find(m => m.key == key);
                    filter.value = value;
                }
                else {
                    this.filters.push({ key: key, value: value } as modelDto.GridFilter)
                }
            }
        }

        if (applyNow == true) {
            this.updateDataGrid();
        }
    }

    removeFilter(key: string) {
        const i = this.filters.findIndex(m => m.key == key);
        if (i >= 0) {
            this.filters.splice(i, 1);
        }
    }

    applyPrimaryFilters() {
        if (this.groupBy !== this.groupByMode) {
            this.clearSecondaryFilters();
        }

        this.groupBy = this.groupByMode;

        this.updateFilters('code', this.engagementCodes);
        this.updateFilters('year', this.inspectionYears);

        if (this.selectedOffices.length > 0) {
            const officeFilter = this.selectedOffices.join(',');
            this.updateFilters('officeId', officeFilter);
        } else {
            this.removeFilter('officeId');
        }

        if (this.selectedRegions.length > 0) {
            const regionFilter = this.selectedRegions.join(',');
            this.updateFilters('region', regionFilter);
        } else {
            this.removeFilter('region');
        }

        if (this.selectedBusinessLines.length > 0) {
            const businessLineFilter = this.selectedBusinessLines.join(',');
            this.updateFilters('businessLineId', businessLineFilter);
        } else {
            this.removeFilter('businessLineId');
        }

        if (this.selectedCaptain !== "") {
            this.updateFilters('selectedCaptainId', this.selectedCaptain);
        }

        this.updateDataGrid();
    }

    clearPrimaryFilters() {
        this.engagementCodes = null;
        this.inspectionYears = null;
        this.selectedOffices = [];
        this.selectedRegions = [];
        this.selectedBusinessLines = [];
        this.selectedCaptain = "";

        this.removeFilter('code');
        this.removeFilter('year');
        this.removeFilter('week');
        this.removeFilter('officeId');
        this.removeFilter('region');
        this.removeFilter('businessLineId');
        this.removeFilter('selectedCaptainId');

        this.groupByMode = 'section';

        this.groupBy = this.groupByMode;

        this.updateDataGrid();
    }


    clearSecondaryFilters() {
        this.engagementCodes = null;
        this.assignedOnFilter = null;
        this.assignedToFilter = null;
        this.sectionNameFilter = null;

        this.removeFilter('code');
        this.removeFilter('assignedOn');
        this.removeFilter('assignedTo');
        this.removeFilter('clientName');
        this.removeFilter('sectionName');
        this.removeFilter('questionName');
        this.removeFilter('nrfs');
        this.removeFilter('observations');
        this.removeFilter('hasComments');

        this.clearInspectionStatusFilters();
        this.inspectionStatusesLabel = 'Filter by...';
        this.removeFilter('inspectionStatus');

        this.clearProgressStatusFilters();
        this.progressStatusesLabel = 'Filter by...';
        this.removeFilter('progressStatus');

        this.updateDataGrid();
    }


    updateDataGrid() {
        this.isBusy.set(loadingKey, true, 'Loading data');
        Promise
            .all([
                this.getRowCount(),
                this.getItems(this.page, this.limit)])
            .then(() => {
                this.getPageCount(this.limit);
            })
            .catch(reason => {
                this.errorHandler.handleError(reason);
            })
            .finally(() => {
                this.isBusy.set(loadingKey, false);
            });
    }


    getEngagementStatusName(status: modelEnum.EngagementStatus) {
        return this.uow.getEngagementStatusName(status);
    }

    getProgressStatusName(status: modelEnum.ProgressStatus) {
        return this.uow.getProgressStatusName(status);
    }


    calculatePercentComplete(numerator: number, denominator: number): number {
        let value = 0.0;

        if (denominator > 0) {
            value = numerator / denominator;
        }

        return value;
    }


    getPageCount(limit: number = defaultLimit) {
        if (this.rowCount > 0) {
            this.pageCount = Math.ceil(this.rowCount / limit);
            if (this.pageCount == 0) {
                this.pageCount = 1;
            }
        } else {
            this.pageCount = 1;
        }
    }

    getRowCount() {
        switch (this.groupBy) {
            case 'engagement-code':
                return this.getEngagementsCount();

            case 'section':
                return this.getEngagementSectionsCount();

            case 'question':
                return this.getEngagementQuestionsCount();
        }
    }

    getItems(page: number = 1, limit: number = defaultLimit) {
        switch (this.groupBy) {
            case 'engagement-code':
                return this.getEngagements(page, limit);

            case 'section':
                return this.getEngagementSections(page, limit);

            case 'question':
                return this.getEngagementQuestions(page, limit);
        }
    }


    getEngagements(page: number = 1, limit: number = defaultLimit) {
        return this.uow.getEngagementsForMyTeams(page, limit, JSON.stringify(this.filters))
            .then(result => {
                this.engagements = result;
            });
    }

    getEngagementsCount() {
        return this.uow.getEngagementsCountForMyTeams(JSON.stringify(this.filters))
            .then(result => {
                this.rowCount = result[0];
            });
    }


    getEngagementSections(page: number = 1, limit: number = defaultLimit) {
        return this.uow.getEngagementSectionsForMyTeams(page, limit, JSON.stringify(this.filters))
            .then(result => {
                this.engagementSections = result;
            });
    }

    getEngagementSectionsCount() {
        return this.uow.getEngagementSectionsCountForMyTeams(JSON.stringify(this.filters))
            .then(result => {
                this.rowCount = result[0];
                this.engagementsCount = result[1];
            });
    }


    getEngagementQuestions(page: number = 1, limit: number = defaultLimit) {
        return this.uow.getEngagementQuestionsForMyTeams(page, limit, JSON.stringify(this.filters))
            .then(result => {
                this.engagementQuestions = result;
            });
    }

    getEngagementQuestionsCount() {
        return this.uow.getEngagementQuestionsCountForMyTeams(JSON.stringify(this.filters))
            .then(result => {
                this.rowCount = result[0];
                this.engagementsCount = result[1];
            });
    }
}
