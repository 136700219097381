import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'profile-nav-menu',
    inputs: ['currentTab'],
    template: `<nav class="nav nav-pills flex-column" id="profiles-menu">
            <a class="nav-item nav-link"
               id="inspection-profiles-tab"
               [class.active]="currentTab == 'inspection-profiles'"
               [routerLink]="['/profile-management/inspection-profiles']">Engagement Review Questionnaires</a>

            <a class="nav-item nav-link"
               id="profile-sections-tab"
               [class.active]="currentTab == 'profile-sections'"
               [routerLink]="['/profile-management/profile-sections']">Questionnaire Sections</a>

            <a class="nav-item nav-link"
               id="profile-questions-tab"
               [class.active]="currentTab == 'profile-questions'"
               [routerLink]="['/profile-management/profile-questions']">Questions</a>
        </nav>`
})
export class ProfileNavMenu implements OnInit {
    @Input() currentTab: string;

    ngOnInit() {
    }
}
