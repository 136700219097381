import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as model from '../../../model/model';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
    public accessType: string;

    me = new BehaviorSubject<model.Employee>(null);
    setMe = (data) => this.me.next(data);

    myEngagements = new BehaviorSubject<model.Engagement>(null);
    setMyEngagements = (data) => this.myEngagements.next(data);

  constructor() { }
}
