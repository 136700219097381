import { Component, Input } from '@angular/core';
import Utilities from '../../services/utilities';
import { EngagementSection } from '../../../model/model';

@Component({
    selector: 'section-list-item',
    inputs: ['node'],
    templateUrl: './section-list-viewer.component.html'
})
export class SectionListItem {
    @Input() node: EngagementSection;
    @Input() context: any;

    constructor() {
    }


    get activatedNodes() {
        return this.node.childSections ? this.node.childSections.filter(m => this.context.hasActionableContent(m)).sort(Utilities.sortByDisplayOrder) : [];
    }
    

    setActiveSection(node: EngagementSection) {
        this.context.setActiveSection(node);
    }
}
