import { Component, Input, OnInit } from '@angular/core';
import Utilities from '../../../services/utilities';
import { ProfileSection, Question } from '../../../../model/model';

@Component({
    selector: 'section-tree-node-viewer',
    inputs: ['node', 'i'],
    templateUrl: './tree-node-viewer.component.html'
})
export class ProfileSectionViewTreeNode implements OnInit {
    @Input() node: ProfileSection;
    @Input() i: number;
    @Input() context: any;

    constructor() {
    }

    ngOnInit() {
    }

    get currentNodes() {
        return this.node.childSections ? this.node.childSections.filter(m => !m.isDeleted).sort(Utilities.sortByDisplayOrder) : [];
    }

    get questions() {
        return this.node.questions ? this.node.questions.filter(m => !m.isDeleted).sort(Utilities.sortByDisplayOrder) : [];
    }


    toggleSection() {
        if (this.node) {
            this.node.isCollapsed = !this.node.isCollapsed;
        }
    }


    viewQuestion(question: Question) {
        this.context.activeQuestion = question;

        this.context.showQuestionViewer = true;
    }

    getDisplayText(question: Question) {
        return question.name || question.description.substr(0, 40) + '...';
    }
}
