import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServerSideConfigDataService } from '../../../services/server-side-config-data/server-side-config-data.service';
import * as moment from 'moment';
import { DIALOG_CANCEL, DIALOG_CLOSE, DIALOG_NO, DIALOG_OK, DIALOG_YES } from './confirmation-dialog.constants';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.less']
})
export class ConfirmationDialogComponent {
    title = '';
    content: string;
    onlyShowOk = false;
    debug: string;

    // used in HTML
    get DIALOG_CANCEL() { return DIALOG_CANCEL; }
    get DIALOG_CLOSE() { return DIALOG_CLOSE; }
    get DIALOG_NO() { return DIALOG_NO; }
    get DIALOG_OK() { return DIALOG_OK; }
    get DIALOG_YES() { return DIALOG_YES; }

    constructor(
        public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
        serverSideConfig: ServerSideConfigDataService
    ) {
        this.title = data.title;
        this.content = data.content;
        this.onlyShowOk = data.onlyShowOk ? data.onlyShowOk : false;
        if (serverSideConfig.configData
            && ['Local', 'Development'].indexOf(serverSideConfig.configData.environment) > -1
        ) {
            this.debug = data.debug;
        } else {
            this.debug = null;
        }
    }

    downloadDebugData(): void {
        if (this.debug) {
            const element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.debug));
            element.setAttribute('download', `debug_${moment().format('MM-DD-YYYY_HH-mm-ss')}.html`);

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        }
    }
}
