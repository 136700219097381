import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../components/shared/confirmation-dialog/confirmation-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class GenericCanDeactivateGuard implements CanDeactivate<ISaveUndoChanges>  {
    constructor(private dialog: MatDialog) { }

    canDeactivate(component: ISaveUndoChanges, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (!component.hasChanges) {
            return true;
        }

        return this.dialog
            .open(ConfirmationDialogComponent, {
                disableClose: true,
                data: {
                    title: 'Pending Changes',
                    //content: "You have pending changes. Would you like to save these changes before you leave?"
                    content: "There are unsaved changes. Continue without saving?"
                }
            })
            .afterClosed()
            .toPromise()
            .then(result => {
                if (result == "Yes") {
                    //return component.saveChanges();
                    component.undoChanges();
                    return true;
                }
                return false;
            });
    }
}

export interface ISaveUndoChanges {
    hasChanges: boolean;
    saveChanges(): Promise<boolean>;
    undoChanges(secondAction?: boolean): void;
}
