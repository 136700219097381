import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AnnotatedMetadata, MetaEntityType, MetaProperty } from '../../../model/metaEntity';
import { ModelBuilderService } from '../model-builder/model-builder.service';
import { BreezeValidatorsService } from '../breeze-validators/breeze-validators.service';
import DefaultBreezeValidators from '../breeze-validators/default-breeze-validators';
import Config from '../config';
import Utilities from '../utilities';
import * as breeze from 'breeze-client';
import 'breeze-client-labs/breeze.savequeuing';

@Injectable({
    providedIn: 'root'
})
export class EntityManagerProviderService {
    private initialized: boolean;

    rights = {
        name: "rights",
        isUnmapped: true,
        nameOnServer: "Rights",
        dataType: breeze.DataType.Undefined
    };

    private serviceName: string = Config.serviceName;
    private masterManager: breeze.EntityManager = new breeze.EntityManager({
        serviceName: this.serviceName,
        saveOptions: new breeze.SaveOptions({
            allowConcurrentSaves: true
        })
    });

    private _manager: breeze.EntityManager;

    private cachedMetadataHash: string;
    private cachedAnnotatedMetadataHash: string;
    private cachedLookupsHash: string;

    constructor(
        private http: HttpClient,
        private modelBuilder: ModelBuilderService,
        private breezeValidators: BreezeValidatorsService
    ) { }

    private prepare() {
        this.cachedMetadataHash = localStorage.getItem(Config.stateKeys.metadataHash);
        this.cachedAnnotatedMetadataHash = localStorage.getItem(Config.stateKeys.annotatedMetadataHash);
        this.cachedLookupsHash = localStorage.getItem(Config.stateKeys.lookupsHash);

        return this.hydrateMetadata()
            .then(() => this.modelBuilder.extendMetadata(this.masterManager.metadataStore))
            .then(() => Promise.all([this.hydrateLookups(), this.hydrateAnnotatedMetadata()]));
    }

    private hydrateMetadata(): Promise<boolean> {
        const metadata = localStorage.getItem(Config.stateKeys.breezeMetadata);

        if (!this.cachedMetadataHash || !metadata || this.cachedMetadataHash !== Config.hashes.currentMetadataHash) {
            return this.masterManager
                .fetchMetadata()
                .then(() => {
                    this.cacheMetadata();
                    return true;
                });
        }

        this.masterManager.metadataStore = breeze.MetadataStore.importMetadata(metadata);
        return Utilities.valueAsPromise(true);
    }

    private cacheMetadata() {
        const meta = this.masterManager.metadataStore.exportMetadata();
        localStorage.setItem(Config.stateKeys.breezeMetadata, meta);
        localStorage.setItem(Config.stateKeys.metadataHash, Config.hashes.currentMetadataHash);
    }

    private hydrateLookups(): Promise<boolean> {
        const lookups = localStorage.getItem(Config.stateKeys.lookups);

        if (!this.cachedLookupsHash || !lookups || this.cachedLookupsHash !== Config.hashes.currentLookupsHash) {

            const query = breeze.EntityQuery
                .from('lookups');

            return this.masterManager
                .executeQuery(query)
                .then(() => {
                    this.cacheLookups();
                    return true;
                });
        }

        this.masterManager.importEntities(lookups);
        return Utilities.valueAsPromise(true);
    }

    private cacheLookups() {
        const lookups = this.masterManager.exportEntities();
        localStorage.setItem(Config.stateKeys.lookups, lookups);
        localStorage.setItem(Config.stateKeys.lookupsHash, Config.hashes.currentLookupsHash);
    }

    private hydrateAnnotatedMetadata(): Promise<boolean> {
        const annotated = localStorage.getItem(Config.stateKeys.annotatedMetadata);

        if (!this.cachedAnnotatedMetadataHash || !annotated || this.cachedAnnotatedMetadataHash !== Config.hashes.currentAnnotatedMetadataHash) {
            return this.http.get<Array<AnnotatedMetadata>>(this.serviceName + '/annotatedmetadata')
                .toPromise()
                .then((data) => {
                    this.parseAnnotatedMetadata(data);
                    this.cacheAnnotatedMetadata(JSON.stringify(data));
                    return true;
                });
        }

        this.parseAnnotatedMetadata(<Array<AnnotatedMetadata>>JSON.parse(annotated));
        return Utilities.valueAsPromise(true);
    }

    private cacheAnnotatedMetadata(data) {
        localStorage.setItem(Config.stateKeys.annotatedMetadata, data);
        localStorage.setItem(Config.stateKeys.annotatedMetadataHash, Config.hashes.currentAnnotatedMetadataHash);
    }

    private parseAnnotatedMetadata(data: Array<AnnotatedMetadata>) {
        const entityManager = this.masterManager;
        const metadataStore = entityManager.metadataStore;

        data.forEach((metaEntity: AnnotatedMetadata) => {
            const entityType = <MetaEntityType>metadataStore.getEntityType(metaEntity.key, true);

            if (entityType) {
                metadataStore[entityType.shortName] = function () {
                    return entityType;
                };

                entityType.displayName = metaEntity.value.meta.displayName;
                entityType.meta = metaEntity.value.meta;
                entityType.props = {};

                const entityProps = <MetaProperty[]>entityType.getProperties();
                entityProps.forEach((entityProp: MetaProperty) => {
                    entityType.props[entityProp.name] = function () {
                        return entityProp;
                    };

                    const metaProp = metaEntity.value.properties[entityProp.name];

                    if (metaProp) {
                        entityProp.displayName = metaProp.displayName;
                        entityProp.meta = metaProp;

                        DefaultBreezeValidators.setupEFValidators(entityProp.validators, entityProp.meta);
                    }
                }, this);
            }
        });

        this.breezeValidators.setupCustomValidators(data, entityManager);
    }

    private buildManager(): void {
        this._manager = this.masterManager.createEmptyCopy();
        this._manager.saveOptions.allowConcurrentSaves = true;
        this._manager.enableSaveQueuing(true);

        // Populate with lookup data
        this._manager.importEntities(this.masterManager.exportEntities());
    }

    manager(): breeze.EntityManager {
        if (!this._manager) {
            this.buildManager();
        }

        return this._manager;
    }

    init(): Promise<boolean> {
        if (this.initialized) {
            return Utilities.valueAsPromise(this.initialized);
        }

        this.masterManager.enableSaveQueuing(true);

        return this.prepare()
            .then(() => {
                this.buildManager();
                this.initialized = true;
                return true;
            })
            .catch((error) => {
                if (!environment.production) {
                    console.log(error);
                }

                return Promise.reject(error);
            });
    }
}
