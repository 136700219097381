import { Component, OnInit } from '@angular/core';
import { ApplicationInsightsService } from '../../services/application-insights/application-insights.service';
import { GlobalErrorHandler } from '../../services/global-error-handler';
import { ToastrService } from 'ngx-toastr';

declare var window: any;
@Component({
    selector: 'app-impersonate',
    templateUrl: './impersonate.component.html',
    styles: [`
        table {
            max-width: 30rem;
        }
        .table td {
            vertical-align: middle;
        }
        input {
            font-size: small;
        }
    `]
})
export class ImpersonateAdminComponent implements OnInit {
    basic: string = '';
    admin: string = '';

    constructor(
        private appInsights: ApplicationInsightsService,
        private errorHandler: GlobalErrorHandler,
        private toastr: ToastrService
	) {
        appInsights.logPageView();
    }

    ngOnInit() {
        this.basic = 'https://' + window.location.host + '/?e2e&impersonate=hjackman@bdo.com&code=n@11!mpPwd';
        this.admin = 'https://' + window.location.host + '/?e2e&impersonate=bwillis@bdo.com&code=n@11!mpPwd';
    }

    log($event, role) {
        if ($event.isSuccess) {
            this.toastr.success(`Copied ${role} URL to clipboard`, '', { timeOut: 2000 });
        }
    }
}
