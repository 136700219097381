<div class="app-shell">
    <app-nav-menu></app-nav-menu>
    <div class="app-content-shell">
        <router-outlet></router-outlet>
    </div>
    <div class="loading-bar-dots loading-data">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
    </div>
    <div class="footer">
        <span>&copy; Copyright BDO USA, P.C., a Virginia Professional Service Corporation {{ currentYear }}. All Rights Reserved.</span>
    </div>
</div>
