import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgIdleModule } from '@ng-idle/core';
import { MatDialogModule } from '@angular/material/dialog';
import { ClipboardModule } from 'ngx-clipboard';

import { AccessDeniedComponent } from './components/shared/access-denied/access-denied.component';
import { AppComponent } from './app.component';
import { ConfirmationDialogComponent } from './components/shared/confirmation-dialog/confirmation-dialog.component';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { SessionTimeoutComponent } from './components/session-timeout/session-timeout.component';
import { SignoutComponent } from './components/signout/signout-component';

import { UnitOfWorkService } from './services/unit-of-work/unit-of-work.service';

import { BreezeBridgeHttpClientModule } from 'breeze-bridge2-angular';
import { ModalModule } from 'ngx-bootstrap';
import { ToastrModule } from 'ngx-toastr';

import { RequireAuthenticatedUserGuard } from './guards/auth/require-authenticated-user.guard';

import { ApplicationInsightsService } from './services/application-insights/application-insights.service';
import { BridgeService } from './services/bridge/bridge.service';
import { EntityManagerProviderService } from './services/entity-manager-provider/entity-manager-provider.service';
import { IsBusyService } from './services/is-busy/is-busy.service';
import { ServerSideConfigDataService } from './services/server-side-config-data/server-side-config-data.service';
import { GlobalErrorHandler } from './services/global-error-handler';

import { NoLoginFoundComponent } from './components/error/not-active.component';
import { MyInspectionsComponent } from './components/home/data-grids/home.component';
import { MyInspectionComponent } from './components/home/forms/inspection.component';
import { InspectionViewerTreeNode } from './components/home/forms/inspection-tree-node-viewer.component';


import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatButtonModule } from '@angular/material/button';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { TeamInspectionsComponent } from './components/team-inspections/data-grids/team-inspections.component';
import { TeamCaptainInspectionComponent } from './components/team-inspections/forms/inspection.component';
import { EngagementViewerTreeNode } from './components/team-inspections/forms/engagement-tree-node-viewer.component';

import { EngagementsComponent } from './components/engagement-management/data-grids/engagements.component';
import { EngagementAdminComponent } from './components/engagement-management/forms/engagement-editor.component';
import { EngagementSectionViewTreeNode } from './components/engagement-management/tree-viewers/engagement-tree-node-viewer.component';
import { ProfileSectionViewTreeNode } from './components/engagement-management/tree-viewers/tree-node-viewer.component';

import { InspectionProfilesComponent } from './components/profile-management/data-grids/inspection-profiles.component';
import { InspectionProfileAdminComponent } from './components/profile-management/forms/inspection-profile-editor.component';

import { ProfileSectionsComponent } from './components/profile-management/data-grids/profile-sections.component';

import { ProfileQuestionsComponent } from './components/profile-management/data-grids/profile-questions.component';
import { ProfileQuestionAdminComponent } from './components/profile-management/forms/question-editor.component';

import { AptCawsAdminComponent } from './components/admin/apt-caws.component';
import { BusinessLinesAdminComponent } from './components/admin/business-lines.component';
import { ClientsAdminComponent } from './components/admin/clients.component';
import { IndustriesAdminComponent } from './components/admin/industries.component';
import { InspectionTeamRolesAdminComponent } from './components/admin/inspection-team-roles.component';
import { OfficesAdminComponent } from './components/admin/offices.component';
import { ProjectsAdminComponent } from './components/admin/projects.component';
import { StandardQuestionOptionsAdminComponent } from './components/admin/standard-question-options.component';
import { TagsAdminComponent } from './components/admin/tags.component';
import { ImpersonateAdminComponent } from './components/admin/impersonate.component';
import { SecurityRolesAdminComponent } from './components/admin/security-roles.component';
import { SpecialtyReviewerTypesAdminComponent } from './components/admin/specialty-reviewer-types.component';
import { AdminNavMenu } from './components/admin/admin-nav.component';

import { ExportQuestionsAndResponsesComponent } from './components/reports/export-questions-and-responses.component';
import { ReportsNavMenu } from './components/reports/reports-nav.component';

import { ProfileSectionTreeNode } from './components/profile-management/forms/tree-node-editor.component';
import { ProfileNavMenu } from './components/profile-management/profile-nav.component';

import { SectionListItem } from './components/shared/section-list-viewer.component';
import { GridPager } from './components/shared/grid-pager.component';
import { HomeGridPager } from './components/home/home-pager.component';
import { DialogHelper } from './services/dialog-helper';
import { NgSelectModule } from '@ng-select/ng-select';
import { InspectionDragdropComponent } from './components/profile-management/forms/inspection-dragdrop/inspection-dragdrop.component';
import Config from './services/config';
import { EmployeeSearchComponent } from './components/shared/employee-search/employee-search.component';

import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { AppRoutingModule } from './app-routing.module';



export function init(): () => Promise<any> {
    return (): Promise<any> => {
        return new Promise((resolve, reject) => {
            Config.init();
            resolve(null);
        });
    };
}

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

const environment = {
    production: false,
    clientId: '72969031-1fef-4778-a15f-9c3689cb1c05',
    authority: 'https://login.microsoftonline.com/6e57fc1a-413e-4050-91da-7d2dc8543e3c/',
    redirectUrl: `https://${document.location.host}/`,
    logoutRedirectUri: `https://${document.location.host}/signout`
}

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: environment.clientId,
            authority: environment.authority,
            redirectUri: environment.redirectUrl,
            postLogoutRedirectUri: environment.logoutRedirectUri
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: isIE, // set to true for IE 11
        }
    });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
    protectedResourceMap.set(`https://${document.location.host}/`, [`${environment.clientId}/user_impersonation`])

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return { interactionType: InteractionType.Redirect };
}

@NgModule({
    declarations: [
        AppComponent,
        NavMenuComponent,
        MyInspectionsComponent,
        MyInspectionComponent,
        InspectionViewerTreeNode,
        SectionListItem,
        SessionTimeoutComponent,
        SignoutComponent,
        AccessDeniedComponent,
        NoLoginFoundComponent,
        TeamInspectionsComponent,
        TeamCaptainInspectionComponent,
        EngagementViewerTreeNode,
        EngagementsComponent,
        EngagementAdminComponent,
        EngagementSectionViewTreeNode,
        InspectionProfilesComponent,
        InspectionProfileAdminComponent,
        ProfileSectionsComponent,
        ProfileQuestionsComponent,
        ProfileQuestionAdminComponent,
        AptCawsAdminComponent,
        BusinessLinesAdminComponent,
        ClientsAdminComponent,
        IndustriesAdminComponent,
        InspectionTeamRolesAdminComponent,
        OfficesAdminComponent,
        ProjectsAdminComponent,
        StandardQuestionOptionsAdminComponent,
        SpecialtyReviewerTypesAdminComponent,
        TagsAdminComponent,
        ImpersonateAdminComponent,
        SecurityRolesAdminComponent,
        ProfileSectionTreeNode,
        ProfileSectionViewTreeNode,
        ProfileNavMenu,
        AdminNavMenu,
        GridPager,
        HomeGridPager,
        InspectionDragdropComponent,
        ConfirmationDialogComponent,
        EmployeeSearchComponent,
        ExportQuestionsAndResponsesComponent,
        ReportsNavMenu

    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        HttpClientModule,
        FormsModule,
        AppRoutingModule,
        BreezeBridgeHttpClientModule,
        BrowserAnimationsModule,
        MatDialogModule,
        ModalModule.forRoot(),
        MatButtonModule,
        NgbModule,
        NgSelectModule,
        DragDropModule,
        ClipboardModule,
        NgIdleModule.forRoot(),
        ToastrModule.forRoot({
            timeOut: Config.toastrTimeoutMs,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
            countDuplicates: true
        }),
        MsalModule
    ],
    entryComponents: [
        SessionTimeoutComponent,
        ConfirmationDialogComponent
    ],
    providers: [
        ApplicationInsightsService,
        BridgeService,
        EntityManagerProviderService,
        IsBusyService,
        ServerSideConfigDataService,
        UnitOfWorkService,
        GlobalErrorHandler,
        RequireAuthenticatedUserGuard,
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        {
            provide: APP_INITIALIZER,
            useFactory: init,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        DialogHelper,
        MsalService,
        MsalGuard,
        MsalBroadcastService
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
