import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { timer, Observable } from 'rxjs';

@Component({
    selector: 'app-session-timeout',
    templateUrl: './session-timeout.component.html',
    styleUrls: ['./session-timeout.component.less']
})
export class SessionTimeoutComponent {
    secondsLeft: number;
    countDown: Observable<number>;

    get timeLeft(): string {
        const minutes = Math.ceil(this.secondsLeft / 60);

        return minutes > 1 ? `${minutes} minutes` : `${this.secondsLeft} second${this.secondsLeft == 1 ? "" : "s"}`;
    }

    constructor(public dialogRef: MatDialogRef<SessionTimeoutComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.secondsLeft = data.timer;
        this.countDown = timer(0, 1000);
        this.countDown.subscribe(val => {
            this.secondsLeft -= 1;

            if (this.secondsLeft == 0) {
                this.dialogRef.close(false);
            }
        });
    }
}
