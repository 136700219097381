<div class="header-main-nav">
    <div class="header-shell">
        <div class="app-title">
            <span>{{appTitle}}</span>
        </div>
        <div class="toolbar">
            <div class="name" *ngIf="showMenu">
                <span class="user-name">{{name | async}}</span>
                <a class="logout" id="logout" (click)="signOut()">(Logout)</a>
            </div>
            <div class="help">
                <a class="fa fa-2x fa-fw fa-question user-guide" data-toggle="tooltip" data-placement="bottom" title="Link Me to a User Guide" target="_blank" href=""></a>
            </div>
        </div>
    </div>
    <div class="main-nav-shell">
        <nav class="navbar navbar-expand-sm navbar-light">
            <div class="navbar-brand">
                <div class="logo" [routerLink]='["/"]'>
                    <img src="images/BDO.svg" alt="BDO" />
                </div>
                <div class="country">
                    <span>United</span>
                    <br />
                    <span>States</span>
                </div>
            </div>

            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#mainNavBar" aria-controls="mainNavBar" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="mainNavBar">
                <ul class="navbar-nav mr-auto" *ngIf="isActive">
                    <li class="nav-item" [class.active]="isSelfOrParentRoute(route)" *ngFor="let route of routes">
                        <a class="nav-link" [routerLink]="route.path">
                            {{route.data.name}}
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</div>
